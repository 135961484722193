import React from 'react'
import './styles.scss'
import FilterDialog from '../filter'
import FilterItem from '../filterItem'

function GenericFilter(props) {
  const { visible, hideFilter, changeSelectorValue, filterProps, disagree, agree, filterObject } =
    props

  const handleChangeSelectorValue = (filter, filterBy) => changeSelectorValue(filter, filterBy)
  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        <>
          {filterObject?.map((values, index) => {
            return (
              <FilterItem
                key={index}
                filterBy={values.filterBy}
                changeSelectorValue={handleChangeSelectorValue}
                selectorItems={values.filterValue?.map((item, index) => ({
                  value: index,
                  name: item,
                }))}
                filterProps={filterProps}
              />
            )
          })}
        </>
      }
    />
  )
}

export default GenericFilter
