import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { createAssetAsset, updateAssetAsset } from '../../../../apis/asset/asset-asset-apis'
import { AssetAsset, AssetAssetDto } from '../../../../domain/asset'

interface Props {
  asset?: AssetAsset
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  name: string
  code: string
}

export const AssetAssetsAddEdit = ({ asset, onClose }: Props) => {
  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      name: asset?.name || '',
      code: asset?.code || '',
    },
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    const formData = watch()
    const dto: AssetAssetDto = {
      name: formData.name,
      code: formData.code,
    }

    setLoading(true)

    const source = asset ? updateAssetAsset(asset.id, dto) : createAssetAsset(dto)

    source
      .then(() => {
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', height: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">
              {!asset && <>Add Asset</>}
              {!!asset && <>Edit Asset</>}
            </Typography>

            <Box flexGrow={1}>
              <Stack spacing={2}>
                <Controller
                  name={'name'}
                  control={control}
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />

                <Controller
                  name={'code'}
                  control={control}
                  rules={{ required: 'Code is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Code"
                      error={!!errors.code}
                      helperText={errors.code?.message}
                    />
                  )}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
