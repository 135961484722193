import React, { useMemo } from 'react'
import Autocomplete from '@mui/lab/Autocomplete'
import TextField from '@mui/material/TextField/TextField'
import { useSelector } from 'react-redux'

export const NotificationsTargetApp = () => {
  const whiteLabels = useSelector((s: any) => s.cities?.whiteLabels as any[])

  const whileLabelOptions = useMemo(() => {
    return whiteLabels
      ? whiteLabels.map((wl: any) => {
          return {
            label: wl.name,
            id: wl.id || 0,
          }
        })
      : []
  }, [whiteLabels])

  return (
    <Autocomplete
      multiple
      options={whileLabelOptions}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label="Apps" placeholder="Select App" />}
    />
  )
}
