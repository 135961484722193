import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 700,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filter: {
    minWidth: 350,
  },
  table: {
    minWidth: 150,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}))
