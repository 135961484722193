/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage, useShowToast } from '../../hooks'
import Loader from '../../components/loader'
import { clearAppVersion, getAppVersionApi, saveAppVersionApi } from '../../actions/stations'
import { strings } from '../../I18n'

const SettingsContainer = () => {
  const dispatch = useDispatch()

  const showToast = useShowToast()

  const [whitelabel, setWhitelabel] = useLocalStorage('whitelabelName', 'sharebike')
  const [iosVersion, setIosVersion] = useState()
  const [androidVersion, setAndroidVersion] = useState()
  const [deeplinkBaseurl, setDeeplinkBaseurl] = useState('')

  const appVersion = useSelector((state) => state.stations.appVersion)
  const appVersionSuccess = useSelector((state) => state.stations.appVersionSuccess)
  const saveAppVersionSuccess = useSelector((state) => state.stations.saveAppVersionSuccess)

  useEffect(() => {
    setAndroidVersion(appVersion?.androidVersion)
    setIosVersion(appVersion?.iosVersion)
    setDeeplinkBaseurl(appVersion?.deeplinkUrl || '')
  }, [appVersion])
  useEffect(() => {
    refetchData()
  }, [saveAppVersionSuccess])

  const clearData = () => {
    setAndroidVersion('')
    setIosVersion('')
  }

  const refetchData = () => {
    dispatch(getAppVersionApi(whitelabel))
  }

  useEffect(() => {
    clearData()
    refetchData()
  }, [whitelabel])

  const saveInformation = () => {
    dispatch(
      saveAppVersionApi({
        code: whitelabel,
        androidVersion: androidVersion,
        iosVersion: iosVersion,
        deeplinkUrl: deeplinkBaseurl,
      })
    )
  }

  useEffect(() => {
    if (saveAppVersionSuccess === true) {
      dispatch(clearAppVersion())
      showToast('Success', 'success')
    }
  }, [saveAppVersionSuccess])

  if (appVersionSuccess === null) {
    return <Loader />
  }
  return (
    <div className="settings-container">
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel shrink>{strings('message.selectWhitelabel')}</InputLabel>
        <Select
          value={whitelabel}
          label={strings('message.selectWhitelabel')}
          onChange={(e) => setWhitelabel(e.target.value)}
        >
          <MenuItem value={'sharebike'}>Sharebike</MenuItem>
          <MenuItem value={'bigIssue'}>Bigissue</MenuItem>
          <MenuItem value={'moby'}>Moby</MenuItem>
          <MenuItem value={'esb'}>ESB</MenuItem>
          <MenuItem value={'farteBysykkel'}>Farte</MenuItem>
        </Select>
      </FormControl>
      <div style={{ marginTop: 20 }}>
        <table style={{ width: '100%' }}>
          <tr>
            <td>
              <p>{strings('descriptions.iosVersion')}</p>
            </td>
            <td>
              <TextField
                id="standard-name"
                label={strings('descriptions.code')}
                type="text"
                value={iosVersion}
                onChange={(event) => setIosVersion(event.target.value)}
                margin="normal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>{strings('descriptions.androidVersion')}</p>
            </td>
            <td>
              <TextField
                id="standard-name"
                label={strings('descriptions.code')}
                type="text"
                value={androidVersion}
                onChange={(event) => setAndroidVersion(event.target.value)}
                margin="normal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>{strings('descriptions.deeplinkBaseUrl')}</p>
            </td>
            <td>
              <TextField
                id="standard-name"
                label={strings('descriptions.url')}
                type="text"
                value={deeplinkBaseurl}
                onChange={(event) => setDeeplinkBaseurl(event.target.value)}
                margin="normal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <Button variant="contained" color="primary" onClick={saveInformation}>
                {strings('descriptions.save')}
              </Button>
            </td>
          </tr>
        </table>
      </div>

      {/*  divider */}
      {/*<div className="divider-gray" />*/}
    </div>
  )
}

export default SettingsContainer
