import React from 'react'
import './styles.scss'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'

function DropDownMenu(props) {
  const { current, menuItems, item } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div role="menu">
      <Button
        className={'buttonMenu'}
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {(current || '').replace(/_/g, ' ')}
      </Button>
      <Popper open={!!anchorEl} anchorEl={anchorEl} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <div>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={false} id="menu-list-grow">
                    {menuItems?.map((menuItem) => (
                      <MenuItem
                        key={menuItem.title}
                        onClick={() => {
                          handleClose()
                          menuItem.action(item)
                        }}
                      >
                        {menuItem.title.replace(/_/g, ' ')}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default DropDownMenu
