import { makeStyles } from '@mui/styles'

export const timeInputStyles = makeStyles(() => ({
  timeInput: {
    width: '10.5rem',
    marginBottom: '0.625rem',
    marginRight: '1rem',
  },
  leftTimeInput: {
    marginRight: '0.5rem',
  },
}))
