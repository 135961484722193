import React from 'react'
import Modal from '@mui/material/Modal'

import './styles.scss'
import { useStyles } from '../../../utils/styles'
import { strings } from '../../../I18n'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'

const ActionButton = withStyles({
  root: {
    fontSize: 14,
    textTransform: 'none',
  },
})(Button)
function ModalContainerWithButton(props) {
  const classes = useStyles()
  const { body, visible, closeAction, className = classes.modal, onSave, title } = props
  const [modalVisible, setModalVisible] = React.useState(false)

  const buttonContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onSave}
      >
        {strings('descriptions.save')}
      </ActionButton>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={closeAction}
      >
        {strings('descriptions.discard')}
      </ActionButton>
    </div>
  )

  React.useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  return (
    <Modal
      className={'modalContainer'}
      open={modalVisible}
      onClose={() => {
        closeAction()
        setModalVisible(false)
      }}
    >
      <div className={className}>
        <div className={'modalHeader'}>
          <div className={'modalTitle'}>{title}</div>
        </div>
        {body}
        {buttonContainer()}
      </div>
    </Modal>
  )
}

export default ModalContainerWithButton
