import React, { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Autocomplete, Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { createAssetProject, updateAssetProject } from '../../../../apis/asset/asset-project-apis'
import { AssetAsset, AssetProject, AssetProjectDto } from '../../../../domain/asset'
import { getAssetAssetsAvailable } from '../../../../apis/asset/asset-asset-apis'

interface Props {
  project?: AssetProject
  cityId: number
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  name: string
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  selectedAssets: AssetAsset[]
}

export const AssetProjectsAddEdit = ({ project, onClose, cityId }: Props) => {
  const minDateRef = useRef<moment.Moment>(moment().seconds(0).millisecond(0))
  const {
    formState: { errors, isValid },
    watch,
    register,
    setValue,
    trigger,
  } = useForm<FormData>({ mode: 'all', defaultValues: { selectedAssets: [] } })
  const [loading, setLoading] = useState(false)
  const [assets, setAssets] = useState<AssetAsset[]>([])
  const { startDate, endDate, selectedAssets, name } = watch()

  const setDates = (key: 'startDate' | 'endDate', value: moment.Moment | null) => {
    setValue(key, value)
    trigger(['startDate', 'endDate'])
  }

  const handleSubmit = () => {
    const dto: AssetProjectDto = {
      name,
      startDate: startDate?.toDate() || new Date(),
      endDate: endDate?.toDate() || new Date(),
      cityId,
      assetIds: selectedAssets.map((a) => a.id),
    }

    setLoading(true)
    const source = project ? updateAssetProject(project.id, dto) : createAssetProject(dto)
    source
      .then(() => {
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (startDate && endDate) {
      getAssetAssetsAvailable(startDate.toDate(), endDate.toDate()).then((a) => setAssets(a))
    } else {
      setAssets([])
    }
  }, [startDate, endDate])

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', height: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">
              {!project && <>Add Project</>}
              {!!project && <>Edit Project</>}
            </Typography>

            <Box flexGrow={1}>
              <Stack spacing={2}>
                <TextField
                  {...register('name', { required: 'Name is required' })}
                  fullWidth
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />

                <DateTimePicker
                  onChange={(value) => setDates('startDate', value)}
                  minDateTime={minDateRef.current}
                  label="Start date"
                />

                <DateTimePicker
                  onChange={(value) => setDates('endDate', value)}
                  minDateTime={watch().startDate || minDateRef.current}
                  label="End date"
                />

                <Autocomplete
                  multiple={true}
                  disabled={!startDate || !endDate}
                  value={selectedAssets}
                  onChange={(event, value) => setValue('selectedAssets', value)}
                  options={assets}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => <TextField {...params} label="Asset" />}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
