import React from 'react'
import './styles.scss'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import SortedTableItem from '../sortedTableItem'
import { StyledTableCell } from '../../../utils/styles'

function TableHeaderRow(props) {
  const { items, sortedIndexes = [] } = props

  const headerCell = (item, index) => {
    let sortedField = sortedIndexes.filter((sortedIndex) => sortedIndex.index === index)

    return sortedField.length > 0 ? (
      <SortedTableItem
        key={item}
        orderKey={sortedField[0].orderKey}
        title={item}
        orderProps={sortedField[0].orderProps}
      />
    ) : (
      <StyledTableCell key={item} align={'center'}>
        {item}
      </StyledTableCell>
    )
  }

  return (
    <TableHead>
      <TableRow>{items.map((item, index) => headerCell(item, index))}</TableRow>
    </TableHead>
  )
}

export default TableHeaderRow
