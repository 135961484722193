export enum PricingPlanDomainType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  CODE = 'CODE',
}

export interface PricingPlanDomain {
  id: number
  dateCreated: Date
  dateUpdated: Date
  type: PricingPlanDomainType
  planId: number
  email?: string
  phone?: string
}

export type PricingPlanDomainsCreateDto = {
  domainDtos: Array<Omit<PricingPlanDomain, 'id' | 'dateCreated' | 'dateUpdated'>>
}
