import React from "react";
import "./styles.scss";

function StatusIndicator(props) {
  const { status } = props;
  return (
    <div className={"indicatorContainer"}>
      <div className={"indicator"} />
      {status}
    </div>
  );
}

export default StatusIndicator;
