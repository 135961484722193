import { combineReducers } from 'redux'
import auth from './auth'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import screen from './screen'
import users from './users'
import stations from './stations'
import payment from './payment'
import vehicle from './vehicle'
import locks from './locks'
import docks from './docks'
import cities from './cities'
import rentals from './rentals'

const authPersistConfig = {
  key: 'auth',
  storage,
  timeout: null,
  blacklist: ['adminDetails'],
}

const citiesPersistConfig = {
  key: 'cities',
  storage,
  timeout: null,
  blacklist: ['downloadedCoupon', 'downloadCouponSuccess'],
}

const stationsPersistConfig = {
  key: 'stations',
  storage,
  timeout: null,
}

const usersPersistConfig = {
  key: 'users',
  storage,
  timeout: null,
  blacklist: ['internalUserCreateSuccess'],
}

const screenPersistConfig = {
  key: 'screen',
  storage,
  timeout: null,
  blacklist: [
    'order',
    'orderBy',
    'filterValue',
    'searchValue',
    'currentPageValue',
    'currentMenu',
    'passedParams',
  ],
}

const vehiclePersistConfig = {
  key: 'vehicle',
  storage,
  timeout: null,
  blacklist: [
    'getVehicleSuccess',
    'getBikeByIdSuccess',
    'getBikeStatusSuccess',
    'getLockStatusesSuccess',
    'getBrandsSuccess',
    'updateBikeSuccess',
    'bikes',
    'bike',
    'bikesResult',
    'brands',
    'lockStatuses',
    'hasMoreBikeHistory',
  ],
}

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  screen: persistReducer(screenPersistConfig, screen),
  users: persistReducer(usersPersistConfig, users),
  stations: persistReducer(stationsPersistConfig, stations),
  payment,
  vehicle: persistReducer(vehiclePersistConfig, vehicle),
  locks,
  docks,
  rentals,
  cities: persistReducer(citiesPersistConfig, cities),
})
