import { GET_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import * as endpoints from '../configs/endpoints'
import * as types from '../constants/actionTypes'

export const getStations = (page, search, selectedCity, sort, filter, size) => {
  //pretty preint all the params
  return {
    endpoint: endpoints.getStations(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [types.GET_STATIONS_REQUEST, types.GET_STATIONS_SUCCESS, types.GET_STATIONS_FAILURE],
  }
}

export const getStationsOnMap = (
  typeFilter,
  stationType,
  stationState,
  bikeState,
  city,
  brokenStatuses,
  search
) => {
  return {
    endpoint: endpoints.getMapStations(
      typeFilter,
      stationType,
      stationState,
      bikeState,
      city,
      brokenStatuses,
      search
    ),
    method: GET_METHOD,
    types: [
      types.GET_STATIONS_ON_MAP_REQUEST,
      types.GET_STATIONS_ON_MAP_SUCCESS,
      types.GET_STATIONS_ON_MAP_FAILURE,
    ],
  }
}

export const getStationsOnMapV2 = (paramsStr) => {
  return {
    endpoint: endpoints.getMapStationsV2(paramsStr),
    method: GET_METHOD,
    types: [
      types.GET_STATIONS_ON_MAP_REQUEST,
      types.GET_STATIONS_ON_MAP_SUCCESS,
      types.GET_STATIONS_ON_MAP_FAILURE,
    ],
  }
}

export const getStationById = (id) => {
  return {
    endpoint: endpoints.stationById(id),
    method: GET_METHOD,
    types: [
      types.GET_STATION_BY_ID_REQUEST,
      types.GET_STATION_BY_ID_SUCCESS,
      types.GET_STATION_BY_ID_FAILURE,
    ],
  }
}

export const updateStation = (id, body) => {
  return {
    endpoint: endpoints.stationById(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_STATION_BY_ID_REQUEST,
      types.UPDATE_STATION_BY_ID_SUCCESS,
      types.UPDATE_STATION_BY_ID_FAILURE,
    ],
    body,
  }
}

export const createStation = (body) => {
  return {
    endpoint: endpoints.createStation(),
    method: POST_METHOD,
    types: [
      types.CREATE_STATION_REQUEST,
      types.CREATE_STATION_SUCCESS,
      types.CREATE_STATION_FAILURE,
    ],
    body,
  }
}

export const unlockStation = (id) => {
  return {
    endpoint: endpoints.unlockStation(id),
    method: POST_METHOD,
    types: [
      types.UNLOCK_STATION_REQUEST,
      types.UNLOCK_STATION_SUCCESS,
      types.UNLOCK_STATION_FAILURE,
    ],
  }
}

export const getVehicleStatuses = () => {
  return {
    endpoint: endpoints.stationVehicleStatuses(),
    method: GET_METHOD,
    types: [
      types.GET_VEHICLE_STATUSES_REQUEST,
      types.GET_VEHICLE_STATUSES_SUCCESS,
      types.GET_VEHICLE_STATUSES_FAILURE,
    ],
  }
}

export const getStationsStatuses = () => {
  return {
    endpoint: endpoints.stationStatuses(),
    method: GET_METHOD,
    types: [
      types.GET_STATIONS_STATUSES_REQUEST,
      types.GET_STATIONS_STATUSES_SUCCESS,
      types.GET_STATIONS_STATUSES_FAILURE,
    ],
  }
}

export const clearStationData = () => {
  return {
    type: types.CLEAR_STATIONS_DATA,
  }
}

export const clearStation = () => {
  return {
    type: types.CLEAR_STATION,
  }
}

export const getStationsByCity = (id = null) => {
  return {
    endpoint: endpoints.stationsListByCity(id),
    method: GET_METHOD,
    types: [
      types.GET_STATIONS_BY_CITY_REQUEST,
      types.GET_STATIONS_BY_CITY_SUCCESS,
      types.GET_STATIONS_BY_CITY_FAILURE,
    ],
  }
}

export const getLinkedStationsByCity = (id = null) => {
  return {
    endpoint: endpoints.stationsListByCity(id, true),
    method: GET_METHOD,
    types: [
      types.GET_LINKED_STATIONS_REQUEST,
      types.GET_LINKED_STATIONS_SUCCESS,
      types.GET_LINKED_STATIONS_FAILURE,
    ],
  }
}

export const refreshStation = (id) => {
  return {
    endpoint: endpoints.refreshStation(id),
    method: POST_METHOD,
    types: [
      types.REFRESH_STATION_REQUEST,
      types.REFRESH_STATION_SUCCESS,
      types.REFRESH_STATION_FAILURE,
    ],
  }
}

export const reloginStation = (id) => {
  return {
    endpoint: endpoints.reloginStation(id),
    method: POST_METHOD,
    types: [
      types.RELOGIN_STATION_REQUEST,
      types.RELOGIN_STATION_SUCCESS,
      types.RELOGIN_STATION_FAILURE,
    ],
  }
}

export const getAppVersionApi = (whitelabelCode) => {
  return {
    endpoint: endpoints.getAppVersion(whitelabelCode),
    method: GET_METHOD,
    types: [
      types.APP_VERSION_GET_REQUEST,
      types.APP_VERSION_GET_SUCCESS,
      types.APP_VERSION_GET_FAILURE,
    ],
  }
}

export const getALlVirtualStationsAction = (cityId) => {
  return {
    endpoint: endpoints.getAllVirtualStationsApi(cityId),
    method: GET_METHOD,
    types: [
      types.GET_ALL_VIRTUAL_STATION_REQUEST,
      types.GET_ALL_VIRTUAL_STATION_SUCCESS,
      types.GET_ALL_VIRTUAL_STATION_FAILURE,
    ],
  }
}

export const saveAppVersionApi = (body) => {
  return {
    endpoint: endpoints.saveAppVersion,
    method: POST_METHOD,
    types: [
      types.SAVE_APP_VERSION_REQUEST,
      types.SAVE_APP_VERSION_SUCCESS,
      types.SAVE_APP_VERSION_FAILURE,
    ],
    body,
  }
}

export const getSpeedLimitZones = (page, search, selectedCity, sort, filter, size) => {
  return {
    endpoint: endpoints.getSpeedLimitZones(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [
      types.GET_SPEED_LIMIT_ZONES_REQUEST,
      types.GET_SPEED_LIMIT_ZONES_SUCCESS,
      types.GET_SPEED_LIMIT_ZONES_FAILURE,
    ],
  }
}

export const clearAppVersion = () => {
  return {
    type: types.CLEAR_APP_VERSION,
  }
}
export const saveLimitZones = (body) => {
  return {
    endpoint: endpoints.createLimitZones,
    method: POST_METHOD,
    types: [
      types.CREATE_LIMIT_ZONES_REQUEST,
      types.CREATE_LIMIT_ZONES_SUCCESS,
      types.CREATE_LIMIT_ZONES_FAILURE,
    ],
    body,
  }
}
export const updateLimitZones = (id, body) => {
  return {
    endpoint: endpoints.updateLimitZones(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_LIMIT_ZONES_REQUEST,
      types.UPDATE_LIMIT_ZONES_SUCCESS,
      types.UPDATE_LIMIT_ZONES_FAILURE,
    ],
    body,
  }
}

export const getLimitZonesById = (id) => {
  return {
    endpoint: endpoints.getLimitZonesById(id),
    method: GET_METHOD,
    types: [
      types.GET_LIMIT_ZONE_BY_ID_REQUEST,
      types.GET_LIMIT_ZONE_BY_ID_SUCCESS,
      types.GET_LIMIT_ZONE_BY_ID_FAILURE,
    ],
  }
}
