import React from 'react'
import './styles.scss'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { strings } from '../../../I18n'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'

function FilterDialog(props) {
  const { visible, hideDialog, body, disagree, agree } = props
  return (
    <Dialog fullWidth={true} onClose={hideDialog} open={visible}>
      <DialogTitle>{strings('message.filterSettings')}</DialogTitle>
      {body}
      <DialogActions>
        <Button onClick={disagree} color="primary">
          {strings('buttons.disagree')}
        </Button>
        <Button onClick={agree} color="primary" autoFocus>
          {strings('buttons.agree')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterDialog
