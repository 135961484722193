import * as types from "../constants/actionTypes";

export const setPageSize = (value) => {
  return {
    type: types.SET_PAGE_SIZE,
    value,
  }
};

export const setSearchValue = (value) => {
  return {
    type: types.SET_SEARCH_VALUE,
    value,
  }
};

export const setFilterValue = (value) => {
  return {
    type: types.SET_FILTER_VALUE,
    value,
  }
};

export const setCurrentPageValue = (value) => {
  return {
    type: types.SET_CURRENT_PAGE_VALUE,
    value,
  }
};

export const setOrder = (value) => {
  return {
    type: types.SET_ORDER,
    value,
  }
};

export const setOrderBy = (value) => {
  return {
    type: types.SET_ORDER_BY,
    value,
  }
};

export const setMapTypeFilter = (value) => {
  return {
    type: types.SET_MAP_TYPE_FILTER,
    value,
  }
};

export const setMapBikeStateFilter = (value) => {
  return {
    type: types.SET_MAP_BIKE_STATE_FILTER,
    value,
  }
};

export const setMapStationTypeFilter = (value) => {
  return {
    type: types.SET_MAP_STATION_TYPE_FILTER,
    value,
  }
};

export const setMapStationStateFilter = (value) => {
  return {
    type: types.SET_MAP_STATION_STATE_FILTER,
    value,
  }
};

export const setPassedParams = (value) => {
  return {
    type: types.SET_PASSED_PARAMS,
    value,
  }
};

export const emptyPassedParams = () => {
  return {
    type: types.EMPTY_PASSED_PARAMS,
  }
};
