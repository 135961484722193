import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTimePicker } from '@material-ui/pickers'
import { FormControl } from '@mui/material'
import TextField from '@mui/material/TextField'

const DateTimePickerController = ({
  name,
  errors,
  required,
  label,
  disablePast = false,
  extraRules,
  control,
  disableMinutes,
  styles,
}) => {
  const scontrol = useFormContext()
  const mcontrol = control || scontrol
  return (
    <FormControl style={{ minWidth: 426, ...styles }}>
      <Controller
        name={name}
        control={mcontrol}
        rules={{
          required: {
            value: !!required,
            message: `${name} is required.`,
          },
          ...extraRules,
        }}
        render={({ field: { ref, ...rest } }) => (
          <DateTimePicker
            renderInput={(props) => <TextField variant="outlined" {...props} />}
            disablePast={disablePast}
            margin="normal"
            id="date-picker-dialog"
            inputVariant="outlined"
            label={label}
            initialFocusedDate={new Date(new Date().setMinutes(0))}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minutesStep={disableMinutes ? 60 : 1}
            {...rest}
          />
        )}
      />
      <p style={{ color: 'red' }}>{errors[name]?.message}</p>
    </FormControl>
  )
}

export default DateTimePickerController
