/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import './styles.scss'
import { useStyles } from '../../utils/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearCreateCoupon,
  clearDeleteCoupon,
  createCouponApi,
  deleteSingleCouponById,
  downloadCouponWithPrefix,
  editSingleCouponById,
  getAllCouponList,
  getCityWhitelabelMappings,
  getSingleCouponById,
  getWhiteLabel,
} from '../../actions/cities'
import { useCity, useShowToast } from '../../hooks'
import Moment from 'moment'
import moment from 'moment'
import Swal from 'sweetalert2'
import useCustomTable from '../../components/table/CustomTable'
import CouponModal from '../../components/modal/CouponModal'
import { reformatFilter, reformatSearch, reformatSort } from '../../utils/utils'
import { strings } from '../../I18n'
import Tag from '../../components/Tags'
import { TAG_COLOR } from '../../constants/const'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CouponDownloadModal from '../../components/modal/couponDownloadModal'

const CouponsScreen = () => {
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const showToast = useShowToast()
  const dispatch = useDispatch()
  const couponList = useSelector((state) => state.cities.couponList)
  const couponListSuccess = useSelector((state) => state.cities.couponListSuccess)
  const createCoupon = useSelector((state) => state.cities.createCoupon)
  const createCouponSuccess = useSelector((state) => state.cities.createCouponSuccess)
  const couponById = useSelector((state) => state.cities.couponById)
  const couponByIdSuccess = useSelector((state) => state.cities.couponByIdSuccess)
  const updateCoupon = useSelector((state) => state.cities.updateCoupon)
  const updateCouponSuccess = useSelector((state) => state.cities.updateCouponSuccess)
  const deleteCouponSuccess = useSelector((state) => state.cities.deleteCouponSuccess)

  const navigate = useNavigate()

  const { selectedCity } = useCity()

  useEffect(() => {
    if (deleteCouponSuccess) {
      dispatch(clearDeleteCoupon())
      reloadData()
      showToast('Coupon Deleted', 'success')
    }
  }, [deleteCouponSuccess])

  useEffect(() => {
    dispatch(getWhiteLabel())
    dispatch(getCityWhitelabelMappings())
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflow: 'scroll',
    maxHeight: '80%',
  }

  const modalStyle = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  }

  const classes = useStyles()

  const periodOptions = [
    {
      label: 'Per ride',
      value: '1',
    },
    {
      label: 'Per day',
      value: '2',
    },
    {
      label: 'Per month',
      value: '3',
    },
  ]

  const columns = useMemo(
    () => [
      {
        name: strings('coupon.couponCode'),
        selector: 'code',
        center: true,
      },
      {
        id: 'sortable',
        name: strings('coupon.description'),
        selector: 'description',
        center: true,
      },
      {
        id: 'sortable1',
        name: strings('coupon.cityId'),
        selector: 'cityId',
        sortable: true,
        center: true,
      },
      {
        id: 'sortable2',
        name: strings('coupon.validFrom'),
        selector: 'validFrom',
        sortable: true,
        cell: (row, index, column, id) => (row?.validFrom ? dateFormat(row?.validFrom) : ''),
        center: true,
      },
      {
        id: 'sortable3',
        name: strings('coupon.expireAt'),
        selector: 'expiredAT',
        sortable: true,
        cell: (row, index, column, id) => (row?.expiredAT ? dateFormat(row?.expiredAT) : ''),
        center: true,
      },
      {
        id: 'sortable4',
        name: strings('coupon.freeRideDuration'),
        selector: 'durationOfFreeRide',
        sortable: true,
        center: true,
        minWidth: '180px',
        cell: (row) => (row?.durationOfFreeRide ? `${row?.durationOfFreeRide} min` : ''),
      },
      {
        id: 'sortable4',
        name: strings('coupon.ridePass'),
        selector: 'ridePass',
        // sortable: true,
        center: true,
        minWidth: '180px',
        cell: (row) => {
          if (row?.ridePass) {
            return (
              <Tag
                text={'Ride Pass'}
                colorTag={row?.ridePass ? TAG_COLOR.MAGENTA : TAG_COLOR.RED}
              />
            )
          } else {
            return null
          }
        },
      },
      {
        id: 'sortable4',
        name: strings('coupon.amount'),
        selector: 'amount',
        // sortable: true,
        center: true,
        minWidth: '180px',
        cell: (row) => row.amount,
      },
      {
        id: 'sortable4',
        name: strings('coupon.ridePassValidity'),
        selector: 'validity',
        // sortable: true,
        center: true,
        minWidth: '180px',
        cell: (row) => (row?.validity ? +`${row?.validity} hours` : ''),
      },
      {
        id: 'sortable5',
        name: strings('coupon.freeRideNumber'),
        selector: 'numberOfOccurrences',
        allowOverflow: true,
        sortable: true,
        center: true,
      },
      {
        id: 'sortable6',
        name: strings('coupon.status'),
        selector: 'status',
        sortable: true,
        center: true,
        cell: (row) => {
          return (
            <Tag
              text={row.status}
              colorTag={row.status === 'ACTIVE' ? TAG_COLOR.GREEN : TAG_COLOR.RED}
            />
          )
        },
      },
      {
        name: strings('coupon.singleCoupon'),
        selector: 'numberOfOccurrences',
        cell: (row, index, column, id) => (row?.forSingleUser ? 'Yes' : 'No'),
        center: true,
      },
      {
        name: strings('coupon.action'),
        selector: 'numberOfOccurrences',
        minWidth: '400px',
        center: true,
        cell: (row, index, column, id) => {
          return (
            <>
              <Tooltip
                title={strings('viewCoupon')}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    dispatch(getSingleCouponById(row.id))
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete coupon" placement="top" classes={{ tooltip: classes.tooltip }}>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    Swal.fire({
                      title: strings('message.removeItem'),
                      text: strings('message.noRevert'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: strings('message.yesDelete'),
                      cancelButtonText: strings('message.cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                        dispatch(deleteSingleCouponById(row?.id))
                      }
                    })
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>

              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  navigate('/dashboard/coupon-user/' + row.id)
                }}
                startIcon={<VisibilityIcon />}
              >
                Show usage
              </Button>
            </>
          )
        },
      },
    ],
    []
  )

  const addButtonClick = () => {
    setAddModalVisible(true)
    setIsEdit(false)
  }

  let filterObject = [
    {
      filterBy: 'status',
      filterValue: ['EXPIRED', 'ACTIVE'],
    },
    {
      filterBy: 'type',
      filterValue: ['ONE_TIME', 'RECURRENT', 'DYNAMIC'],
    },
    {
      filterBy: 'ridePass',
      filterValue: ['true', 'false'],
    },
  ]

  const onDownloadClick = () => {
    setDownloadModalVisible(true)
  }
  const closeCouponModal = () => {
    setDownloadModalVisible(false)
  }
  const onSubmitCouponDownload = (data) => {
    dispatch(downloadCouponWithPrefix(data?.prefix))
  }

  const reloadData = () => {
    setLoading(true)
    dispatch(
      getAllCouponList(
        localPage,
        reformatSearch(searchFilter, debouncedSearchTerm),
        selectedCity,
        reformatSort(sortData),
        reformatFilter(filterData),
        localRows
      )
    )
  }

  const searchDataOptions = [
    { value: 'code', label: 'code' },
    { value: 'description', label: 'description' },
  ]

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
    searchFilter,
  } = useCustomTable(
    columns,
    couponList,
    reloadData,
    addButtonClick,
    filterObject,
    'Coupons',
    true,
    true,
    onDownloadClick,
    searchDataOptions
  )

  useEffect(() => {
    if (couponListSuccess) {
      setLoading(false)
    }
  }, [couponListSuccess])

  useEffect(() => {
    resetPagination()
  }, [selectedCity, debouncedSearchTerm])

  useEffect(() => {
    reloadData()
  }, [localPage, debouncedSearchTerm, selectedCity, sortData, filterData, localRows, searchFilter])

  const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY HH:mm')

  function handleClose() {
    setAddModalVisible(false)
    setIsEdit(false)
  }

  const onSubmit = (data) => {
    if (isEdit) {
      let submittingData = {
        ...data,
        rewardApplyCount: 1,
        rewardPeriod: 1,
        freeRide: true,
        durationOfFreeRide: Math.abs(data.durationOfFreeRide),
        numberOfOccurrences: Math.abs(data.numberOfOccurrences),
        expiredAT:
          moment(data.expiredAT).set('hour', 23).set('minute', 59).format('YYYY-MM-DD') +
          'T23:59:06.070Z',
      }
      dispatch(editSingleCouponById(couponById.id, submittingData))
    } else {
      let submittingData = {
        ...data,
        rewardApplyCount: 1,
        durationOfFreeRide: Math.abs(data.durationOfFreeRide),
        numberOfOccurrences: Math.abs(data.numberOfOccurrences),
        whiteLabelId: data.whiteLabelId === 10 ? null : data.whiteLabelId,
        rewardPeriod: 1,
        freeRide: true,
        expiredAT:
          moment(data.expiredAT).set('hour', 23).set('minute', 59).format('YYYY-MM-DD') +
          'T23:59:06.070Z',
      }
      dispatch(createCouponApi(submittingData))
    }
  }

  useEffect(() => {
    if (createCouponSuccess === true) {
      showToast('Coupon Created', 'success')
      reloadData()
      dispatch(clearCreateCoupon())
      setAddModalVisible(false)
    }
  }, [createCouponSuccess])

  useEffect(() => {
    if (updateCouponSuccess === true) {
      showToast('Coupon Edited', 'success')
      reloadData()
      dispatch(clearCreateCoupon())
      setAddModalVisible(false)
    }
  }, [updateCouponSuccess])

  // if (couponListSuccess === null) {
  //   return <Loader />
  // }

  return (
    <>
      {/*<Paper className={classes.paper}>*/}
      {renderHeaderBar()}
      {renderCustomTable()}
      {/*</Paper>*/}

      <CouponModal
        onSubmit={onSubmit}
        addModalVisible={addModalVisible}
        handleClose={handleClose}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setAddModalVisible={setAddModalVisible}
      />

      <CouponDownloadModal
        onSubmit={onSubmitCouponDownload}
        downloadModalVisible={downloadModalVisible}
        handleClose={closeCouponModal}
        setDownloadModalVisible={setDownloadModalVisible}
      />
    </>
  )
}

export default CouponsScreen
