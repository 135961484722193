import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Autocomplete, Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { RentalRuleAction, RentalRuleCause, RentalRuleDto } from '../../domain/rental-rule'
import { createRentalRule } from '../../apis/rental/rental-rule-apis'
import { ACTION_OPTIONS, actionToOption, CAUSE_OPTIONS, causeToOption } from './rental-rules-domain'

interface Props {
  cityId: number
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  cause: RentalRuleCause
  action: RentalRuleAction
  waitingTimeInMinute: number
}

export const RentalRulesAddEdit = ({ onClose, cityId }: Props) => {
  const {
    formState: { errors, isValid },
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      cause: RentalRuleCause.PAUSE,
      action: RentalRuleAction.FINISH,
      waitingTimeInMinute: 60,
    },
  })
  const [loading, setLoading] = useState(false)
  const { cause, action, waitingTimeInMinute } = watch()

  const handleSubmit = () => {
    const dto: RentalRuleDto = {
      action,
      cause,
      cityId,
      waitingTimeInMs: waitingTimeInMinute * 60000,
      brands: [],
    }

    setLoading(true)
    createRentalRule(dto)
      .then(() => {
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', height: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">Add Rental Rule</Typography>

            <Box flexGrow={1}>
              <Stack spacing={2}>
                <Autocomplete
                  value={cause ? causeToOption(cause) : null}
                  onChange={(event, value) => setValue('cause', value?.id || RentalRuleCause.PAUSE)}
                  options={CAUSE_OPTIONS}
                  getOptionLabel={(o) => o.label}
                  renderInput={(params) => <TextField {...params} label="Cause" />}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Waiting time (in minutes)"
                  {...register('waitingTimeInMinute', {
                    required: 'Waiting time is required',
                    min: 0,
                  })}
                  error={!!errors.waitingTimeInMinute}
                  helperText={errors.waitingTimeInMinute?.message}
                />

                <Autocomplete
                  value={action ? actionToOption(action) : null}
                  onChange={(event, value) =>
                    setValue('action', value?.id || RentalRuleAction.FINISH)
                  }
                  options={ACTION_OPTIONS}
                  getOptionLabel={(o) => o.label}
                  renderInput={(params) => <TextField {...params} label="Action" />}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
