import { apiClient } from '../apiClient'
import { Rental } from '../../domain/rental'

export const getRentals = (cityId: number): Promise<Rental[]> => {
  return apiClient
    .get(`/journey-service/dashboard/journeys`, {
      params: {
        cityId,
      },
    })
    .then((r) => r.data.content)
}
