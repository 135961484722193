/* eslint-disable */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { connect, useSelector } from 'react-redux'
import Marker from './Marker'
import { FROM_TABLE, VIRTUAL_STATION } from '../../constants/const'
import { strings } from '../../I18n'
import {
  alarmBike,
  clearBike,
  clearBikeStatusData,
  getAllBrokenVehicleStatus,
  getVehicle,
  lockBattery,
  lockBike,
  unlockBattery,
  unlockBike,
} from '../../actions/vehicle'
import './styles.scss'
import { getStationsByCity, getStationsOnMapV2 } from '../../actions/stations'
import {
  clearRentalsData,
  finishRent,
  getFinallyDataOfRental,
  getRentals,
  rentalsDockList,
} from '../../actions/rentals'
import {
  setMapBikeStateFilter,
  setMapStationStateFilter,
  setMapStationTypeFilter,
  setMapTypeFilter,
  setPassedParams,
} from '../../actions/screen'
import useSupercluster from 'use-supercluster'
import { truckTrackingVehicleIds } from '../../constants/filterConst'
import InputDialog from '../../components/dialog/inputDialog'
import { generatePolygons, isBigissue } from '../../utils/utils'
import { useNavigate } from 'react-router'
import * as routes from '../../constants/routes'
import { MAP_KEY } from '../../configs/settings'
import { useCity, useShowToast } from '../../hooks'
import { isPointInPolygon } from 'geolib'

import CustomImageMarker from './CustomImageMarker'
import MapFilterAndSearch from './components/mapFilterAndSearch/MapFilterAndSearch'
import { useSearchParams } from 'react-router-dom'
import { convertFilterToSearch, convertSearchToFilter, filterParamsHasValue } from './utils'
import { DEFAULT_FILTER_PARAMS } from './domain'

const MapContainer = (props) => {
  const {
    unlockBike,
    alarmBike,
    lockBike,
    unlockBattery,
    lockBattery,
    mapData,
    savedCity,
    rentalsDockList,
    getFinallyDataOfRental,
    getStationsByCity,
    getFinallyRentalData,
    clearRentalsData,
    finishRent,
    finallyRentalData,
    typeFilter,
    stationTypeFilter,
    stationStateFilter,
    bikeStateFilter,
    setMapTypeFilter,
    setMapBikeStateFilter,
    setMapStationTypeFilter,
    setMapStationStateFilter,
    citiesList,
    setPassedParams,
    getVehicle,
    getRentals,
  } = props

  const whiteLabelCode = useSelector((state) => state.auth.whiteLabelCode)
  const [currentCenter, setCenter] = useState({
    lat: 59.912438985282066,
    lng: 10.74462890624996,
  })
  const [polygonsArray, setPolygonsArray] = useState([])
  const [currentZoom, setZoom] = useState(isBigissue(whiteLabelCode) ? 14 : 5)
  const [info, showInfo] = useState(null)
  const markerRef = useRef(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [map, setMapRef] = useState(null)
  const [bounds, setBounds] = useState([
    -81.73828125000004, 31.618451023612238, 103.22753906249994, 75.26498102307212,
  ])

  const [selectedStation, setSelectedStation] = useState(null)

  const [bikeHistory, setBikeHistory] = useState({
    bikeId: null,
    vehicleCode: null,
    bikeName: null,
  })
  const [search, setSearchValue] = useState('')

  const points = (mapData.vehicles || []).map((item) => ({
    ...item,
    properties: { cluster: false, crimeId: item.id },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(item?.longitude || 0), parseFloat(item?.latitude || 0)],
    },
  }))

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom: currentZoom,
    options: {
      radius: 75,
      maxZoom: 8,
      minPoints: 75,
    },
  })
  const showToast = useShowToast()

  const [finishRentDialogVisibility, setFinishRentDialogVisibility] = React.useState(false)

  const navigate = useNavigate()
  const { selectedCity, savedCityFullInfo } = useCity()

  const updateSaveCityInfo = () => {
    if (savedCityFullInfo?.longitude && savedCityFullInfo?.latitude) {
      const currentCoords = {
        lng: savedCityFullInfo?.longitude || 0,
        lat: savedCityFullInfo?.latitude || 0,
      }
      setZoom(8)
      setCenter(currentCoords)
    } else {
      const currentCoords = {
        lng: 10.74462890624996,
        lat: 59.912438985282066,
      }
      setZoom(1)
      setCenter(currentCoords)
    }
  }

  useEffect(() => {
    if (citiesList.length === 1) {
      const currentCoords = {
        lng: citiesList[0]?.longitude || 0,
        lat: citiesList[0]?.latitude || 0,
      }
      setTimeout(() => {
        setCenter(currentCoords)
        setZoom(14)
      }, 500)
    } else {
      updateSaveCityInfo()
    }
  }, [savedCityFullInfo, citiesList, map])

  const [filterObject, setFilterObject] = useState(null)
  const initializeCity = () => {
    props.getAllBrokenVehicleStatus()
    setTimeout(() => {
      updateSaveCityInfo()
    }, 600)
  }

  useEffect(() => {
    initializeCity()
  }, [])

  useEffect(() => {
    if (props.brokenBikeStatuses) {
      let customFilterObject = [
        {
          filterBy: 'status',
          filterValue: [...props.brokenBikeStatuses],
        },
      ]
      setFilterObject(customFilterObject)
    }
  }, [props.brokenBikeStatuses])

  useEffect(() => {
    document.addEventListener('contextmenu', () => {})
    return () => {
      props.clearBike()
      document.removeEventListener('mousedown', () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleApiLoaded = (map, maps) => {
    setMapsReference(maps)
    setMapRef(map)
    generatePolygons(mapData.stations || [], maps, polygonsArray, setPolygonsArray)
  }

  useEffect(() => {
    if (mapData.stations && mapsReference && map) {
      polygonsArray.forEach((item) => item.setMap(null))
      setPolygonsArray([])
      generatePolygons(mapData.stations || [], mapsReference, polygonsArray, setPolygonsArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData.stations])

  useEffect(() => {
    if (polygonsArray) {
      polygonsArray.forEach((item) => item.setMap(map))
      polygonsArray.forEach((item) =>
        mapsReference.event.addListener(item, 'click', (e) => {
          const { latLng } = e
          const lat = latLng.lat()
          const lng = latLng.lng()
          const virtualStation = mapData.stations
            .filter((item) => {
              return item.type === VIRTUAL_STATION
            })
            .map((item) => {
              return {
                ...item,
                convertedGeo: item.geometry.map((itemGeo) => ({
                  latitude: itemGeo[0],
                  longitude: itemGeo[1],
                })),
              }
            })
          if (virtualStation.length > 0) {
            for (let vStation of virtualStation) {
              if (isPointInPolygon({ latitude: lat, longitude: lng }, vStation.convertedGeo)) {
                // setSelectedStation(vStation)
                break
              }
            }
          }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonsArray])

  useEffect(() => {
    if (props.bikeStatusByIdSuccess) {
      showToast('Bike status updated successfully', 'success')
      props.clearBikeStatusData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bikeStatusByIdSuccess])

  const onChildClick = (value, type, from) => {
    if (type) {
      return
    }

    setCenter({
      lat: value.latitude,
      lng: value.longitude,
    })
    if (from === FROM_TABLE) {
      if (value?.type === VIRTUAL_STATION) {
        setZoom(20)
      } else {
        setZoom(20)
      }
    }

    // setSelectedStation(null)
    if (value?.type === VIRTUAL_STATION) {
      setSelectedStation(value)
    } else {
      setSelectedStation(null)
      showInfo(value)
    }
  }

  const onChange = ({ center, zoom, bounds, size }) => {
    if (center && zoom) {
      if (zoom !== currentZoom && !!info) {
        showInfo(null)
      }
      if (zoom !== currentZoom && !!selectedStation) {
        setSelectedStation(null)
      }
      setCenter(center)
      setZoom(zoom)
    }
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
  }

  const hideRentDialog = React.useCallback(() => {
    setFinishRentDialogVisibility(false)
    clearRentalsData()
  }, [setFinishRentDialogVisibility, clearRentalsData])

  const showRentDialog = React.useCallback(() => {
    setFinishRentDialogVisibility(true)
  }, [setFinishRentDialogVisibility])

  const finishRentAction = React.useCallback(
    (id, data) => {
      hideRentDialog()
      finishRent(id, data)
    },
    [hideRentDialog, finishRent]
  )

  const _onClick = (e = null) => {
    if (!!!e?.event?.target?.closest('.markerPopup')) {
      if (!!info) {
        showInfo(null)
      }
    }
    if (!!selectedStation) {
      setSelectedStation(null)
    }
  }

  useEffect(() => {
    if (getFinallyRentalData) {
      showRentDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFinallyRentalData])

  const endRent = (cityId, rentalId) => {
    getStationsByCity(cityId)
    rentalsDockList(cityId)
    getFinallyDataOfRental(rentalId)
  }

  if (mapsReference && markerRef.current) {
    mapsReference.OverlayView.preventMapHitsAndGesturesFrom(markerRef.current)
    markerRef.current.addEventListener('contextmenu', (e) => {
      e.stopPropagation()
    })
  }

  const mapBikes = useMemo(
    () =>
      clusters.map((vehicle) => {
        const [longitude, latitude] = vehicle.geometry.coordinates
        const { cluster: isCluster, point_count: pointCount } = vehicle.properties
        if (isCluster) {
          return (
            <div
              style={{
                zIndex: 1000,
              }}
              onClick={() => {
                setCenter({ lat: latitude, lng: longitude })
                setZoom(currentZoom + 1)
              }}
              className={'cluster'}
              lat={latitude || 0}
              lng={longitude || 0}
              key={`cluster-${vehicle.id}`}
            >
              {pointCount}
            </div>
          )
        } else {
          return (
            <Marker
              zIndex={1000}
              key={'vh' + vehicle.code}
              {...vehicle}
              lat={vehicle?.latitude || 0}
              lng={vehicle?.longitude || 0}
              onClick={() => onChildClick(vehicle)}
              isTruckTrackingBike={truckTrackingVehicleIds.includes(vehicle.code)}
              markerRef={markerRef}
              info={info}
              unlockBike={unlockBike}
              alarmBike={alarmBike}
              lockBike={lockBike}
              unlockBattery={unlockBattery}
              lockBattery={lockBattery}
              setBikeHistory={setBikeHistory}
              endRent={endRent}
              setPassedParams={setPassedParams}
              getVehicle={getVehicle}
              savedCity={savedCity}
              getRentals={getRentals}
              statuses={props.statuses}
              showInfo={showInfo}
              setSelectedStation={setSelectedStation}
            />
          )
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      info,
      markerRef,
      unlockBike,
      alarmBike,
      clusters,
      mapsReference,
      setPassedParams,
      props.statuses,
    ]
  )

  useEffect(() => {
    if (bikeHistory) {
      polygonsArray.forEach((item) => item.setMap(null))
      setPolygonsArray([])
      // setZoom(16)
      setCenter({ lat: currentCenter.lat - 0.0015, lng: currentCenter.lng })
    } else {
      if (mapsReference && map) {
        generatePolygons(mapData.stations || [], mapsReference, polygonsArray, setPolygonsArray)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikeHistory])

  const mapStations = useMemo(
    () =>
      (mapData?.stations || [])
        // .filter((item) => item?.type !== VIRTUAL_STATION)
        .map((station) => (
          <Marker
            key={'st' + station.id}
            {...station}
            lat={station?.latitude || 0}
            lng={station?.longitude || 0}
            onClick={() => onChildClick(station)}
            markerRef={markerRef}
            info={info}
            selectedStation={selectedStation}
            unlockBike={unlockBike}
            alarmBike={alarmBike}
            savedCity={savedCity}
            showInfo={showInfo}
            setSelectedStation={setSelectedStation}
          />
        )),
    [info, markerRef, unlockBike, alarmBike, mapData.stations, mapsReference, selectedStation]
  )

  const mapRenderVirtualStationsIcon = useMemo(() => {
    return mapData?.stations?.map((station) => {
      if (station.type === VIRTUAL_STATION) {
        return (
          <CustomImageMarker
            key={`cim-${station?.id}`}
            lat={station?.latitude || 0}
            lng={station?.longitude || 0}
            station={station}
            statuses={props.statuses}
          />
        )
      }
    })
  }, [markerRef, mapData.stations, mapsReference])

  const [searchParams, setSearchParams] = useSearchParams()
  const filterParams = useMemo(() => convertSearchToFilter(searchParams), [searchParams])

  useEffect(() => {
    if (!filterParamsHasValue(filterParams)) {
      setSearchParams(convertFilterToSearch(DEFAULT_FILTER_PARAMS))
    }
  }, [])

  useEffect(() => {
    if (filterParamsHasValue(filterParams)) {
      let paramsStr = searchParams.toLocaleString()

      if (savedCityFullInfo) {
        const obj = Object.fromEntries(searchParams)
        obj.cityId = savedCityFullInfo.id
        paramsStr = new URLSearchParams(obj).toLocaleString()
      }

      props.getStationsOnMapV2(decodeURIComponent(paramsStr))
      _onClick()
    }
  }, [searchParams, savedCityFullInfo])

  return (
    <>
      <div className="containerMapV2">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: MAP_KEY,
          }}
          yesIWantToUseGoogleMapApiInternals
          zoom={currentZoom}
          onClick={_onClick}
          onChange={onChange}
          center={currentCenter || { lat: 0, lng: 0 }}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {mapRenderVirtualStationsIcon}
          {mapStations}
          {mapBikes}
        </GoogleMapReact>
        <MapFilterAndSearch
          params={filterParams}
          onUpdateParams={(params) => setSearchParams(convertFilterToSearch(params))}
        />
      </div>
      <InputDialog
        title={strings('message.finishTrip')}
        visibility={finishRentDialogVisibility}
        hideDialog={hideRentDialog}
        positiveAction={finishRentAction}
        negativeAction={hideRentDialog}
        stationsList={props.stationsByCity}
        docksList={props.renMapContainertalDocks}
        rentalData={finallyRentalData}
      />
      {bikeHistory.bikeId &&
        bikeHistory.vehicleCode &&
        navigate(routes.BIKE_HISTORY, {
          state: {
            bikeId: bikeHistory.bikeId,
            vehicleCode: bikeHistory.vehicleCode,
            bikeName: bikeHistory.bikeName,
          },
        })}
    </>
  )
}

const mapStateToProps = ({
  stations: { mapData, getMapStationsSuccess, stationsByCity },
  cities: { savedCity, citiesList },
  screen: { typeFilter, stationTypeFilter, stationStateFilter, bikeStateFilter },
  rentals: { getFinallyRentalData, finallyRentalData, rentalDocks },
  vehicle: { statuses, bikeStatusByIdSuccess, brokenBikeStatuses },
}) => ({
  mapData,
  getMapStationsSuccess,
  statuses,
  savedCity,
  getFinallyRentalData,
  finallyRentalData,
  stationsByCity,
  rentalDocks,
  typeFilter,
  stationTypeFilter,
  stationStateFilter,
  bikeStateFilter,
  citiesList,
  bikeStatusByIdSuccess,
  brokenBikeStatuses,
})

const mapDispatchToProps = {
  unlockBike,
  alarmBike,
  lockBike,
  unlockBattery,
  lockBattery,
  getStationsOnMapV2,
  clearBike,
  getStationsByCity,
  rentalsDockList,
  getFinallyDataOfRental,
  clearRentalsData,
  finishRent,
  setMapTypeFilter,
  setMapBikeStateFilter,
  setMapStationTypeFilter,
  setMapStationStateFilter,
  setPassedParams,
  getVehicle,
  getRentals,
  clearBikeStatusData,
  getAllBrokenVehicleStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer)
