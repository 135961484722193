import React from 'react'
import './styles.scss'
import TextField from '@mui/material/TextField/TextField'
import { style } from '../../../utils/styles'
import { MenuItem } from '@mui/material'

function ModalText(props) {
  const {
    label,
    value,
    onChange,
    type = 'text',
    content,
    defaultValue,
    visible = true,
    styles = {},
    emptyValue = 'None',
    customStyle = null,
    multiline = false,
    withEmptyValue = true,
    disabled = false,
  } = props

  const dropProps = !!content ? { select: true } : null

  return visible ? (
    <TextField
      multiline={multiline}
      defaultValue={defaultValue}
      style={customStyle ? customStyle : style.modalText}
      disabled={!onChange || disabled}
      label={label}
      value={value}
      onChange={onChange}
      variant={'outlined'}
      type={type}
      InputProps={{
        style: styles,
      }}
      {...dropProps}
    >
      {!!content && withEmptyValue && <MenuItem value={null}>{emptyValue}</MenuItem>}
      {content}
    </TextField>
  ) : null
}

export default ModalText
