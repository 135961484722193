/* eslint-disable */
import React, { useEffect, useMemo } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { getCityWhitelabelMappings, getWhiteLabel } from '../../actions/cities'
import { useCity, useShowToast } from '../../hooks'
import Moment from 'moment'
import useCustomTable from '../../components/table/CustomTable'
import { reformatFilter, reformatSort, stringFromValue } from '../../utils/utils'
import Tag from '../../components/Tags'
import { TAG_COLOR } from '../../constants/const'
import {
  clearInternalUserData,
  createInternalUser,
  deleteInternalUser,
  getAllManagedUser,
  getInternalUser,
  updateInternalUser,
} from '../../actions/users'
import { couponUserList } from '../../actions/payment'
import { Link, useParams } from 'react-router-dom'

const CouponUserScreen = (props) => {
  const { id } = useParams()

  const showToast = useShowToast()
  const { selectedCity } = useCity()

  const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY hh:mm A')

  const columns = useMemo(
    () => [
      {
        name: 'Username',
        selector: 'userid',
        center: true,
        cell: (row) => (
          <Link
            to={`/dashboard/user/${row.userid}`}
            target="_blank"
            className={'bikeLink'}
            style={row.userid && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
          >
            {stringFromValue(row?.userid)}
          </Link>
        ),
      },
      {
        name: 'Coupon Code',
        selector: 'sortOrder',
        center: true,
        cell: (row) => row?.coupon?.code,
      },
      {
        name: 'Time Of Activation',
        selector: 'dateCreated',
        center: true,
        cell: (row) => dateFormat(row?.dateCreated),
      },
      {
        name: 'Expiry Date',
        selector: 'expiredAT',
        center: true,
        cell: (row) => dateFormat(row?.expiredAT),
      },
      {
        name: 'Usage (Rentals)',
        selector: 'sortOrder',
        center: true,
        cell: (row) => row?.numberOfOccurrencesLeft + ' /' + row?.coupon?.numberOfOccurrences,
      },

      {
        name: 'Status',
        selector: 'status',
        center: true,
        cell: (row) => {
          return (
            <Tag
              text={row.status}
              colorTag={row.status === 'ACTIVE' ? TAG_COLOR.GREEN : TAG_COLOR.RED}
            />
          )
        },
      },
    ],
    []
  )

  let filterObject = [
    {
      filterBy: 'status',
      filterValue: ['ACTIVE', 'INACTIVE'],
    },
  ]

  const reloadData = () => {
    setLoading(true)

    props.couponUserList(
      localPage,
      debouncedSearchTerm,
      selectedCity,
      reformatSort(sortData),
      reformatFilter(filterData),
      localRows,
      id
    )
  }

  function addButtonClick() {}

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(
    columns,
    props.couponUserListData,
    reloadData,
    addButtonClick,
    filterObject,
    'Coupons',
    false,
    false
  )

  useEffect(() => {
    if (props.couponUserListSuccess) {
      setLoading(false)
    }
  }, [props.couponUserListSuccess])

  useEffect(() => {
    resetPagination()
  }, [selectedCity, debouncedSearchTerm])

  useEffect(() => {
    reloadData()
  }, [localPage, debouncedSearchTerm, selectedCity, sortData, filterData, localRows])

  return (
    <>
      {/*<Paper className={classes.paper}>*/}
      {renderHeaderBar()}
      {renderCustomTable()}
      {/*</Paper>*/}
    </>
  )
}

const mapStateToProps = ({
  users: {
    managedUser,
    managedUserSuccess,
    internalUserUpdateSuccess,
    internalUserCreateSuccess,
    deleteInternalUserSuccess,
  },
  payment: { couponUserListData, couponUserListSuccess },
}) => ({
  managedUser,
  managedUserSuccess,
  internalUserUpdateSuccess,
  internalUserCreateSuccess,
  deleteInternalUserSuccess,
  couponUserListData,
  couponUserListSuccess,
})

const mapDispatchToProps = {
  getWhiteLabel,
  getCityWhitelabelMappings,
  getInternalUser,
  updateInternalUser,
  getAllManagedUser,
  createInternalUser,
  clearInternalUserData,
  deleteInternalUser,
  couponUserList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponUserScreen)
