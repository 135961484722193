import React from 'react'
import './styles.scss'
import ModalContainer from '../modal'
import { strings } from '../../../I18n'
import ModalText from '../modalTextField'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { useInput, useShowToast } from '../../../hooks'
import MenuItem from '@mui/material/MenuItem'

import { useStyles } from '../../../utils/styles'
import {
  ACTON_OMNI_BIKE,
  E_BIKE,
  HONGJI_BIKE,
  OMNI_BIKE,
  RIDEMOVI_BIKE,
} from '../../../constants/const'

function BikeModal(props) {
  const showToast = useShowToast()
  const {
    bike,
    clearBike,
    updateBike,
    visible,
    statuses = [],
    cityNameList,
    stations,
    getStations,
    linkedStations,
    getLinkedStations,
    bikeTypes,
  } = props
  const classes = useStyles()
  const [bikeId, setBikeId] = React.useState('')
  const name = useInput('')
  const city = useInput('')
  const latitude = useInput(0)
  const longitude = useInput(0)
  const status = useInput(null)
  const station = useInput('')
  const code = useInput('')
  const externalId = useInput('')
  const additionalCode = useInput('')
  const linkedStation = useInput(null)
  const battery = useInput(0)
  const type = useInput('')

  const initValue = () => {
    setBikeId(bike?.id)
    name.initValue(bike?.name)
    status.initValue(bike?.status)
    station.initValue(bike?.station?.id)
    city.initValue(bike?.city?.id)
    code.initValue(bike?.code)
    externalId.initValue(bike?.lock?.externalId)
    additionalCode.initValue(bike?.lock?.additionalCode)
    linkedStation.initValue(bike?.linkedStation?.id)
    battery.initValue(bike?.battery)
    type.initValue(bike?.type)
    if (!bike) {
      name.initValue('')
      status.initValue('')
      city.initValue('')
      station.initValue('')
      linkedStation.initValue('')
    } else {
      latitude.initValue(bike?.latitude)
      longitude.initValue(bike?.longitude)
    }
  }

  React.useEffect(() => {
    if (city?.value !== bike?.city?.id) {
      station.clearValue()
      linkedStation.clearValue()
    }
    if (!!city.value) {
      getLinkedStations(city?.value)
      getStations(city?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city.value])

  React.useEffect(() => {
    initValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bike])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const isFlexibleType = function (brand) {
    return (
      brand === OMNI_BIKE ||
      brand === ACTON_OMNI_BIKE ||
      brand === HONGJI_BIKE ||
      brand === RIDEMOVI_BIKE
    )
  }

  const isAdditionalIdsType = function (brand) {
    return brand === RIDEMOVI_BIKE
  }

  const save = () => {
    if (name.value === '') {
      showToast('fill name field', 'error')
      return
    } else if (latitude.value === '') {
      showToast('fill latitude field', 'error')
      return
    } else if (longitude.value === '') {
      showToast('fill longitude field', 'error')
      return
    } else if (status.value === '') {
      showToast('fill status field', 'error')
      return
    } else if (city.value === '') {
      showToast('fill bike field', 'error')
      return
    }
    const body = {
      name: name.value,
      status: status.value,
      stationId: station.value,
      linkedStationId: linkedStation.value,
      latitude: parseFloat(latitude.value),
      longitude: parseFloat(longitude.value),
      cityId: city.value,
      code: code.value,
      type: type.value,
      lockAdditionalCode: additionalCode.value || null,
    }
    if (bike?.type === E_BIKE || battery.value !== null) {
      body.battery = battery.value
    }
    if (isFlexibleType(bike?.lock?.brand)) {
      body.type = type.value
    }
    updateBike(bikeId, body)
  }

  const inputContainer = (
    <div>
      <ModalText label={strings('descriptions.id')} value={bikeId} />
      {isAdditionalIdsType(bike?.brand) ? (
        <ModalText label={strings('descriptions.externalId')} {...externalId} disabled={true} />
      ) : null}
      <ModalText
        label={strings('descriptions.code')}
        {...{ ...code, onChange: isFlexibleType(bike?.brand) ? code.onChange : null }}
      />
      <ModalText label={strings('descriptions.additionalCode')} {...additionalCode} />
      <ModalText label={strings('descriptions.name')} {...name} />
      <ModalText type={'number'} label={strings('descriptions.latitude')} {...latitude} />
      {bike?.type === E_BIKE ? (
        <ModalText type={'number'} label={strings('descriptions.battery') + ', %'} {...battery} />
      ) : null}
      <ModalText type={'number'} label={strings('descriptions.longitude')} {...longitude} />
    </div>
  )

  const buttonContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={save}
      >
        {strings('descriptions.save')}
      </ActionButton>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={clearBike}
      >
        {strings('descriptions.discard')}
      </ActionButton>
    </div>
  )

  const dropContent = (
    <div>
      {isFlexibleType(bike?.brand) ? (
        <ModalText
          defaultValue={bike?.type}
          label={strings('descriptions.type')}
          content={bikeTypes.map((item) => (
            <MenuItem key={item} value={item}>
              {item.replace(/_/g, ' ')}
            </MenuItem>
          ))}
          withEmptyValue={false}
          {...type}
        />
      ) : null}
      <ModalText
        defaultValue={bike?.status}
        label={strings('descriptions.status')}
        withEmptyValue={false}
        content={statuses.map((item) => (
          <MenuItem key={item} value={item}>
            {item.replace(/_/g, ' ')}
          </MenuItem>
        ))}
        {...status}
      />
      <ModalText
        defaultValue={bike?.city?.id}
        label={strings('descriptions.cityName')}
        content={cityNameList?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name.replace(/_/g, ' ')}
          </MenuItem>
        ))}
        {...city}
      />
      <ModalText
        defaultValue={bike?.station?.id}
        label={strings('descriptions.station')}
        content={stations?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name.replace(/_/g, ' ')}
          </MenuItem>
        ))}
        {...station}
      />
      <ModalText
        defaultValue={bike?.linkedStation?.id}
        label={strings('descriptions.linkedStation')}
        content={linkedStations?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name.replace(/_/g, ' ')}
          </MenuItem>
        ))}
        {...linkedStation}
      />
    </div>
  )

  return (
    <ModalContainer
      className={classes.modal}
      visible={visible}
      closeAction={() => {
        clearBike()
      }}
      body={
        <>
          <div className={'stationModalContainer'}>
            <div style={{ height: '80vh' }}>
              {inputContainer}
              {dropContent}
            </div>
          </div>
          {buttonContainer()}
        </>
      }
    />
  )
}

export default BikeModal
