import * as types from '../constants/actionTypes'

let initialState = {
  clientsResult: null,
  clients: [],
  totalPages: 0,
  currentPage: null,
  getClientsSuccess: null,
  removeUserSuccess: null,
  updateUserSuccess: null,
  user: null,
  getUserSuccess: null,
  getUserForNewPageSuccess: null,
  planStatuses: [],
  getPlanStatusesSuccess: null,
  planTypes: [],
  getPlanTypesSuccess: null,
  cancelSubscriptionSuccess: null,

  managedUser: null,
  managedUserSuccess: null,

  internalUserCreate: null,
  internalUserCreateSuccess: null,

  internalUser: null,
  internalUserSuccess: null,

  internalUserUpdate: null,
  internalUserUpdateSuccess: null,

  deleteInternalUser: null,
  deleteInternalUserSuccess: null,
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_REQUEST: {
      return {
        ...state,
        getClientsSuccess: undefined,
      }
    }
    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        clients: action.result.content,
        clientsResult: action.result,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        totalElements: action.result.totalElements,
        getClientsSuccess: true,
      }
    }
    case types.GET_USERS_FAILURE: {
      return {
        ...state,
        clients: [],
        getClientsSuccess: false,
      }
    }
    case types.GET_USER_REQUEST: {
      return {
        ...state,
        getUserSuccess: undefined,
      }
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.result,
        getUserSuccess: true,
      }
    }
    case types.GET_USER_FOR_NEW_PAGE_SUCCESS: {
      return {
        ...state,
        user: action.result,
        getUserForNewPageSuccess: true,
      }
    }
    case types.GET_USER_FAILURE: {
      return {
        ...state,
        user: null,
        getUserSuccess: false,
      }
    }
    case types.CLEAR_USER: {
      return {
        ...state,
        user: null,
        getUserSuccess: null,
        updateUserSuccess: null,
      }
    }
    case types.DELETE_USERS_REQUEST: {
      return {
        ...state,
        removeUserSuccess: null,
      }
    }
    case types.DELETE_USERS_SUCCESS: {
      return {
        ...state,
        removeUserSuccess: true,
      }
    }
    case types.DELETE_USERS_FAILURE: {
      return {
        ...state,
        removeUserSuccess: false,
      }
    }
    case types.UPDATE_USER_REQUEST: {
      return {
        ...state,
        updateUserSuccess: undefined,
      }
    }
    case types.UPDATE_USER_SUCCESS: {
      const index = state.clients.findIndex((item) => item.id === action.result.id)
      state.clients[index] = {
        ...state.clients[index],
        fullName: action.result.fullName,
      }
      return {
        ...state,
        updateUserSuccess: true,
        user: action.result,
      }
    }
    case types.UPDATE_USER_FAILURE: {
      return {
        ...state,
        updateUserSuccess: false,
      }
    }
    case types.GET_PLAN_STATUSES_REQUEST: {
      return {
        ...state,
        getPlanStatusesSuccess: undefined,
      }
    }
    case types.GET_PLAN_STATUSES_SUCCESS: {
      return {
        ...state,
        planStatuses: action.result,
        getPlanStatusesSuccess: true,
      }
    }
    case types.GET_PLAN_STATUSES_FAILURE: {
      return {
        ...state,
        getPlanStatusesSuccess: false,
      }
    }
    case types.GET_PLAN_TYPES_REQUEST: {
      return {
        ...state,
        getPlanTypesSuccess: undefined,
      }
    }
    case types.GET_PLAN_TYPES_SUCCESS: {
      return {
        ...state,
        planTypes: action.result,
        getPlanTypesSuccess: true,
      }
    }
    case types.GET_PLAN_TYPES_FAILURE: {
      return {
        ...state,
        getPlanTypesSuccess: false,
      }
    }
    case types.CLEAR_USER_DATA: {
      return {
        ...state,
        getPlanTypesSuccess: null,
        getPlanStatusesSuccess: null,
      }
    }
    case types.CANCEL_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        cancelSubscriptionSuccess: null,
      }
    }
    case types.CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        cancelSubscriptionSuccess: true,
      }
    }
    case types.CANCEL_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        cancelSubscriptionSuccess: false,
      }
    }

    case types.GET_MANAGED_USER_LIST_REQUEST: {
      return {
        ...state,
        managedUserSuccess: null,
      }
    }
    case types.GET_MANAGED_USER_LIST_SUCCESS: {
      return {
        ...state,
        managedUser: action.result,
        managedUserSuccess: true,
      }
    }
    case types.GET_MANAGED_USER_LIST_FAILURE: {
      return {
        ...state,
        managedUserSuccess: false,
      }
    }

    case types.POST_CREATE_INTERNAL_USER_REQUEST: {
      return {
        ...state,
        internalUserCreateSuccess: null,
      }
    }
    case types.POST_CREATE_INTERNAL_USER_SUCCESS: {
      return {
        ...state,
        internalUserCreate: action.result,
        internalUserCreateSuccess: true,
      }
    }
    case types.POST_CREATE_INTERNAL_USER_FAILURE: {
      return {
        ...state,
        internalUserCreateSuccess: false,
      }
    }

    case types.GET_INTERNAL_USER_REQUEST: {
      return {
        ...state,
        internalUserSuccess: null,
      }
    }
    case types.GET_INTERNAL_USER_SUCCESS: {
      return {
        ...state,
        internalUser: action.result,
        internalUserSuccess: true,
      }
    }
    case types.GET_INTERNAL_USER_FAILURE: {
      return {
        ...state,
        internalUserSuccess: false,
      }
    }

    case types.PUT_INTERNAL_USER_UPDATE_REQUEST: {
      return {
        ...state,
        internalUserUpdateSuccess: null,
      }
    }
    case types.PUT_INTERNAL_USER_UPDATE_SUCCESS: {
      return {
        ...state,
        internalUserUpdate: action.result,
        internalUserUpdateSuccess: true,
      }
    }
    case types.PUT_INTERNAL_USER_UPDATE_FAILURE: {
      return {
        ...state,
        internalUserUpdateSuccess: false,
      }
    }

    case types.DELETE_INTERNAL_USER_REQUEST: {
      return {
        ...state,
        deleteInternalUserSuccess: null,
      }
    }
    case types.DELETE_INTERNAL_USER_SUCCESS: {
      return {
        ...state,
        deleteInternalUser: action.result,
        deleteInternalUserSuccess: true,
      }
    }
    case types.DELETE_INTERNAL_USER_FAILURE: {
      return {
        ...state,
        deleteInternalUserSuccess: false,
      }
    }

    case types.CLEAR_INTERNAL_USER: {
      return {
        ...state,
        internalUserUpdateSuccess: null,
        internalUserSuccess: null,
        internalUserCreateSuccess: null,
        deleteInternalUserSuccess: null,
      }
    }

    default:
      return state
  }
}

export default users
