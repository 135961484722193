import { RentalRuleAction, RentalRuleCause } from '../../domain/rental-rule'

const causeToLabel = new Map<RentalRuleCause, string>()
causeToLabel.set(RentalRuleCause.PAUSE, 'When the rent is paused')
causeToLabel.set(RentalRuleCause.START, 'After the rent is started')
export const causeToOption = (id: RentalRuleCause) => ({ id, label: causeToLabel.get(id) || '' })
export const CAUSE_OPTIONS = Object.values(RentalRuleCause).map(causeToOption)

const actionToLabel = new Map<RentalRuleAction, string>()
actionToLabel.set(RentalRuleAction.FINISH, 'Finish the rental')
export const actionToOption = (id: RentalRuleAction) => ({ id, label: actionToLabel.get(id) || '' })
export const ACTION_OPTIONS = Object.values(RentalRuleAction).map(actionToOption)
