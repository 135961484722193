import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const useFilters = () => {
  const [filters, setFilters] = useState<any>(null)
  const savedFilters = useSelector((state: any) => state.screen.filterValue)

  useEffect(() => setFilters(savedFilters || null), [savedFilters])

  const filtersMerged = useMemo(() => {
    if (filters) {
      return filters.reduce((result: any, filter: any) => {
        result[filter.filterBy] = filter.filter

        return result
      }, {})
    }

    return null
  }, [filters])

  return { filters, filtersMerged }
}
