import React from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { getDocks, unlockDock, updateDock } from '../../../actions/docks'
import { sortedItem, stringFromValue } from '../../../utils/utils'
import { strings } from '../../../I18n'
import Paper from '@mui/material/Paper/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TabletPaginator from '../../table/tabletPaginator'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { useStyles } from '../../../utils/styles'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import {
  DOCK_CITY,
  DOCK_CODE,
  DOCK_STATUS,
  DOCK_TYPE,
  ONLINE_STATUS,
} from '../../../constants/orderConst'
import DropDownMenu from '../../menu'

function DockItem(props) {
  const {
    docks,
    docksResult,
    handleChangePage,
    unlockDockSuccess,
    getDocks,
    orderProps,
    pageSize,
    dockStatuses,
    updateDock,
    savedCity,
  } = props
  const [pressUnlock, setPressUnlock] = React.useState(false)
  const classes = useStyles()

  React.useEffect(() => {
    if (pressUnlock && unlockDockSuccess) {
      getDocks(0, '', '', savedCity || '', pageSize)
      setPressUnlock(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlockDockSuccess, pressUnlock, getDocks])

  const unlock = (dock) => {
    setPressUnlock(true)
    props.unlockDock(dock.id)
  }

  const UnlockButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const menuItems = (dock) =>
    dockStatuses.map((status) => ({
      action: () => updateDock(dock.id, { status: status }),
      title: status,
    }))

  const dropDown = (dock) => <DropDownMenu menuItems={menuItems(dock)} current={dock?.status} />

  const titles = [
    strings('descriptions.code'),
    strings('descriptions.stationName'),
    strings('descriptions.stationCode'),
    strings('descriptions.bikeName'),
    strings('descriptions.bikeCode'),
    strings('descriptions.type'),
    strings('descriptions.status'),
    strings('descriptions.cityProject'),
    '',
  ]

  const unlockAction = (dock) =>
    dock?.status === ONLINE_STATUS ? (
      <UnlockButton
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => unlock(dock)}
      >
        {strings('descriptions.unlock')}
      </UnlockButton>
    ) : (
      <div>-</div>
    )

  const sortedFields = [
    sortedItem(0, DOCK_CODE, orderProps),
    // sortedItem(2, DOCK_VEHICLE_CODE, orderProps),
    sortedItem(5, DOCK_TYPE, orderProps),
    sortedItem(6, DOCK_STATUS, orderProps),
    sortedItem(7, DOCK_CITY, orderProps),
  ]

  const tabletBody = () => (
    <TableBody>
      {docks.map((dock) => (
        <TableItemRow
          key={dock.code}
          id={dock.code}
          items={[
            stringFromValue(dock.code),
            stringFromValue(dock.station?.name),
            stringFromValue(dock.station?.code),
            stringFromValue(dock.vehicle?.name),
            stringFromValue(dock.vehicle?.code),
            dock.type,
            dropDown(dock),
            stringFromValue(dock.station?.city?.name),
            unlockAction(dock),
          ]}
        />
      ))}
    </TableBody>
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            {tabletBody()}
          </Table>
        </TableContainer>
        <TabletPaginator items={docks} pageData={docksResult} handleChangePage={handleChangePage} />
      </Paper>
    </div>
  )
}

const mapStateToProps = ({
  docks: { unlockDockSuccess, dockStatuses },
  screen: { pageSize },
  cities: { savedCity },
}) => ({
  unlockDockSuccess,
  pageSize,
  dockStatuses,
  savedCity,
})

const mapDispatchToProps = {
  unlockDock,
  getDocks,
  updateDock,
}

export default connect(mapStateToProps, mapDispatchToProps)(DockItem)
