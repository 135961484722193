export const shareBikeTheme = {
  navBackground: '#3f51b5',
  navText: '#ffffff',
  navLogoutText: '#3f51b5',
}
export const mobyTheme = {
  navBackground: '#000000',
  navText: '#c8ff00',
  navLogoutText: '#000000',
}
export const bigIssueTheme = {
  navBackground: '#ed3159',
  navText: '#ffffff',
  navLogoutText: '#ed3159',
}

export const farteTheme = {
  navBackground: '#FFD520',
  navText: '#000000',
  navLogoutText: '#FFD520',
}

export const sigoTheme = {
  navBackground: '#00cc77',
  navText: '#ffffff',
  navLogoutText: '#00cc77',
}
