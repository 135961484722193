/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Paper } from '@mui/material'
import './styles.scss'
import { useStyles } from '../../utils/styles'
import TableContainer from '@mui/material/TableContainer'
import TableHeaderRow from '../../components/table/tableHeaderRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import { strings } from '../../I18n'
import { reformatFilter, reformatSort, sortedItem, stringFromValue } from '../../utils/utils'
import {
  STATION_CODE,
  STATION_DOCK_CODE,
  STATION_LAST_SEEN,
  STATION_NAME,
  STATION_STATUS,
  STATION_TYPE,
  STATION_VEHICLE_COUNT,
} from '../../constants/orderConst'
import SearchBlockWithRefreshBtn from '../../components/table/searchBlockWithRefreshBtn'
import Modal from '@mui/material/Modal'
import { FormProvider, useForm } from 'react-hook-form'
import InputController from '../../components/inputController'
import DatePickerController from '../../components/inputController/DatePickerController'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelBookingByCode,
  clearCreateCoupon,
  deleteSingleReferralById,
  getAllBookingList,
  getUserReferralsList,
} from '../../actions/cities'
import TabletPaginator from '../../components/table/tabletPaginator'
import { useCity, useShowToast } from '../../hooks'
import Swal from 'sweetalert2'
import Loader from '../../components/loader'
import useCustomTable from '../../components/table/CustomTable'
import { Link } from 'react-router-dom'

const ReferralsScreen = () => {
  const dispatch = useDispatch()

  const showToast = useShowToast()

  const userReferral = useSelector((state) => state.cities.userReferral)
  const userReferralSuccess = useSelector((state) => state.cities.userReferralSuccess)
  const deleteReferralSuccess = useSelector((state) => state.cities.deleteReferralSuccess)
  const classes = useStyles()

  const { selectedCity } = useCity()

  const userLink = useCallback(
    (userId, userName) =>
      userId ? (
        <Link
          to={`/dashboard/user/${userId}`}
          target="_blank"
          className={'bikeLink'}
          style={userId && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {stringFromValue(userName)}
        </Link>
      ) : (
        <p>{stringFromValue(userName)}</p>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const columns = useMemo(
    () => [
      {
        id: 'sortable1',
        name: strings('referral.referralCode'),
        selector: 'code',
        center: true,
        sortable: true,
      },
      {
        name: strings('descriptions.userName'),
        selector: 'username',
        center: true,
        cell: (row) => userLink(row.userId, row.username),
      },
      {
        name: strings('referral.phoneNo'),
        selector: 'email',
        center: true,
      },
      {
        name: strings('referral.email'),
        selector: 'email',
        center: true,
      },
      {
        name: strings('referral.phone'),
        selector: 'phone',
        center: true,
      },
      {
        id: 'sortable2',
        name: strings('referral.referralStatus'),
        selector: 'referralStatus',
        center: true,
        sortable: true,
      },
      {
        name: strings('message.action'),
        selector: 'referralStatus',
        center: true,
        cell: (row, index, column, id) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                Swal.fire({
                  title: strings('message.removeItem'),
                  text: strings('message.noRevert'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('message.yesDelete'),
                  cancelButtonText: strings('message.cancel'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(deleteSingleReferralById(row.userId))
                  }
                })
              }}
            >
              {strings('message.delete')}
            </Button>
          )
        },
      },
    ],
    []
  )
  const addButtonClick = () => {}

  let filterObject = [
    {
      filterBy: 'referralStatus',
      filterValue: ['ACTIVE', 'INACTIVE'],
    },
  ]

  const reloadData = () => {
    setLoading(true)
    dispatch(
      getUserReferralsList(
        localPage,
        debouncedSearchTerm,
        selectedCity,
        reformatSort(sortData),
        reformatFilter(filterData),
        localRows
      )
    )
  }

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(
    columns,
    userReferral,
    reloadData,
    addButtonClick,
    filterObject,
    'Referrals',
    true,
    false
  )
  useEffect(() => {
    resetPagination()
  }, [selectedCity, debouncedSearchTerm])

  useEffect(() => {
    reloadData()
  }, [localPage, debouncedSearchTerm, selectedCity, sortData, filterData, localRows])

  useEffect(() => {
    if (userReferralSuccess) {
      setLoading(false)
    }
  }, [userReferralSuccess])

  useEffect(() => {
    if (deleteReferralSuccess === true) {
      showToast('Referral Deleted', 'success')
      // Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
      dispatch(clearCreateCoupon())
      reloadData()
    }
  }, [deleteReferralSuccess])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderHeaderBar()}
        {renderCustomTable()}
      </Paper>
    </div>
  )
}

export default ReferralsScreen
