export const EXPIRED_SESSION = 'EXPIRED_SESSION'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SAVE_USER = 'SAVE_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'

export const CURRENT_MENU = 'CURRENT_MENU'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
export const SET_CURRENT_PAGE_VALUE = 'SET_CURRENT_PAGE_VALUE'
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDER_BY = 'SET_ORDER_BY'
export const SET_MAP_TYPE_FILTER = 'SET_MAP_TYPE_FILTER'
export const SET_MAP_STATION_TYPE_FILTER = 'SET_MAP_STATION_TYPE_FILTER'
export const SET_MAP_BIKE_STATE_FILTER = 'SET_MAP_BIKE_STATE_FILTER'
export const SET_MAP_STATION_STATE_FILTER = 'SET_MAP_STATION_STATE_FILTER'
export const SET_PASSED_PARAMS = 'SET_PASSED_PARAMS'
export const EMPTY_PASSED_PARAMS = 'EMPTY_PASSED_PARAMS'

//---Users---
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FOR_NEW_PAGE_SUCCESS = 'GET_USER_FOR_NEW_PAGE_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const CLEAR_USER = 'CLEAR_USER'
export const CLEAR_INTERNAL_USER = 'CLEAR_INTERNAL_USER'
export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'
export const GET_PLAN_STATUSES_REQUEST = 'GET_PLAN_STATUSES_REQUEST'
export const GET_PLAN_STATUSES_SUCCESS = 'GET_PLAN_STATUSES_SUCCESS'
export const GET_PLAN_STATUSES_FAILURE = 'GET_PLAN_STATUSES_FAILURE'
export const GET_PLAN_TYPES_REQUEST = 'GET_PLAN_TYPES_REQUEST'
export const GET_PLAN_TYPES_SUCCESS = 'GET_PLAN_TYPES_SUCCESS'
export const GET_PLAN_TYPES_FAILURE = 'GET_PLAN_TYPES_FAILURE'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const SET_ADMIN_DETAILS_REQUEST = 'SET_ADMIN_DETAILS_REQUEST'
export const SET_ADMIN_DETAILS_SUCCESS = 'SET_ADMIN_DETAILS_SUCCESS'
export const SET_ADMIN_DETAILS_FAILURE = 'SET_ADMIN_DETAILS_FAILURE'
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE'
//---end---

//---Stations---
export const GET_STATIONS_REQUEST = 'GET_STATIONS_REQUEST'
export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS'
export const GET_STATIONS_FAILURE = 'GET_STATIONS_FAILURE'
export const GET_STATION_BY_ID_REQUEST = 'GET_STATION_BY_ID_REQUEST'
export const GET_STATION_BY_ID_SUCCESS = 'GET_STATION_BY_ID_SUCCESS'
export const GET_STATION_BY_ID_FAILURE = 'GET_STATION_BY_ID_FAILURE'
export const UPDATE_STATION_BY_ID_REQUEST = 'UPDATE_STATION_BY_ID_REQUEST'
export const UPDATE_STATION_BY_ID_SUCCESS = 'UPDATE_STATION_BY_ID_SUCCESS'
export const UPDATE_STATION_BY_ID_FAILURE = 'UPDATE_STATION_BY_ID_FAILURE'
export const CREATE_STATION_REQUEST = 'CREATE_STATION_REQUEST'
export const CREATE_STATION_SUCCESS = 'CREATE_STATION_SUCCESS'
export const CREATE_STATION_FAILURE = 'CREATE_STATION_FAILURE'
export const UNLOCK_STATION_REQUEST = 'UNLOCK_STATION_REQUEST'
export const UNLOCK_STATION_SUCCESS = 'UNLOCK_STATION_SUCCESS'
export const UNLOCK_STATION_FAILURE = 'UNLOCK_STATION_FAILURE'
export const CLEAR_STATION = 'CLEAR_STATION'
export const GET_VEHICLE_STATUSES_REQUEST = 'GET_VEHICLE_STATUSES_REQUEST'
export const GET_VEHICLE_STATUSES_SUCCESS = 'GET_VEHICLE_STATUSES_SUCCESS'
export const GET_VEHICLE_STATUSES_FAILURE = 'GET_VEHICLE_STATUSES_FAILURE'
export const GET_STATIONS_STATUSES_REQUEST = 'GET_STATIONS_STATUSES_REQUEST'
export const GET_STATIONS_STATUSES_SUCCESS = 'GET_STATIONS_STATUSES_SUCCESS'
export const GET_STATIONS_STATUSES_FAILURE = 'GET_STATIONS_STATUSES_FAILURE'
export const CLEAR_RENTAL_DATA = 'CLEAR_RENTAL_DATA'
export const CLEAR_STATIONS_DATA = 'CLEAR_STATIONS_DATA'
export const GET_STATIONS_ON_MAP_REQUEST = 'GET_STATIONS_ON_MAP_REQUEST'
export const GET_STATIONS_ON_MAP_SUCCESS = 'GET_STATIONS_ON_MAP_SUCCESS'
export const GET_STATIONS_ON_MAP_FAILURE = 'GET_STATIONS_ON_MAP_FAILURE'
export const GET_STATIONS_BY_CITY_REQUEST = 'GET_STATIONS_BY_CITY_REQUEST'
export const GET_STATIONS_BY_CITY_SUCCESS = 'GET_STATIONS_BY_CITY_SUCCESS'
export const GET_STATIONS_BY_CITY_FAILURE = 'GET_STATIONS_BY_CITY_FAILURE'
export const GET_LINKED_STATIONS_REQUEST = 'GET_LINKED_STATIONS_REQUEST'
export const GET_LINKED_STATIONS_SUCCESS = 'GET_LINKED_STATIONS_SUCCESS'
export const GET_LINKED_STATIONS_FAILURE = 'GET_LINKED_STATIONS_FAILURE'
export const REFRESH_STATION_REQUEST = 'REFRESH_STATION_REQUEST'
export const REFRESH_STATION_SUCCESS = 'REFRESH_STATION_SUCCESS'
export const REFRESH_STATION_FAILURE = 'REFRESH_STATION_FAILURE'
export const RELOGIN_STATION_REQUEST = 'RELOGIN_STATION_REQUEST'
export const RELOGIN_STATION_SUCCESS = 'RELOGIN_STATION_SUCCESS'
export const RELOGIN_STATION_FAILURE = 'RELOGIN_STATION_FAILURE'
//---end---

//---Transactions--
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE'
export const GET_PAYMENT_BY_ID_REQUEST = 'GET_PAYMENT_BY_ID_REQUEST'
export const GET_PAYMENT_BY_ID_SUCCESS = 'GET_PAYMENT_BY_ID_SUCCESS'
export const GET_PAYMENT_BY_ID_FAILURE = 'GET_PAYMENT_BY_ID_FAILURE'
export const CLEAR_PAYMENT_DATA = 'CLEAR_PAYMENT_DATA'

export const GET_WALLET_PRESETS_REQUEST = 'GET_WALLET_PRESETS_REQUEST'
export const GET_WALLET_PRESETS_SUCCESS = 'GET_WALLET_PRESETS_SUCCESS'
export const GET_WALLET_PRESETS_FAILURE = 'GET_WALLET_PRESETS_FAILURE'
export const CLEAR_WALLET_PRESETS = 'CLEAR_WALLET_PRESETS'

export const GET_WALLET_WHITELABEL_PRESET_REQUEST = 'GET_WALLET_WHITELABEL_PRESET_REQUEST'
export const GET_WALLET_WHITELABEL_PRESET_SUCCESS = 'GET_WALLET_WHITELABEL_PRESET_SUCCESS'
export const GET_WALLET_WHITELABEL_PRESET_FAILURE = 'GET_WALLET_WHITELABEL_PRESET_FAILURE'

export const GET_USER_LEDGER_REQUEST = 'GET_USER_LEDGER_REQUEST'
export const GET_USER_LEDGER_SUCCESS = 'GET_USER_LEDGER_SUCCESS'
export const GET_USER_LEDGER_FAILURE = 'GET_USER_LEDGER_FAILURE'

export const GET_USER_DETAIL_INFO_REQUEST = 'GET_USER_DETAIL_INFO_REQUEST'
export const GET_USER_DETAIL_INFO_SUCCESS = 'GET_USER_DETAIL_INFO_SUCCESS'
export const GET_USER_DETAIL_INFO_FAILURE = 'GET_USER_DETAIL_INFO_FAILURE'

//---end---

//---Vehicles--
export const GET_VEHICLES_REQUEST = 'GET_VEHICLES_REQUEST'
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS'
export const GET_VEHICLES_FAILURE = 'GET_VEHICLES_FAILURE'
export const UPDATE_BIKE_REQUEST = 'UPDATE_BIKE_REQUEST'
export const UPDATE_BIKE_SUCCESS = 'UPDATE_BIKE_SUCCESS'
export const UPDATE_BIKE_FAILURE = 'UPDATE_BIKE_FAILURE'
export const GET_BIKE_BY_ID_REQUEST = 'GET_BIKE_BY_ID_REQUEST'
export const GET_BIKE_BY_ID_SUCCESS = 'GET_BIKE_BY_ID_SUCCESS'
export const GET_BIKE_BY_ID_FAILURE = 'GET_BIKE_BY_ID_FAILURE'
export const GET_BIKE_STATUS_REQUEST = 'GET_BIKE_STATUS_REQUEST'
export const GET_BIKE_STATUS_SUCCESS = 'GET_BIKE_STATUS_SUCCESS'
export const GET_BIKE_STATUS_FAILURE = 'GET_BIKE_STATUS_FAILURE'
export const CLEAR_BIKE = 'CLEAR_BIKE'
export const UNLOCK_BIKE_REQUEST = 'UNLOCK_BIKE_REQUEST'
export const UNLOCK_BIKE_SUCCESS = 'UNLOCK_BIKE_SUCCESS'
export const UNLOCK_BIKE_FAILURE = 'UNLOCK_BIKE_FAILURE'
export const ALARM_BIKE_REQUEST = 'ALARM_BIKE_REQUEST'
export const ALARM_BIKE_SUCCESS = 'ALARM_BIKE_SUCCESS'
export const ALARM_BIKE_FAILURE = 'ALARM_BIKE_FAILURE'
export const LOCK_BIKE_REQUEST = 'LOCK_BIKE_REQUEST'
export const LOCK_BIKE_SUCCESS = 'LOCK_BIKE_SUCCESS'
export const LOCK_BIKE_FAILURE = 'LOCK_BIKE_FAILURE'
export const GET_BIKE_BRANDS_REQUEST = 'GET_BIKE_BRANDS_REQUEST'
export const GET_BIKE_BRANDS_SUCCESS = 'GET_BIKE_BRANDS_SUCCESS'
export const GET_BIKE_BRANDS_FAILURE = 'GET_BIKE_BRANDS_FAILURE'
export const GET_BIKE_LOCK_STATUSES_REQUEST = 'GET_BIKE_LOCK_STATUSES_REQUEST'
export const GET_BIKE_LOCK_STATUSES_SUCCESS = 'GET_BIKE_LOCK_STATUSES_SUCCESS'
export const GET_BIKE_LOCK_STATUSES_FAILURE = 'GET_BIKE_LOCK_STATUSES_FAILURE'
export const BIKE_TYPES_REQUEST = 'BIKE_TYPES_REQUEST'
export const BIKE_TYPES_SUCCESS = 'BIKE_TYPES_SUCCESS'
export const BIKE_TYPES_FAILURE = 'BIKE_TYPES_FAILURE'
export const BIKES_CLEAR_DATA = 'BIKES_CLEAR_DATA'
export const BIKE_HISTORY_REQUEST = 'BIKE_HISTORY_REQUEST'
export const BIKE_HISTORY_SUCCESS = 'BIKE_HISTORY_SUCCESS'
export const BIKE_HISTORY_FAILURE = 'BIKE_HISTORY_FAILURE'

export const PAGINATED_BIKE_HISTORY_REQUEST = 'PAGINATED_BIKE_HISTORY_REQUEST'
export const PAGINATED_BIKE_HISTORY_SUCCESS = 'PAGINATED_BIKE_HISTORY_SUCCESS'
export const PAGINATED_BIKE_HISTORY_FAILURE = 'PAGINATED_BIKE_HISTORY_FAILURE'

export const BIKE_HISTORY_CLEAR_HAS_BIKE_HISTORY = 'BIKE_HISTORY_CLEAR_HAS_BIKE_HISTORY'
//---end---

//---Locks--
export const GET_LOCKS_REQUEST = 'GET_LOCKS_REQUEST'
export const GET_LOCKS_SUCCESS = 'GET_LOCKS_SUCCESS'
export const GET_LOCKS_FAILURE = 'GET_LOCKS_FAILURE'
export const UNLOCK_LOCK_REQUEST = 'UNLOCK_LOCK_REQUEST'
export const UNLOCK_LOCK_SUCCESS = 'UNLOCK_LOCK_SUCCESS'
export const UNLOCK_LOCK_FAILURE = 'UNLOCK_LOCK_FAILURE'
//---end---

//---Docks--
export const GET_DOCKS_REQUEST = 'GET_DOCKS_REQUEST'
export const GET_DOCKS_SUCCESS = 'GET_DOCKS_SUCCESS'
export const GET_DOCKS_FAILURE = 'GET_DOCKS_FAILURE'
export const UNLOCK_DOCK_REQUEST = 'UNLOCK_DOCK_REQUEST'
export const UNLOCK_DOCK_SUCCESS = 'UNLOCK_DOCK_SUCCESS'
export const UNLOCK_DOCK_FAILURE = 'UNLOCK_DOCK_FAILURE'
export const UPDATE_DOCK_REQUEST = 'UPDATE_DOCK_REQUEST'
export const UPDATE_DOCK_SUCCESS = 'UPDATE_DOCK_SUCCESS'
export const UPDATE_DOCK_FAILURE = 'UPDATE_DOCK_FAILURE'
export const GET_DOCK_STATUSES_REQUEST = 'GET_DOCK_STATUSES_REQUEST'
export const GET_DOCK_STATUSES_SUCCESS = 'GET_DOCK_STATUSES_SUCCESS'
export const GET_DOCK_STATUSES_FAILURE = 'GET_DOCK_STATUSES_FAILURE'
export const CLEAR_DOCKS_DATA = 'CLEAR_DOCKS_DATA'
//---end---

//---Cities--
export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'
export const GET_CITIES_LIST_REQUEST = 'GET_CITIES_LIST_REQUEST'
export const GET_CITIES_LIST_SUCCESS = 'GET_CITIES_LIST_SUCCESS'
export const GET_CITIES_LIST_FAILURE = 'GET_CITIES_LIST_FAILURE'
export const GET_CITY_BY_ID_REQUEST = 'GET_CITY_BY_ID_REQUEST'
export const GET_CITY_BY_ID_SUCCESS = 'GET_CITY_BY_ID_SUCCESS'
export const GET_CITY_BY_ID_FAILURE = 'GET_CITY_BY_ID_FAILURE'
export const UPDATE_CITY_REQUEST = 'UPDATE_CITY_REQUEST'
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS'
export const UPDATE_CITY_FAILURE = 'UPDATE_CITY_FAILURE'
export const CREATE_CITY_REQUEST = 'CREATE_CITY_REQUEST'
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS'
export const CREATE_CITY_FAILURE = 'CREATE_CITY_FAILURE'
export const CLEAR_CITY = 'CLEAR_CITY'
export const CLEAR_PRICING_PLAN = 'CLEAR_PRICING_PLAN'
export const SAVE_CITY = 'SAVE_CITY'
export const SAVE_CITY_WITH_FULL_INFO = 'SAVE_CITY_WITH_FULL_INFO'
export const CLEAR_SAVED_CITY = 'CLEAR_SAVED_CITY'
export const GET_CURRENT_PRICING_PLAN_REQUEST = 'GET_CURRENT_PRICING_PLAN_REQUEST'
export const GET_CURRENT_PRICING_PLAN_SUCCESS = 'GET_CURRENT_PRICING_PLAN_SUCCESS'
export const GET_CURRENT_PRICING_PLAN_FAILURE = 'GET_CURRENT_PRICING_PLAN_FAILURE'
export const CLEAR_PRICING_PLANS = 'CLEAR_PRICING_PLANS'
//---end---

//---Rentals--
export const GET_RENTALS_REQUEST = 'GET_RENTALS_REQUEST'
export const GET_RENTALS_SUCCESS = 'GET_RENTALS_SUCCESS'
export const GET_RENTALS_FAILURE = 'GET_RENTALS_FAILURE'
export const GET_RENTALS_FOR_EXCEL_REQUEST = 'GET_RENTALS_FOR_EXCEL_REQUEST'
export const GET_RENTALS_FOR_EXCEL_SUCCESS = 'GET_RENTALS_FOR_EXCEL_SUCCESS'
export const GET_RENTALS_FOR_EXCEL_FAILURE = 'GET_RENTALS_FOR_EXCEL_FAILURE'
export const CLEAR_RENTALS_FOR_EXCEL = 'CLEAR_RENTALS_FOR_EXCEL'
export const GET_RENTAL_BY_ID_REQUEST = 'GET_RENTAL_BY_ID_REQUEST'
export const GET_RENTAL_BY_ID_SUCCESS = 'GET_RENTAL_BY_ID_SUCCESS'
export const GET_RENTAL_BY_ID_FAILURE = 'GET_RENTAL_BY_ID_FAILURE'
export const GET_RENTAL_DOCS_REQUEST = 'GET_RENTAL_DOCS_REQUEST'
export const GET_RENTAL_DOCS_SUCCESS = 'GET_RENTAL_DOCS_SUCCESS'
export const GET_RENTAL_DOCS_FAILURE = 'GET_RENTAL_DOCS_FAILURE'
export const GET_RENTAL_USER_REQUEST = 'GET_RENTAL_USER_REQUEST'
export const GET_RENTAL_USER_SUCCESS = 'GET_RENTAL_USER_SUCCESS'
export const GET_RENTAL_USER_FAILURE = 'GET_RENTAL_USER_FAILURE'
export const GET_RENTAL_VEHICLE_REQUEST = 'GET_RENTAL_VEHICLE_REQUEST'
export const GET_RENTAL_VEHICLE_SUCCESS = 'GET_RENTAL_VEHICLE_SUCCESS'
export const GET_RENTAL_VEHICLE_FAILURE = 'GET_RENTAL_VEHICLE_FAILURE'
export const UPDATE_RENTAL_BY_ID_REQUEST = 'UPDATE_RENTAL_BY_ID_REQUEST'
export const UPDATE_RENTAL_BY_ID_SUCCESS = 'UPDATE_RENTAL_BY_ID_SUCCESS'
export const UPDATE_RENTAL_BY_ID_FAILURE = 'UPDATE_RENTAL_BY_ID_FAILURE'
export const POST_FINISH_RENT_REQUEST = 'POST_FINISH_RENT_REQUEST'
export const POST_FINISH_RENT_SUCCESS = 'POST_FINISH_RENT_SUCCESS'
export const POST_FINISH_RENT_FAILURE = 'POST_FINISH_RENT_FAILURE'
export const GET_RENTALS_STATUSES_REQUEST = 'GET_RENTALS_STATUSES_REQUEST'
export const GET_RENTALS_STATUSES_SUCCESS = 'GET_RENTALS_STATUSES_SUCCESS'
export const GET_RENTALS_STATUSES_FAILURE = 'GET_RENTALS_STATUSES_FAILURE'
export const CLEAR_RENTAL = 'CLEAR_RENTAL'
export const GET_VEHICLE_BY_CODE_REQUEST = 'GET_VEHICLE_BY_CODE_REQUEST'
export const GET_VEHICLE_BY_CODE_SUCCESS = 'GET_VEHICLE_BY_CODE_SUCCESS'
export const GET_VEHICLE_BY_CODE_FAILURE = 'GET_VEHICLE_BY_CODE_FAILURE'
export const GET_FINALLY_DATA_OF_RENTAL_REQUEST = 'GET_FINALLY_DATA_OF_RENTAL_REQUEST'
export const GET_FINALLY_DATA_OF_RENTAL_SUCCESS = 'GET_FINALLY_DATA_OF_RENTAL_SUCCESS'
export const GET_FINALLY_DATA_OF_RENTAL_FAILURE = 'GET_FINALLY_DATA_OF_RENTAL_FAILURE'

export const GET_USER_REFERRAL_REQUEST = 'GET_USER_REFERRAL_REQUEST'
export const GET_USER_REFERRAL_SUCCESS = 'GET_USER_REFERRAL_SUCCESS'
export const GET_USER_REFERRAL_FAILURE = 'GET_USER_REFERRAL_FAILURE'

export const GET_COUPON_LIST_REQUEST = 'GET_COUPON_LIST_REQUEST'
export const GET_COUPON_LIST_SUCCESS = 'GET_COUPON_LIST_SUCCESS'
export const GET_COUPON_LIST_FAILURE = 'GET_COUPON_LIST_FAILURE'

export const POST_COUPON_CREATE_REQUEST = 'POST_COUPON_CREATE_REQUEST'
export const POST_COUPON_CREATE_SUCCESS = 'POST_COUPON_CREATE_SUCCESS'
export const POST_COUPON_CREATE_FAILURE = 'POST_COUPON_CREATE_FAILURE'
export const GET_COUPON_DOWNLOAD_REQUEST = 'GET_COUPON_DOWNLOAD_REQUEST'
export const GET_COUPON_DOWNLOAD_SUCCESS = 'GET_COUPON_DOWNLOAD_SUCCESS'
export const GET_COUPON_DOWNLOAD_FAILURE = 'GET_COUPON_DOWNLOAD_FAILURE'
export const CLEAR_DOWNLOADED_COUPON = 'CLEAR_DOWNLOADED_COUPON'

export const CLEAR_CREATE_COUPON = 'CLEAR_CREATE_COUPON'

export const COUPON_BY_ID_REQUEST = 'COUPON_BY_ID_REQUEST'
export const COUPON_BY_ID_SUCCESS = 'COUPON_BY_ID_SUCCESS'
export const COUPON_BY_ID_FAILURE = 'COUPON_BY_ID_FAILURE'

export const UPDATE_COUPON_BY_ID_REQUEST = 'UPDATE_COUPON_BY_ID_REQUEST'
export const UPDATE_COUPON_BY_ID_SUCCESS = 'UPDATE_COUPON_BY_ID_SUCCESS'
export const UPDATE_COUPON_BY_ID_FAILURE = 'UPDATE_COUPON_BY_ID_FAILURE'

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE'

export const DELETE_MAIN_COUPON_REQUEST = 'DELETE_MAIN_COUPON_REQUEST'
export const DELETE_MAIN_COUPON_SUCCESS = 'DELETE_MAIN_COUPON_SUCCESS'
export const DELETE_MAIN_COUPON_FAILURE = 'DELETE_MAIN_COUPON_FAILURE'

export const CLEAR_DELETE_MAIN_COUPON = 'CLEAR_DELETE_MAIN_COUPON'

export const COUPON_LIST_BY_USER_LIST_REQUEST = 'COUPON_LIST_BY_USER_LIST_REQUEST'
export const COUPON_LIST_BY_USER_LIST_SUCCESS = 'COUPON_LIST_BY_USER_LIST_SUCCESS'
export const COUPON_LIST_BY_USER_LIST_FAILURE = 'COUPON_LIST_BY_USER_LIST_FAILURE'

export const DELETE_COUPON_BY_USER_REQUEST = 'DELETE_COUPON_BY_USER_REQUEST'
export const DELETE_COUPON_BY_USER_SUCCESS = 'DELETE_COUPON_BY_USER_SUCCESS'
export const DELETE_COUPON_BY_USER_FAILURE = 'DELETE_COUPON_BY_USER_FAILURE'

export const LOCK_BATTERY_REQUEST = 'LOCK_BATTERY_REQUEST'
export const LOCK_BATTERY_SUCCESS = 'LOCK_BATTERY_SUCCESS'
export const LOCK_BATTERY_FAILURE = 'LOCK_BATTERY_FAILURE'

export const UNLOCK_BATTERY_REQUEST = 'UNLOCK_BATTERY_REQUEST'
export const UNLOCK_BATTERY_SUCCESS = 'UNLOCK_BATTERY_SUCCESS'
export const UNLOCK_BATTERY_FAILURE = 'UNLOCK_BATTERY_FAILURE'

export const CLEAR_LOCKED_UNLOCKED_HONJI = 'CLEAR_LOCKED_UNLOCKED_HONJI'

export const APP_VERSION_GET_REQUEST = 'APP_VERSION_GET_REQUEST'
export const APP_VERSION_GET_SUCCESS = 'APP_VERSION_GET_SUCCESS'
export const APP_VERSION_GET_FAILURE = 'APP_VERSION_GET_FAILURE'

export const SAVE_APP_VERSION_REQUEST = 'SAVE_APP_VERSION_REQUEST'
export const SAVE_APP_VERSION_SUCCESS = 'SAVE_APP_VERSION_SUCCESS'
export const SAVE_APP_VERSION_FAILURE = 'SAVE_APP_VERSION_FAILURE'

export const CLEAR_APP_VERSION = 'CLEAR_APP_VERSION'

export const GET_BOOKING_LIST_REQUEST = 'GET_BOOKING_LIST_REQUEST'
export const GET_BOOKING_LIST_SUCCESS = 'GET_BOOKING_LIST_SUCCESS'
export const GET_BOOKING_LIST_FAILURE = 'GET_BOOKING_LIST_FAILURE'

export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST'
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS'
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE'

export const CLEAR_CANCEL_BOOKING = 'CLEAR_CANCEL_BOOKING'

export const UPDATE_BIKE_BY_ID_REQUEST = 'UPDATE_BIKE_BY_ID_REQUEST'
export const UPDATE_BIKE_BY_ID_SUCCESS = 'UPDATE_BIKE_BY_ID_SUCCESS'
export const UPDATE_BIKE_BY_ID_FAILURE = 'UPDATE_BIKE_BY_ID_FAILURE'

export const CLEAR_BIKE_STATUS_DATA = 'CLEAR_BIKE_STATUS_DATA'

export const BROKEN_BIKE_STATUS_REQUEST = 'BROKEN_BIKE_STATUS_REQUEST'
export const BROKEN_BIKE_STATUS_SUCCESS = 'BROKEN_BIKE_STATUS_SUCCESS'
export const BROKEN_BIKE_STATUS_FAILURE = 'BROKEN_BIKE_STATUS_FAILURE'

export const GET_MAIN_RENTAL_USER_REQUEST = 'GET_MAIN_RENTAL_USER_REQUEST'
export const GET_MAIN_RENTAL_USER_SUCCESS = 'GET_MAIN_RENTAL_USER_SUCCESS'
export const GET_MAIN_RENTAL_USER_FAILURE = 'GET_MAIN_RENTAL_USER_FAILURE'

export const GET_IMAGE_DIRECTORY_DATA_REQUEST = 'GET_IMAGE_DIRECTORY_DATA_REQUEST'
export const GET_IMAGE_DIRECTORY_DATA_SUCCESS = 'GET_IMAGE_DIRECTORY_DATA_SUCCESS'
export const GET_IMAGE_DIRECTORY_DATA_FAILURE = 'GET_IMAGE_DIRECTORY_DATA_FAILURE'
export const CLEAR_IMAGE_DIRECTORY_DATA = 'CLEAR_IMAGE_DIRECTORY_DATA'

//---end---

// whitelabel
export const GET_WHITE_LABEL = 'GET_WHITE_LABEL'
export const GET_WHITE_LABEL_SUCCESS = 'GET_WHITE_LABEL_SUCCESS'
export const GET_WHITE_LABEL_FAILURE = 'GET_WHITE_LABEL_FAILURE'

export const GET_ALL_VIRTUAL_STATION_REQUEST = 'GET_ALL_VIRTUAL_STATION_REQUEST'
export const GET_ALL_VIRTUAL_STATION_SUCCESS = 'GET_ALL_VIRTUAL_STATION_SUCCESS'
export const GET_ALL_VIRTUAL_STATION_FAILURE = 'GET_ALL_VIRTUAL_STATION_FAILURE'

export const GET_PRICING_PLAN_DETAILS_REQUEST = 'GET_PRICING_PLAN_DETAILS_REQUEST'
export const GET_PRICING_PLAN_DETAILS_SUCCESS = 'GET_PRICING_PLAN_DETAILS_SUCCESS'
export const GET_PRICING_PLAN_DETAILS_FAILURE = 'GET_PRICING_PLAN_DETAILS_FAILURE'

export const GET_PRICING_PLAN_FIELDS_REQUEST = 'GET_PRICING_PLAN_FIELDS_REQUEST'
export const GET_PRICING_PLAN_FIELDS_SUCCESS = 'GET_PRICING_PLAN_FIELDS_SUCCESS'
export const GET_PRICING_PLAN_FIELDS_FAILURE = 'GET_PRICING_PLAN_FIELDS_FAILURE'

export const DELETE_PRICING_PLAN_REQUEST = 'DELETE_PRICING_PLAN_REQUEST'
export const DELETE_PRICING_PLAN_SUCCESS = 'DELETE_PRICING_PLAN_SUCCESS'
export const DELETE_PRICING_PLAN_FAILURE = 'DELETE_PRICING_PLAN_FAILURE'

export const GET_MANAGED_USER_LIST_REQUEST = 'GET_MANAGED_USER_LIST_REQUEST'
export const GET_MANAGED_USER_LIST_SUCCESS = 'GET_MANAGED_USER_LIST_SUCCESS'
export const GET_MANAGED_USER_LIST_FAILURE = 'GET_MANAGED_USER_LIST_FAILURE'

export const POST_CREATE_INTERNAL_USER_REQUEST = 'POST_CREATE_INTERNAL_USER_REQUEST'
export const POST_CREATE_INTERNAL_USER_SUCCESS = 'POST_CREATE_INTERNAL_USER_SUCCESS'
export const POST_CREATE_INTERNAL_USER_FAILURE = 'POST_CREATE_INTERNAL_USER_FAILURE'

export const GET_INTERNAL_USER_REQUEST = 'GET_INTERNAL_USER_REQUEST'
export const GET_INTERNAL_USER_SUCCESS = 'GET_INTERNAL_USER_SUCCESS'
export const GET_INTERNAL_USER_FAILURE = 'GET_INTERNAL_USER_FAILURE'

export const PUT_INTERNAL_USER_UPDATE_REQUEST = 'PUT_INTERNAL_USER_UPDATE_REQUEST'
export const PUT_INTERNAL_USER_UPDATE_SUCCESS = 'PUT_INTERNAL_USER_UPDATE_SUCCESS'
export const PUT_INTERNAL_USER_UPDATE_FAILURE = 'PUT_INTERNAL_USER_UPDATE_FAILURE'

export const GET_CURRENCY_MAPPINGS_REQUEST = 'GET_CURRENCY_MAPPINGS_REQUEST'
export const GET_CURRENCY_MAPPINGS_SUCCESS = 'GET_CURRENCY_MAPPINGS_SUCCESS'
export const GET_CURRENCY_MAPPINGS_FAILURE = 'GET_CURRENCY_MAPPINGS_FAILURE'

export const GET_CITY_WHITELABEL_MAPPINGS_REQUEST = 'GET_CITY_WHITELABEL_MAPPINGS_REQUEST'
export const GET_CITY_WHITELABEL_MAPPINGS_SUCCESS = 'GET_CITY_WHITELABEL_MAPPINGS_SUCCESS'
export const GET_CITY_WHITELABEL_MAPPINGS_FAILURE = 'GET_CITY_WHITELABEL_MAPPINGS_FAILURE'

export const ADJUST_WALLET_BALANCE_REQUEST = 'ADJUST_WALLET_BALANCE_REQUEST'
export const ADJUST_WALLET_BALANCE_SUCCESS = 'ADJUST_WALLET_BALANCE_SUCCESS'
export const ADJUST_WALLET_BALANCE_FAILURE = 'ADJUST_WALLET_BALANCE_FAILURE'

export const DELETE_INTERNAL_USER_REQUEST = 'DELETE_INTERNAL_USER_REQUEST'
export const DELETE_INTERNAL_USER_SUCCESS = 'DELETE_INTERNAL_USER_SUCCESS'
export const DELETE_INTERNAL_USER_FAILURE = 'DELETE_INTERNAL_USER_FAILURE'

export const GET_USER_COUPON_LIST_REQUEST = 'GET_USER_COUPON_LIST_REQUEST'
export const GET_USER_COUPON_LIST_SUCCESS = 'GET_USER_COUPON_LIST_SUCCESS'
export const GET_USER_COUPON_LIST_FAILURE = 'GET_USER_COUPON_LIST_FAILURE'

export const CREATE_PRICING_PLAN_REQUEST = 'CREATE_PRICING_PLAN_REQUEST'
export const CREATE_PRICING_PLAN_SUCCESS = 'CREATE_PRICING_PLAN_SUCCESS'
export const CREATE_PRICING_PLAN_FAILURE = 'CREATE_PRICING_PLAN_FAILURE'

export const UPDATE_PRICING_PLAN_REQUEST = 'UPDATE_PRICING_PLAN_REQUEST'
export const UPDATE_PRICING_PLAN_SUCCESS = 'UPDATE_PRICING_PLAN_SUCCESS'
export const UPDATE_PRICING_PLAN_FAILURE = 'UPDATE_PRICING_PLAN_FAILURE'

export const GET_SPEED_LIMIT_ZONES_REQUEST = 'GET_SPEED_LIMIT_ZONES_REQUEST'
export const GET_SPEED_LIMIT_ZONES_SUCCESS = 'GET_SPEED_LIMIT_ZONES_SUCCESS'
export const GET_SPEED_LIMIT_ZONES_FAILURE = 'GET_SPEED_LIMIT_ZONES_FAILURE'

export const CREATE_LIMIT_ZONES_REQUEST = 'CREATE_LIMIT_ZONES_REQUEST'
export const CREATE_LIMIT_ZONES_SUCCESS = 'CREATE_LIMIT_ZONES_SUCCESS'
export const CREATE_LIMIT_ZONES_FAILURE = 'CREATE_LIMIT_ZONES_FAILURE'

export const UPDATE_LIMIT_ZONES_REQUEST = 'UPDATE_LIMIT_ZONES_REQUEST'
export const UPDATE_LIMIT_ZONES_SUCCESS = 'UPDATE_LIMIT_ZONES_SUCCESS'
export const UPDATE_LIMIT_ZONES_FAILURE = 'UPDATE_LIMIT_ZONES_FAILURE'

export const GET_LIMIT_ZONE_BY_ID_REQUEST = 'GET_LIMIT_ZONE_BY_ID_REQUEST'
export const GET_LIMIT_ZONE_BY_ID_SUCCESS = 'GET_LIMIT_ZONE_BY_ID_SUCCESS'
export const GET_LIMIT_ZONE_BY_ID_FAILURE = 'GET_LIMIT_ZONE_BY_ID_FAILURE'
