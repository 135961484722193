import React from 'react'
import Modal from '@mui/material/Modal'

import './styles.scss'
import { useStyles } from '../../../utils/styles'

function ModalContainer(props) {
  const classes = useStyles()
  const { body, children, visible, closeAction, className = classes.modal } = props
  const [modalVisible, setModalVisible] = React.useState(false)

  React.useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  return (
    <Modal
      className={'modalContainer'}
      open={modalVisible}
      onClose={() => {
        closeAction()
        setModalVisible(false)
      }}
    >
      <div className={className}>{children || body}</div>
    </Modal>
  )
}

export default ModalContainer
