import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Select, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import MenuItem from '@mui/material/MenuItem'
import { PricingPlanDomainsCreateDto, PricingPlanDomainType } from '../../domain/pricing-plan'
import { createPricingPlanDomains } from '../../apis/pricing-plan/pricing-plan-apis'

interface Props {
  planId: number
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  type: PricingPlanDomainType
  identifiers: string
}

export const PricingPlanDomainAdd = ({ onClose, planId }: Props) => {
  const {
    formState: { errors, isValid },
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      type: PricingPlanDomainType.EMAIL,
    },
  })
  const [loading, setLoading] = useState(false)
  const { type, identifiers } = watch()

  const handleSubmit = () => {
    setLoading(true)

    const items = identifiers.split(/\n/).map((item) => item.replace(/[,.;]+$/gi, ''))

    const dto: PricingPlanDomainsCreateDto = {
      domainDtos: items.map((item) => {
        return {
          planId,
          type,
          email: type === PricingPlanDomainType.EMAIL ? item : undefined,
          phone: type === PricingPlanDomainType.PHONE ? item : undefined,
        }
      }),
    }

    createPricingPlanDomains(planId, dto)
      .then(() => {
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', height: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">Add Pricing Plan Domain</Typography>

            <Box flexGrow={1}>
              <Stack spacing={2}>
                <Select
                  value={type}
                  onChange={(e) => setValue('type', e.target.value as PricingPlanDomainType)}
                >
                  <MenuItem value={PricingPlanDomainType.EMAIL}>
                    {PricingPlanDomainType.EMAIL}
                  </MenuItem>
                  <MenuItem value={PricingPlanDomainType.PHONE}>
                    {PricingPlanDomainType.PHONE}
                  </MenuItem>
                </Select>

                <TextField
                  fullWidth
                  multiline
                  label={type === PricingPlanDomainType.EMAIL ? 'Emails' : 'Phones'}
                  rows={7}
                  {...register('identifiers', {
                    required: 'Waiting time is required',
                    min: 0,
                  })}
                  error={!!errors.identifiers}
                  helperText={errors.identifiers?.message}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
