import React, { useState } from 'react'
import ModalContainer from '../modal'
import { Button, Grid } from '@mui/material'
import { strings } from '../../../I18n'
import CloseIcon from '@mui/icons-material/Close'
import { useStyles } from '../cancelSubscriptionModal/styles'
import { DotLoader } from 'react-spinners'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ImagePreviewModalMeta } from './ImagePreviewModalMeta'

const ImagePreviewModal = ({
  visible,
  previewImageUrls,
  setImagePreviewModal,
  setPreviewImageUrls,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)

  const localCloseAction = () => {
    setImagePreviewModal(false)
    setPreviewImageUrls([])
  }

  const loadingContainer = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <DotLoader color="#36d7b7" />
    </div>
  )

  const openUrlInANewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const imagePreviewWIthFlexWrap = () => {
    return (
      <div
        className={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {previewImageUrls.map((item, index) => (
          <div
            style={{
              cursor: 'pointer',
              position: 'relative',
            }}
            key={index}
            onClick={() => {
              openUrlInANewTab(item)
            }}
          >
            <LazyLoadImage
              src={item}
              alt="image"
              effect="blur"
              beforeLoad={() => setLoading(true)}
              afterLoad={() => setLoading(false)}
              width="100%"
              height="100%"
            />
            <div style={{ position: 'absolute', bottom: 4, right: 0 }}>
              <ImagePreviewModalMeta url={item} />
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <ModalContainer
      closeAction={localCloseAction}
      visible={visible}
      body={
        <>
          <div>
            {loading && loadingContainer()}

            {previewImageUrls.length === 1 ? (
              imagePreviewWIthFlexWrap()
            ) : (
              <div>
                <Grid container spacing={2}>
                  {previewImageUrls.map((item, index) => (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      key={index}
                      onClick={() => {
                        openUrlInANewTab(item)
                      }}
                    >
                      <LazyLoadImage
                        src={item}
                        alt="image"
                        effect="blur"
                        width="100%"
                        height="100%"
                        style={{ objectFit: 'cover' }}
                        beforeLoad={() => setLoading(true)}
                        afterLoad={() => setLoading(false)}
                      />
                      <div style={{ position: 'absolute', bottom: 8, right: 8 }}>
                        <ImagePreviewModalMeta url={item} />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}

            <Grid direction="row" container justify="flex-end" spacing={2}>
              <Grid item></Grid>
              <Grid item>
                <Button
                  onClick={localCloseAction}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<CloseIcon />}
                  className={classes.buttonStyle}
                >
                  {strings('buttons.close')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      }
    />
  )
}

export default ImagePreviewModal
