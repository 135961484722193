import React from 'react'
import './styles.scss'
import { Card, CardContent, FormGroup, Slider } from '@mui/material'
import {
  ElectricBike,
  ElectricScooter,
  Lock,
  LockOpen,
  Moped,
  PedalBike,
  Wifi,
  WifiOff,
} from '@mui/icons-material'
import IconCheckbox from '../iconCheckbox/IconCheckbox'
import {
  DEFAULT_FILTER_PARAMS,
  LockState,
  LockStatus,
  MapFilterParams,
  STATION_TYPE_OPTIONS,
  StationStatus,
  StationType,
  VEHICLE_STATUS_OPTIONS,
  VehicleStatus,
  VehicleType,
} from '../../domain'
import LisaSelect from '../../../../components/lisaSelect/LisaSelect'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { isFilterParamsDefault } from '../../utils'
import Button from '@mui/material/Button'

interface Props {
  params: MapFilterParams
  onUpdateParams: (params: MapFilterParams) => void
  onClose: () => void
}

export default function MapFilter({ params, onUpdateParams, onClose }: Props) {
  const isVehicleTypeChecked = (vehicleType: VehicleType) => {
    return params.vehicleTypes.has(vehicleType)
  }

  const onVehicleTypeChange = (vehicleType: VehicleType, checked: boolean) => {
    const { vehicleTypes } = params

    checked ? vehicleTypes.add(vehicleType) : vehicleTypes.delete(vehicleType)

    const newParams: MapFilterParams = {
      ...params,
      vehicleTypes: new Set<VehicleType>(vehicleTypes),
    }

    onUpdateParams(newParams)
  }

  const onVehicleStatusChange = (vehicleStatuses: VehicleStatus[]) => {
    onUpdateParams({
      ...params,
      vehicleStatuses: new Set<VehicleStatus>(vehicleStatuses),
    })
  }

  const isLockStateChecked = (lockState: LockState) => {
    return params.lockStates.has(lockState)
  }

  const onLockStateChange = (lockState: LockState, checked: boolean) => {
    const { lockStates } = params

    checked ? lockStates.add(lockState) : lockStates.delete(lockState)

    const newParams: MapFilterParams = {
      ...params,
      lockStates: new Set<LockState>(lockStates),
    }

    onUpdateParams(newParams)
  }

  const isLockStatusChecked = (lockStatus: LockStatus) => {
    return params.lockStatuses.has(lockStatus)
  }

  const onLockStatusChange = (lockStatus: LockStatus, checked: boolean) => {
    const { lockStatuses } = params

    checked ? lockStatuses.add(lockStatus) : lockStatuses.delete(lockStatus)

    const newParams: MapFilterParams = {
      ...params,
      lockStatuses: new Set<LockStatus>(lockStatuses),
    }

    onUpdateParams(newParams)
  }

  const onLockBatteryLevelChange = (value: number | number[]) => {
    let [lockMinBattery, lockMaxBattery] = typeof value === 'number' ? [value, value] : value

    onUpdateParams({
      ...params,
      lockMinBattery,
      lockMaxBattery,
    })
  }

  const isStationStatusChecked = (stationStatus: StationStatus) => {
    return params.stationStatuses.has(stationStatus)
  }

  const onStationStatusChange = (stationStatus: StationStatus, checked: boolean) => {
    const { stationStatuses } = params

    checked ? stationStatuses.add(stationStatus) : stationStatuses.delete(stationStatus)

    const newParams: MapFilterParams = {
      ...params,
      stationStatuses: new Set<StationStatus>(stationStatuses),
    }

    onUpdateParams(newParams)
  }

  const onStationTypeChange = (stationTypes: StationType[]) => {
    onUpdateParams({
      ...params,
      stationTypes: new Set<StationType>(stationTypes),
    })
  }

  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseUp">
      <Card className="mapFilter">
        <CardContent>
          <div>
            <h5 className="mapFilterTitle">Vehicles</h5>

            <FormGroup row className="mapFilterFormGroup">
              <IconCheckbox
                IconClass={PedalBike}
                isChecked={isVehicleTypeChecked(VehicleType.BIKE)}
                onCheckChange={(checked) => onVehicleTypeChange(VehicleType.BIKE, checked)}
              />
              <IconCheckbox
                IconClass={ElectricBike}
                isChecked={isVehicleTypeChecked(VehicleType.E_BIKE)}
                onCheckChange={(checked) => onVehicleTypeChange(VehicleType.E_BIKE, checked)}
              />
              <IconCheckbox
                IconClass={ElectricScooter}
                isChecked={isVehicleTypeChecked(VehicleType.SCOOTER)}
                onCheckChange={(checked) => onVehicleTypeChange(VehicleType.SCOOTER, checked)}
              />
              <IconCheckbox
                IconClass={ElectricScooter}
                isChecked={isVehicleTypeChecked(VehicleType.E_SCOOTER)}
                onCheckChange={(checked) => onVehicleTypeChange(VehicleType.E_SCOOTER, checked)}
              />
              <IconCheckbox
                IconClass={Moped}
                isChecked={isVehicleTypeChecked(VehicleType.CARGO)}
                onCheckChange={(checked) => onVehicleTypeChange(VehicleType.CARGO, checked)}
              />
            </FormGroup>
          </div>

          <div className="mapFilterSection">
            <LisaSelect
              options={VEHICLE_STATUS_OPTIONS}
              value={[...params.vehicleStatuses]}
              onChange={(event) => onVehicleStatusChange(event.target.value as VehicleStatus[])}
              label={'Vehicle Status'}
            />
          </div>

          <div className="mapFilterSection">
            <h5 className="mapFilterTitle">Connectivity & Lock State</h5>

            <div className="mapFilterLocks">
              <FormGroup row className="mapFilterFormGroup">
                <IconCheckbox
                  IconClass={Lock}
                  isChecked={isLockStateChecked(LockState.CLOSED)}
                  onCheckChange={(checked) => onLockStateChange(LockState.CLOSED, checked)}
                />
                <IconCheckbox
                  IconClass={LockOpen}
                  isChecked={isLockStateChecked(LockState.OPEN)}
                  onCheckChange={(checked) => onLockStateChange(LockState.OPEN, checked)}
                />
              </FormGroup>

              <FormGroup row className="mapFilterFormGroup">
                <IconCheckbox
                  IconClass={Wifi}
                  isChecked={isLockStatusChecked(LockStatus.ONLINE)}
                  onCheckChange={(checked) => onLockStatusChange(LockStatus.ONLINE, checked)}
                />
                <IconCheckbox
                  IconClass={WifiOff}
                  isChecked={isLockStatusChecked(LockStatus.OFFLINE)}
                  onCheckChange={(checked) => onLockStatusChange(LockStatus.OFFLINE, checked)}
                />
              </FormGroup>
            </div>
          </div>

          <div className="mapFilterSection">
            <h5 className="mapFilterTitle">Battery Level</h5>

            <div className="mapFilterBatteryLevel">
              <Slider
                value={[params.lockMinBattery || 0, params.lockMaxBattery || 100]}
                step={10}
                onChange={(event, value) => onLockBatteryLevelChange(value)}
                disableSwap
              />
            </div>
          </div>

          <div className="mapFilterSection">
            <h5 className="mapFilterTitle">Zones</h5>

            <div className="mapFilterFormGroup">
              <IconCheckbox
                IconClass={Wifi}
                isChecked={isStationStatusChecked(StationStatus.ONLINE)}
                onCheckChange={(checked) => onStationStatusChange(StationStatus.ONLINE, checked)}
              />
              <IconCheckbox
                IconClass={WifiOff}
                isChecked={isStationStatusChecked(StationStatus.OFFLINE)}
                onCheckChange={(checked) => onStationStatusChange(StationStatus.OFFLINE, checked)}
              />
            </div>

            <LisaSelect
              options={STATION_TYPE_OPTIONS}
              value={[...params.stationTypes]}
              onChange={(event) => onStationTypeChange(event.target.value as StationType[])}
              label={'Type'}
            />
          </div>

          {!isFilterParamsDefault(params) && (
            <div className="mapFilterBottom">
              <Button
                onClick={() => onUpdateParams({ ...DEFAULT_FILTER_PARAMS, search: params.search })}
              >
                Set Defaults
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </ClickAwayListener>
  )
}
