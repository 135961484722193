import React from 'react'
import './styles.scss'
import { useStyles } from '../../../utils/styles'
import Pagination from '@mui/lab/Pagination'
import { connect } from 'react-redux'

function TablePaginationActions(props) {
  const classes = useStyles()
  const { count, page, onPageChange, pageSize } = props

  const handleChange = (event, value) => {
    onPageChange(event, value - 1)
  }

  return (
    <div className={classes.rootPaginator}>
      <Pagination
        count={Math.ceil(count / pageSize)}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={handleChange}
      />
    </div>
  )
}

const mapStateToProps = ({ screen: { pageSize } }) => ({
  pageSize,
})

export default connect(mapStateToProps, null)(TablePaginationActions)
