import React, { useMemo, useState } from 'react'
import { useCity } from '../../hooks'
import ModalContainer from '../modal/modal'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { exportVehiclesToFile, exportVehiclesToFileExample } from '../../apis/vehicle/vehicle-apis'
import { fileUtils } from '../../utils/fileUtils'
import { useFilters } from '../../hooks/filters-hooks'
import { useBikesResult } from '../../hooks/vehicles-hooks'

enum ExportMode {
  CURRENT = 'current',
  ALL = 'all',
  EXAMPLE = 'example',
}

interface Props {
  onSuccess: () => void
  onError: () => void
  onClose: () => void
}

export const ExportBox = ({ onSuccess, onError, onClose }: Props) => {
  const { savedCityFullInfo } = useCity()
  const { filtersMerged } = useFilters()
  const { bikesResult } = useBikesResult()
  const [exportMode, setExportMode] = useState<ExportMode>(ExportMode.CURRENT)

  const params = useMemo(() => {
    const page = exportMode === ExportMode.ALL ? 0 : bikesResult?.number
    const size = exportMode === ExportMode.ALL ? bikesResult?.totalElements : bikesResult?.number

    return {
      ...filtersMerged,
      page,
      size,
      cityId: savedCityFullInfo.id,
    }
  }, [filtersMerged, bikesResult, exportMode, savedCityFullInfo])

  const exportData = async () => {
    const isExample = exportMode === ExportMode.EXAMPLE
    const source = isExample ? exportVehiclesToFileExample() : exportVehiclesToFile(params)
    const fileName = isExample ? 'vehicles-example.xlsx' : 'vehicles.xlsx'

    source
      .then((response) => fileUtils.downloadFile(response?.data, fileName))
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <ModalContainer visible={true} closeAction={onClose}>
      <div>
        <Typography variant="h6" gutterBottom>
          Export Vehicles to Excel
        </Typography>

        <FormControl>
          <RadioGroup
            defaultValue={ExportMode.CURRENT}
            name="radio-buttons-group"
            onChange={(e) => setExportMode(e.target.value as ExportMode)}
          >
            <FormControlLabel
              value={ExportMode.CURRENT}
              control={<Radio />}
              label="Export Current Page (with applied filters)"
            />
            <FormControlLabel
              value={ExportMode.ALL}
              control={<Radio />}
              label="Export All (with applied filters)"
            />
            <FormControlLabel
              value={ExportMode.EXAMPLE}
              control={<Radio />}
              label="Export Example CSV"
            />
          </RadioGroup>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={exportData}>
            Export
          </Button>
        </Box>
      </div>
    </ModalContainer>
  )
}
