import * as routes from '../../constants/routes'
import DashboardItem from '../../components/items/dashboardItem'
import React from 'react'
import { useLocation } from 'react-router-dom'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import HandymanIcon from '@mui/icons-material/Handyman'
import EventIcon from '@mui/icons-material/Event'

export const AssetsMenu = ({ navigate }: any) => {
  const location = useLocation()

  return (
    <div className="dashboardMenu">
      <DashboardItem
        name={'Projects'}
        route={routes.ASSET_PROJECTS}
        IconComponent={AccountTreeIcon}
        isSelected={location.pathname === routes.ASSET_PROJECTS}
      />
      <DashboardItem
        name={'Assets'}
        route={routes.ASSET_ASSETS}
        IconComponent={HandymanIcon}
        isSelected={location.pathname === routes.ASSET_ASSETS}
      />
      <DashboardItem
        name={'Bookings'}
        route={routes.ASSET_BOOKINGS}
        IconComponent={EventIcon}
        isSelected={location.pathname === routes.ASSET_BOOKINGS}
      />
    </div>
  )
}
