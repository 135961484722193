import React from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import './styles.scss'
import { strings } from '../../../I18n'
import { sortedItem, stringByStatus, stringFromValue } from '../../../utils/utils'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import DropDownMenu from '../../menu'
import TabletPaginator from '../../table/tabletPaginator'
import { useStyles } from '../../../utils/styles'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import {
  USER_EMAIL,
  USER_ID,
  USER_NAME,
  USER_PHONE,
  USER_CREATION_DATE,
  USER_SUBSCRIPTION_TYPE,
  USER_SUBSCRIPTION_STATUS,
  // USER_PLAN_EXPIRY_DATE,
  USER_ACTIVE_CITY,
} from '../../../constants/orderConst'
import {
  CANCEL_SUBSCRIPTION,
  REMOVE_USER,
  SHOW_COUPON,
  SHOW_LEDGER,
} from '../../../constants/menuActions'
import { getUser } from '../../../actions/users'
import {
  ACTIVE,
  DELETED,
  EXPIRED,
  INACTIVE,
  PAY_AS_YOU_GO,
  SUBSCRIBED,
  UNSUBSCRIBED,
} from '../../../constants/const'

function ClientItem(props) {
  const { selectMenu, clients, clientsResult, handleChangePage, orderProps, cities, getUser } =
    props
  const classes = useStyles()

  const menuItems = React.useMemo(
    () => [
      {
        action: (client) => selectMenu({ action: REMOVE_USER, client }),
        title: strings('buttons.deleteUser'),
      },
      {
        action: (client) => selectMenu({ action: SHOW_COUPON, client }),
        title: strings('buttons.showCoupons'),
      },
      {
        action: (client) => selectMenu({ action: CANCEL_SUBSCRIPTION, client }),
        title: strings('buttons.cancelSubscription'),
      },
      {
        action: (client) => selectMenu({ action: SHOW_LEDGER, client }),
        title: strings('buttons.showLedger'),
      },
    ],
    [selectMenu]
  )

  //Backend requirements logic
  const clientDropDown = (client) => {
    if (client?.plan?.oneTime === true) {
      return (
        <DropDownMenu
          item={client}
          menuItems={menuItems.slice(0, 3)}
          current={strings('descriptions.actions')}
        />
      )
    } else {
      if (client?.plan?.type !== PAY_AS_YOU_GO && getSubscriptionStatus(client) === SUBSCRIBED) {
        return (
          <DropDownMenu
            item={client}
            menuItems={menuItems}
            current={strings('descriptions.actions')}
          />
        )
      } else {
        return (
          <DropDownMenu
            item={client}
            menuItems={[menuItems[0], menuItems[1], menuItems[3]]}
            current={strings('descriptions.actions')}
          />
        )
      }
    }
  }

  const momentDate = (date) => (date ? Moment(date + 'Z').format('DD.MM.YYYY HH:mm') : '-')

  const getNextBillingDate = (client) => {
    if (client?.plan?.oneTime === true) {
      return '-'
    } else {
      if (client?.plan?.canceledAt === null) {
        return client?.plan?.currentPeriodEnd
          ? Moment(client?.plan?.currentPeriodEnd + 'Z').format('DD.MM.YYYY HH:mm')
          : '-'
      } else {
        return '-'
      }
    }
  }

  //Backend requirements logic
  const getPlanExpiryDate = (client) => {
    if (client?.plan?.canceledAt !== null) {
      return client?.plan?.currentPeriodEnd
        ? Moment(client?.plan?.currentPeriodEnd + 'Z').format('DD.MM.YYYY HH:mm')
        : '-'
    } else if (client?.plan?.oneTime === true) {
      return client?.plan?.currentPeriodEnd
        ? Moment(client?.plan?.currentPeriodEnd + 'Z').format('DD.MM.YYYY HH:mm')
        : '-'
    } else {
      return '-'
    }
  }

  const showClientInfo = (client) => {
    getUser(client.id)
  }

  const clickOnLink = React.useCallback((client) => {
    if (!!client.stripeProfileUrl) {
      const win = window.open(client.stripeProfileUrl, '_blank')
      if (win != null) {
        win.focus()
      }
    }
  }, [])

  const clientLink = React.useCallback((client) => {
    return client.stripeProfileUrl && client?.stripeProfileUrl !== ''
      ? strings('descriptions.showPayment')
      : '-'
  }, [])

  const link = React.useCallback(
    (client) => (
      <p
        className={'bikeLink'}
        style={client && { color: 'blue', cursor: 'pointer' }}
        onClick={() => clickOnLink(client)}
      >
        {client?.stripeProfileUrl && clientLink(client)}
      </p>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clients]
  )

  const getPlanStatus = React.useCallback(
    (client) => {
      if (client?.plan?.type === PAY_AS_YOU_GO) {
        if (client?.plan?.status === ACTIVE) {
          return ACTIVE
        } else if (client?.plan?.status === INACTIVE) {
          return INACTIVE
        } else if (client?.plan?.status === UNSUBSCRIBED) {
          return '-'
        } else {
          return '-'
        }
      } else {
        if (client?.plan?.status === ACTIVE) {
          return ACTIVE
        } else if (client?.plan?.status === INACTIVE) {
          return INACTIVE
        } else {
          return '-'
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clients]
  )

  const getSubscriptionStatus = React.useCallback(
    (client) => {
      if (client?.plan?.type === PAY_AS_YOU_GO) {
        if (client?.plan?.status === ACTIVE || client?.plan?.status === INACTIVE) {
          return SUBSCRIBED
        } else if (client?.plan?.status === UNSUBSCRIBED) {
          return DELETED
        } else {
          return '-'
        }
      } else {
        if (client?.plan?.currentPeriodEnd === null) {
          return EXPIRED
        } else if (client?.plan?.canceledAt === null) {
          return SUBSCRIBED
        } else if (client?.plan?.canceledAt !== null) {
          return UNSUBSCRIBED
        } else {
          return '-'
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clients]
  )

  const titles = React.useMemo(
    () => [
      '',
      strings('descriptions.userId'),
      strings('descriptions.name'),
      strings('descriptions.email'),
      strings('descriptions.phone'),
      strings('descriptions.cityName'),
      strings('descriptions.accountStatus'),
      strings('descriptions.creationDate'),
      strings('descriptions.emailVerified'),
      strings('descriptions.subscription'),
      strings('descriptions.planType'),
      strings('descriptions.planStatus'),
      strings('descriptions.planExpiryDate'),
      strings('descriptions.nextBillingDate'),
      strings('descriptions.link'),
      '',
    ],
    []
  )

  const edit = React.useCallback(
    (user) => (
      <div className={'edit'} onClick={() => showClientInfo(user)}>
        <div className={'userLink'} style={{ padding: 5 }}>
          {strings('descriptions.edit')}
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const sortedFields = React.useMemo(
    () => [
      sortedItem(1, USER_ID, orderProps),
      sortedItem(2, USER_NAME, orderProps),
      sortedItem(3, USER_EMAIL, orderProps),
      sortedItem(4, USER_PHONE, orderProps),
      sortedItem(5, USER_ACTIVE_CITY, orderProps),
      sortedItem(6, USER_CREATION_DATE, orderProps),
      sortedItem(8, USER_SUBSCRIPTION_STATUS, orderProps),
      sortedItem(9, USER_SUBSCRIPTION_TYPE, orderProps),
      // sortedItem(10, USER_PLAN_EXPIRY_DATE, orderProps),
    ],
    [orderProps]
  )

  const getPricingPlanType = (baseType, isWallet) => {
    if (isWallet) {
      return strings('descriptions.wallet')
    } else {
      return baseType.replace(/_/g, ' ')
    }
  }

  const tabletBody = () => (
    <TableBody>
      {clients.map((client) => (
        <TableItemRow
          id={client.id}
          key={client.id}
          items={[
            edit(client),
            stringFromValue(client.id),
            stringFromValue(client.fullName),
            stringFromValue(client.email),
            stringFromValue(client.phone),
            stringFromValue(cities.find((city) => city.id === client?.plan?.cityId)?.name),
            stringFromValue(client.status),
            momentDate(client?.dateCreated),
            stringByStatus(client.emailVerified),
            // stringFromValue(client?.plan?.status.replace(/_/g, " ")),
            getSubscriptionStatus(client),
            getPricingPlanType(client?.plan?.type, client?.plan?.paymentMethodWallet),
            getPlanStatus(client),
            getPlanExpiryDate(client),
            getNextBillingDate(client),
            link(client),
            clientDropDown(client),
          ]}
        />
      ))}
    </TableBody>
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            {tabletBody()}
          </Table>
        </TableContainer>
        <TabletPaginator
          items={clients}
          pageData={clientsResult}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </div>
  )
}

const mapDispatchToProps = {
  getUser,
}

export default connect(null, mapDispatchToProps)(ClientItem)
