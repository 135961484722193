import { apiClient } from '../apiClient'
import { PricingPlan } from '../../domain/city'

export const getPricingPlans = (cityId: number): Promise<PricingPlan[]> => {
  return apiClient
    .get(`/payment-service/dashboard/v2/user/settings/cities/plans`, {
      params: {
        cityId,
      },
    })
    .then((r) => r.data.currentPlans)
}
