/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { FormInputDropdown } from '../../inputController/FormInputDropdown'
import InputController from '../../inputController'
import { Box, Button, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import { strings } from '../../../I18n'
import { CITYID, EMAIL, PASSWORD, WHITELABELID } from '../../../constants/const'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SwitchController from '../../inputController/SwitchController'

const UserAddModal = ({
  onSubmit,
  addModalVisible,
  handleClose,
  isEdit,
  setIsEdit,
  setAddModalVisible,
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '40%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block',
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '80%',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '1px solid white',
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '8px',
    },
  }

  const internalUser = useSelector((state) => state.users.internalUser)
  const internalUserSuccess = useSelector((state) => state.users.internalUserSuccess)
  const whiteLabels = useSelector((state) => state.cities.whiteLabels)
  const cityWhitelabelMapping = useSelector((state) => state.cities.cityWhitelabelMapping)
  const [visibleProperties, setVisibleProperties] = useState([EMAIL, PASSWORD])

  const [cityToWhitelabelMapping, setCityToWhitelabelMapping] = useState(null)

  const userTypes = [
    {
      value: 'SUPER_ADMIN',
      label: 'SUPER_ADMIN',
    },
    {
      value: 'WHITELABEL_ADMIN',
      label: 'WHITELABEL_ADMIN',
    },
    {
      value: 'CITY_ADMIN',
      label: 'CITY_ADMIN',
    },
  ]

  const remappedWhiteLabels = useMemo(() => {
    let remappedValues = whiteLabels?.map((whiteLabel) => {
      return {
        value: whiteLabel.id,
        label: whiteLabel.name,
      }
    })

    //update null value to empty string
    remappedValues = remappedValues?.map((whiteLabel) => {
      if (whiteLabel.value === null) {
        whiteLabel.value = 1000
      }
      return whiteLabel
    })

    // return remappedValues?.filter((whiteLabel) => whiteLabel.value !== null)
    return remappedValues
  }, whiteLabels)

  const schema = yup.object().shape({
    //  userType is required
    userType: yup.string().required('User type is required'),
    //  if CITY_ADMIN then cityId is required
    cityId: yup.string().when('userType', {
      is: 'CITY_ADMIN',
      then: yup.string().required('City is required'),
    }),
    //  email is check valid email
    email: yup.string().email('Email is not valid').required('Email is required'),
    //  if WHITELABEL_ADMIN then whitelabelId is required
    // whiteLabelId: yup.string().when('userType', {
    //   is: 'WHITELABEL_ADMIN',
    //   then: yup.string().required('Whitelabel is required'),
    // }),
    //whiteLabelId is required if userType is WHITELABEL_ADMIN or CITY_ADMIN
    whiteLabelId: yup.string().when('userType', {
      is: (userType) => userType === 'WHITELABEL_ADMIN' || userType === 'CITY_ADMIN',
      then: yup.string().required('Whitelabel is required'),
    }),
    //  password is required
    password: yup.string().required('Password is required'),
    // confirmPassword is required and should be same as password
    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const editSchema = yup.object().shape({
    //  userType is required
    userType: yup.string().required('User type is required'),
    //  if CITY_ADMIN then cityId is required
    cityId: yup
      .string()
      .nullable()
      .when('userType', {
        is: 'CITY_ADMIN',
        then: yup.string().required('City is required'),
      }),
    //  email is check valid email
    email: yup.string().email('Email is not valid').required('Email is required'),
    whiteLabelId: yup
      .string()
      .nullable()
      .when('userType', {
        is: (userType) => userType === 'WHITELABEL_ADMIN' || userType === 'CITY_ADMIN',
        then: yup.string().required('Whitelabel is required'),
      }),
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(isEdit ? editSchema : schema),
    defaultValues: {
      userType: 'SUPER_ADMIN',
    },
  })

  const getUserType = () => {
    if (internalUser?.cityId) {
      return 'CITY_ADMIN'
    } else if (internalUser?.whiteLabelId) {
      return 'WHITELABEL_ADMIN'
    }
    return 'SUPER_ADMIN'
  }

  useEffect(() => {
    if (internalUserSuccess === true) {
      setIsEdit(true)
      setAddModalVisible(true)
      reset({
        ...internalUser,
        userType: getUserType(),
        whiteLabelId:
          getUserType() === 'CITY_ADMIN' && internalUser?.whiteLabelId === null
            ? 1000
            : internalUser?.whiteLabelId,
      })
    }
  }, [internalUserSuccess])

  const userTypesWatch = watch('userType')
  const whiteLabelIdWatch = watch('whiteLabelId')

  useEffect(() => {
    let realId = whiteLabelIdWatch === 1000 ? null : whiteLabelIdWatch
    const whitelabel = cityWhitelabelMapping?.find((mapping) => mapping.id === realId)
    if (cityWhitelabelMapping) {
      setCityToWhitelabelMapping(() => {
        return whitelabel?.cities?.map((city) => {
          return {
            value: city.id,
            label: city.name,
          }
        })
      })
      setTimeout(() => {
        if (getValues()?.userType === 'CITY_ADMIN') {
          let checkCity = cityToWhitelabelMapping?.findIndex(
            (item) => item?.value === getValues()?.cityId
          )
          if (checkCity) {
            reset({
              ...getValues(),
              cityId: '',
            })
          }
        }
      }, 500)
    }
  }, [whiteLabelIdWatch])

  useEffect(() => {
    if (userTypesWatch) {
      if (userTypesWatch === 'WHITELABEL_ADMIN') {
        setVisibleProperties([EMAIL, PASSWORD, WHITELABELID])
      } else if (userTypesWatch === 'CITY_ADMIN') {
        setVisibleProperties([EMAIL, PASSWORD, WHITELABELID, CITYID])
      } else {
        setVisibleProperties([EMAIL, PASSWORD])
      }
    }
  }, [userTypesWatch])

  useEffect(() => {
    if (!addModalVisible) {
      reset({
        userType: getUserType(),
      })
    }
  }, [addModalVisible])

  return (
    <Modal
      open={addModalVisible}
      onClose={handleClose}
      className={modalStyle}
      style={{ height: window.innerHeight }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...control}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInputDropdown
              name="userType"
              options={userTypes}
              errors={errors}
              label="User type"
              required={false}
              styles={{
                minWidth: '400px',
              }}
            />

            <InputController
              name={'email'}
              label={'Email'}
              required={true}
              disabled={!!isEdit}
              styles={{
                minWidth: '400px',
              }}
            />

            {visibleProperties && visibleProperties.includes(PASSWORD) && !isEdit && (
              <InputController
                name={'password'}
                label={'password'}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
                muiProps={{
                  type: 'password',
                }}
              />
            )}

            {visibleProperties && visibleProperties.includes(PASSWORD) && !isEdit && (
              <InputController
                name={'confirmPassword'}
                label={'Confirm Password'}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
                muiProps={{
                  type: 'password',
                }}
              />
            )}

            {visibleProperties && visibleProperties.includes(WHITELABELID) && (
              <FormInputDropdown
                name="whiteLabelId"
                options={remappedWhiteLabels}
                errors={errors}
                label={'Select Whitelabel'}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
              />
            )}

            {visibleProperties && visibleProperties.includes(CITYID) && (
              <FormInputDropdown
                name="cityId"
                options={cityToWhitelabelMapping}
                errors={errors}
                label={strings('coupon.selectCity')}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
              />
            )}

            <SwitchController
              control={control}
              name="developerAccount"
              errors={errors}
              label={strings('descriptions.isDeveloperAccount')}
              disabled={false}
            />

            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Button type="submit" variant="contained" color="primary">
                  {isEdit ? strings('coupon.update') : strings('coupon.add')}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setAddModalVisible(false)
                  }}
                >
                  {strings('coupon.dismiss')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}

export default UserAddModal
