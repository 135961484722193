import React from 'react'
import { FormControl, TextField, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Controller, useFormContext } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import HelpIcon from '@mui/icons-material/Help'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '16px',
  },
}))

const InputController = ({
  name,
  label,
  required,
  multiline,
  extraRules,
  muiProps,
  styles,
  disabled,
  inputPrefix,
  info,
  control,
  convertType,
}) => {
  const scontrol = useFormContext()
  const dcontrol = control || scontrol
  const classes = useStyles()

  const parsedErrorMessage = (message) => {
    return message?.message || message?.types?.required
  }
  return (
    <FormControl style={{ marginBottom: 10, marginTop: 10, ...styles }}>
      <Controller
        name={name}
        control={dcontrol}
        rules={{
          required: { value: !!required, message: required ? `${name} is required` : '' },
          ...extraRules,
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <TextField
            {...field}
            {...muiProps}
            disabled={disabled}
            inputRef={ref}
            id={name}
            autoComplete={name}
            multiline={!!multiline}
            value={convertType === 'cent' ? field.value / 100 : field.value}
            onChange={(event) => {
              if (convertType === 'cent') {
                field.onChange(event.target.value * 100)
              } else {
                field.onChange(event.target.value)
              }
            }}
            variant="outlined"
            fullWidth={true}
            error={!!error}
            helperText={error ? parsedErrorMessage(error) : null}
            label={label}
            InputProps={{
              startAdornment: inputPrefix ? (
                <InputAdornment position="start">{inputPrefix}</InputAdornment>
              ) : null,
              endAdornment: info && (
                <>
                  <Tooltip
                    arrow
                    title={info}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <HelpIcon />
                  </Tooltip>
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default InputController
