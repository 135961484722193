import React from 'react'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'

import ModalContainer from '../modal'
import { useStyles } from '../../../utils/styles'
import ModalText from '../modalTextField'
import { strings } from '../../../I18n'
import { useInput, useShowToast } from '../../../hooks'

function ClientModal(props) {
  const { visible, client = null, closeUserModal, updateUser } = props
  const classes = useStyles()
  const showToast = useShowToast()
  const [clientId, setClientId] = React.useState('')
  const name = useInput('')
  const email = useInput('')

  const initValue = () => {
    setClientId(client?.id)
    name.initValue(client?.fullName)
    email.initValue(client?.email)
  }

  React.useEffect(() => {
    initValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const save = () => {
    if (name.value === '') {
      showToast('fill name field', 'error')
      return
    } else if (email.value === '') {
      showToast('fill email field', 'error')
      return
    }
    const body = {
      fullName: name.value,
      email: email.value,
    }
    updateUser(clientId, body)
  }

  const userInfoContainer = (
    <div>
      <ModalText label={strings('descriptions.id')} value={clientId} />
      <ModalText label={strings('descriptions.userName')} {...name} />
      <ModalText label={strings('descriptions.email')} {...email} />
      <ModalText label={strings('descriptions.phone')} value={client?.phone} />
    </div>
  )

  const buttonsContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton onClick={save} variant="contained">
        {strings('descriptions.save')}
      </ActionButton>
      <ActionButton onClick={closeUserModal} variant="contained">
        {strings('buttons.discard')}
      </ActionButton>
    </div>
  )

  return (
    <ModalContainer
      className={classes.modal}
      visible={visible}
      closeAction={closeUserModal}
      body={
        <>
          <div>
            <div>{userInfoContainer}</div>
            {buttonsContainer()}
          </div>
        </>
      }
    />
  )
}

export default ClientModal
