import { GET_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import * as endpoints from '../configs/endpoints'
import * as types from '../constants/actionTypes'

export const getRentals = (page = 0, search = '', sort = 'id,desc', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.rentals(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [types.GET_RENTALS_REQUEST, types.GET_RENTALS_SUCCESS, types.GET_RENTALS_FAILURE],
  }
}

export const getRentalsForExcel = (
  page = 0,
  search = '',
  sort = 'id,desc',
  filter = '',
  pageSize
) => {
  return {
    endpoint: endpoints.rentals(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [
      types.GET_RENTALS_FOR_EXCEL_REQUEST,
      types.GET_RENTALS_FOR_EXCEL_SUCCESS,
      types.GET_RENTALS_FOR_EXCEL_FAILURE,
    ],
  }
}

export const clearRentalsForExcel = () => {
  return {
    type: types.CLEAR_RENTALS_FOR_EXCEL,
  }
}

export const getRentalById = (id) => {
  return {
    endpoint: endpoints.rentalById(id),
    method: GET_METHOD,
    types: [
      types.GET_RENTAL_BY_ID_REQUEST,
      types.GET_RENTAL_BY_ID_SUCCESS,
      types.GET_RENTAL_BY_ID_FAILURE,
    ],
  }
}

export const finishRent = (id, body) => {
  return {
    endpoint: endpoints.finishRent(id),
    method: POST_METHOD,
    types: [
      types.POST_FINISH_RENT_REQUEST,
      types.POST_FINISH_RENT_SUCCESS,
      types.POST_FINISH_RENT_FAILURE,
    ],
    body,
  }
}

export const updateRental = (id, body) => {
  return {
    endpoint: endpoints.rentalById(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_RENTAL_BY_ID_REQUEST,
      types.UPDATE_RENTAL_BY_ID_SUCCESS,
      types.UPDATE_RENTAL_BY_ID_FAILURE,
    ],
    body,
  }
}

export const rentalsVehiclesList = () => {
  return {
    endpoint: endpoints.rentalsVehiclesList(),
    method: GET_METHOD,
    types: [
      types.GET_RENTAL_VEHICLE_REQUEST,
      types.GET_RENTAL_VEHICLE_SUCCESS,
      types.GET_RENTAL_VEHICLE_FAILURE,
    ],
  }
}

export const getFinallyDataOfRental = (id) => {
  return {
    endpoint: endpoints.finallyDataOfRental(id),
    method: GET_METHOD,
    types: [
      types.GET_FINALLY_DATA_OF_RENTAL_REQUEST,
      types.GET_FINALLY_DATA_OF_RENTAL_SUCCESS,
      types.GET_FINALLY_DATA_OF_RENTAL_FAILURE,
    ],
  }
}

export const rentalsDockList = (id = null) => {
  return {
    endpoint: endpoints.rentalsDockList(id),
    method: GET_METHOD,
    types: [
      types.GET_RENTAL_DOCS_REQUEST,
      types.GET_RENTAL_DOCS_SUCCESS,
      types.GET_RENTAL_DOCS_FAILURE,
    ],
  }
}

export const rentalsUserList = (search) => {
  return {
    endpoint: endpoints.rentalUserList(search),
    method: GET_METHOD,
    types: [
      types.GET_RENTAL_USER_REQUEST,
      types.GET_RENTAL_USER_SUCCESS,
      types.GET_RENTAL_USER_FAILURE,
    ],
  }
}

export const mainUserList = (search) => {
  return {
    endpoint: endpoints.userList(search),
    method: GET_METHOD,
    types: [
      types.GET_MAIN_RENTAL_USER_REQUEST,
      types.GET_MAIN_RENTAL_USER_SUCCESS,
      types.GET_MAIN_RENTAL_USER_FAILURE,
    ],
  }
}

export const getRentalsStatuses = () => {
  return {
    endpoint: endpoints.rentalStatuses(),
    method: GET_METHOD,
    types: [
      types.GET_RENTALS_STATUSES_REQUEST,
      types.GET_RENTALS_STATUSES_SUCCESS,
      types.GET_RENTALS_STATUSES_FAILURE,
    ],
  }
}

export const clearRentalsData = () => {
  return {
    type: types.CLEAR_RENTAL_DATA,
  }
}

export const clearRental = () => {
  return {
    type: types.CLEAR_RENTAL,
  }
}

export const getVehicleByCode = (code) => {
  return {
    endpoint: endpoints.getVehicleByCode(code),
    method: GET_METHOD,
    types: [
      types.GET_VEHICLE_BY_CODE_REQUEST,
      types.GET_VEHICLE_BY_CODE_SUCCESS,
      types.GET_VEHICLE_BY_CODE_FAILURE,
    ],
  }
}

export const getImageDirectoryDataAction = (id) => {
  return {
    endpoint: endpoints.getImageDirectoryDataEndpoint(id),
    method: GET_METHOD,
    types: [
      types.GET_IMAGE_DIRECTORY_DATA_REQUEST,
      types.GET_IMAGE_DIRECTORY_DATA_SUCCESS,
      types.GET_IMAGE_DIRECTORY_DATA_FAILURE,
    ],
  }
}

export const clearImageDirectoryData = () => {
  return {
    type: types.CLEAR_IMAGE_DIRECTORY_DATA,
  }
}
