import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { style } from '../../../utils/styles'

const ModalDropdownField = ({ label, value, onChange, data }) => {
  const [lData, setLData] = useState(value)
  return (
    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-filled-label">{label}</InputLabel>
      <Select
        style={style.modalText}
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select"
        value={lData}
        label={label}
        onChange={(e) => {
          setLData(e.target.value)
          onChange(e.target.value)
        }}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ModalDropdownField
