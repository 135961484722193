import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import * as routes from '../../../constants/routes'

function DashboardItem(props) {
  const { name, action, isHeaderItem, icon, IconComponent, isSelected = false, route } = props
  const [div, setDiv] = React.useState(null)

  let background = isSelected ? 'whitesmoke' : 'white'

  React.useEffect(() => {
    setDiv(document.getElementById(name))
  }, [name])

  const onMouseOver = () =>
    !isSelected && action && div?.setAttribute('style', 'background-color:whitesmoke;')

  const onMouseOut = () =>
    !isSelected && action && div?.setAttribute('style', 'background-color:white;')
  return isHeaderItem ? (
    <div
      id={name}
      className={'dashboardHeaderItem'}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{ background }}
    >
      {icon && <img src={icon} alt={''} height="22" width="22" />}
      {!!IconComponent && <IconComponent sx={{ width: 22, height: 22, color: 'black' }} />}
      <p className={'item'}>{name}</p>
    </div>
  ) : (
    <Link
      id={name}
      to={route || routes.DASHBOARD_SCREEN}
      className={'dashboardItem'}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={action}
      style={{ background }}
    >
      {icon && <img src={icon} alt={''} height="22" width="22" />}
      {!!IconComponent && <IconComponent sx={{ width: 22, height: 22, color: 'black' }} />}
      <p className={'item'}>{name}</p>
    </Link>
  )
}

export default DashboardItem
