/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Paper, TextField } from '@mui/material'
import { useStyles } from '../../utils/styles'
import {
  disable2FA,
  enrollSecondFactor,
  isFirebase2FaEnabled,
  isMailVerified,
  refreshCurrentUser,
  setupRecaptcha,
  userChangeListener,
  verifyEmail,
  verifyOTP,
} from '../../utils/firebase'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { connect, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { logOut } from '../../actions/auth'
import {
  setMapBikeStateFilter,
  setMapStationStateFilter,
  setMapStationTypeFilter,
  setMapTypeFilter,
} from '../../actions/screen'
import { clearSavedCity, selectCity, selectCityFullInfo } from '../../actions/cities'
import { bikesStates, itemsTypes, stationStates, stationTypes } from '../../constants/const'
import { useTimer } from 'use-timer'
import { secondsToMin } from '../../utils/utils'
import { strings } from '../../I18n'

let interval = null
let otpInterval = null

const MFAContainer = (props) => {
  const {
    logOut,
    setMapTypeFilter,
    setMapBikeStateFilter,
    setMapStationTypeFilter,
    setMapStationStateFilter,
    clearSavedCity,
  } = props
  const classes = useStyles()

  const adminDetails = useSelector((state) => state.auth.adminDetails)

  const [email, setEmail] = useState('')
  const [isCapchaVerified, setIsCapchaVerified] = useState()

  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isPhoneNumberVerify, setIsPhoneNumberVerify] = useState(false)
  const [isResendOTP, setIsResendOTP] = useState(false)
  const [message, setMessage] = useState()
  const [is2FaEnabled, setIs2FaEnabled] = useState(false)
  const [otp, setOtp] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isSendPhoneNumberVerifyClicked, setIsSendPhoneNumberVerifyClicked] = useState(false)
  const { time, start, pause, reset, status } = useTimer({
    initialTime: 120,
    timerType: 'DECREMENTAL',
  })

  useEffect(() => {
    if (time === 0) {
      reset()
      setIsResendOTP(false)
      setIsSendPhoneNumberVerifyClicked(false)
    }
  }, [time])

  useEffect(() => {
    if (isSendPhoneNumberVerifyClicked) {
      reset()
      start()
    }
  }, [isSendPhoneNumberVerifyClicked])

  useEffect(() => {
    userChangeListener((firebaseUser) => {
      setIsEmailVerified(firebaseUser.emailVerified)
    })
  }, [])

  // Log out

  const logoutAction = useCallback(() => {
    setMapTypeFilter(itemsTypes)
    setMapBikeStateFilter(bikesStates)
    setMapStationTypeFilter(stationTypes)
    setMapStationStateFilter(stationStates)
    clearSavedCity()
    logOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsCapchaVerified(false)
    setMessage('')
    setupRecaptcha(
      () => {
        setIsCapchaVerified(true)
        setMessage('')
      },
      () => {
        setIsCapchaVerified(false)
      }
    )
  }, [])

  useEffect(() => {
    if (adminDetails?.email) {
      setEmail(adminDetails?.email)
    }
  }, [adminDetails])
  useEffect(() => {
    checkIsMailVerifiedOrnot()
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setIs2FaEnabled(isFirebase2FaEnabled())
  }, [])

  const checkIsMailVerifiedOrnot = () => {
    interval = setInterval(() => {
      refreshCurrentUser()
      let data = isMailVerified()
      if (data) {
        setIsEmailVerified(true)
        clearInterval(interval)
      } else {
        setIsEmailVerified(false)
      }
    }, 1000)
  }

  const localVerifyEmail = () => {
    let data = verifyEmail()
    data
      .then(() => {
        setMessage('Please check your email and click on the link to verify your email')
      })
      .catch((err) => {
        setMessage(err.message)
      })
  }

  const add2Fa = () => {
    setMessage('')
    if (isEmailVerified === false) {
      setMessage('Please verify your email first before adding 2FA')
      return
    }
    if (!isCapchaVerified) {
      setMessage('Please verify your capcha')
    } else if (!phoneNumber) {
      setMessage('Please enter phone number')
    }

    if (isCapchaVerified && phoneNumber) {
      enrollSecondFactor(
        '+' + phoneNumber,
        (err) => {
          setMessage(err?.message)
          setIsSendPhoneNumberVerifyClicked(false)
          setIsCapchaVerified(false)
        },
        () => {
          setIsSendPhoneNumberVerifyClicked(true)
          setIsCapchaVerified(false)
          reset()
          start()
        }
      )
      setMessage('Please enter OTP')
      !isResendOTP && setIsResendOTP(true)
    }
  }
  const verifyOtpLocal = () => {
    if (otp) {
      verifyOTP(otp)
        .then(() => {
          clearInterval(otpInterval && otpInterval)
          setSuccessMessage('OTP verified successfully 2FA is enabled. Please login again')
          setMessage('')
          setIsPhoneNumberVerify(true)
          setTimeout(() => {
            logoutAction()
          }, 2000)
        })
        .catch((err) => {
          setMessage(err?.message || 'Invalid OTP please try again')
        })
    } else {
      setMessage('Please enter OTP that you received on your phone')
    }
  }
  const localDisable2fa = () => {
    disable2FA(() => {
      setMessage('2FA disabled')
    })
    setTimeout(() => {
      logoutAction()
    }, 1000)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ padding: 20 }}>
        <div style={{ marginTop: 20 }}>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 20,
              color: 'red',
            }}
          >
            {message}
          </p>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 20,
              color: 'green',
            }}
          >
            {successMessage}
          </p>
          {is2FaEnabled ? (
            <Button
              style={{ marginBottom: 20 }}
              variant="contained"
              color="secondary"
              onClick={localDisable2fa}
            >
              {strings('disableMultiFactorAuthentication')}
            </Button>
          ) : (
            <>
              <div id="recaptcha-container"></div>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>
                    <p>{strings('descriptions.email')}</p>
                  </td>
                  <td>
                    <TextField
                      style={{ width: '100%' }}
                      disabled
                      id="standard-name"
                      label={strings('placeholders.email')}
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      margin="normal"
                    />
                  </td>
                  <td>
                    {!isEmailVerified ? (
                      <Button variant="contained" color="primary" onClick={localVerifyEmail}>
                        {strings('verifyEmail')}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: 200,
                        }}
                      >
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{strings('placeholders.phone')}</p>
                  </td>
                  <td>
                    {/*<TextField*/}
                    {/*  style={{ width: '100%' }}*/}
                    {/*  id="standard-name"*/}
                    {/*  label="PhoneNumber"*/}
                    {/*  type="text"*/}
                    {/*  value={phoneNumber}*/}
                    {/*  onChange={(event) => setPhoneNumber(event.target.value)}*/}
                    {/*  margin="normal"*/}
                    {/*/>*/}
                    <PhoneInput
                      containerStyle={{
                        width: '100%',
                      }}
                      inputStyle={{
                        width: '100%',
                        height: 45,
                      }}
                      country={'us'}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </td>
                  <td>
                    {!isPhoneNumberVerify ? (
                      <>
                        {isResendOTP ? (
                          <Button variant="contained" color="primary" onClick={add2Fa}>
                            {strings('resendOtp')}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={add2Fa}
                            disabled={!isEmailVerified}
                          >
                            {strings('verifyPhoneNumber')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          width: 200,
                        }}
                      >
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{strings('otp')}</p>
                  </td>
                  <td>
                    {isSendPhoneNumberVerifyClicked && (
                      <p
                        style={{
                          fontSize: 20,
                          color: 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {`${secondsToMin(time)} left`}
                      </p>
                    )}

                    <TextField
                      style={{ width: '100%' }}
                      id="standard-name"
                      label={strings('otp')}
                      type="number"
                      value={otp}
                      onChange={(event) => setOtp(event.target.value)}
                      margin="normal"
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={verifyOtpLocal}
                      disabled={!isSendPhoneNumberVerifyClicked}
                    >
                      {strings('verifyOtp')}
                    </Button>
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
      </Paper>
    </div>
  )
}

// export default MFAContainer

const mapStateToProps = ({ auth: { adminDetails }, cities: { citiesList, savedCity } }) => ({
  adminDetails,
  citiesList,
  savedCity,
})

const mapDispatchToProps = {
  logOut,
  setMapTypeFilter,
  setMapBikeStateFilter,
  setMapStationTypeFilter,
  setMapStationStateFilter,
  selectCity,
  clearSavedCity,
  selectCityFullInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(MFAContainer)
