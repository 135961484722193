import * as endpoints from "../configs/endpoints";
import { GET_METHOD, POST_METHOD } from "../constants/apiMethods";
import * as types from "../constants/actionTypes";

export const getLocks = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getLocks(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [
      types.GET_LOCKS_REQUEST,
      types.GET_LOCKS_SUCCESS,
      types.GET_LOCKS_FAILURE
    ]
  }
};

export const unlockLock = (id) => {
  return {
    endpoint: endpoints.unlockLocks(id),
    method: POST_METHOD,
    types: [
      types.UNLOCK_LOCK_REQUEST,
      types.UNLOCK_LOCK_SUCCESS,
      types.UNLOCK_LOCK_FAILURE
    ]
  }
};
