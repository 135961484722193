import { ImageListItem, ImageListItemBar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Rental } from '../../domain/rental'
import { constructImageDirectory } from '../../utils/utils'
import * as Storage from 'firebase/storage'
import { PricingPlan } from '../../domain/city'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

interface Props {
  rental: Rental
  cityCode: string
  plans: PricingPlan[]
}

export const RentalsGalleryItem = ({ rental, cityCode, plans }: Props) => {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')

  useEffect(() => {
    setLoading(true)

    const directory = constructImageDirectory({
      cityCode,
      pricingPlanType: plans[0]?.type,
      id: rental.id,
    })

    const storage = Storage.getStorage()
    const storageRef = Storage.ref(storage, directory)

    Storage.list(storageRef, { maxResults: 1 })
      .then(({ items: [item] }) => {
        return item ? Storage.getDownloadURL(item) : ''
      })
      .then((url) => {
        setUrl(url)
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) return <CircularProgress size={50} />

  return (
    <ImageListItem>
      {url ? <img src={url} alt="" /> : <div style={{ height: 200 }}></div>}
      <ImageListItemBar
        title={rental.id}
        subtitle={rental.status}
        actionIcon={<Button>View</Button>}
      />
    </ImageListItem>
  )
}
