import React, { useRef, useState } from 'react'
import './styles.scss'
import sampleParkingIcon from '../../../images/stations/sampleParkingIcon.svg'
import { strings } from '../../../I18n'
import ModalDropdownField from '../../../components/modal/modalDropdonField'
import ModalText from '../../../components/modal/modalTextField'
import { useOnClickOutside } from '../../../hooks'

const CustomImageMarker = ({ station, statuses }) => {
  const [localSelectedStation, setLocalSelectedStation] = useState()
  const ref = useRef()

  const handleStationClick = (lstation) => {
    if (localSelectedStation) {
      setLocalSelectedStation(null)
    } else {
      setLocalSelectedStation(lstation)
    }
  }

  useOnClickOutside(ref, () => setLocalSelectedStation(null))

  const returnModalText = (label, value, onChange) => {
    if (onChange) {
      return <ModalDropdownField label={label} value={value} onChange={onChange} data={statuses} />
    } else {
      return (
        <ModalText
          styles={{ height: 35 }}
          {...{ label }}
          visible={!!localSelectedStation}
          {...{ value }}
        />
      )
    }
  }

  const virtualStationInput = () => (
    <>
      {returnModalText(strings('descriptions.stationName'), localSelectedStation?.name || '')}
      {/*{returnModalText(strings('descriptions.docksNumber'), localSelectedStation?.dockCount || 0)}*/}
      {returnModalText('Total number of bikes', localSelectedStation?.vehicleCount || 0)}
      {/*{returnModalText(*/}
      {/*  strings('descriptions.bikesNumber'),*/}
      {/*  localSelectedStation?.availableVehicleCount || 0*/}
      {/*)}*/}
      {/*{returnModalText(*/}
      {/*  strings('descriptions.brokenNumber'),*/}
      {/*  localSelectedStation?.notAvailableVehicleCount || 0*/}
      {/*)}*/}
    </>
  )

  return (
    <>
      <div
        className="custom-virtual-station-marker"
        onClick={() => handleStationClick(station)}
        ref={ref}
      >
        <img className="custom-virtual-station-marker__icon" src={sampleParkingIcon} alt="" />
      </div>

      {localSelectedStation && (
        <div className="markerPopupCustomImageAnimated">{virtualStationInput()}</div>
      )}
    </>
  )
}

export default CustomImageMarker
