import React from 'react'
import './styles.scss'
import Button from '@mui/material/Button'

function ClickedBlock(props) {
  const { description, title, action } = props

  return (
    <div className={'modalClickedBlockContainer'}>
      <p className={'descriptionModal'}>{description}</p>
      <Button
        className={'clickedBlockButton'}
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={action}
      >
        {title}
      </Button>
    </div>
  )
}

export default ClickedBlock
