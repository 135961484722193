import React, { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { LoadingButton } from '@mui/lab'
import { getAssetAssets } from '../../../../apis/asset/asset-asset-apis'
import { AssetAsset, AssetBookingDto, AssetProject } from '../../../../domain/asset'
import { getAssetProjects } from '../../../../apis/asset/asset-project-apis'
import { useSelector } from 'react-redux'
import Autocomplete from '@mui/lab/Autocomplete'
import { DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { createAssetBooking } from '../../../../apis/asset/asset-booking-apis'

interface Props {
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  assetId: number
  projectId: string
  startDate: moment.Moment
  endDate: moment.Moment
}

export const AssetBookingsAdd = ({ onClose }: Props) => {
  const minDateRef = useRef<moment.Moment>(moment())
  const cityId = useSelector((s: any) => s?.auth?.adminDetails?.cityId)
  const [selectedAsset, setSelectedAsset] = useState<AssetAsset | null>(null)
  const [assets, setAssets] = useState<AssetAsset[]>([])
  const [selectedProject, setSelectedProject] = useState<AssetProject | null>(null)
  const [projects, setProjects] = useState<AssetProject[]>([])
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const [loading, setLoading] = useState(false)

  const getIsValid = () => {
    if (!selectedAsset) return false
    if (!!selectedProject) return true

    return !!startDate && !!endDate && endDate.isAfter(startDate)
  }

  const handleProjectChange = (value: AssetProject | null) => {
    setSelectedProject(value)
    // Project could be started in the past, but not finished yet, so start date is now
    setStartDate(value?.startDate ? moment.max(moment(value.startDate), moment()) : null)
    setEndDate(value?.endDate ? moment(value.endDate) : null)
  }

  const handleSubmit = () => {
    let dto: AssetBookingDto

    if (!!selectedProject) {
      dto = {
        assetId: selectedAsset?.id || 0,
        projectId: selectedProject?.id || 0,
      }
    } else {
      dto = {
        assetId: selectedAsset?.id || 0,
        startDate: startDate?.toDate() || minDateRef.current.toDate(),
        endDate: endDate?.toDate() || minDateRef.current.toDate(),
      }
    }

    setLoading(true)
    createAssetBooking(dto)
      .then(() => onClose(true))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAssetAssets().then((assets) => setAssets(assets))
    getAssetProjects(cityId, true).then((projects) => setProjects(projects))
  }, [cityId])

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '50%', height: '50%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">Add Booking</Typography>

            <Box flexGrow={1}>
              <Stack spacing={3}>
                <Autocomplete
                  value={selectedProject}
                  onChange={(event, value) => handleProjectChange(value)}
                  options={projects}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => <TextField {...params} label="Project (Optional)" />}
                />

                <Box display="flex" gap={2} width="100%">
                  <DateTimePicker
                    label="Start date"
                    sx={{ flexGrow: 1 }}
                    minDateTime={minDateRef.current}
                    disabled={!!selectedProject}
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                  />

                  <DateTimePicker
                    label="End date"
                    sx={{ flexGrow: 1 }}
                    minDateTime={startDate || minDateRef.current}
                    disabled={!!selectedProject}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                  />
                </Box>

                <Autocomplete
                  value={selectedAsset}
                  onChange={(event, value) => setSelectedAsset(value)}
                  options={assets}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => <TextField {...params} label="Asset" />}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!getIsValid()}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
