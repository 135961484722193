import { apiClientJSONAssets } from '../apiClient'
import { AssetAsset, AssetAssetDto } from '../../domain/asset'

export const getAssetAssets = (): Promise<AssetAsset[]> => {
  return apiClientJSONAssets.get(`/asset`).then((r) => r.data)
}

export const getAssetAssetsAvailable = (startDate: Date, endDate: Date): Promise<AssetAsset[]> => {
  return apiClientJSONAssets
    .get(`/asset/available`, {
      params: {
        startDate,
        endDate,
      },
    })
    .then((r) => r.data)
}

export const createAssetAsset = (dto: AssetAssetDto): Promise<void> => {
  return apiClientJSONAssets.post(`/asset`, dto)
}

export const updateAssetAsset = (assetId: number, dto: AssetAssetDto): Promise<void> => {
  return apiClientJSONAssets.patch(`/asset/${assetId}`, dto)
}

export const deleteAssetAsset = (assetId: number): Promise<void> => {
  return apiClientJSONAssets.delete(`/asset/${assetId}`)
}
