import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { logOut } from '../../actions/auth'
import i18n, { localeLoad, strings } from '../../I18n'
import {
  setMapBikeStateFilter,
  setMapStationStateFilter,
  setMapStationTypeFilter,
  setMapTypeFilter,
} from '../../actions/screen'
import { bikesStates, itemsTypes, stationStates, stationTypes } from '../../constants/const'
import {
  AppBar,
  Button,
  ClickAwayListener,
  FormControl,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Toolbar,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { clearSavedCity, selectCity, selectCityFullInfo } from '../../actions/cities'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import VpnKey from '@mui/icons-material/VpnKey'
import FlagIcon from '@mui/icons-material/Flag'
import { getWhiteLabelLogo } from '../../utils/utils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTheme } from '../../hooks'
import { MFA_ROUTE } from '../../constants/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { logoutFromFirebase } from '../../utils/firebase'
import './styles.scss'
import InputBase from '@mui/material/InputBase/InputBase'

const Header = (props) => {
  const {
    adminDetails,
    logOut,
    setMapTypeFilter,
    setMapBikeStateFilter,
    setMapStationTypeFilter,
    setMapStationStateFilter,
    citiesList,
    savedCity,
    clearSavedCity,
  } = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [item, setItem] = React.useState('')
  const [sortedCityList, setSortedCityList] = useState([])
  const [city, setCity] = useState(null)
  const [logo, setLogo] = useState(null)

  const theme = useTheme()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setLogo(getWhiteLabelLogo(adminDetails?.whiteLabelCode))
  }, [adminDetails])

  useEffect(() => {
    if (savedCity && citiesList?.length > 0) {
      citiesList.forEach((city) => {
        if (city.id === parseInt(savedCity.split('cityId=')[1])) {
          setCity(city)
          setItem(city)
        }
      })
    }
  }, [savedCity, citiesList])

  useEffect(() => {
    if (citiesList.length > 0) {
      let sortedCityList = [...citiesList]
      sortedCityList.sort((a, b) => a.name.localeCompare(b.name))
      setSortedCityList(sortedCityList)
    }
  }, [citiesList])

  const onSelect = (event) => {
    if (event.target.value === '') {
      //setFilter([{ filter: "", filterBy: "" }]);
      props.selectCityFullInfo('')
      props.selectCity('')
    } else {
      //setFilter([{ filter: event.target.value.name, filterBy: "cityId" }]);
      props.selectCityFullInfo(event.target.value)
      props.selectCity(`cityId=${event.target.value.id}`)
    }
    setItem(event.target.value)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const logoutAction = useCallback(() => {
    setMapTypeFilter(itemsTypes)
    setMapBikeStateFilter(bikesStates)
    setMapStationTypeFilter(stationTypes)
    setMapStationStateFilter(stationStates)
    clearSavedCity()
    logOut()
    logoutFromFirebase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadLang = useCallback(async () => {
    if (i18n.defaultLocale === 'nb') {
      await localeLoad('en')
    } else {
      await localeLoad('nb')
    }

    navigate(location.pathname, { replace: true })
  }, [navigate, location.pathname])

  return (
    <div className="headerRoot">
      <AppBar
        position="static"
        style={{
          backgroundColor: theme.navBackground,
        }}
      >
        <Toolbar className="headerHeader">
          <img
            src={logo}
            // eslint-disable-next-line
            alt="brand logo"
            className="headerSiteLogo"
          />
          {adminDetails?.cityId === null ? (
            <>
              <FormControl variant="outlined" className="headerFormStyle">
                <Select
                  displayEmpty
                  className="headerSelectStyle"
                  style={{ color: theme.navText }}
                  value={item}
                  onChange={onSelect}
                  input={<InputBase />}
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon {...props} style={{ color: theme.navText }} />
                  )}
                >
                  {savedCity && (
                    <MenuItem value={city}>
                      <span>{city?.name}</span>
                    </MenuItem>
                  )}
                  <MenuItem value="">
                    <span>{strings('descriptions.allCities')}</span>
                  </MenuItem>
                  {sortedCityList.map((eachCity) => {
                    return (
                      <MenuItem value={eachCity} key={eachCity.id}>
                        {eachCity.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </>
          ) : (
            <div></div>
          )}
          <Button
            variant="h2"
            size="small"
            onClick={handleToggle}
            className="headerButton"
            style={{ color: theme.navText }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          >
            {adminDetails?.email}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 101 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        className="headerMenuItem"
                        style={{ color: theme.navLogoutText }}
                        onClick={() => {
                          // setIsMfaEnabled((prevState) => !prevState)
                          navigate(MFA_ROUTE)
                        }}
                      >
                        <ListItemIcon style={{ minWidth: '30px' }}>
                          <VpnKey fontSize="small" />
                        </ListItemIcon>
                        {strings('descriptions.enableMFA')}
                      </MenuItem>
                      <MenuItem
                        className="headerMenuItem"
                        style={{ color: theme.navLogoutText }}
                        onClick={logoutAction}
                      >
                        <ListItemIcon style={{ minWidth: '30px' }}>
                          <PowerSettingsNewIcon fontSize="small" />
                        </ListItemIcon>
                        {strings('descriptions.logOut')}
                      </MenuItem>

                      <MenuItem
                        className="headerMenuItem"
                        style={{ color: theme.navLogoutText }}
                        onClick={loadLang}
                      >
                        <ListItemIcon style={{ minWidth: '30px' }}>
                          <FlagIcon fontSize="small" />
                        </ListItemIcon>
                        {strings('descriptions.translate')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <AccountCircle style={{ color: theme.navText }} />
        </Toolbar>
      </AppBar>
    </div>
  )
}

const mapStateToProps = ({ auth: { adminDetails }, cities: { citiesList, savedCity } }) => ({
  adminDetails,
  citiesList,
  savedCity,
})

const mapDispatchToProps = {
  logOut,
  setMapTypeFilter,
  setMapBikeStateFilter,
  setMapStationTypeFilter,
  setMapStationStateFilter,
  selectCity,
  clearSavedCity,
  selectCityFullInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
