import i18n from 'i18n-js'
import memorize from 'lodash/memoize'

import en from './locales/en.json'
import nb from './locales/nb_NO.json'

// const translations = { en, nb }

const translations = { en, nb }

let lang = window.navigator.languages ? window.navigator.languages[0] : null
lang =
  lang ||
  window.navigator.language ||
  window.navigator.browserLanguage ||
  window.navigator.userLanguage

let shortLang = lang
if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0]

if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0]

const fallback = 'en'

i18n.translations = translations
i18n.locale = fallback
i18n.defaultLocale = fallback
i18n.fallbacks = true

const translate = memorize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
)

translate.cache.clear()

let currentLang = fallback

export function getLangToDisplay() {
  return i18n.defaultLocale
}

export function strings(name, params) {
  const previousLang = currentLang
  currentLang = fallback
  if (previousLang !== currentLang) {
    translate.cache.clear()
  }
  i18n.locale = currentLang
  const result = translate(name, params)
  return result
}

export const localeLoad = async (lang) => {
  if (lang === 'en') {
    await import('./locales/en.json').then((per) => {
      translate.cache.clear()
      i18n.locale = lang
      i18n.defaultLocale = lang
      i18n.translations = { en }
    })
  } else {
    await import('./locales/nb_NO.json').then((per) => {
      translate.cache.clear()
      i18n.locale = lang
      i18n.defaultLocale = lang
      i18n.translations = { nb }
    })
  }
}

export default i18n
