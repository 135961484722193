import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Menu } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

interface Props {
  status: string
  onChange: (status: string) => void
}

export const BikeItemStatus = ({ status, onChange }: Props) => {
  const buttonRef = useRef<any>()
  const [open, setOpen] = useState<boolean>(false)
  const statuses = useSelector((s: any) => s.vehicle.statuses)

  const handleChange = (status: string) => {
    onChange(status)
    setOpen(false)
  }

  return (
    <>
      <Button ref={buttonRef} onClick={() => setOpen(true)}>
        {status}
      </Button>
      <Menu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        {statuses.map((status: string) => {
          return (
            <MenuItem key={status} onClick={() => handleChange(status)}>
              {status}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
