import React from 'react'
import { Button } from '@mui/material'
import './style.scss'
const ButtonWithLoader = ({ text = '', isLoading = false }) => {
  return (
    <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
      {isLoading ? <div className="loader"></div> : text}
    </Button>
  )
}
export default ButtonWithLoader
