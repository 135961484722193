import React, { useEffect, useState, useCallback } from 'react'
import './styles.scss'
import { strings } from '../../I18n'
import { connect } from 'react-redux'
import { getDocks, clearDocksData } from '../../actions/docks'
import DockItem from '../../components/items/dockItem'
import SearchBlock from '../../components/table/searchBlock'
import Loader from '../../components/loader'
import DockFilter from '../../components/dialog/dockFilter'

function DockContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    filterProps,
    getDocksSuccess,
    savedCity,
    updateDockSuccess,
    dockStatuses,
    clearDocksData,
  } = props
  const [docks, setDocks] = useState(props.docks)
  const [filterVisible, setFilterVisible] = useState(false)
  const [filterValues, setFilterValues] = React.useState([])

  const handleChangePage = useCallback(
    (event, newPage) => {
      changePage(newPage)
    },
    [changePage]
  )

  useEffect(() => {
    setDocks(props.docks)
  }, [props.docks])

  const renderDockList = useCallback(
    () => (
      <DockItem
        docks={docks}
        docksResult={props.docksResult}
        handleChangePage={handleChangePage}
        orderProps={orderProps}
        citiesList={props.citiesList}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docks, props, handleChangePage, orderProps, savedCity]
  )

  const removeFilter = React.useCallback(
    (indexValue) => {
      const filterValues = filterProps.filterValue.filter((item, index) => index !== indexValue)
      filterProps.setFilterValue(filterValues?.length > 0 ? filterValues : null)
      setFilterValues(filterValues)
    },
    [filterProps]
  )

  const changeSelectorValue = React.useCallback(
    (filter, filterBy) => {
      if (filterValues.filter((item) => item.filterBy === filterBy).length > 0) {
        if (!filter.length) {
          setFilterValues(filterValues?.filter((item) => item.filterBy !== filterBy))
        } else {
          setFilterValues(
            filterValues.map((item) => {
              if (item.filterBy === filterBy) {
                return { filter, filterBy }
              }
              return item
            })
          )
        }
      } else {
        setFilterValues([...filterValues, { filter, filterBy }])
      }
    },
    [filterValues, setFilterValues]
  )

  const applyFilter = useCallback(() => {
    setFilterVisible(false)
    clearDocksData()
    filterProps.setFilterValue(filterValues)
  }, [filterValues, filterProps, clearDocksData])

  const pressFilter = () => {
    setFilterVisible(true)
  }

  const hideFilter = () => {
    setFilterVisible(false)
  }

  const content = () => (
    <>
      <SearchBlock
        title={strings('title.docks')}
        searchValue={searchValue}
        onChange={changeSearch}
        clear={clearSearchValue}
        filterProps={filterProps.filterValue}
        pressFilter={pressFilter}
        removeFilter={removeFilter}
      />
      <DockFilter
        visible={filterVisible}
        hideFilter={hideFilter}
        changeSelectorValue={changeSelectorValue}
        dockStatuses={dockStatuses}
        filterProps={filterValues}
        agree={applyFilter}
        disagree={hideFilter}
      />
      {docks.length > 0 ? (
        <div className={'dashboardList'}>{renderDockList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noDocks')}</p>
      )}
    </>
  )

  const loading = () => <Loader />

  return (
    <div className={'dashboardContent'}>
      {getDocksSuccess && updateDockSuccess !== undefined ? content() : loading()}
    </div>
  )
}

const mapStateToProps = ({
  docks: { docks, docksResult, getDocksSuccess, updateDockSuccess, dockStatuses },
  cities: { citiesList, savedCity },
}) => ({
  docks,
  docksResult,
  citiesList,
  savedCity,
  getDocksSuccess,
  updateDockSuccess,
  dockStatuses,
})

const mapDispatchToProps = {
  getDocks,
  clearDocksData,
}

export default connect(mapStateToProps, mapDispatchToProps)(DockContainer)
