import { GET_METHOD, PATCH_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import * as endpoints from '../configs/endpoints'
import * as types from '../constants/actionTypes'

export const getVehicle = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getVehicle(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [types.GET_VEHICLES_REQUEST, types.GET_VEHICLES_SUCCESS, types.GET_VEHICLES_FAILURE],
  }
}

export const updateBike = (id, body) => {
  return {
    endpoint: endpoints.bikeById(id),
    method: PUT_METHOD,
    types: [types.UPDATE_BIKE_REQUEST, types.UPDATE_BIKE_SUCCESS, types.UPDATE_BIKE_FAILURE],
    body,
  }
}
export const updateBikeStatusById = (id, body) => {
  return {
    endpoint: endpoints.bikeStatusById(id),
    method: PATCH_METHOD,
    types: [
      types.UPDATE_BIKE_BY_ID_REQUEST,
      types.UPDATE_BIKE_BY_ID_SUCCESS,
      types.UPDATE_BIKE_BY_ID_FAILURE,
    ],
    body,
  }
}

export const getBikeId = (id) => {
  return {
    endpoint: endpoints.getBikeById(id),
    method: GET_METHOD,
    types: [
      types.GET_BIKE_BY_ID_REQUEST,
      types.GET_BIKE_BY_ID_SUCCESS,
      types.GET_BIKE_BY_ID_FAILURE,
    ],
  }
}

export const clearBike = () => {
  return {
    type: types.CLEAR_BIKE,
  }
}

export const getBikeStatus = () => {
  return {
    endpoint: endpoints.bikeStatus(),
    method: GET_METHOD,
    types: [
      types.GET_BIKE_STATUS_REQUEST,
      types.GET_BIKE_STATUS_SUCCESS,
      types.GET_BIKE_STATUS_FAILURE,
    ],
  }
}

export const unlockBike = (id) => {
  return {
    endpoint: endpoints.unlockBike(id),
    method: POST_METHOD,
    types: [types.UNLOCK_BIKE_REQUEST, types.UNLOCK_BIKE_SUCCESS, types.UNLOCK_BIKE_FAILURE],
  }
}

export const alarmBike = (id) => {
  return {
    endpoint: endpoints.alarmBike(id),
    method: POST_METHOD,
    types: [types.ALARM_BIKE_REQUEST, types.ALARM_BIKE_SUCCESS, types.ALARM_BIKE_FAILURE],
  }
}

export const lockBike = (id) => {
  return {
    endpoint: endpoints.lockBike(id),
    method: POST_METHOD,
    types: [types.LOCK_BIKE_REQUEST, types.LOCK_BIKE_SUCCESS, types.LOCK_BIKE_FAILURE],
  }
}

export const getBikeHistory = (page, size, vehicleCode, startDate, endDate) => {
  return {
    endpoint: endpoints.bikeHistory(page, size, vehicleCode, startDate, endDate),
    method: GET_METHOD,
    types: [types.BIKE_HISTORY_REQUEST, types.BIKE_HISTORY_SUCCESS, types.BIKE_HISTORY_FAILURE],
  }
}
export const getPaginatedBikeHistory = (page, size, vehicleCode, startDate, endDate) => {
  return {
    endpoint: endpoints.bikeHistory(page, size, vehicleCode, startDate, endDate),
    method: GET_METHOD,
    types: [
      types.PAGINATED_BIKE_HISTORY_REQUEST,
      types.PAGINATED_BIKE_HISTORY_SUCCESS,
      types.PAGINATED_BIKE_HISTORY_FAILURE,
    ],
  }
}

export const getBikeTypes = () => {
  return {
    endpoint: endpoints.listOfBikeTypes,
    method: GET_METHOD,
    types: [types.BIKE_TYPES_REQUEST, types.BIKE_TYPES_SUCCESS, types.BIKE_TYPES_FAILURE],
  }
}

export const getBikeBrands = () => {
  return {
    endpoint: endpoints.getBikeBrandItems(),
    method: GET_METHOD,
    types: [types.GET_BIKE_BRANDS_REQUEST, types.GET_BIKE_BRANDS_SUCCESS, types.GET_USERS_FAILURE],
  }
}

export const getBikeLockStatuses = () => {
  return {
    endpoint: endpoints.getBikeLockStatusItems(),
    method: GET_METHOD,
    types: [
      types.GET_BIKE_LOCK_STATUSES_REQUEST,
      types.GET_BIKE_LOCK_STATUSES_SUCCESS,
      types.GET_BIKE_LOCK_STATUSES_FAILURE,
    ],
  }
}
export const lockBattery = (code) => {
  return {
    endpoint: endpoints.lockBattery(code),
    method: POST_METHOD,
    types: [types.LOCK_BATTERY_REQUEST, types.LOCK_BATTERY_SUCCESS, types.LOCK_BATTERY_FAILURE],
  }
}

export const unlockBattery = (code) => {
  return {
    endpoint: endpoints.unLockBattery(code),
    method: POST_METHOD,
    types: [
      types.UNLOCK_BATTERY_REQUEST,
      types.UNLOCK_BATTERY_SUCCESS,
      types.UNLOCK_BATTERY_FAILURE,
    ],
  }
}

export const getAllBrokenVehicleStatus = () => {
  return {
    endpoint: endpoints.getBrokenVehicleStatus(),
    method: GET_METHOD,
    types: [
      types.BROKEN_BIKE_STATUS_REQUEST,
      types.BROKEN_BIKE_STATUS_SUCCESS,
      types.BROKEN_BIKE_STATUS_FAILURE,
    ],
  }
}

export const clearBikesData = () => {
  return {
    type: types.BIKES_CLEAR_DATA,
  }
}

export const clearHasBikeHistory = () => {
  return {
    type: types.BIKE_HISTORY_CLEAR_HAS_BIKE_HISTORY,
  }
}

export const clearBikeStatusData = () => {
  return {
    type: types.CLEAR_BIKE_STATUS_DATA,
  }
}

export const clearHonjiBikesData = () => {
  return {
    type: types.CLEAR_LOCKED_UNLOCKED_HONJI,
  }
}
