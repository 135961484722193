import React, { useMemo } from 'react'
import Moment from 'moment'

export const ImagePreviewModalMeta = ({ url }) => {
  const meta = useMemo(() => new URLSearchParams(url), [url])
  const timeCreated = useMemo(() => {
    return meta.has('timeCreated')
      ? Moment(meta.get('timeCreated')).format('DD.MM.YYYY HH:mm')
      : null
  }, [meta])

  return (
    <div style={{ padding: 3, background: 'rgba(255, 255, 255, 0.5)' }}>
      <strong>{timeCreated}</strong>
    </div>
  )
}
