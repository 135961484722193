/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import './styles.scss'
import ConfirmationDialog from '../confirmationDialog'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import { strings } from '../../../I18n'
import MenuItem from '@mui/material/MenuItem'
import Moment from 'moment'
import { DateTimePicker } from '@material-ui/pickers'
import { GoogleMap, Marker, OverlayView, Polygon, useJsApiLoader } from '@react-google-maps/api'
import { MAP_KEY } from '../../../configs/settings'
import greenBike from '../../../images/bikes/greenBike.png'
import { connect } from 'react-redux'
import { useShowToast } from '../../../hooks'

function InputDialogWithMap(props) {
  const {
    visibility,
    hideDialog,
    title,
    positiveAction,
    negativeAction,
    stationsList = [],
    docksList = [],
    rentalData = {},
    disablePast,
  } = props

  const [selectedStation, setStation] = useState('')
  const [selectedDock, setDock] = useState('')
  const [amount, setAmount] = useState((rentalData?.finalAmount || 0) / 100)
  const [endDate, setEndDate] = useState(rentalData?.endTime || '')
  const [isDockDisabled, disableDock] = useState(true)
  const [mapInstance, setMapInstance] = useState(null)

  const showToast = useShowToast()

  const mapContainerStyle = {
    height: '50vh',
    width: '100%',
  }

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
  })

  const onMapLoaded = (lMap) => {
    setMapInstance(lMap)
  }

  useEffect(() => {
    if (rentalData) {
      setAmount(rentalData?.finalAmount / 100)
      setEndDate(rentalData?.endTime)
    }
  }, [rentalData])

  useEffect(() => {
    if (
      !(
        (stationsList?.find((station) => station.code === selectedStation) || {})?.type === 'CHARGE'
      )
    ) {
      disableDock(true)
    } else {
      disableDock(false)
      setDock('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStation])

  const dropContent = (item, type) => {
    if (type === 'station') {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      )
    } else if (type === 'dock') {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.code}
        </MenuItem>
      )
    }
  }

  const endRent = () => {
    const data = {}
    if (selectedStation) {
      data.endStationCode = selectedStation
    }
    if (selectedDock) {
      data.endDockCode = selectedDock
    }
    if (amount) {
      data.finalAmount = amount * 100
    }
    if (endDate) {
      data.endTime = endDate
    }
    data.shouldAdjustAmount = true
    positiveAction(rentalData.id, data)
  }

  const remapDataToPath = (ldata) => {
    return ldata?.map((lGeometry, index) => {
      return {
        lat: parseFloat(lGeometry[0]),
        lng: parseFloat(lGeometry[1]),
      }
    })
  }

  const options = {
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
    fillColor: 'rgba(68,255,44,0.25)',
    strokeColor: 'rgb(47,146,28)',
    strokeWeight: 2,
    cursor: 'pointer',
  }

  const center = { lat: rentalData?.latitude || 0, lng: rentalData?.longitude || 0 }

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 10,
  }

  return (
    <ConfirmationDialog
      visibility={visibility}
      hideDialog={hideDialog}
      title={title}
      positiveAction={endRent}
      negativeAction={negativeAction}
      selectedStation={selectedStation}
      disableAgree={amount <= 0}
      content={
        <DialogContent>
          {loadError ? (
            <div>
              <h1>{strings('descriptions.mpNotLoaded')}</h1>
            </div>
          ) : !isLoaded ? (
            <div>
              <h1>{strings('descriptions.loading')}</h1>
            </div>
          ) : (
            <GoogleMap
              id="marker-example"
              mapContainerStyle={mapContainerStyle}
              zoom={10}
              center={center}
              onLoad={onMapLoaded}
              onUnmount={(map) => {
                setMapInstance(null)
              }}
            >
              {props.mapData?.stations?.map((bikePath, index) => {
                return (
                  <Polygon
                    paths={remapDataToPath(bikePath?.geometry)}
                    options={options}
                    key={bikePath?.name}
                  />
                )
              })}

              {props.mapData?.stations?.map((bikePath) => {
                if (bikePath?.latitude && bikePath?.longitude) {
                  return (
                    <OverlayView
                      position={{
                        lat: bikePath?.latitude,
                        lng: bikePath?.longitude,
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      key={bikePath?.name}
                    >
                      <div className="tooltip-sample" style={divStyle}>
                        <p>{bikePath?.name}</p>
                      </div>
                    </OverlayView>
                  )
                }
              })}

              <Marker
                icon={{
                  url: greenBike,
                  scaledSize: mapInstance ? new window.google.maps.Size(30, 30) : null,
                }}
                position={{
                  lat: rentalData?.latitude || 0,
                  lng: rentalData?.longitude || 0,
                }}
              />
            </GoogleMap>
          )}
          <TextField
            value={selectedStation}
            onChange={({ target }) => setStation(target.value)}
            label={strings('descriptions.endStation')}
            select
            fullWidth
            margin="dense"
            required
            helperText="You must select a station"
            error={selectedStation === '' ? true : false}
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {stationsList?.map((item) => dropContent(item, 'station'))}
          </TextField>
          {!isDockDisabled ? (
            <TextField
              value={selectedDock}
              onChange={({ target }) => setDock(target.value)}
              label={strings('descriptions.endDock')}
              select
              fullWidth
              margin="dense"
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {docksList?.map((item) => dropContent(item, 'dock'))}
            </TextField>
          ) : null}
          <DateTimePicker
            disablePast={disablePast}
            margin="dense"
            fullWidth
            variant="inline"
            ampm={false}
            format={'DD-MM-YYYY HH:mm'}
            label={strings('descriptions.endTime')}
            value={endDate ? Moment(endDate + 'Z').format('YYYY-MM-DDTHH:mm') : null}
            onChange={(value) => setEndDate(Moment(value).utc().format('YYYY-MM-DDTHH:mm:ss'))}
          />
          <TextField
            onChange={({ target }) => setAmount(target.value)}
            value={amount}
            autoFocus
            margin="dense"
            label={strings('placeholders.finalAmount')}
            type="number"
            fullWidth
          />
        </DialogContent>
      }
    />
  )
}

const mapStateToProps = ({ stations: { mapData } }) => ({
  mapData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InputDialogWithMap)
