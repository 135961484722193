export const FILTER_BY_STATUS = 'status'
export const FILTER_BY_CITY_ID = 'cityId'
export const FILTER_BY_PAYMENT_STATUS = 'status'
export const FILTER_BY_TRANS_PLAN_TYPE = 'planType'
export const FILTER_BY_VEHICLE_STATUS = 'stationVehiclesStatus'
export const FILTER_BY_STATION_TYPES = 'stationTypes'
export const FILTER_BY_STATION_STATUS = 'stationStatuses'
export const FILTER_BY_STATION_VEHICLE_STATUS = 'vehicleStatuses'
export const FILTER_BY_BRAND = 'brand'
export const FILTER_BY_LOCK_STATUS = 'lockStatus'
export const STATION_NAME = 'station_name'
export const FILTER_BY_PLAN_TYPE = 'planType'
export const FILTER_BY_PROFILE_STATUS = 'userStatus'
export const FILTER_BY_PLAN_STATUS = 'planStatus'
export const STATION_STATUSES = 'stationStatuses='
export const STATION_TYPES = 'stationTypes='

//TODO Change this const location

export const truckTrackingVehicleIds = [1000619021, 1000619024]
// export const truckTrackingVehicleIds = [2999201619]
