import { apiClient } from '../apiClient'
import { RentalRule, RentalRuleDto } from '../../domain/rental-rule'

export const getRentalRules = (cityId: number): Promise<RentalRule[]> => {
  return apiClient.get(`/journey-service/dashboard/rules/${cityId}`).then((r) => r.data)
}

export const createRentalRule = (dto: RentalRuleDto): Promise<RentalRule> => {
  return apiClient.post(`/journey-service/dashboard/rules`, dto).then((r) => r.data)
}

export const deleteRentalRule = (id: number): Promise<void> => {
  return apiClient.delete(`/journey-service/dashboard/rules/${id}`)
}
