import React from 'react'

const FormBuilderBase = ({ title, content, visible = true }) => {
  return (
    <div
      className="base-background"
      style={{
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px',
        marginTop: '20px',
        display: visible ? 'block' : 'none',
      }}
    >
      <h3 style={{ marginBottom: 10 }}>{title}</h3>
      <div className="divider"></div>
      {content}
    </div>
  )
}

export default FormBuilderBase
