/*eslint-disable*/
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { adjustWalletBalance, getUserDetailInfo, getUserLedgerAction } from '../../actions/payment'
import './styles.scss'
import { TAG_COLOR } from '../../constants/const'
import Tag from '../../components/Tags'
import useCustomTable from '../../components/table/CustomTable'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DateRangePicker } from 'react-date-range'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  convertDateFromUtc0ToLocal,
  convertDateToHumanReadableFormat,
  convertDateToUtc0InMilliseconds,
  modifyCurrencyToSymbol,
  modifyCurrencyToSymbolAndValue,
  reformatFilter,
  reformatSort,
} from '../../utils/utils'
import { useCity } from '../../hooks'
import moment from 'moment'
import { Button, Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import E_Bike from '../../images/bikes/bicycle.png'
import Bike from '../../images/bikes/bike.png'
import { useStyles } from '../../components/modal/pricingPlanModal/styles'
import { subDays } from 'date-fns'
import { strings } from '../../I18n'
import AdjustBalanceModal from '../../components/modal/AdjustBalanceModal'

const UserLedger = (props) => {
  const { id, cityId } = useParams()
  const { selectedCity } = useCity()
  const [userDetailInfoLoading, setUserDetailInfoLoading] = useState(false)
  const [adjustModalVisible, setAdjustModalVisible] = useState(false)
  const classes = useStyles()
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const openAdjustModal = () => {
    setAdjustModalVisible(true)
  }
  const closeAdjustModal = () => {
    setAdjustModalVisible(false)
  }

  const [showFilter, setShowFilter] = useState(false)

  const toggleShowFilter = () => {
    setShowFilter((prevState) => !prevState)
  }

  useEffect(() => {
    setUserDetailInfoLoading(props.userDetailInfoSuccess === undefined)
  }, [props.userDetailInfoSuccess])

  const refetchData = () => {
    props.getUserLedgerAction(
      localPage,
      debouncedSearchTerm,
      selectedCity,
      reformatSort(sortData),
      reformatFilter(filterData),
      localRows,
      id,
      convertDateToUtc0InMilliseconds(state[0].startDate),
      convertDateToUtc0InMilliseconds(state[0].endDate)
    )
    props.getUserDetailInfo(id)
  }

  const formatDateAndTimeForLedger = (date) => {
    const dateObj = new Date(date)
    const dateStr = dateObj.toLocaleDateString('en-US')
    const timeStr = dateObj.toLocaleTimeString('en-US')
    return `${dateStr} ${timeStr}`
  }

  const columns = useMemo(
    () => [
      {
        name: strings('descriptions.paymentDate'),
        selector: 'cityId',
        center: true,
        cell: (row) => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDateAndTimeForLedger(convertDateFromUtc0ToLocal(row.dateCreated))}
          </div>
        ),
      },
      {
        name: strings('descriptions.remarks'),
        selector: 'note',
        center: true,
      },
      {
        name: strings('descriptions.particulars'),
        selector: 'particulars',
        center: true,
      },

      {
        name: strings('descriptions.debit'),
        selector: 'debit',
        center: true,
        cell: (row, index, column, id) => {
          return (
            <>{row?.debit === 0 ? '-' : <Tag text={row?.debit / 100} colorTag={TAG_COLOR.RED} />}</>
          )
        },
      },
      {
        name: strings('descriptions.credit'),
        selector: 'credit',
        center: true,
        cell: (row, index, column, id) => {
          return (
            <>
              {row?.credit === 0 ? (
                '-'
              ) : (
                <Tag text={row?.credit / 100} colorTag={TAG_COLOR.GREEN} />
              )}
            </>
          )
        },
      },
      {
        name: strings('descriptions.balance'),
        selector: 'balance',
        center: true,
        cell: (row, index, column, id) => row?.balance / 100,
      },
    ],
    []
  )

  const reloadData = () => {
    refetchData()
  }
  const addButtonClick = () => {}
  let filterObject = []

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(
    columns,
    props.userLedgerList,
    reloadData,
    addButtonClick,
    filterObject,
    strings('descriptions.userLedger')
  )

  const pricingPlan = useCallback(
    (plan) => (
      <>
        <Card elevation={4} className={classes.card}>
          <CardContent>
            <Typography className={classes.planType} variant="body2">
              {plan?.typeDisplayValue}
            </Typography>
            <Typography className={classes.priceTextStyle}>{plan?.price}</Typography>
            {!!plan?.rules &&
              plan?.rules.map((rule) => {
                return (
                  <Paper elevation={0}>
                    <Grid className={classes.planRuleStyle} container justify="space-between">
                      <div className={classes.planRuleLableStyle}>
                        {rule?.label?.toUpperCase() === 'E-BIKES' ? (
                          <img
                            className={classes.planRuleLableImageStyle}
                            src={E_Bike}
                            height="16px"
                            alt={rule.label}
                          />
                        ) : (
                          <img
                            className={classes.planRuleLableImageStyle}
                            src={Bike}
                            height="26px"
                            alt={rule.label}
                          />
                        )}
                        <span>{rule?.label}</span>
                      </div>
                      <Typography variant="subtitle2" display="inline">
                        {rule?.value}
                      </Typography>
                    </Grid>
                  </Paper>
                )
              })}
            <Typography className={classes.descriptionTextStyle} variant="caption">
              {plan?.description}
            </Typography>
          </CardContent>
        </Card>
      </>
    ),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    refetchData()
  }, [
    localPage,
    debouncedSearchTerm,
    selectedCity,
    sortData,
    filterData,
    localRows,
    id,
    state,
    props.adjustWalletSuccess,
  ])

  const adjustWalletBalanceSave = (data) => {
    const submittingData = {
      userId: id,
      cityId,
      amount: data?.action === 'ADD' ? data?.amount * 100 : -data?.amount * 100,
      note: data?.note,
    }
    props.adjustWalletBalance(submittingData)
    closeAdjustModal()
  }

  return (
    <div>
      <div className="data" style={{ marginTop: 25, padding: 15 }}>
        {/*<Table dataSource={props.userLedgerList?.content} columns={columns} pagination={false} />*/}
        <div className={'header'}>
          <div className={'title'}>{strings('descriptions.userLedger')}</div>
          {/*  refresh  button*/}
          <div className={'refresh'}>
            <div className={'iconButton'}>
              <IconButton aria-label="delete" onClick={reloadData}>
                <RefreshIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={toggleShowFilter}>
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
        </div>
        {/*{renderHeaderBar()}*/}
        {/*{renderCustomTable()}*/}
        {showFilter && (
          <div className="calendar-filter">
            <DateRangePicker
              onChange={(item) => setState([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          </div>
        )}

        <div className="main-container">
          <div className="table-container">
            <div className="table-header">
              <p
                style={{
                  float: 'left',
                }}
              >
                {strings('descriptions.ledgerFromTo', {
                  from: convertDateToHumanReadableFormat(state[0].startDate),
                  to: convertDateToHumanReadableFormat(state[0].endDate),
                })}
              </p>
              <div className="adjust-balance">
                {/*  mui button primary*/}
                <Button variant="contained" color="primary" onClick={openAdjustModal}>
                  Adjust Balance
                </Button>
              </div>
            </div>

            {renderCustomTable()}
          </div>

          <div className="user-card">
            <div className="user-card-header">
              <p>{strings('descriptions.paymentDetails')}</p>
            </div>
            {userDetailInfoLoading ? (
              <h2>{strings('descriptions.loading')}</h2>
            ) : (
              <div className="user-info-card">
                <div className="user-info-card-header">
                  <div className="header-image">
                    {/*  dummy avatar image */}
                    <img
                      className="header-img"
                      src="https://www.w3schools.com/howto/img_avatar.png"
                      alt="Avatar"
                    />
                  </div>
                  <div className="user-name">
                    <p>{props.userDetailInfo?.userName}</p>
                  </div>
                  <div className="user-date">
                    <p>
                      {moment(props.userDetailInfo?.accountCreationDate).format('MMMM DD, YYYY')}
                    </p>
                  </div>
                </div>
                <div className="divider" />

                <div className="user-pricing-info">
                  <div className="amount-title">
                    <p>
                      {props.userDetailInfo?.balance?.balance >= 0
                        ? strings('descriptions.balance')
                        : 'Total Due'}
                    </p>
                  </div>
                  <div
                    className={
                      props.userDetailInfo?.balance?.balance >= 0
                        ? 'user-current-balance'
                        : 'user-current-due'
                    }
                  >
                    <h1>
                      {modifyCurrencyToSymbolAndValue(
                        props.userDetailInfo?.balance?.currency,
                        props.userDetailInfo?.balance?.balance / 100
                      )}
                    </h1>
                  </div>
                  <div className="user-pricing-info">
                    <p>
                      {' '}
                      {props.userDetailInfo?.card
                        ? `**** **** **** ${props.userDetailInfo?.card}`
                        : strings('descriptions.noCardSelected')}{' '}
                    </p>
                  </div>
                </div>
                <div className="divider" />

                <div className="user-plan-info">
                  <div className="plan-header">
                    <p>{strings('descriptions.pricingPlan')}</p>
                  </div>
                  <div className="plan">
                    {props.userDetailInfo?.plan ? (
                      pricingPlan(props.userDetailInfo?.plan)
                    ) : (
                      <h3>{strings('descriptions.noPricingPlanSelected')}</h3>
                    )}
                  </div>
                  {/*<div className="print-pricing-plan"></div>*/}
                </div>

                <div className="divider" />

                {/*<div className="user-plan-simple-info">*/}
                {/*  <div className="billing-plan">*/}
                {/*    <p>Billing Plan</p>*/}
                {/*  </div>*/}
                {/*  <div className="invoice-info">*/}
                {/*    <p>Invoice Number</p>*/}
                {/*  </div>*/}
                {/*  <div className="due-date">*/}
                {/*    <p>15 march, 2020</p>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="final-sections">
                  <div className="subtotal">
                    <p>{strings('descriptions.fees')}</p>
                    {/*<p className="subtotal-amount">{strings('descriptions.fee')}</p>*/}
                    <p className="tax-amount">
                      {strings('descriptions.fee')}
                      {modifyCurrencyToSymbolAndValue(
                        props.userDetailInfo?.balance?.currency,
                        props.userDetailInfo?.directPaymentFee / 100
                      )}
                    </p>
                  </div>
                  <div className="amount-due">
                    <p>
                      {strings('descriptions.amountDueWithBalance', {
                        balance: modifyCurrencyToSymbol(props.userDetailInfo?.balance?.currency),
                      })}
                    </p>
                    <p className="total-amount">
                      {modifyCurrencyToSymbolAndValue(
                        props.userDetailInfo?.balance?.currency,
                        props.userDetailInfo?.balance?.balance < 0
                          ? Math.abs(props.userDetailInfo?.balance?.balance / 100)
                          : 0
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AdjustBalanceModal
        onSubmit={adjustWalletBalanceSave}
        addModalVisible={adjustModalVisible}
        handleClose={closeAdjustModal}
        setAddModalVisible={setAdjustModalVisible}
        currency={props.userDetailInfo?.balance?.currency}
      />
    </div>
  )
}

const mapStateToProps = ({
  payment: {
    userLedgerList,
    userLedgerListSuccess,
    userDetailInfo,
    userDetailInfoSuccess,
    adjustWalletSuccess,
  },
}) => ({
  userLedgerList,
  userLedgerListSuccess,
  userDetailInfo,
  userDetailInfoSuccess,
  adjustWalletSuccess,
})

const mapDispatchToProps = {
  getUserLedgerAction,
  getUserDetailInfo,
  adjustWalletBalance,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLedger)
