import React from 'react'
import { Controller } from 'react-hook-form'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { FormControl } from '@mui/material'

function RadioGroupController({ control, name, label, options, row = false }) {
  const parseMessage = (message) => {
    return message?.message || message?.types?.required
  }

  return (
    <FormControl component="fieldset">
      {/*<FormLabel component="legend">{label}</FormLabel>*/}
      <h4>{label}</h4>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <RadioGroup value={value} onChange={onChange} row={row}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {error && <p style={{ color: 'red' }}>{parseMessage(error)}</p>}
          </>
        )}
      />
    </FormControl>
  )
}

export default RadioGroupController
