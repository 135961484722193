import { Box, ImageList } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { getRentals } from '../../apis/rental/rental-apis'
import { useCity } from '../../hooks'
import { Rental } from '../../domain/rental'
import { getPricingPlans } from '../../apis/rental/city-apis'
import { PricingPlan } from '../../domain/city'
import { RentalsGalleryItem } from './RentalsGalleryItem'

export const RentalsGallery = () => {
  const { selectedCity, savedCityFullInfo } = useCity()
  const cityId = parseInt(selectedCity)
  const tomorrowRef = useRef<Moment>(moment().add(1, 'days'))
  const [date, setDate] = useState<Moment>(moment())
  const [rentals, setRentals] = useState<Rental[]>([])
  const [plans, setPlans] = useState<PricingPlan[]>([])

  useEffect(() => {
    if (cityId) {
      getRentals(cityId).then((result) => {
        setRentals(result)
      })

      getPricingPlans(cityId).then((result) => {
        setPlans(result)
      })
    } else {
      setRentals([])
      setPlans([])
    }
  }, [cityId])

  const showImages = useMemo(() => {
    return !!savedCityFullInfo && !!rentals.length && !!plans.length
  }, [savedCityFullInfo, rentals, plans])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p className="dashboardTitle">Rentals Gallery</p>

        <DatePicker
          label="Date"
          maxDate={tomorrowRef.current}
          value={date}
          onChange={(value) => setDate(value || moment())}
        />
      </Box>

      {showImages && (
        <ImageList cols={3} sx={{ marginTop: 2 }}>
          {rentals.map((rental) => (
            <RentalsGalleryItem
              rental={rental}
              cityCode={savedCityFullInfo.code}
              plans={plans}
              key={rental.id}
            />
          ))}
        </ImageList>
      )}
    </Box>
  )
}
