import React, { useEffect, useMemo, useState } from 'react'
import { useCity } from '../../hooks'
import ModalContainer from '../modal/modal'
import { Autocomplete, Box, Button, Typography } from '@mui/material'
import { importVehiclesFromFile } from '../../apis/vehicle/vehicle-apis'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useDispatch, useSelector } from 'react-redux'
import { getStationsByCity } from '../../actions/stations'
import TextField from '@mui/material/TextField/TextField'

interface Props {
  onSuccess: () => void
  onError: () => void
  onClose: () => void
}

export const ImportBox = ({ onSuccess, onError, onClose }: Props) => {
  const { savedCityFullInfo } = useCity()
  const [importFile, setImportFile] = useState<File | null>(null)
  const dispatch = useDispatch()
  const stations = useSelector((s: any) => s.stations?.stationsByCity)
  const stationOptions = useMemo(
    () => stations.map((s: any) => ({ id: s.code, label: s.name })),
    [stations]
  )
  const [selectedStation, setSelectedStation] = useState<any>(null)

  const importData = async () => {
    if (importFile) {
      importVehiclesFromFile(savedCityFullInfo.id, selectedStation?.id, importFile)
        .then(onSuccess)
        .catch(onError)
    }
  }

  useEffect(() => {
    savedCityFullInfo && dispatch(getStationsByCity(savedCityFullInfo.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCityFullInfo])

  return (
    <ModalContainer visible={true} closeAction={onClose}>
      <div>
        <Typography variant="h6" gutterBottom>
          Import Vehicles from Excel
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}>
          <Button
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {importFile ? importFile.name : 'Upload file'}
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".xls,.xlsx"
              onChange={(event) => setImportFile(event.target.files && event.target.files[0])}
            />
          </Button>
        </Box>

        <Box sx={{ marginBottom: 5 }}>
          <Autocomplete
            options={stationOptions}
            renderInput={(params) => <TextField {...params} label="Station (Optional)" />}
            onChange={(e, value) => setSelectedStation(value)}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={importData} disabled={!importFile}>
            Import
          </Button>
        </Box>
      </div>
    </ModalContainer>
  )
}
