import * as types from '../constants/actionTypes'

let initialState = {
  getPaymentSuccess: null,
  paymentsResult: null,
  payments: [],
  getPaymentByIdSuccess: null,
  payment: null,
  walletPresets: [],
  walletPresetsSuccess: null,

  walletPresetsByWhitelabel: null,
  walletPresetsByWhitelabelSuccess: null,

  userLedgerList: null,
  userLedgerListSuccess: null,

  userDetailInfo: null,
  userDetailInfoSuccess: null,

  currencyMappings: null,
  currencyMappingsSuccess: null,

  adjustWallet: null,
  adjustWalletSuccess: null,

  couponUserListData: null,
  couponUserListSuccess: null,
}

const payment = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYMENT_REQUEST: {
      return {
        ...state,
        getPaymentSuccess: undefined,
      }
    }
    case types.GET_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentsResult: action.result,
        payments: action.result.content,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getPaymentSuccess: true,
      }
    }
    case types.GET_PAYMENT_FAILURE: {
      return {
        ...state,
        clients: [],
        getPaymentSuccess: false,
      }
    }
    case types.GET_PAYMENT_BY_ID_REQUEST: {
      return {
        ...state,
        getPaymentByIdSuccess: undefined,
      }
    }
    case types.GET_PAYMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        payment: action.result,
        getPaymentByIdSuccess: true,
      }
    }
    case types.GET_PAYMENT_BY_ID_FAILURE: {
      return {
        ...state,
        getPaymentByIdSuccess: false,
      }
    }
    case types.CLEAR_PAYMENT_DATA: {
      return {
        ...state,
        getPaymentByIdSuccess: null,
      }
    }

    case types.GET_WALLET_PRESETS_REQUEST: {
      return {
        ...state,
        walletPresetsSuccess: undefined,
      }
    }
    case types.GET_WALLET_PRESETS_SUCCESS: {
      return {
        ...state,
        walletPresets: action.result,
        walletPresetsSuccess: true,
      }
    }
    case types.GET_WALLET_PRESETS_FAILURE: {
      return {
        ...state,
        walletPresetsSuccess: false,
      }
    }

    case types.GET_WALLET_WHITELABEL_PRESET_REQUEST: {
      return {
        ...state,
        walletPresetsByWhitelabelSuccess: undefined,
      }
    }
    case types.GET_WALLET_WHITELABEL_PRESET_SUCCESS: {
      return {
        ...state,
        walletPresetsByWhitelabel: action.result,
        walletPresetsByWhitelabelSuccess: true,
      }
    }
    case types.GET_WALLET_WHITELABEL_PRESET_FAILURE: {
      return {
        ...state,
        walletPresetsByWhitelabelSuccess: false,
      }
    }

    case types.GET_USER_LEDGER_REQUEST: {
      return {
        ...state,
        userLedgerListSuccess: undefined,
      }
    }
    case types.GET_USER_LEDGER_SUCCESS: {
      return {
        ...state,
        userLedgerList: action.result,
        userLedgerListSuccess: true,
      }
    }
    case types.GET_USER_LEDGER_FAILURE: {
      return {
        ...state,
        userLedgerListSuccess: false,
      }
    }

    case types.GET_USER_DETAIL_INFO_REQUEST: {
      return {
        ...state,
        userDetailInfoSuccess: undefined,
      }
    }
    case types.GET_USER_DETAIL_INFO_SUCCESS: {
      return {
        ...state,
        userDetailInfo: action.result,
        userDetailInfoSuccess: true,
      }
    }
    case types.GET_USER_DETAIL_INFO_FAILURE: {
      return {
        ...state,
        userDetailInfoSuccess: false,
      }
    }

    case types.CLEAR_WALLET_PRESETS: {
      return {
        ...state,
        walletPresetsSuccess: null,
      }
    }

    case types.GET_CURRENCY_MAPPINGS_REQUEST: {
      return {
        ...state,
        currencyMappingsSuccess: undefined,
      }
    }
    case types.GET_CURRENCY_MAPPINGS_SUCCESS: {
      return {
        ...state,
        currencyMappings: action.result,
        currencyMappingsSuccess: true,
      }
    }
    case types.GET_CURRENCY_MAPPINGS_FAILURE: {
      return {
        ...state,
        currencyMappingsSuccess: false,
      }
    }

    case types.ADJUST_WALLET_BALANCE_REQUEST: {
      return {
        ...state,
        adjustWalletSuccess: undefined,
      }
    }
    case types.ADJUST_WALLET_BALANCE_SUCCESS: {
      return {
        ...state,
        adjustWallet: action.result,
        adjustWalletSuccess: true,
      }
    }
    case types.ADJUST_WALLET_BALANCE_FAILURE: {
      return {
        ...state,
        adjustWalletSuccess: false,
      }
    }

    case types.GET_USER_COUPON_LIST_REQUEST: {
      return {
        ...state,
        couponUserListSuccess: undefined,
      }
    }
    case types.GET_USER_COUPON_LIST_SUCCESS: {
      return {
        ...state,
        couponUserListData: action.result,
        couponUserListSuccess: true,
      }
    }
    case types.GET_USER_COUPON_LIST_FAILURE: {
      return {
        ...state,
        couponUserListSuccess: false,
      }
    }

    default:
      return state
  }
}

export default payment
