import React from 'react'
import './styles.scss'
import Cancel from '@mui/icons-material/Cancel'
import moment from 'moment'
import Swal from 'sweetalert2'
import { deleteCouponByUserId } from '../../../actions/cities'
import { useDispatch } from 'react-redux'
import { strings } from '../../../I18n'

const CouponItem = ({ getCouponListByUser, onClose, selectedUser }) => {
  const dispatch = useDispatch()
  return (
    <div className="couponContainer">
      <div
        className="crossIcon"
        onClick={() => {
          onClose()
          Swal.fire({
            title: 'Are you sure want to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(
                deleteCouponByUserId(getCouponListByUser?.userid, selectedUser?.plan?.cityId)
              )
            }
          })
        }}
      >
        <Cancel fontSize="large" />
      </div>
      <p className="couponName">{`#${getCouponListByUser?.coupon?.code}`}</p>
      <p className="couponDescription">{`${getCouponListByUser?.coupon?.description}`}</p>
      <p className="couponExpireDate">
        {strings('expiresOn')}
        {`${moment(getCouponListByUser?.coupon?.expiredAT).format('DD.MM.YYYY')}`}
      </p>
    </div>
  )
}

export default CouponItem
