import React from 'react'
import { Box, Stack } from '@mui/material'
import { NotificationsTarget } from './NotificationsTarget'
import { NotificationsContent } from './NotificationsContent'

export const NotificationsContainer = () => {
  return (
    <Box sx={{ padding: '30px' }}>
      <Stack spacing={5}>
        <NotificationsContent />
        <NotificationsTarget />
      </Stack>
    </Box>
  )
}
