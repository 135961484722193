import {
  DEFAULT_FILTER_PARAMS,
  LockState,
  LockStatus,
  MapFilterParams,
  StationStatus,
  StationType,
  VehicleStatus,
  VehicleType,
} from './domain'

export const getCollectionFromSearch = <T>(
  searchParams: URLSearchParams,
  key: keyof MapFilterParams
): Set<T> => {
  const keyStr = String(key)
  return searchParams.has(keyStr)
    ? new Set((searchParams.get(keyStr) || '').split(',') as T[])
    : new Set()
}

export const setCollectionToSearch = (
  searchParams: URLSearchParams,
  key: keyof MapFilterParams,
  value?: Set<any>
): void => {
  if (value && value.size) {
    searchParams.set(key, [...value].join(','))
  }
}

export const getNumberFromSearch = (
  searchParams: URLSearchParams,
  key: keyof MapFilterParams
): number | undefined => {
  return searchParams.has(key) ? parseInt(searchParams.get(key) || '', 10) : undefined
}

export const setNumberToSearch = (
  searchParams: URLSearchParams,
  key: keyof MapFilterParams,
  value?: number
): void => {
  if (typeof value === 'number' && !isNaN(value)) {
    searchParams.set(key, value.toString())
  }
}

export const convertSearchToFilter = (searchParams: URLSearchParams): MapFilterParams => {
  return {
    search: searchParams.get('search') || '',

    stationTypes: getCollectionFromSearch<StationType>(searchParams, 'stationTypes'),
    stationStatuses: getCollectionFromSearch<StationStatus>(searchParams, 'stationStatuses'),

    vehicleTypes: getCollectionFromSearch<VehicleType>(searchParams, 'vehicleTypes'),
    vehicleStatuses: getCollectionFromSearch<VehicleStatus>(searchParams, 'vehicleStatuses'),

    lockStatuses: getCollectionFromSearch<LockStatus>(searchParams, 'lockStatuses'),
    lockStates: getCollectionFromSearch<LockState>(searchParams, 'lockStates'),
    lockMinBattery: getNumberFromSearch(searchParams, 'lockMinBattery'),
    lockMaxBattery: getNumberFromSearch(searchParams, 'lockMaxBattery'),
  }
}

export const convertFilterToSearch = (filterParams: MapFilterParams): URLSearchParams => {
  const searchParams = new URLSearchParams()

  if (filterParams.search) {
    searchParams.set('search', filterParams.search)
  }

  setCollectionToSearch(searchParams, 'stationTypes', filterParams.stationTypes)
  setCollectionToSearch(searchParams, 'stationStatuses', filterParams.stationStatuses)

  setCollectionToSearch(searchParams, 'vehicleTypes', filterParams.vehicleTypes)
  setCollectionToSearch(searchParams, 'vehicleStatuses', filterParams.vehicleStatuses)

  setCollectionToSearch(searchParams, 'lockStatuses', filterParams.lockStatuses)
  setCollectionToSearch(searchParams, 'lockStates', filterParams.lockStates)
  setNumberToSearch(searchParams, 'lockMinBattery', filterParams.lockMinBattery)
  setNumberToSearch(searchParams, 'lockMaxBattery', filterParams.lockMaxBattery)

  return searchParams
}

export const filterParamsHasValue = (filterParams: MapFilterParams): boolean => {
  return (
    !!filterParams.search ||
    !!filterParams.stationTypes.size ||
    !!filterParams.stationStatuses.size ||
    !!filterParams.vehicleTypes.size ||
    !!filterParams.vehicleStatuses.size ||
    !!filterParams.lockStatuses.size ||
    !!filterParams.lockStates.size ||
    typeof filterParams.lockMinBattery === 'number' ||
    typeof filterParams.lockMaxBattery === 'number'
  )
}

export const isFilterParamsDefault = (filterParams: MapFilterParams): boolean => {
  return Object.entries(DEFAULT_FILTER_PARAMS).every(([key, defParam]) => {
    if (key === 'search') {
      return true
    }

    // @ts-ignore
    const param = filterParams[key]
    const leftValue = param instanceof Set ? [...param].sort() : param
    const rightValue = defParam instanceof Set ? [...defParam].sort() : defParam

    return String(leftValue) === String(rightValue)
  })
}
