import React, { useCallback } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useStyles } from './styles'
import ModalContainer from '../modal'
import { Button, Card, CardContent, Divider, Grid, Paper, Typography } from '@mui/material'
import { strings } from '../../../I18n'
import E_Bike from '../../../images/bikes/bicycle.png'
import Bike from '../../../images/bikes/bike.png'
import { TAG_COLOR } from '../../../constants/const'
import Tag from '../../Tags'

function PricingPlansModal(props) {
  const classes = useStyles()
  const { visible, clearPricingPlans, pricingPlans, clickedCity, deletePlan } = props

  const pricingPlan = useCallback(
    (plan) => (
      <>
        <Card elevation={4} className={classes.card}>
          <CardContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography className={classes.planType} variant="body2">
                {plan?.typeDisplayValue}
              </Typography>
              {/*  Edit button*/}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={true}
                  className={classes.editButton}
                  onClick={() => {
                    //navigate to pricing plan editor to new tab
                    window.open(
                      `${window.location.origin}/dashboard/pricing-plan-editor/${
                        plan?.draftId ? plan?.draftId : plan?.id
                      }?isDraft=${!!plan?.draftId}`,
                      '_blank'
                    )
                  }}
                >
                  {strings('descriptions.edit')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.editButton}
                  onClick={() => {
                    clearPricingPlans()
                    deletePlan(plan?.draftId || plan?.id, !!plan?.draftId)
                  }}
                >
                  {strings('message.delete')}
                </Button>
              </div>
            </div>
            <Typography className={classes.priceTextStyle}>{plan?.price}</Typography>
            {!!plan?.rules &&
              plan?.rules.map((rule) => {
                return (
                  <Paper elevation={0}>
                    <Grid className={classes.planRuleStyle} container justify="space-between">
                      <div className={classes.planRuleLableStyle}>
                        {rule?.label?.toUpperCase() === 'E-BIKES' ? (
                          <img
                            className={classes.planRuleLableImageStyle}
                            src={E_Bike}
                            height="16px"
                            alt={rule.label}
                          />
                        ) : (
                          <img
                            className={classes.planRuleLableImageStyle}
                            src={Bike}
                            height="26px"
                            alt={rule.label}
                          />
                        )}
                        <span>{rule?.label}</span>
                      </div>
                      <Typography variant="subtitle2" display="inline">
                        {rule?.value}
                      </Typography>
                    </Grid>
                  </Paper>
                )
              })}
            <Typography className={classes.descriptionTextStyle} variant="caption">
              {plan?.description}
            </Typography>
            {plan?.draftId && <Tag colorTag={TAG_COLOR.GREEN} text={'DRAFT'} />}
          </CardContent>
        </Card>
      </>
    ),
    // eslint-disable-next-line
    []
  )

  return (
    <ModalContainer
      visible={visible}
      className={classes.container}
      closeAction={clearPricingPlans}
      body={
        <>
          <Typography className={classes.cityNameStyle} align="center" variant="h5">
            {clickedCity?.name}
          </Typography>
          <Divider className={classes.dividerStyle} light variant="middle" />
          {pricingPlans.length > 0 ? (
            pricingPlans.map((plan) => pricingPlan(plan))
          ) : (
            <Card elevation={2} className={classes.card}>
              <CardContent>
                <Typography variant="h6" align="center">
                  {strings('descriptions.noPricingPlan')}
                </Typography>
              </CardContent>
            </Card>
          )}
          <Grid direction="row" container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CloseIcon />}
              className={classes.buttonStyle}
              onClick={() => clearPricingPlans()}
            >
              {strings('buttons.close')}
            </Button>
          </Grid>
        </>
      }
    />
  )
}

export default PricingPlansModal
