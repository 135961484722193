import * as types from '../constants/actionTypes'

let initialState = {
  getLocksSuccess: null,
  locksResult: null,
  locks: [],
  unlockLockSuccess: null
};

const locks = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_LOCKS_REQUEST: {
      return {
        ...state,
        getLocksSuccess: undefined
      }
    }
    case types.GET_LOCKS_SUCCESS: {
      return {
        ...state,
        locksResult: action.result,
        locks: action.result.content,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getLocksSuccess: true
      }
    }
    case types.GET_LOCKS_FAILURE: {
      return {
        ...state,
        locks: [],
        getLocksSuccess: false
      }
    }
    case types.UNLOCK_LOCK_REQUEST: {
      return {
        ...state,
        unlockLockSuccess: undefined
      }
    }
    case types.UNLOCK_STATION_SUCCESS: {
      return {
        ...state,
        unlockLockSuccess: true
      }
    }
    case types.UNLOCK_STATION_FAILURE: {
      return {
        ...state,
        unlockLockSuccess: false
      }
    }
    default:
      return state
  }
};

export default locks;
