import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import { apiMiddleware } from '../middleware/api';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const middlewares = [thunk, apiMiddleware];
// const { logger } = require('redux-logger');

if (process.env.NODE_ENV === 'development') {
	// middlewares.push(logger);
}

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export { store, persistor };
