/*eslint-disable*/
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { persistor, store } from './store'
import i18n from './I18n'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { isLocalHost } from './utils/utils'

// import { loadLocale } from "./i18n";

{
  !isLocalHost() &&
    Sentry.init({
      dsn: 'https://7e9ce347d04f4b22aa8f3680eeb27a86@o4504875636948992.ingest.sentry.io/4504875649662976',
      environment: 'development',

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new BrowserTracing(),
        // new Sentry.Replay({
        //   // Additional SDK configuration goes in here, for example:
        //   maskAllText: false,
        //   blockAllMedia: false,
        // }),
      ],
    })
}

const AppContainer = () => {
  const [lang, setLang] = React.useState(i18n.defaultLocale)
  React.useEffect(() => {
    setLang(i18n.defaultLocale)
  }, [lang])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<AppContainer />)

// ReactDOM.render(<AppContainer />, document.getElementById('root'))
