import React from "react";
import { ReactComponent as BikeLoader } from "../../images/loader.svg";
import "./styles.scss";

const Loader = () => (
  <div className={"loaderDdashboardContent"}>
    <div className={"loaderDashboardProgress"}>
      <BikeLoader />
    </div>
  </div>
);

export default Loader;
