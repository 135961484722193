/* eslint-disable */
import React, { useEffect } from 'react'
import './styles.scss'
import { strings } from '../../I18n'
import ClientItem from '../../components/items/clientItem'
import { connect, useDispatch } from 'react-redux'
import SearchBlock from '../../components/table/searchBlock'
import { CANCEL_SUBSCRIPTION, REMOVE_USER } from '../../constants/actionTypes'
import ConfirmationDialog from '../../components/dialog/confirmationDialog'
import {
  cancelSubscription,
  clearUser,
  clearUserData,
  getUser,
  getUserPlanStatuses,
  getUserPlanTypes,
  removeUser,
  updateUser,
} from '../../actions/users'
import { emptyPassedParams, setSearchValue } from '../../actions/screen'
import ClientFilter from '../../components/dialog/clientFilter'
import ClientModal from '../../components/modal/clientModal'
import { useShowToast } from '../../hooks'
import Loader from '../../components/loader'
import 'react-tabs/style/react-tabs.css'
import CancelSubscriptionModal from '../../components/modal/cancelSubscriptionModal'
import moment from 'moment'
import PreviewCouponModal from '../../components/modal/previewCouponModal'
import { SHOW_COUPON, SHOW_LEDGER } from '../../constants/menuActions'
import { clearCreateCoupon, getAllCouponByUserId } from '../../actions/cities'
import { useNavigate, useParams } from 'react-router-dom'

function ClientContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    filterProps,
    citiesList,
    getUserSuccess,
    user,
    clearUser,
    updateUser,
    updateUserSuccess,
    getUserPlanStatuses,
    getUserPlanTypes,
    getPlanTypesSuccess,
    clearUserData,
    planTypes,
    planStatuses,
    getClientsSuccess,
    cancelSubscriptionSuccess,
    getCouponListByUser,
    getCouponListByUserSuccess,
    deleteCouponByIdSuccess,
  } = props

  const dispatch = useDispatch()
  let { id } = useParams()

  const [confirmDialogVisible, setConfirmDialogVisible] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [filterVisible, setFilterVisible] = React.useState(false)
  const [filterValues, setFilterValues] = React.useState([])
  const [userInfoModalVisible, setUserInfoModalVisible] = React.useState(false)

  const [couponModal, setCouponModal] = React.useState(false)

  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] = React.useState(false)
  const [cancelSubscriptionDate, setCancelSubscriptionDate] = React.useState(
    moment().add(1, 'days')
  )
  const [subscriptionType, setSubscriptionType] = React.useState('immediate')

  const showToast = useShowToast()

  const pressFilter = () => {
    getUserPlanStatuses()
    getUserPlanTypes()
  }

  const hideFilter = () => {
    setFilterVisible(false)
    clearUserData()
  }

  const showUserModal = () => setUserInfoModalVisible(true)
  const hideUserModal = () => setUserInfoModalVisible(false)
  const showCancelSubscriptionModal = () => setCancelSubscriptionModalVisible(true)
  const closeCouponModal = () => setCouponModal(false)
  const showCouponModal = () => setCouponModal(true)
  let navigate = useNavigate()

  // useEffect(() => {
  //   if (userId) {
  //     setSearchValue(userId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userId, setSearchValue]);

  useEffect(() => {
    if (deleteCouponByIdSuccess === true) {
      showToast('Coupon removed', 'success')
      dispatch(clearCreateCoupon())
    }
  }, [deleteCouponByIdSuccess])

  useEffect(() => {
    if (getCouponListByUserSuccess === true) {
      dispatch(clearCreateCoupon())
      showCouponModal()
    }
  }, [getCouponListByUserSuccess])

  useEffect(() => {
    if (getPlanTypesSuccess) {
      setFilterVisible(true)
    }
  }, [getPlanTypesSuccess])

  useEffect(() => {
    if (getUserSuccess) {
      showUserModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserSuccess])

  useEffect(() => {
    if (!!filterProps?.filterValue) {
      setFilterValues(filterProps?.filterValue || [])
    }
  }, [filterProps])

  useEffect(() => {
    if (updateUserSuccess) {
      showToast('Success', 'success')
      clearUser()
      hideUserModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserSuccess, clearUser])

  useEffect(() => {
    if (cancelSubscriptionSuccess) {
      showToast('Success', 'success')
      closeCancelSubscriptionModal()
      setSelectedUser(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSubscriptionSuccess])

  const closeUserModal = () => {
    hideUserModal()
    clearUser()
  }

  const closeCancelSubscriptionModal = () => {
    setCancelSubscriptionModalVisible(false)
  }

  const hideDialog = React.useCallback(() => {
    setConfirmDialogVisible(false)
    setSelectedUser(null)
  }, [setConfirmDialogVisible])

  const showDialog = React.useCallback(() => {
    setConfirmDialogVisible(true)
  }, [setConfirmDialogVisible])

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      changePage(newPage)
    },
    [changePage]
  )

  const selectMenu = React.useCallback(
    (event) => {
      switch (event.action) {
        case REMOVE_USER:
          showDialog()
          setSelectedUser(event.client)
          break
        case CANCEL_SUBSCRIPTION:
          showCancelSubscriptionModal()
          setSelectedUser(event.client)
          break
        case SHOW_LEDGER:
          setSelectedUser(event.client)
          navigate(`/dashboard/ledger/${event.client.id}/${event.client?.plan?.cityId}`)
          break
        case SHOW_COUPON:
          dispatch(getAllCouponByUserId(event.client.id, event.client?.plan?.cityId))
          setSelectedUser(event.client)
        default:
          break
      }
    },
    [showDialog, setSelectedUser]
  )

  const removeUser = React.useCallback(() => {
    props.removeUser(selectedUser.id)
    hideDialog()
  }, [selectedUser, props, hideDialog])

  const handleCancelSubscriptionRequest = React.useCallback(() => {
    if (subscriptionType === 'date' && selectedUser) {
      props.cancelSubscription(
        selectedUser?.plan?.cityId,
        selectedUser?.id,
        selectedUser?.plan?.id,
        moment(cancelSubscriptionDate).format('X')
      )
    } else if (subscriptionType === 'immediate' && selectedUser) {
      props.cancelSubscription(
        selectedUser?.plan?.cityId,
        selectedUser?.id,
        selectedUser?.plan?.id,
        ''
      )
    }
  }, [selectedUser, props, cancelSubscriptionDate, subscriptionType])

  const applyFilter = React.useCallback(() => {
    setFilterVisible(false)
    clearUserData()
    filterProps.setFilterValue(filterValues)
  }, [filterValues, filterProps, clearUserData])

  const changeSelectorValue = React.useCallback(
    (filter, filterBy) => {
      if (filterValues.filter((item) => item.filterBy === filterBy).length > 0) {
        if (!filter.length) {
          setFilterValues(filterValues?.filter((item) => item.filterBy !== filterBy))
        } else {
          setFilterValues(
            filterValues.map((item) => {
              if (item.filterBy === filterBy) {
                return { filter, filterBy }
              }
              return item
            })
          )
        }
      } else {
        setFilterValues([...filterValues, { filter, filterBy }])
      }
    },
    [filterValues, setFilterValues]
  )

  const removeFilter = React.useCallback(
    (indexValue) => {
      const filterValues = filterProps.filterValue.filter((item, index) => index !== indexValue)
      filterProps.setFilterValue(filterValues?.length > 0 ? filterValues : null)
      setFilterValues(filterValues)
    },
    [filterProps]
  )

  const renderClientList = React.useCallback(
    () => (
      <ClientItem
        clients={props.clients}
        selectMenu={selectMenu}
        clientsResult={props.clientsResult}
        handleChangePage={handleChangePage}
        orderProps={orderProps}
        cities={citiesList}
      />
    ),
    [selectMenu, props, handleChangePage, orderProps, citiesList]
  )

  const dialogTitle = React.useMemo(
    () =>
      !!selectedUser?.fullName
        ? strings('message.removeUserWithName', { user: selectedUser.fullName })
        : strings('message.removeUser'),
    [selectedUser]
  )

  const content = () => (
    <>
      <SearchBlock
        title={strings('title.users')}
        searchValue={searchValue}
        onChange={changeSearch}
        clear={clearSearchValue}
        pressFilter={pressFilter}
        removeFilter={removeFilter}
        filterProps={filterProps.filterValue}
      />
      {props.clients.length > 0 || user?.length > 0 ? (
        <div className={'dashboardList'}>{renderClientList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noUsers')}</p>
      )}
      <ConfirmationDialog
        visibility={confirmDialogVisible}
        hideDialog={hideDialog}
        title={dialogTitle}
        positiveAction={removeUser}
        negativeAction={hideDialog}
      />
      <ClientModal
        visible={userInfoModalVisible}
        closeUserModal={closeUserModal}
        client={user}
        updateUser={updateUser}
      />
      <ClientFilter
        agree={applyFilter}
        disagree={hideFilter}
        visible={filterVisible}
        hideFilter={hideFilter}
        changeSelectorValue={changeSelectorValue}
        filterProps={filterValues}
        types={planTypes}
        statuses={planStatuses}
      />
      <CancelSubscriptionModal
        visible={cancelSubscriptionModalVisible}
        cancelSubscriptionDate={cancelSubscriptionDate}
        setCancelSubscriptionDate={setCancelSubscriptionDate}
        handleCancelSubscriptionRequest={handleCancelSubscriptionRequest}
        subscriptionType={subscriptionType}
        setSubscriptionType={setSubscriptionType}
        closeCancelSubscriptionModal={closeCancelSubscriptionModal}
      />

      <PreviewCouponModal
        open={couponModal}
        onClose={closeCouponModal}
        getCouponListByUser={getCouponListByUser}
        selectedUser={selectedUser}
      />
    </>
  )

  const loading = () => <Loader />

  return <div className={'dashboardContent'}>{getClientsSuccess ? content() : loading()}</div>
}

const mapStateToProps = ({
  users: {
    clients,
    clientsResult,
    getUserSuccess,
    user,
    updateUserSuccess,
    getPlanTypesSuccess,
    planTypes,
    planStatuses,
    getClientsSuccess,
    cancelSubscriptionSuccess,
  },
  cities: { citiesList, getCouponListByUser, getCouponListByUserSuccess, deleteCouponByIdSuccess },
  screen: { passedParams },
}) => ({
  clients,
  clientsResult,
  citiesList,
  getUserSuccess,
  user,
  updateUserSuccess,
  getPlanTypesSuccess,
  planTypes,
  planStatuses,
  getClientsSuccess,
  passedParams,
  cancelSubscriptionSuccess,
  getCouponListByUser,
  getCouponListByUserSuccess,
  deleteCouponByIdSuccess,
})

const mapDispatchToProps = {
  removeUser,
  clearUser,
  updateUser,
  getUserPlanStatuses,
  getUserPlanTypes,
  clearUserData,
  getUser,
  emptyPassedParams,
  setSearchValue,
  cancelSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer)
