import * as types from '../constants/actionTypes'

let initialState = {
  getVehicleSuccess: null,
  getBikeByIdSuccess: null,
  getBikeStatusSuccess: null,
  getLockStatusesSuccess: null,
  getBrandsSuccess: null,
  updateBikeSuccess: null,
  getBikeTypesSuccess: null,
  bikes: [],
  bike: null,
  statuses: [],
  bikesResult: null,
  brands: [],
  lockStatuses: [],
  bikeTypes: [],
  bikeHistory: null,
  hasMoreBikeHistory: true,
  bikeHistorySuccess: null,
  lockHonji: null,
  lockHonjiSuccess: null,
  unlockHonji: null,
  unlockHonjiSuccess: null,

  bikeStatusById: null,
  bikeStatusByIdSuccess: null,

  brokenBikeStatuses: null,
  brokenBikeStatusesSuccess: null,

  paginatedBikeHistory: null,
  paginatedBikeHistorySuccess: null,
  hasMoreBikeHistoryInTable: true,
}

const vehicle = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_BIKE_STATUS_DATA: {
      return {
        ...state,
        bikeStatusByIdSuccess: null,
      }
    }

    case types.BROKEN_BIKE_STATUS_REQUEST: {
      return {
        ...state,
        brokenBikeStatusesSuccess: undefined,
      }
    }
    case types.BROKEN_BIKE_STATUS_SUCCESS: {
      return {
        ...state,
        brokenBikeStatuses: action.result,
        brokenBikeStatusesSuccess: true,
      }
    }
    case types.BROKEN_BIKE_STATUS_FAILURE: {
      return {
        ...state,
        brokenBikeStatusesSuccess: false,
      }
    }

    case types.UPDATE_BIKE_BY_ID_REQUEST: {
      return {
        ...state,
        bikeStatusByIdSuccess: undefined,
      }
    }
    case types.UPDATE_BIKE_BY_ID_SUCCESS: {
      return {
        ...state,
        bikeStatusById: action.result,
        bikeStatusByIdSuccess: true,
      }
    }
    case types.UPDATE_BIKE_BY_ID_FAILURE: {
      return {
        ...state,
        bikeStatusByIdSuccess: false,
      }
    }

    case types.GET_VEHICLES_REQUEST: {
      return {
        ...state,
        getVehicleSuccess: undefined,
      }
    }

    case types.CLEAR_LOCKED_UNLOCKED_HONJI: {
      return {
        ...state,
        lockHonjiSuccess: null,
        unlockHonjiSuccess: null,
      }
    }

    case types.GET_VEHICLES_SUCCESS: {
      return {
        ...state,
        bikes: action.result.content,
        bikesResult: action.result,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getVehicleSuccess: true,
      }
    }
    case types.GET_VEHICLES_FAILURE: {
      return {
        ...state,
        bikes: [],
        getVehicleSuccess: false,
      }
    }

    case types.LOCK_BATTERY_REQUEST: {
      return {
        ...state,
        lockHonjiSuccess: undefined,
      }
    }
    case types.LOCK_BATTERY_SUCCESS: {
      return {
        ...state,
        lockHonji: action.result,
        lockHonjiSuccess: true,
      }
    }
    case types.LOCK_BATTERY_FAILURE: {
      return {
        ...state,
        lockHonjiSuccess: false,
      }
    }

    case types.UNLOCK_BATTERY_REQUEST: {
      return {
        ...state,
        unlockHonjiSuccess: undefined,
      }
    }
    case types.UNLOCK_BATTERY_SUCCESS: {
      return {
        ...state,
        unlockHonji: action.result,
        unlockHonjiSuccess: true,
      }
    }
    case types.UNLOCK_BATTERY_FAILURE: {
      return {
        ...state,
        unlockHonjiSuccess: false,
      }
    }

    case types.UPDATE_BIKE_REQUEST: {
      return {
        ...state,
        updateBikeSuccess: undefined,
      }
    }
    case types.UPDATE_BIKE_SUCCESS: {
      const index = state.bikes.findIndex((item) => item.id === action.result.id)
      state.bikes[index] = action.result
      return {
        ...state,
        updateBikeSuccess: true,
      }
    }
    case types.UPDATE_BIKE_FAILURE: {
      return {
        ...state,
        updateBikeSuccess: false,
      }
    }
    case types.GET_BIKE_BY_ID_REQUEST: {
      return {
        ...state,
        getBikeByIdSuccess: undefined,
      }
    }
    case types.GET_BIKE_BY_ID_SUCCESS: {
      return {
        ...state,
        bike: action.result,
        getBikeByIdSuccess: true,
      }
    }
    case types.GET_BIKE_BY_ID_FAILURE: {
      return {
        ...state,
        getBikeByIdSuccess: false,
      }
    }
    case types.GET_BIKE_STATUS_REQUEST: {
      return {
        ...state,
        getBikeStatusSuccess: undefined,
      }
    }
    case types.GET_BIKE_STATUS_SUCCESS: {
      return {
        ...state,
        statuses: action.result,
        getBikeStatusSuccess: true,
      }
    }
    case types.GET_BIKE_STATUS_FAILURE: {
      return {
        ...state,
        getBikeStatusSuccess: false,
      }
    }
    case types.GET_BIKE_BRANDS_REQUEST: {
      return {
        ...state,
        getBrandsSuccess: undefined,
      }
    }
    case types.GET_BIKE_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: action.result,
        getBrandsSuccess: true,
      }
    }
    case types.GET_BIKE_BRANDS_FAILURE: {
      return {
        ...state,
        getBrandsSuccess: false,
      }
    }
    case types.GET_BIKE_LOCK_STATUSES_REQUEST: {
      return {
        ...state,
        getLockStatusesSuccess: undefined,
      }
    }
    case types.GET_BIKE_LOCK_STATUSES_SUCCESS: {
      return {
        ...state,
        lockStatuses: action.result,
        getLockStatusesSuccess: true,
      }
    }
    case types.GET_BIKE_LOCK_STATUSES_FAILURE: {
      return {
        ...state,
        getLockStatusesSuccess: false,
      }
    }
    case types.BIKES_CLEAR_DATA: {
      return {
        ...state,
        getLockStatusesSuccess: null,
        getBikeStatusSuccess: null,
      }
    }
    case types.BIKE_TYPES_REQUEST: {
      return {
        ...state,
        getBikeTypesSuccess: undefined,
      }
    }
    case types.BIKE_TYPES_SUCCESS: {
      return {
        ...state,
        bikeTypes: action.result,
        getBikeTypesSuccess: true,
      }
    }
    case types.BIKE_TYPES_FAILURE: {
      return {
        ...state,
        getBikeTypesSuccess: false,
      }
    }

    case types.PAGINATED_BIKE_HISTORY_REQUEST: {
      return {
        ...state,
        paginatedBikeHistorySuccess: undefined,
      }
    }
    case types.PAGINATED_BIKE_HISTORY_SUCCESS: {
      return {
        ...state,
        paginatedBikeHistory: action.result,
        paginatedBikeHistorySuccess: true,
        hasMoreBikeHistoryInTable: action.result.content.flat().length > 0,
      }
    }
    case types.PAGINATED_BIKE_HISTORY_FAILURE: {
      return {
        ...state,
        paginatedBikeHistorySuccess: false,
      }
    }

    case types.CLEAR_BIKE: {
      return {
        ...state,
        bike: null,
        updateBikeSuccess: null,
        getBikeByIdSuccess: null,
      }
    }
    case types.BIKE_HISTORY_REQUEST: {
      return {
        ...state,
        bikeHistorySuccess: undefined,
      }
    }
    case types.BIKE_HISTORY_FAILURE: {
      return {
        ...state,
        bikeHistorySuccess: false,
      }
    }
    case types.BIKE_HISTORY_SUCCESS: {
      return {
        ...state,
        bikeHistory:
          action.result.number === 0
            ? action.result
            : {
                ...action.result,
                content: [...state.bikeHistory.content, ...action.result.content],
              },
        bikeHistorySuccess: true,
        //  has content
        hasMoreBikeHistory: action.result.content.flat().length > 0,
      }
    }
    case types.BIKE_HISTORY_CLEAR_HAS_BIKE_HISTORY: {
      return {
        ...state,
        hasMoreBikeHistory: true,
      }
    }
    default:
      return state
  }
}

export default vehicle
