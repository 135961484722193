import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import { sendPasswordReset, setupRecaptcha } from '../../utils/firebase'
import * as routes from '../../constants/routes'
import { clearErrorMessage, logIn, logOut, saveToken } from '../../actions/auth'
import { strings } from '../../I18n'
import { isMobile } from 'react-device-detect'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useStyles } from '../../utils/styles'
import ModalContainer from '../../components/modal/modal'

function ForgotPasswordScreen(props) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [isCapchaFilled, setIsCapchaFilled] = React.useState(false)
  const [isEmailSent, setIsEmailSent] = React.useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsCapchaFilled(false)
    setupRecaptcha(() => {
      setMessage('')
      setIsCapchaFilled(true)
    })
  }, [])

  const changeEmail = ({ target }) => setEmail(target.value)

  const resetPassword = (event) => {
    event.preventDefault()

    if (!isCapchaFilled) {
      setMessage('Please fill out the captcha')
      return
    }
    setLoading(true)
    sendPasswordReset(email)
      .then((msg) => {
        setLoading(false)
        setIsEmailSent(true)
      })
      .catch((err) => {
        setMessage(err.message)
        setLoading(false)
      })
  }

  const messageModal = () => {
    return (
      <ModalContainer
        visible={isEmailSent}
        body={
          <div className="sentEmailContainer">
            <h1 className="centerBold">{strings('descriptions.checkYourEmail')}</h1>
            <h2 className="centerBold">{strings('descriptions.emailInstructions')}</h2>
            <p>{strings('descriptions.checkInboxOrSpam')}</p>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                navigate(-1)
              }}
            >
              {strings('placeholders.login')}
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <>
      <div className={isMobile ? 'mobileApp' : 'app'}>
        <div className={'authBlock'}>
          <div className={'inputBlock'}>
            <p className={'textDescription'}>{strings('placeholders.email')}:</p>
            <input
              className={'input'}
              placeholder={strings('placeholders.email')}
              type={'email'}
              name={'email'}
              onChange={changeEmail}
            />
          </div>
          <p className={'message'}>{message}</p>
          <div id="recaptcha-container"></div>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              variant="outlined"
              className={'button'}
              onClick={resetPassword}
            >
              {strings('buttons.resetPassword')}
            </Button>
            <Link to={routes.LOGIN_SCREEN} className={'link'}>
              <p className={'item'}>{strings('placeholders.back')}</p>
            </Link>
          </div>
        </div>
      </div>
      {messageModal()}
    </>
  )
}

const mapDispatchToProps = {
  logIn,
  saveToken,
  logOut,
  clearErrorMessage,
}

export default connect(null, mapDispatchToProps)(ForgotPasswordScreen)
