/*eslint-disable*/
import React from 'react'
import './styles.scss'
import CityItem from '../../components/items/cityItem'
import { connect } from 'react-redux'
import {
  clearCity,
  clearPricingPlan,
  clearPricingPlans,
  createCity,
  deletePricingPlanById,
  getCities,
  getPricingPlanFields,
  getPricingPlans,
  updateCity,
} from '../../actions/cities'
import { strings } from '../../I18n'
import CityModal from '../../components/modal/cityModal'
import { NEW_CITY, UPDATE_CITY } from '../../constants/const'
import SearchBlock from '../../components/table/searchBlock'
import { useShowToast } from '../../hooks'
import Loader from '../../components/loader'
import PricingPlansModal from '../../components/modal/pricingPlanModal'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

function CityContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    pageSize,
    getCitiesSuccess,
    getCities,
    getPricingPlanSuccess,
    pricingPlans,
    getPricingPlanFields,
  } = props
  const showToast = useShowToast()

  const navigate = useNavigate()
  const [cityAction, setCityAction] = React.useState('')
  const [modalVisible, setModalVisible] = React.useState(false)
  const [pricingPlansModalVisible, setPricingPlansModalVisible] = React.useState(false)

  const [clickedCity, setClickedCity] = React.useState()
  const [currentPage, setCurrentPage] = React.useState(0)

  const closeCityModal = () => {
    setModalVisible(false)
  }

  React.useEffect(() => {
    getPricingPlanFields()
    setCityAction(UPDATE_CITY)
    setModalVisible(!!props.city)
  }, [props.city])

  React.useEffect(() => {
    if (props.deletePricingPlanSuccess) {
      props.clearPricingPlan()
      setPricingPlansModalVisible(false)
      showToast('Pricing plan deleted', 'success')
    }
  }, [props.deletePricingPlanSuccess])

  const deletePricingPlan = (id, isDraft) => {
    Swal.fire({
      title: 'Are you sure want to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        props.deletePricingPlanById(id, isDraft)
      }
    })
  }

  React.useEffect(() => {
    getPricingPlanSuccess && setPricingPlansModalVisible(true)
  }, [getPricingPlanSuccess])

  React.useEffect(() => {
    if (props.updateCitySuccess) {
      clearCity()
      getCities(currentPage, '', '', '', pageSize)
      showToast('Success', 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateCitySuccess])

  React.useEffect(() => {
    if (props.createCitySuccess) {
      clearCity()
      showToast('Success', 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createCitySuccess])

  const clearCity = React.useCallback(() => {
    props.clearCity()
    setModalVisible(false)
  }, [props, setModalVisible])

  const clearPricingPlans = React.useCallback(() => {
    props.clearPricingPlans()
    setPricingPlansModalVisible(false)
  }, [props, setPricingPlansModalVisible])

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setCurrentPage(newPage)
      changePage(newPage)
    },
    [changePage]
  )

  const renderCitiesList = React.useCallback(
    () => (
      <CityItem
        cities={props.cities}
        citiesResult={props.citiesResult}
        handleChangePage={handleChangePage}
        orderProps={orderProps}
        setClickedCity={setClickedCity}
      />
    ),
    [props, handleChangePage, orderProps]
  )

  const createCity = React.useCallback(() => {
    setCityAction(NEW_CITY)
    setModalVisible(true)
  }, [setCityAction, setModalVisible])
  // [...pricingPlans?.currentPlans, ...pricingPlans?.draftPlans] || []
  const allPlans = React.useMemo(() => {
    const plans = []
    if (pricingPlans?.currentPlans) {
      plans.push(...pricingPlans?.currentPlans)
    }
    if (pricingPlans?.draftPlans) {
      plans.push(...pricingPlans?.draftPlans)
    }
    return plans
  }, [pricingPlans])
  const content = () => (
    <>
      <SearchBlock
        title={strings('title.cities')}
        searchValue={searchValue}
        onChange={changeSearch}
        clear={clearSearchValue}
        addAction={createCity}
        extraContent={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                navigate('/dashboard/pricing-plan-editor/')
              }}
            >
              {strings('pricingPlan.addPlan')}
            </Button>
          </>
        }
      />
      {props.cities.length > 0 ? (
        <div className={'dashboardList'}>{renderCitiesList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noCities')}</p>
      )}
      <CityModal
        clearCity={clearCity}
        city={props.city}
        cityAction={cityAction === NEW_CITY ? props.createCity : props.updateCity}
        visible={modalVisible}
        closeCityModal={closeCityModal}
      />
      <PricingPlansModal
        clearPricingPlans={clearPricingPlans}
        visible={pricingPlansModalVisible}
        pricingPlans={allPlans}
        cities={props.cities}
        clickedCity={clickedCity}
        deletePlan={deletePricingPlan}
      />
    </>
  )

  const loading = () => <Loader />

  return <div className={'dashboardContent'}>{getCitiesSuccess ? content() : loading()}</div>
}

const mapStateToProps = ({
  cities: {
    cities,
    citiesResult,
    updateCitySuccess,
    createCitySuccess,
    cityByIdSuccess,
    city,
    getCitiesSuccess,
    getPricingPlanSuccess,
    pricingPlans,
    deletePricingPlanSuccess,
  },
  screen: { pageSize },
}) => ({
  cities,
  city,
  updateCitySuccess,
  createCitySuccess,
  cityByIdSuccess,
  citiesResult,
  pageSize,
  getCitiesSuccess,
  getPricingPlanSuccess,
  pricingPlans,
  deletePricingPlanSuccess,
})

const mapDispatchToProps = {
  getCities,
  updateCity,
  createCity,
  clearCity,
  getPricingPlans,
  clearPricingPlans,
  getPricingPlanFields,
  deletePricingPlanById,
  clearPricingPlan,
}

export default connect(mapStateToProps, mapDispatchToProps)(CityContainer)
