import { apiClient, apiClientJSON } from '../apiClient'

export const exportVehiclesToFile = (params: object) => {
  return apiClient.get(`/station-service/dashboard/vehicles/export`, {
    responseType: 'blob',
    params,
  })
}

export const exportVehiclesToFileExample = () => {
  return apiClient.get(`/station-service/dashboard/vehicles/export/example`, {
    responseType: 'blob',
  })
}

export const importVehiclesFromFile = (cityId: number, stationCode: number, file: File) => {
  const data = new FormData()
  data.append('file', file)

  return apiClientJSON.post(`/station-service/dashboard/vehicles/import`, data, {
    params: {
      cityId,
      stationCode,
    },
  })
}

export const deleteVehicle = (vehicleId: number) => {
  return apiClient.delete(`/station-service/dashboard/vehicles/${vehicleId}`)
}
