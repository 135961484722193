import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FormControl } from '@mui/material'

const DatePickerController = ({
  name,
  format = 'DD/MM/yyyy',
  errors,
  required,
  label,
  disablePast = false,
  extraRules,
  control,
}) => {
  const scontrol = useFormContext()
  const mcontrol = control || scontrol
  return (
    <FormControl style={{ minWidth: 400 }}>
      <Controller
        name={name}
        control={mcontrol}
        rules={{
          required: {
            value: !!required,
            message: `${name} is required.`,
          },
          ...extraRules,
        }}
        render={({ field: { ref, ...rest } }) => (
          <KeyboardDatePicker
            disablePast={disablePast}
            margin="normal"
            id="date-picker-dialog"
            label={label}
            initialFocusedDate={Date.now()}
            format={format}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            {...rest}
          />
        )}
      />
      <p style={{ color: 'red' }}>{errors[name]?.message}</p>
    </FormControl>
  )
}

export default DatePickerController
