/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import InputController from '../../components/inputController'
import './styles.scss'
import { FormInputDropdown } from '../../components/inputController/FormInputDropdown'
import { Button, Grid } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SwitchController from '../../components/inputController/SwitchController'
import CheckboxGroup from '../../components/inputController/CheckBoxCntroller'
import { DevTool } from '@hookform/devtools'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import TextAreaController from '../../components/inputController/TextAreaController'
import { connect } from 'react-redux'
import {
  clearPricingPlan,
  createPricingPlan,
  getDraftPricingPlanDetails,
  getPricingPlanDetails,
  updatePricingPlan,
} from '../../actions/cities'
import { ProductComponent } from '../../components/PricingPlanProductComponent'
import { remapArrayToValueAndLabel } from '../../utils/utils'
import RadioGroupController from '../../components/inputController/RadioGroupController'
import { useRemapCities, useShowToast } from '../../hooks'
import { strings } from '../../I18n'
import {
  config,
  CONVERT_TYPE,
  groupOptions,
  PAY_AS_YOU_GO,
  planType,
  saveOptions,
  SUBSCRIPTION,
  subscriptionPeriod,
} from '../../constants/const'
import CircularProgress from '@mui/material/CircularProgress'
import ModalContainerWithButton from '../../components/modal/modalWithButton'
import moment from 'moment'
import DateTimePickerController from '../../components/inputController/DateTimePickerController'
import { PRICING_PLAN_DOMAINS_ROUTE } from '../../constants/routes'

const PricingPlanEditor = (props) => {
  const { id } = useParams()
  const showToast = useShowToast()
  const { remappedCities } = useRemapCities()
  const [remapCurrency, setRemapCurrency] = useState([])
  const navigate = useNavigate()
  const [saveModalVisible, setSaveModalVisible] = useState(false)
  const query = useLocation()
  const extractedIsDraft = new URLSearchParams(query.search).get('isDraft')

  useEffect(() => {
    if (id) {
      if (extractedIsDraft === 'true') {
        props.getDraftPricingPlanDetails(id)
      } else {
        props.getPricingPlanDetails(id)
      }
    }
  }, [id])

  const schema = yup.object().shape({
    cityId: yup.string().required(strings('validations.cityIsRequired')),
    type: yup.string().required(strings('validations.planTypeIsRequired')),

    displayOrder: yup
      .number()
      .positive(strings('validations.mustBeAPositiveNumber'))
      .required(strings('validations.displayOrderIsRequired'))
      .typeError(strings('validations.mustBeANumber')),
    amount: yup.number().when('type', {
      is: PAY_AS_YOU_GO,
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .min(0, strings('validations.amountMustyBeANumber'))
        .required(strings('validations.amountIsRequiredIfPlanTypeIsSubscription')),
    }),

    minBalanceToRide: yup.number().when('paymentMethodWallet', {
      is: true,
      then: yup
        .number()
        .positive(strings('validations.mustBeAPositiveNumber'))
        .required(strings('validations.minBalanceToRideIsRequiredIfPaymentMethodIsWallet')),
      otherwise: yup.number().nullable(),
    }),

    minTopUpAmount: yup.number().when('paymentMethodWallet', {
      is: true,
      then: yup
        .number()
        .positive(strings('validations.mustBeAPositiveNumber'))
        .required(strings('validations.minTopupAmountToRideIsRequiredIfPaymentMethodIsWal'))
        .typeError(strings('validations.mustBeANumber')),
      otherwise: yup.number().nullable(),
    }),
    maxTopUpAmount: yup.number().when('paymentMethodWallet', {
      is: true,
      then: yup
        .number()
        .positive(strings('validations.mustBeAPositiveNumber'))
        .required(strings('validations.maxTopupAmountToRideIsRequiredIfPaymentMethodIsWal'))
        .typeError(strings('validations.mustBeANumber')),
      otherwise: yup.number().nullable(),
    }),

    directPaymentFee: yup.number().when('paymentMethodWallet', {
      is: true,
      then: yup
        .number()
        .positive(strings('validations.mustBeAPositiveNumber'))
        .required(strings('validations.directPaymentFeeIsRequiredIfPaymentMethodIsWallet'))
        .typeError(strings('validations.mustBeANumber')),
      otherwise: yup.number().nullable(),
    }),
    // endDateMillis: yup.string().when(['type', 'interval'], {
    //   is: (type, interval) => type === 'SUBSCRIPTION' && ['SEASON', 'ONE_TIME'].includes(interval),
    //   then: yup.string().nullable().required(strings('validations.endDateIsRequired')),
    //   otherwise: yup.string().nullable(),
    // }),

    // Must select one supportedBikeTypes if min is not 1 then throw error "You must select at least one supportedBikeTypes"
    supportedBikeTypes: yup
      .array()
      .min(1, strings('validations.youMustSelectAtLeastOneVehicleType'))
      .required(strings('validations.youMustSelectAtLeastOneVehicleType')),
    walletPresets: yup
      .array()
      .nullable()
      .of(yup.number(strings('validations.mustBeANumber')).integer().positive())
      .test('unique', strings('validations.duplicateValuesAreNotAllowed'), function (value) {
        if (!value) return true
        return new Set(value).size === value.length
      })
      .min(0, strings('validations.walletpresetsMustBeANonemptyArrayOfPositiveInteger'))
      .test(
        'is-number',
        strings('validations.walletpresetsMustBeANonemptyArrayOfPositiveInteger'),
        function (value) {
          if (!value) return true
          return value.every((v) => typeof v === 'number' && !isNaN(v))
        },
        { path: 'walletPresets' }
      ),
    products: yup.array().of(
      yup.object().shape({
        startupAmount: yup
          .number()
          .min(0, strings('validations.startupAmountMustBeAPositiveNumber'))
          .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
          .nullable()
          .typeError(strings('validations.startupAmountMustBeANumber')),

        depositAmount: yup
          .number()
          .min(0, strings('validations.depositAmountMustBePositive'))
          .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
          .nullable()
          .typeError(strings('validations.startupAmountMustBeANumber3')),

        minutesFree: yup
          .number()
          .min(0, strings('validations.minFreeMustBePositive'))
          .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
          .nullable()
          .typeError(strings('validations.startupAmountMustBeANumber2')),

        maxAmount: yup
          .number()
          .min(0, strings('validations.maxAmountMustBePositive'))
          .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
          .nullable()
          .typeError(strings('validations.startupAmountMustBeANumber')),
      })
    ),
  })

  const defautlValues = {
    type: planType[0].value,
    interval: subscriptionPeriod[0].value,
    currency: 'NOK',
    saveOption: saveOptions[0].value,
    endDateMillis: null,
  }

  if (id) {
    defautlValues.cityId = ''
  }
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defautlValues,
    },
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'walletPresets', // unique name for your Field Array
  })

  const {
    fields: productField,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'products', // unique name for your Field Array
  })

  const watchProducts = watch('products')
  const watchSaveOption = watch('saveOption')

  useEffect(() => {
    if (!id) {
      reset({
        currency: 'SEK',
        type: planType[0].value,
        interval: subscriptionPeriod[0].value,
        saveOption: saveOptions[0].value,
        endDateMillis: null,
      })
    }
  }, [id, reset, remappedCities])

  const onSubmit = (data) => {
    let isValid = true

    if (watchType === SUBSCRIPTION) {
      data.products.forEach((product, index) => {
        if (!product?.followingHoursRate?.value) {
          setError(`products.${index}.followingHoursRate.value`, {
            types: {
              required: strings('validations.validDate'),
            },
          })
          isValid = false
        }
        if (!product?.followingHoursRate?.intervalMinutes) {
          setError(`products.${index}.followingHoursRate.intervalMinutes`, {
            types: {
              required: strings('validations.validInterval'),
            },
          })
          isValid = false
        }

        if (product.maxAmount) {
          if (!product.maxAmountDurationValidity) {
            setError(`products.${index}.maxAmountDurationValidity`, {
              types: {
                required: strings('validations.maxAmountDurationIsRequired'),
              },
            })
            isValid = false
          }
        }
      })
    } else {
      data.products.forEach((product, index) => {
        if (!product.rate.value) {
          setError(`products.${index}.rate.value`, {
            types: {
              required: strings('validations.enterAValidRate'),
            },
          })
          isValid = false
        }

        if (!product?.rate?.intervalMinutes || product?.rate?.intervalMinutes < 0) {
          setError(`products.${index}.rate.intervalMinutes`, {
            types: {
              required: strings('validations.enterAValidMinutesInterval'),
            },
          })
          isValid = false
        }
        if (product.maxAmount) {
          if (!product.maxAmountDurationValidity) {
            setError(`products.${index}.maxAmountDurationValidity`, {
              types: {
                required: strings('validations.maxAmountDurationIsRequiredIfMaxAmountIsPresent'),
              },
            })
            isValid = false
          }
        }
      })
    }

    if (isValid) {
      setSaveModalVisible(true)
    }
  }

  const savePlan = () => {
    const data = getValues()
    data.presets = data?.walletPresets?.join(',') || null
    data.releaseDateAndTimeInMili = null
    data.endDateMillis = moment(data.endDateMillis).utcOffset(0).valueOf()

    if (data.saveOption !== saveOptions[0].value) {
      const deployDateTime = data.deployDateTime || moment.now()

      const diff = moment(deployDateTime).diff(moment().minute(0).valueOf(), 'minute')

      if (diff >= 59) {
        data.releaseDateAndTimeInMili = moment(deployDateTime).utcOffset(0).seconds(0).valueOf()
      } else {
        showToast(strings('validations.pleaseSelectTimeAfter1Hour'), 'error')
        return
      }
    }
    if (id) {
      props.updatePricingPlan(id, data)
    } else {
      props.createPricingPlan(data)
    }
    setSaveModalVisible(false)
  }

  useEffect(() => {
    if (props.createPricingPlanSuccess) {
      props.clearPricingPlan()
      showToast('Pricing Plan Created', 'success')

      //  navigate to /dashboard/cities
      navigate('/dashboard/cities')
    }
  }, [props.createPricingPlanSuccess])

  useEffect(() => {
    if (props.updatePricingPlanSuccess) {
      props.clearPricingPlan()
      showToast(strings('validations.pricingPlanUpdated'), 'success')

      //  navigate to /dashboard/cities
      navigate('/dashboard/cities')
    }
  }, [props.updatePricingPlanSuccess])

  const supportedBikeTypes = watch('supportedBikeTypes')
  const currency = watch('currency')
  const watchType = watch('type')
  const watchInterval = watch('interval')
  const walletActiveWatch = watch('paymentMethodWallet')

  useEffect(() => {
    // if type is SUBSCRIPTION then set amount to null and interval to null
    if (watchType !== 'SUBSCRIPTION') {
      setValue('interval', subscriptionPeriod[0].value)
    }
  }, [watchType])

  useEffect(() => {
    if (id && props.getPricingPlanDetailsSuccess && props.pricingPlanDetails) {
      const constructResetValues = {
        ...props.pricingPlanDetails,
        cityId: props.pricingPlanDetails?.cityId,
        saveOption: saveOptions[0].value,
        walletPresets: props.pricingPlanDetails?.presets?.split(','),
        products: [],
        supportedBikeTypes: props.pricingPlanDetails?.products.map((item) => item.vehicleType),
      }

      if (props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'BIKE')) {
        constructResetValues.products.push({
          ...props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'BIKE'),
          maxAmountDurationValidity: props.pricingPlanDetails?.products
            .find((item) => item.vehicleType === 'BIKE')
            ?.maxAmountDurationValidity?.toString(),
        })
      }
      if (props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'E_BIKE')) {
        constructResetValues.products.push({
          ...props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'E_BIKE'),
          maxAmountDurationValidity: props.pricingPlanDetails?.products
            .find((item) => item.vehicleType === 'E_BIKE')
            ?.maxAmountDurationValidity?.toString(),
        })
      }
      if (props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'SCOOTER')) {
        constructResetValues.products.push({
          ...props.pricingPlanDetails?.products.find((item) => item.vehicleType === 'SCOOTER'),
          maxAmountDurationValidity: props.pricingPlanDetails?.products
            .find((item) => item.vehicleType === 'SCOOTER')
            ?.maxAmountDurationValidity?.toString(),
        })
      }

      if (extractedIsDraft === 'true') {
        constructResetValues.saveOption = saveOptions[1].value
        constructResetValues.deployDateTime = props.pricingPlanDetails?.releaseDateAndTimeInMili
      }

      reset(constructResetValues)
    }
  }, [props.getPricingPlanDetailsSuccess])

  useEffect(() => {
    const uniqueTypes = watchProducts?.map((item) => item?.vehicleType)
    //if not resists in uniqueTypes then add it

    if (supportedBikeTypes?.length > 0) {
      // setSelectedState(supportedBikeTypes[0])
      //if not resists in uniqueTypes then append it or else remove it

      supportedBikeTypes.forEach((item) => {
        if (!uniqueTypes.includes(item)) {
          productAppend({ vehicleType: item })
        }
      })

      //  supportedBikeTypes get items that are not present in uniqueTypes
      const filtered = uniqueTypes.filter((item) => !supportedBikeTypes.includes(item))

      // remove filtered from products by index
      filtered.forEach((item) => {
        const index = uniqueTypes.findIndex((type) => type === item)
        productRemove(index)
      })
    } else {
      setValue('products', [])
    }
  }, [supportedBikeTypes])

  useEffect(() => {
    setRemapCurrency(remapArrayToValueAndLabel(props.pricingPlanFields?.currencies))
  }, [props.pricingPlanFields?.currencies])

  useEffect(() => {
    if (watchType === PAY_AS_YOU_GO) {
      unregister('amount')
    } else {
      register('amount')
    }
  }, [watchType])

  useEffect(() => {
    if (walletActiveWatch) {
      unregister('minBalanceToRide')
      unregister('minTopUpAmount')
      unregister('maxTopUpAmount')
      unregister('directPaymentFee')
      unregister('walletPresets')
    } else {
      register('minBalanceToRide')
      register('minTopUpAmount')
      register('maxTopUpAmount')
      register('directPaymentFee')
      register('walletPresets')
    }
  }, [walletActiveWatch])

  return (
    <div>
      <FormProvider {...control}>
        <h1 className="pricing-plan-heading">
          <span>{strings('message.registerPricingPlan')}</span>
          {props?.pricingPlanDetails?.type === 'BUSINESS' && (
            <Link to={PRICING_PLAN_DOMAINS_ROUTE.replace(':id', id)}>Pricing plan domains</Link>
          )}
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            padding: 40,
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={3} md={12}>
              <div className="w-100">
                <FormInputDropdown
                  name="cityId"
                  options={remappedCities}
                  errors={errors}
                  label={strings('pricingPlan.city')}
                  required={false}
                  styles={{
                    minWidth: '90%',
                  }}
                  control={control}
                />
                <InputController
                  name={'displayOrder'}
                  label={strings('pricingPlan.displayOrder')}
                  info={strings('pricingPlan.displayOrderInfo')}
                  required={false}
                  muiProps={{
                    type: 'number',
                  }}
                  styles={{
                    minWidth: '90%',
                  }}
                  control={control}
                />
                <FormInputDropdown
                  name="currency"
                  options={remapCurrency}
                  errors={errors}
                  label={strings('pricingPlan.currency')}
                  required={false}
                  styles={{
                    minWidth: '90%',
                  }}
                  control={control}
                />

                <FormInputDropdown
                  name="type"
                  options={remapArrayToValueAndLabel(props.pricingPlanFields?.pricingPlanTypes)}
                  errors={errors}
                  label={strings('pricingPlan.pricingPlanType')}
                  required={false}
                  styles={{
                    minWidth: '90%',
                  }}
                  control={control}
                />

                {watchType === SUBSCRIPTION && (
                  <>
                    {/*{(watchInterval === 'ONE_TIME' || watchInterval === 'SEASON') && (*/}
                    {/*  <DateTimePickerController*/}
                    {/*    name={'endDateMillis'}*/}
                    {/*    errors={errors}*/}
                    {/*    required={true}*/}
                    {/*    label={strings('pricingPlan.subscriptionEndDate')}*/}
                    {/*    disablePast={true}*/}
                    {/*    styles={{*/}
                    {/*      minWidth: '90%',*/}
                    {/*    }}*/}
                    {/*    control={control}*/}
                    {/*  />*/}
                    {/*)}*/}
                  </>
                )}

                {watchType !== PAY_AS_YOU_GO && (
                  <>
                    <InputController
                      name={'amount'}
                      label={strings('pricingPlan.amount')}
                      info={strings('pricingPlan.amountInfo')}
                      required={false}
                      muiProps={{
                        type: 'number',
                      }}
                      inputPrefix={currency}
                      styles={{
                        minWidth: '90%',
                      }}
                      control={control}
                      convertType={CONVERT_TYPE.CENT}
                      disabled={id ? watchType === SUBSCRIPTION : false}
                    />
                    <FormInputDropdown
                      name="interval"
                      options={subscriptionPeriod}
                      errors={errors}
                      label={'Subscription period'}
                      required={false}
                      styles={{
                        minWidth: '90%',
                      }}
                      control={control}
                    />
                  </>
                )}

                <SwitchController
                  control={control}
                  name="paymentMethodWallet"
                  errors={errors}
                  label={strings('pricingPlan.wallet')}
                  disabled={false}
                />
                {/*<RadioGroupController*/}
                {/*  options={pricingPlanInterval}*/}
                {/*  name={'interval'}*/}
                {/*  control={control}*/}
                {/*  label={strings('pricingPlan.rate')}*/}
                {/*/>*/}
              </div>
            </Grid>
            <Grid item lg={6} md={12}>
              <div>
                <h4>{'Title'}</h4>
                <Tabs>
                  <TabList>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      EN
                    </Tab>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      NB
                    </Tab>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      SV
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`title.locale.en`}
                        label={strings('pricingPlan.englishDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`title.locale.no`}
                        label={strings('pricingPlan.norwayDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`title.locale.sv`}
                        label={strings('pricingPlan.sweishDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                </Tabs>
              </div>

              <div>
                <h4>{strings('pricingPlan.description')}</h4>
                <Tabs>
                  <TabList>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      EN
                    </Tab>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      NB
                    </Tab>
                    <Tab
                      style={{
                        width: '10%',
                        textAlign: 'center',
                      }}
                    >
                      SV
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`description.locale.en`}
                        label={strings('pricingPlan.englishDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`description.locale.no`}
                        label={strings('pricingPlan.norwayDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                  <TabPanel>
                    <h2>
                      <TextAreaController
                        name={`description.locale.sv`}
                        label={strings('pricingPlan.sweishDescription')}
                        required={false}
                        multiline={true}
                        styles={{
                          width: '100%',
                        }}
                        control={control}
                      />
                    </h2>
                  </TabPanel>
                </Tabs>
              </div>
            </Grid>
            <Grid item lg={3} md={12}>
              <CheckboxGroup
                control={control}
                label={strings('pricingPlan.vehicleType')}
                name="supportedBikeTypes"
                options={groupOptions}
                config={config}
                customStyles={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  width: '200px',
                }}
              />
            </Grid>
          </Grid>

          {walletActiveWatch && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <InputController
                name={'minBalanceToRide'}
                label={strings('pricingPlan.walletMin')}
                info={strings('pricingPlan.walletMinDes')}
                required={false}
                muiProps={{
                  type: 'number',
                }}
                inputPrefix={currency}
                styles={{
                  minWidth: '400px',
                }}
                control={control}
                convertType={CONVERT_TYPE.CENT}
              />
              <InputController
                name={'minTopUpAmount'}
                label={strings('pricingPlan.minTopupAmount')}
                info={strings('pricingPlan.minTopupAmountInfo')}
                required={false}
                muiProps={{
                  type: 'number',
                }}
                inputPrefix={currency}
                styles={{
                  minWidth: '400px',
                }}
                control={control}
                convertType={CONVERT_TYPE.CENT}
              />
              <InputController
                name={'maxTopUpAmount'}
                label={strings('pricingPlan.maxTopupAmount')}
                info={strings('pricingPlan.maxTopupAmountInfo')}
                required={false}
                muiProps={{
                  type: 'number',
                }}
                inputPrefix={currency}
                styles={{
                  minWidth: '400px',
                }}
                control={control}
                convertType={CONVERT_TYPE.CENT}
              />
              <InputController
                name={'directPaymentFee'}
                label={strings('pricingPlan.directTransactionFee')}
                info={strings('pricingPlan.directTransactionFeeInfo')}
                required={false}
                muiProps={{
                  type: 'number',
                }}
                inputPrefix={currency}
                styles={{
                  minWidth: '400px',
                }}
                control={control}
                convertType={CONVERT_TYPE.CENT}
              />

              <div>
                {fields.map((field, index) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={field.id}
                  >
                    <InputController
                      name={`walletPresets.${index}`}
                      label={strings('pricingPlan.walletAmount')}
                      required={false}
                      muiProps={{
                        type: 'number',
                      }}
                      convertType={CONVERT_TYPE.CENT}
                      control={control}
                    />
                    <Button
                      style={{ marginLeft: '5%' }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        remove(index)
                      }}
                    >
                      {strings('pricingPlan.remove')}
                    </Button>
                  </div>
                ))}
                <p
                  style={{
                    color: 'red',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {errors?.walletPresets?.message}
                </p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    append(0)
                  }}
                >
                  {strings('pricingPlan.configureWalletPresets')}
                </Button>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            {productField.map((field, index) => {
              return (
                <ProductComponent
                  key={field.id}
                  productIndex={index}
                  control={control}
                  currency={currency}
                  type={field.vehicleType}
                  pricingPlanType={watchType}
                  unregister={unregister}
                  register={register}
                />
              )
            })}
          </div>

          {/*  submit button*/}

          {props.createPricingPlanSuccess === undefined ||
          props.updatePricingPlanSuccess === undefined ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                marginTop: '20px',
              }}
            >
              {strings('pricingPlan.savePlan')}
            </Button>
          )}
        </form>
      </FormProvider>
      <DevTool control={control} />
      <ModalContainerWithButton
        visible={saveModalVisible}
        closeAction={() => {
          setSaveModalVisible(false)
        }}
        onSave={savePlan}
        title={strings('pricingPlan.savePlanOptions')}
        body={
          <>
            <RadioGroupController
              options={saveOptions}
              name={'saveOption'}
              control={control}
              label={strings('pricingPlan.saveOption')}
            />
            {watchSaveOption === 'later' && (
              <>
                <DateTimePickerController
                  name={'deployDateTime'}
                  errors={errors}
                  required={true}
                  label={strings('pricingPlan.deployDateTime')}
                  disablePast={true}
                  disableMinutes={true}
                  styles={{
                    minWidth: '400px',
                  }}
                  control={control}
                />
              </>
            )}
          </>
        }
      />
    </div>
  )
}

const mapStateToProps = ({
  vehicle: { bikes },
  cities: {
    pricingPlanDetails,
    pricingPlanFields,
    getPricingPlanDetailsSuccess,
    createPricingPlanSuccess,
    updatePricingPlanSuccess,
  },
}) => ({
  pricingPlanDetails,
  pricingPlanFields,
  getPricingPlanDetailsSuccess,
  createPricingPlanSuccess,
  updatePricingPlanSuccess,
})

const mapDispatchToProps = {
  getPricingPlanDetails,
  createPricingPlan,
  clearPricingPlan,
  updatePricingPlan,
  getDraftPricingPlanDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlanEditor)
