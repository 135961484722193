/*eslint-disable*/
import React, { useEffect, useMemo } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import {
  clearStation,
  clearStationData,
  createStation,
  getLimitZonesById,
  getSpeedLimitZones,
  getStationById,
  getStations,
  getStationsStatuses,
  getVehicleStatuses,
  refreshStation,
  saveLimitZones,
  updateLimitZones,
  updateStation,
} from '../../actions/stations'
import { emptyPassedParams } from '../../actions/screen'
import { strings } from '../../I18n'
import StationModal from '../../components/modal/stationModal'
import { useCity, useShowToast } from '../../hooks'
import useCustomTable from '../../components/table/CustomTable'
import {
  CHARGE_STATION,
  stationTypes,
  stationVehicleStatuses,
  TAG_COLOR,
} from '../../constants/const'
import { cityNameById, reformatFilter, reformatSort, stringFromValue } from '../../utils/utils'
import Moment from 'moment/moment'
import StatusIndicator from '../../components/statusIndicator'
import DropDownMenu from '../../components/menu'
import Tag from '../../components/Tags'

export const TAB_STATE = {
  STATIONS: 'stations',
  LIMITING_ZONES: 'limiting_zones',
}

function StationContainer(props) {
  const {
    clearStation,
    getStations,
    vehicleStatuses,
    clearStationData,
    stationsStatuses,
    getStationsStatuses,
    getStationsSuccess,
    savedCity,
    passedParams,
    getStationById,
    emptyPassedParams,
  } = props

  const showToast = useShowToast()
  const [stationModalVisible, setStationModalVisible] = React.useState(false)
  const [selectedTab, setSelectedTab] = React.useState(TAB_STATE.STATIONS)
  const [filterObject, setFilterObject] = React.useState([])
  const { selectedCity } = useCity()
  const [isEdit, setIsEdit] = React.useState(false)

  useEffect(() => {
    props.getVehicleStatuses()
    props.getStationsStatuses()
  }, [])

  const toggleTabState = (tab) => {
    setSelectedTab(tab)
  }

  const showModal = () => setStationModalVisible(true)

  const hideModal = () => setStationModalVisible(false)

  useEffect(() => {
    if (passedParams) {
      getStationById(passedParams)
      setIsEdit(true)
      emptyPassedParams()
    }
  }, [passedParams, getStationById, emptyPassedParams])

  useEffect(() => {
    if (props.createStationSuccess) {
      showToast('Success', 'success')
      clearStation()
      hideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.createStationSuccess,
    clearStation,
    getStations,
    savedCity,
    props.createSpeedLimitZoneSuccess,
  ])

  useEffect(() => {
    if (
      props.updateStationSuccess ||
      props.updateSpeedLimitZoneSuccess ||
      props.createSpeedLimitZoneSuccess
    ) {
      showToast('Success', 'success')
      clearStation()
      clearStationData()
      reloadData()
      // getStations(0, '', '', savedCity || '', pageSize)
      hideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.updateStationSuccess,
    clearStation,
    savedCity,
    props.updateSpeedLimitZoneSuccess,
    props.createSpeedLimitZoneSuccess,
  ])

  useEffect(() => {
    if (props.stationByIdSuccess) {
      clearStationData()
      showModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stationByIdSuccess, savedCity, props.speedLimitZoneSuccess])

  useEffect(() => {
    if (props.speedLimitZoneSuccess) {
      clearStationData()
      showModal()
    }
  }, [props.speedLimitZoneSuccess, savedCity])

  const closeModal = () => {
    props.clearStation()
    hideModal()
  }

  const showCreationModal = () => {
    getStationsStatuses()
    showModal()
  }

  const edit = (station) => (
    <div className={'edit'} onClick={() => showStationInfo(station)}>
      <div className={'stationLink'} style={{ padding: 5 }}>
        {strings('descriptions.edit')}
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )
  const momentDate = (date) => (date ? Moment(date + 'Z').format('DD.MM.YYYY HH:mm') : '-')

  const menuItems = React.useMemo(
    () => [
      {
        action: (station) => props.refreshStation(station.id),
        title: strings('buttons.refresh'),
      },
      {
        action: (station) => props.reloginStation(station.id),
        title: strings('buttons.relogin'),
      },
    ],
    [props.refreshStation, props.reloginStation]
  )

  const actions = (station) =>
    station.type === CHARGE_STATION ? (
      <DropDownMenu
        item={station}
        menuItems={menuItems}
        current={strings('descriptions.actions')}
      />
    ) : null

  const showStationInfo = (station) => {
    if (selectedTab === TAB_STATE.STATIONS) {
      props.getStationById(station.id)
      setIsEdit(true)
    } else {
      props.getLimitZonesById(station.id)
      setIsEdit(true)
    }

    getStationsStatuses()
  }

  const columns = useMemo(
    () => [
      {
        id: 'sortable7',
        name: '',
        selector: 'id',
        center: true,
        sortable: true,
        cell: (row) => edit(row),
      },
      {
        id: 'sortable8',
        name: strings('descriptions.stationCode'),
        selector: 'code',
        center: true,
        sortable: true,
      },
      {
        id: 'sortable9',
        name: strings('descriptions.stationName'),
        selector: 'code',
        center: true,
        sortable: true,
        cell: (row) => stringFromValue(row?.name),
      },
      {
        id: 'sortable10',
        name: strings('descriptions.lastSeenOnline'),
        selector: 'code',
        center: true,
        sortable: true,
        cell: (row) => momentDate(row?.lastSeenOnline),
      },
      {
        id: 'sortable1',
        name: strings('descriptions.placesNumber'),
        selector: 'dockCount',
        center: true,
        sortable: true,
      },
      {
        id: 'sortable12',
        name: strings('descriptions.bikeNumber'),
        selector: 'vehicleCount',
        center: true,
        sortable: true,
      },
      {
        id: 'sortable13',
        name: strings('descriptions.status'),
        selector: 'status',
        center: true,
        sortable: true,
        cell: (row) => <StatusIndicator status={row?.status?.replace(/_/g, ' ')} />,
      },
      {
        id: 'sortable14',
        name: strings('descriptions.type'),
        selector: 'type',
        center: true,
        sortable: true,
        cell: (row) => row?.type?.replace(/_/g, ' '),
      },
      {
        id: 'sortable15',
        name: strings('descriptions.cityProject'),
        selector: 'vehicleCount',
        center: true,
        sortable: false,
        cell: (row) => stringFromValue(cityNameById(props?.citiesList, row?.cityId)),
      },
      {
        id: 'sortable16',
        name: '',
        selector: 'vehicleCount',
        center: true,
        sortable: false,
        width: '200px',
        cell: (row) => actions(row),
      },
    ],
    [selectedTab]
  )

  const reloadData = () => {
    if (selectedTab === TAB_STATE.STATIONS) {
      getStations(
        localPage,
        debouncedSearchTerm,
        selectedCity || '',
        reformatSort(sortData),
        reformatFilter(filterData),
        localRows
      )
    } else {
      props.getSpeedLimitZones(
        localPage,
        debouncedSearchTerm,
        selectedCity || '',
        reformatSort(sortData),
        reformatFilter(filterData),
        localRows
      )
    }
  }

  const addButtonClick = () => {
    setIsEdit(false)
    showCreationModal()
  }

  useEffect(() => {
    if (vehicleStatuses && stationsStatuses) {
      setFilterObject([
        {
          filterBy: 'stationVehiclesStatus',
          filterValue: vehicleStatuses,
        },
        {
          filterBy: 'stationTypes',
          filterValue: stationTypes,
        },
        {
          filterBy: 'stationStatuses',
          filterValue: stationsStatuses,
        },

        {
          filterBy: 'vehicleStatuses',
          filterValue: stationVehicleStatuses,
        },
      ])
    }
  }, [vehicleStatuses, stationsStatuses, stationTypes])

  const speedLimitZoneColumns = useMemo(
    () => [
      {
        id: 'sortable7',
        name: '',
        selector: 'id',
        center: true,
        sortable: true,
        cell: (row) => edit(row),
      },
      {
        id: 'sortable1',
        name: 'ID',
        selector: 'id',
        center: true,
        sortable: false,
      },
      {
        id: 'sortable1',
        name: 'Name',
        selector: 'name',
        center: true,
        sortable: false,
      },
      {
        id: 'sortable2',
        name: 'Code',
        selector: 'code',
        center: true,
        sortable: false,
      },
      {
        id: 'sortable3',
        name: 'CityId',
        selector: 'cityId',
        center: true,
        sortable: false,
      },
      {
        id: 'sortable4',
        name: 'Max Speed',
        selector: 'maxSpeed',
        center: true,
        sortable: false,
        cell: (row) => `${row?.maxSpeed} km/h`,
      },
      {
        id: 'sortable5',
        name: 'Description',
        selector: 'description',
        center: true,
        sortable: false,
      },
      {
        id: 'sortable6',
        name: 'Status',
        selector: 'status',
        center: true,
        sortable: false,
        cell: (row) => (
          <Tag
            colorTag={row?.status === 'ONLINE' ? TAG_COLOR.GREEN : TAG_COLOR.RED}
            text={row.status}
          />
        ),
      },
    ],
    [selectedTab]
  )

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(
    selectedTab === TAB_STATE.STATIONS ? columns : speedLimitZoneColumns,
    selectedTab === TAB_STATE.STATIONS ? props.stationsResult : props.speedLimitZoneList,
    reloadData,
    addButtonClick,
    filterObject,
    selectedTab === TAB_STATE.STATIONS ? 'Stations' : 'Speed limiting zones',
    selectedTab === TAB_STATE.STATIONS,
    true
  )

  useEffect(() => {
    resetPagination()
  }, [selectedTab])

  useEffect(() => {
    reloadData()
  }, [
    localPage,
    debouncedSearchTerm,
    selectedCity,
    sortData,
    filterData,
    localRows,
    selectedTab,
    props.updateStationSuccess,
    props.createStationSuccess,
  ])

  useEffect(() => {
    setLoading(getStationsSuccess === undefined || !props.speedLimitZoneListSuccess === undefined)
  }, [getStationsSuccess])

  const content = () => (
    <>
      {renderHeaderBar()}
      {renderCustomTable()}
      <StationModal
        visible={stationModalVisible}
        clearStation={closeModal}
        station={props.station}
        speedLimitZone={props.speedLimitZone}
        updateStation={isEdit ? props.updateStation : props.createStation}
        updateSpeedLimitZone={isEdit ? props.updateLimitZones : props.saveLimitZones}
        cityNameList={props.citiesList}
        statuses={stationsStatuses}
        selectedTab={selectedTab}
        isEdit={isEdit}
      />
    </>
  )

  return <div className={'dashboardContent'}>{content()}</div>
}

const mapStateToProps = ({
  stations: {
    stations,
    station,
    stationsResult,
    stationByIdSuccess,
    updateStationSuccess,
    createStationSuccess,
    vehicleStatuses,
    getVehicleStatusesSuccess,
    stationsStatuses,
    getStationsSuccess,
    speedLimitZoneList,
    speedLimitZoneListSuccess,
    speedLimitZone,
    updateSpeedLimitZoneSuccess,
    createSpeedLimitZoneSuccess,
    speedLimitZoneSuccess,
  },
  cities: { citiesList, savedCity },
  screen: { pageSize, passedParams },
}) => ({
  stations,
  station,
  stationByIdSuccess,
  updateStationSuccess,
  createStationSuccess,
  stationsResult,
  citiesList,
  savedCity,
  pageSize,
  vehicleStatuses,
  getVehicleStatusesSuccess,
  stationsStatuses,
  getStationsSuccess,
  passedParams,
  speedLimitZoneList,
  speedLimitZoneListSuccess,
  speedLimitZone,
  updateSpeedLimitZoneSuccess,
  createSpeedLimitZoneSuccess,
  speedLimitZoneSuccess,
})

const mapDispatchToProps = {
  getStations,
  clearStation,
  updateStation,
  createStation,
  getVehicleStatuses,
  clearStationData,
  getStationsStatuses,
  getStationById,
  emptyPassedParams,
  getSpeedLimitZones,
  refreshStation,
  updateLimitZones,
  saveLimitZones,
  getLimitZonesById,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationContainer)
