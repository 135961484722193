import { Box, ListItemIcon, ListItemText, Menu } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import { Photo, Place, Route } from '@mui/icons-material'
import React, { useMemo, useRef, useState } from 'react'

interface Props {
  rental: any
  onOpenPath: () => void
}

export const RentalsItemActions = ({ rental, onOpenPath }: Props) => {
  const linksRef = useRef<any>()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const startPosition = useMemo(() => {
    const { startLatitude, startLongitude } = rental || {}

    if (startLatitude && startLongitude) {
      return [startLatitude, startLongitude]
    } else {
      return null
    }
  }, [rental])

  const endPosition = useMemo(() => {
    const { endLatitude, endLongitude } = rental || {}

    if (endLatitude && endLongitude) {
      return [endLatitude, endLongitude]
    } else {
      return null
    }
  }, [rental])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <Tooltip title="Actions">
          <IconButton ref={linksRef} size="small" onClick={() => setIsMenuOpen(true)}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={linksRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
          {!!rental?.routeImageUrl && (
            <MenuItem onClick={() => onOpenPath()}>
              <ListItemIcon>
                <Photo fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Path</ListItemText>
            </MenuItem>
          )}
          {!!startPosition && (
            <MenuItem
              onClick={() =>
                window.open(`https://www.google.com/maps?q=${startPosition[0]},${startPosition[1]}`)
              }
            >
              <ListItemIcon>
                <Place fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Start Point</ListItemText>
            </MenuItem>
          )}
          {!!endPosition && (
            <MenuItem
              onClick={() =>
                window.open(`https://www.google.com/maps?q=${endPosition[0]},${endPosition[1]}`)
              }
            >
              <ListItemIcon>
                <Place fontSize="small" />
              </ListItemIcon>
              <ListItemText>View End Point</ListItemText>
            </MenuItem>
          )}
          {!!startPosition && !!endPosition && (
            <MenuItem
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/dir/${startPosition[0]},${startPosition[1]}/${endPosition[0]},${endPosition[1]}`
                )
              }
            >
              <ListItemIcon>
                <Route fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Path on Map</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  )
}
