import React from 'react'
import Switch from 'react-switch'
import { Controller } from 'react-hook-form'
import { FormControl } from '@mui/material'

const SwitchController = ({ control, name, label, errors, required, extraRules, disabled }) => {
  return (
    <FormControl style={{ minWidth: 400, marginTop: 20, marginBottom: 20 }}>
      <div
        style={{
          display: 'flex',
        }}
      >
        <p
          style={{
            marginRight: 10,
          }}
        >
          {label}
        </p>
        <Controller
          control={control}
          name={name}
          rules={{
            required: {
              value: !!required,
              message: `${name} is required.`,
            },
            ...extraRules,
          }}
          render={({ field: { onChange, value } }) => (
            <Switch
              disabled={disabled}
              onChange={(checked) => {
                onChange(checked)
              }}
              value={value}
              checked={value}
            />
          )}
        />
      </div>

      <p style={{ color: 'red' }}>{errors[name]?.message}</p>
    </FormControl>
  )
}

export default SwitchController
