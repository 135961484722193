import React from 'react'
import './styles.scss'
import { TAG_COLOR } from '../../constants/const'

const Tag = ({ text, colorTag }) => {
  const getTagByColor = () => {
    switch (colorTag) {
      case TAG_COLOR.RED:
        return 'red-tag'
      case TAG_COLOR.MAGENTA:
        return 'magenta-tag'
      case TAG_COLOR.GREEN:
        return 'green-tag'
      default:
        return 'red-tag'
    }
  }

  return <div className={getTagByColor()}>{text}</div>
}

export default Tag
