import React, { useRef, useState } from 'react'
import { Box, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import { setPassedParams } from '../../../actions/screen'
import { useDispatch, useStore } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import {
  alarmBike,
  lockBattery,
  lockBike,
  unlockBattery,
  unlockBike,
} from '../../../actions/vehicle'
import { HONGJI_BIKE } from '../../../constants/const'
import { deleteVehicle } from '../../../apis/vehicle/vehicle-apis'
import ModalContainer from '../../modal/modal'
import Button from '@mui/material/Button'
import {
  Alarm,
  Block,
  ChargingStation,
  History,
  Key,
  KeyOff,
  Lock,
  LockOpen,
  Map,
  Person,
  Route,
} from '@mui/icons-material'

interface Props {
  bike: any
  onEdit: (bike: any) => void
  onDelete: (bike: any) => void
}

export const BikeItemActions = ({ bike, onEdit, onDelete }: Props) => {
  const linksRef = useRef<any>()
  const [linksOpen, setLinksOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const store = useStore()
  const dispatch = useDispatch()

  const openMap = () => {
    window.open(bike.googleMapLink, '_blank')
  }

  const navigateToHistory = () => {
    navigate(routes.BIKE_HISTORY, {
      state: {
        bikeId: bike.id,
        vehicleCode: bike.lock?.code,
        bikeName: bike.name,
      },
    })
  }

  const navigateToStation = () => {
    dispatch(setPassedParams(bike.station?.id))
    navigate(routes.DASHBOARD_STATIONS)
  }

  const lockBikeAction = () => {
    dispatch(lockBike(bike.id))
  }

  const unlockBikeAction = () => {
    dispatch(unlockBike(bike.id))
  }

  const alarmBikeAction = () => {
    dispatch(alarmBike(bike.id))
  }

  const navigateToUser = () => {
    navigate(`/dashboard/user/${bike.userId}`)
  }

  const navigateToRental = () => {
    store.dispatch(setPassedParams(bike.journeyId))
    navigate(routes.DASHBOARD_RENTALS)
  }

  const lockBatteryAction = () => {
    dispatch(lockBattery(bike.id))
  }

  const unlockBatteryAction = () => {
    dispatch(unlockBattery(bike.id))
  }

  const deleteBikeAction = async () => {
    await deleteVehicle(bike.id)
    setDeleteModalOpen(false)
    onDelete(bike)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <Tooltip title="Actions">
          <IconButton ref={linksRef} size="small" onClick={() => setLinksOpen(true)}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={linksRef.current} open={linksOpen} onClose={() => setLinksOpen(false)}>
          <MenuItem onClick={openMap}>
            <ListItemIcon>
              <Map fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Map</ListItemText>
          </MenuItem>
          <MenuItem onClick={navigateToHistory}>
            <ListItemIcon>
              <History fontSize="small" />
            </ListItemIcon>
            <ListItemText>View History</ListItemText>
          </MenuItem>
          <MenuItem onClick={navigateToStation}>
            <ListItemIcon>
              <ChargingStation fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Station</ListItemText>
          </MenuItem>
          <MenuItem onClick={lockBikeAction}>
            <ListItemIcon>
              <Lock fontSize="small" />
            </ListItemIcon>
            <ListItemText>Lock Bike</ListItemText>
          </MenuItem>
          <MenuItem onClick={unlockBikeAction}>
            <ListItemIcon>
              <LockOpen fontSize="small" />
            </ListItemIcon>
            <ListItemText>Unlock Bike</ListItemText>
          </MenuItem>
          <MenuItem onClick={alarmBikeAction}>
            <ListItemIcon>
              <Alarm fontSize="small" />
            </ListItemIcon>
            <ListItemText>Alarm Bike</ListItemText>
          </MenuItem>
          {!!bike.userId && (
            <MenuItem onClick={navigateToUser}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              <ListItemText>View user</ListItemText>
            </MenuItem>
          )}
          {!!bike.journeyId && (
            <MenuItem onClick={navigateToRental}>
              <ListItemIcon>
                <Route fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Rental</ListItemText>
            </MenuItem>
          )}
          {bike.brand === HONGJI_BIKE && (
            <MenuItem onClick={lockBatteryAction}>
              <ListItemIcon>
                <Key fontSize="small" />
              </ListItemIcon>
              <ListItemText>Lock Battery</ListItemText>
            </MenuItem>
          )}
          {bike.brand === HONGJI_BIKE && (
            <MenuItem onClick={unlockBatteryAction}>
              <ListItemIcon>
                <KeyOff fontSize="small" />
              </ListItemIcon>
              <ListItemText>Unlock Battery</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => setDeleteModalOpen(true)}
            sx={(theme) => ({ color: theme.palette.error.main })}
          >
            <ListItemIcon>
              <Block fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete Bike</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        <Tooltip title="Edit">
          <IconButton ref={linksRef} size="small" onClick={() => onEdit(bike)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <ModalContainer visible={deleteModalOpen}>
        <Box>
          <Typography variant="h6">Do you really want to delete the vehicle?</Typography>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.spacing(4),
              gap: theme.spacing(2),
            })}
          >
            <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={deleteBikeAction}>
              Delete
            </Button>
          </Box>
        </Box>
      </ModalContainer>
    </Box>
  )
}
