import * as types from '../constants/actionTypes'
import * as endpoints from '../configs/endpoints'
import { GET_METHOD } from '../constants/apiMethods'

export const logIn = (user) => {
  return {
    type: types.SAVE_USER,
    user,
  }
}

export const logOut = () => {
  return {
    type: types.REMOVE_USER,
  }
}

export const saveToken = (token) => {
  return {
    type: types.SAVE_TOKEN,
    token,
  }
}

export const clearErrorMessage = () => {
  return {
    type: types.CLEAR_ERROR,
  }
}

export const getAdminDetails = () => {
  return {
    endpoint: endpoints.getAdminInfo(),
    method: GET_METHOD,
    types: [
      types.SET_ADMIN_DETAILS_REQUEST,
      types.SET_ADMIN_DETAILS_SUCCESS,
      types.SET_ADMIN_DETAILS_FAILURE,
    ],
  }
}
