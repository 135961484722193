import * as types from '../constants/actionTypes'

let initialState = {
  getCitiesSuccess: null,
  citiesResult: null,
  cities: [],
  cityByIdSuccess: null,
  city: null,
  updateCitySuccess: null,
  createCitySuccess: null,
  citiesListSuccess: null,
  citiesList: [],
  savedCity: null,
  pricingPlans: [],
  getPricingPlanSuccess: null,

  userReferral: null,
  userReferralSuccess: null,

  couponList: null,
  couponListSuccess: null,

  createCoupon: null,
  createCouponSuccess: null,

  couponById: null,
  couponByIdSuccess: null,

  updateCoupon: null,
  updateCouponSuccess: null,

  deleteReferral: null,
  deleteReferralSuccess: null,

  deleteCoupon: null,
  deleteCouponSuccess: null,

  getCouponListByUser: null,
  getCouponListByUserSuccess: null,

  deleteCouponById: null,
  deleteCouponByIdSuccess: null,

  bookingList: null,
  bookingListSuccess: null,

  cancelBooking: null,
  cancelBookingSuccess: null,

  savedCityFullInfo: null,

  pricingPlanDetails: null,
  getPricingPlanDetailsSuccess: null,

  pricingPlanFields: null,
  getPricingPlanFieldsSuccess: null,

  cityWhitelabelMapping: null,
  getCityWhitelabelMappingSuccess: null,

  createPricingPlan: null,
  createPricingPlanSuccess: null,

  updatePricingPlan: null,
  updatePricingPlanSuccess: null,

  deletePricingPlan: null,
  deletePricingPlanSuccess: null,

  downloadedCoupon: null,
  downloadCouponSuccess: null,
}

const cities = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_CANCEL_BOOKING: {
      return {
        ...state,
        cancelBookingSuccess: null,
      }
    }

    case types.CANCEL_BOOKING_REQUEST: {
      return {
        ...state,
        cancelBookingSuccess: null,
      }
    }
    case types.CANCEL_BOOKING_SUCCESS: {
      return {
        ...state,
        cancelBookingSuccess: true,
        cancelBooking: action.result,
      }
    }
    case types.CANCEL_BOOKING_FAILURE: {
      return {
        ...state,
        cancelBookingSuccess: false,
      }
    }

    case types.GET_BOOKING_LIST_REQUEST: {
      return {
        ...state,
        bookingListSuccess: null,
      }
    }
    case types.GET_BOOKING_LIST_SUCCESS: {
      return {
        ...state,
        bookingListSuccess: true,
        bookingList: action.result,
      }
    }
    case types.GET_BOOKING_LIST_FAILURE: {
      return {
        ...state,
        bookingListSuccess: false,
      }
    }

    case types.GET_CITIES_REQUEST: {
      return {
        ...state,
        getCitiesSuccess: undefined,
      }
    }
    case types.CLEAR_CREATE_COUPON: {
      return {
        ...state,
        createCouponSuccess: null,
        couponByIdSuccess: null,
        updateCouponSuccess: null,
        deleteReferralSuccess: null,

        getCouponListByUserSuccess: null,
        deleteCouponByIdSuccess: null,
      }
    }
    case types.CLEAR_DELETE_MAIN_COUPON: {
      return {
        ...state,
        deleteCouponSuccess: null,
      }
    }

    case types.DELETE_COUPON_BY_USER_REQUEST: {
      return {
        ...state,
        deleteCouponByIdSuccess: null,
      }
    }
    case types.DELETE_COUPON_BY_USER_SUCCESS: {
      return {
        ...state,
        deleteCouponByIdSuccess: true,
        deleteCouponById: action.result,
      }
    }
    case types.DELETE_COUPON_BY_USER_FAILURE: {
      return {
        ...state,
        deleteCouponByIdSuccess: false,
      }
    }

    case types.COUPON_LIST_BY_USER_LIST_REQUEST: {
      return {
        ...state,
        getCouponListByUserSuccess: null,
      }
    }
    case types.COUPON_LIST_BY_USER_LIST_SUCCESS: {
      return {
        ...state,
        getCouponListByUserSuccess: true,
        getCouponListByUser: action.result,
      }
    }
    case types.COUPON_LIST_BY_USER_LIST_FAILURE: {
      return {
        ...state,
        getCouponListByUserSuccess: false,
      }
    }

    case types.DELETE_MAIN_COUPON_REQUEST: {
      return {
        ...state,
        deleteCouponSuccess: null,
      }
    }
    case types.DELETE_MAIN_COUPON_SUCCESS: {
      return {
        ...state,
        deleteCouponSuccess: true,
        deleteCoupon: action.result,
      }
    }
    case types.DELETE_MAIN_COUPON_FAILURE: {
      return {
        ...state,
        deleteCouponSuccess: false,
      }
    }

    case types.DELETE_COUPON_REQUEST: {
      return {
        ...state,
        deleteReferralSuccess: null,
      }
    }
    case types.DELETE_COUPON_SUCCESS: {
      return {
        ...state,
        deleteReferralSuccess: true,
        deleteReferral: action.result,
      }
    }
    case types.DELETE_COUPON_FAILURE: {
      return {
        ...state,
        deleteReferralSuccess: false,
      }
    }

    case types.UPDATE_COUPON_BY_ID_REQUEST: {
      return {
        ...state,
        updateCouponSuccess: null,
      }
    }
    case types.UPDATE_COUPON_BY_ID_SUCCESS: {
      return {
        ...state,
        updateCouponSuccess: true,
        updateCoupon: action.result,
      }
    }
    case types.UPDATE_COUPON_BY_ID_FAILURE: {
      return {
        ...state,
        updateCouponSuccess: false,
      }
    }

    case types.COUPON_BY_ID_REQUEST: {
      return {
        ...state,
        couponByIdSuccess: null,
      }
    }
    case types.COUPON_BY_ID_SUCCESS: {
      return {
        ...state,
        couponByIdSuccess: true,
        couponById: action.result,
      }
    }
    case types.COUPON_BY_ID_FAILURE: {
      return {
        ...state,
        couponByIdSuccess: false,
      }
    }

    case types.POST_COUPON_CREATE_REQUEST: {
      return {
        ...state,
        createCouponSuccess: undefined,
      }
    }
    case types.POST_COUPON_CREATE_SUCCESS: {
      return {
        ...state,
        createCouponSuccess: true,
        createCoupon: action.result,
      }
    }
    case types.POST_COUPON_CREATE_FAILURE: {
      return {
        ...state,
        createCouponSuccess: false,
      }
    }

    case types.GET_COUPON_LIST_REQUEST: {
      return {
        ...state,
        couponListSuccess: null,
      }
    }
    case types.GET_COUPON_LIST_SUCCESS: {
      return {
        ...state,
        couponListSuccess: true,
        couponList: action.result,
      }
    }
    case types.GET_COUPON_LIST_FAILURE: {
      return {
        ...state,
        couponListSuccess: false,
      }
    }
    case types.GET_COUPON_DOWNLOAD_REQUEST: {
      return {
        ...state,
        downloadCouponSuccess: undefined,
      }
    }
    case types.GET_COUPON_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        downloadCouponSuccess: true,
        downloadedCoupon: action.result,
      }
    }
    case types.GET_COUPON_DOWNLOAD_FAILURE: {
      return {
        ...state,
        downloadCouponSuccess: false,
      }
    }
    case types.CLEAR_DOWNLOADED_COUPON: {
      return {
        ...state,
        downloadedCoupon: null,
      }
    }
    case types.GET_USER_REFERRAL_REQUEST: {
      return {
        ...state,
        userReferralSuccess: null,
      }
    }
    case types.GET_USER_REFERRAL_SUCCESS: {
      return {
        ...state,
        userReferralSuccess: true,
        userReferral: action.result,
      }
    }
    case types.GET_USER_REFERRAL_FAILURE: {
      return {
        ...state,
        userReferralSuccess: false,
      }
    }

    case types.GET_CITIES_SUCCESS: {
      return {
        ...state,
        citiesResult: action.result,
        cities: action.result.content,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getCitiesSuccess: true,
        city: null,
        createCitySuccess: null,
        cityByIdSuccess: null,
        updateCitySuccess: null,
      }
    }
    case types.GET_CITIES_FAILURE: {
      return {
        ...state,
        docks: [],
        getCitiesSuccess: false,
      }
    }
    case types.GET_CITY_BY_ID_REQUEST: {
      return {
        ...state,
        cityByIdSuccess: null,
      }
    }
    case types.GET_CITY_BY_ID_SUCCESS: {
      return {
        ...state,
        cityByIdSuccess: true,
        city: action.result,
      }
    }
    case types.GET_CITY_BY_ID_FAILURE: {
      return {
        ...state,
        cityByIdSuccess: false,
      }
    }
    case types.UPDATE_CITY_REQUEST: {
      return {
        ...state,
        updateCitySuccess: null,
      }
    }
    case types.UPDATE_CITY_SUCCESS: {
      const index = state.cities.findIndex((item) => item.id === action.result.id)
      state.cities[index] = action.result
      return {
        ...state,
        updateCitySuccess: true,
      }
    }
    case types.UPDATE_CITY_FAILURE: {
      return {
        ...state,
        updateCitySuccess: false,
      }
    }
    case types.CREATE_CITY_REQUEST: {
      return {
        ...state,
        createCitySuccess: null,
      }
    }
    case types.CREATE_CITY_SUCCESS: {
      return {
        ...state,
        createCitySuccess: true,
      }
    }
    case types.CREATE_CITY_FAILURE: {
      return {
        ...state,
        createCitySuccess: false,
      }
    }
    case types.CLEAR_CITY: {
      return {
        ...state,
        city: null,
        cityByIdSuccess: null,
      }
    }
    case types.GET_CITIES_LIST_REQUEST: {
      return {
        ...state,
        citiesListSuccess: null,
      }
    }
    case types.GET_CITIES_LIST_SUCCESS: {
      return {
        ...state,
        citiesListSuccess: true,
        citiesList: action.result,
      }
    }
    case types.GET_CITIES_LIST_FAILURE: {
      return {
        ...state,
        citiesListSuccess: false,
      }
    }
    case types.SAVE_CITY: {
      return {
        ...state,
        savedCity: action.payload,
      }
    }
    case types.SAVE_CITY_WITH_FULL_INFO: {
      return {
        ...state,
        savedCityFullInfo: action.payload,
      }
    }
    case types.CLEAR_SAVED_CITY: {
      return {
        ...state,
        savedCity: null,
      }
    }
    case types.GET_CURRENT_PRICING_PLAN_REQUEST: {
      return {
        ...state,
        getPricingPlanSuccess: undefined,
      }
    }
    case types.GET_CURRENT_PRICING_PLAN_SUCCESS: {
      return {
        ...state,
        pricingPlans: action.result,
        getPricingPlanSuccess: true,
      }
    }
    case types.GET_CURRENT_PRICING_PLAN_FAILURE: {
      return {
        ...state,
        getPricingPlanSuccess: false,
      }
    }
    case types.CLEAR_PRICING_PLANS: {
      return {
        ...state,
        pricingPlans: [],
        getPricingPlanSuccess: null,
      }
    }

    case types.GET_WHITE_LABEL: {
      return {
        ...state,
        whiteLabels: undefined,
      }
    }

    case types.GET_WHITE_LABEL_SUCCESS: {
      return {
        ...state,
        whiteLabels: action.result,
        getWhiteLabelSuccess: true,
      }
    }

    case types.GET_WHITE_LABEL_FAILURE: {
      return {
        ...state,
        whiteLabels: null,
        getWhiteLabelSuccess: false,
      }
    }

    case types.GET_PRICING_PLAN_DETAILS_REQUEST: {
      return {
        ...state,
        getPricingPlanDetailsSuccess: undefined,
      }
    }

    case types.GET_PRICING_PLAN_DETAILS_SUCCESS: {
      return {
        ...state,
        pricingPlanDetails: action.result,
        getPricingPlanDetailsSuccess: true,
      }
    }

    case types.GET_PRICING_PLAN_DETAILS_FAILURE: {
      return {
        ...state,
        getPricingPlanDetailsSuccess: false,
      }
    }

    case types.GET_PRICING_PLAN_FIELDS_REQUEST: {
      return {
        ...state,
        getPricingPlanFieldsSuccess: undefined,
      }
    }

    case types.GET_PRICING_PLAN_FIELDS_SUCCESS: {
      return {
        ...state,
        pricingPlanFields: action.result,
        getPricingPlanFieldsSuccess: true,
      }
    }

    case types.GET_PRICING_PLAN_FIELDS_FAILURE: {
      return {
        ...state,
        getPricingPlanFieldsSuccess: false,
      }
    }

    case types.GET_CITY_WHITELABEL_MAPPINGS_REQUEST: {
      return {
        ...state,
        getCityWhitelabelMappingSuccess: undefined,
      }
    }

    case types.GET_CITY_WHITELABEL_MAPPINGS_SUCCESS: {
      return {
        ...state,
        cityWhitelabelMapping: action.result,
        getCityWhitelabelMappingSuccess: true,
      }
    }

    case types.GET_CITY_WHITELABEL_MAPPINGS_FAILURE: {
      return {
        ...state,
        getCityWhitelabelMappingSuccess: false,
      }
    }

    case types.CREATE_PRICING_PLAN_REQUEST: {
      return {
        ...state,
        createPricingPlanSuccess: undefined,
      }
    }

    case types.CREATE_PRICING_PLAN_SUCCESS: {
      return {
        ...state,
        createPricingPlan: action.result,
        createPricingPlanSuccess: true,
      }
    }

    case types.CREATE_PRICING_PLAN_FAILURE: {
      return {
        ...state,
        createPricingPlanSuccess: false,
      }
    }

    case types.UPDATE_PRICING_PLAN_REQUEST: {
      return {
        ...state,
        createPricingPlanSuccess: undefined,
      }
    }

    case types.UPDATE_PRICING_PLAN_SUCCESS: {
      return {
        ...state,
        updatePricingPlan: action.result,
        updatePricingPlanSuccess: true,
      }
    }

    case types.UPDATE_PRICING_PLAN_FAILURE: {
      return {
        ...state,
        updatePricingPlanSuccess: false,
      }
    }

    case types.DELETE_PRICING_PLAN_REQUEST: {
      return {
        ...state,
        deletePricingPlanSuccess: undefined,
      }
    }

    case types.DELETE_PRICING_PLAN_SUCCESS: {
      return {
        ...state,
        deletePricingPlan: action.result,
        deletePricingPlanSuccess: true,
      }
    }

    case types.DELETE_PRICING_PLAN_FAILURE: {
      return {
        ...state,
        deletePricingPlanSuccess: false,
      }
    }

    case types.CLEAR_PRICING_PLAN: {
      return {
        ...state,
        createPricingPlanSuccess: null,
        updatePricingPlanSuccess: null,
        getPricingPlanSuccess: null,
        deletePricingPlanSuccess: null,
      }
    }

    default:
      return state
  }
}

export default cities
