import React from 'react';
import './styles.scss';
import { strings } from "../../I18n";

const NotFoundContainer = () => {
  return (
    <div className={'notFoundContainer'}>
      <div className={'notFoundTitle'}>{strings('title.notFound')}</div>
    </div>
  )
};

export default NotFoundContainer;