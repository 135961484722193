import React, { useEffect, useState } from 'react'
import { AssetBooking } from '../../../domain/asset'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { getAssetBookings } from '../../../apis/asset/asset-booking-apis'
import { AssetBookingsAdd } from './components/AssetBookingsAdd'
import { AssetBookingsDelete } from './components/AssetBookingsDelete'

export const AssetBookings = () => {
  const [bookings, setBookings] = useState<AssetBooking[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [bookingToDelete, setBookingToDelete] = useState<AssetBooking | null>(null)

  const fetchAndSetBookings = () => {
    getAssetBookings().then((bookings) => setBookings(bookings))
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetBookings()
    }

    setIsAddOpen(false)
    setBookingToDelete(null)
  }

  useEffect(fetchAndSetBookings, [])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography variant="h4">Bookings</Typography>

        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Id</strong>
            </TableCell>
            <TableCell>
              <strong>Start Date</strong>
            </TableCell>
            <TableCell>
              <strong>End Date</strong>
            </TableCell>
            <TableCell>
              <strong>Asset</strong>
            </TableCell>
            <TableCell>
              <strong>Project</strong>
            </TableCell>
            <TableCell sx={{ width: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((b) => (
            <TableRow key={b.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{b.id}</TableCell>
              <TableCell>{b.startDate.toLocaleString()}</TableCell>
              <TableCell>{b.startDate.toLocaleString()}</TableCell>
              <TableCell>{b.asset?.name}</TableCell>
              <TableCell>{b.project?.name}</TableCell>
              <TableCell>
                <ButtonGroup size="small">
                  <IconButton color="error" onClick={() => setBookingToDelete(b)}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {isAddOpen && <AssetBookingsAdd onClose={handleClose} />}
      {!!bookingToDelete && <AssetBookingsDelete booking={bookingToDelete} onClose={handleClose} />}
    </Box>
  )
}
