/*eslint-disable*/
import React, { useCallback, useEffect, useState } from 'react'
import './styles.scss'
import TransactionItem from '../../components/items/transactionItem'
import { connect } from 'react-redux'
import {
  clearPaymentData,
  getCurrenciesMappings,
  getPayment,
  getPaymentById,
} from '../../actions/payment'
import { emptyPassedParams, setSearchValue } from '../../actions/screen'
import { strings } from '../../I18n'
import SearchBlockWithRefreshBtn from '../../components/table/searchBlockWithRefreshBtn'
import Loader from '../../components/loader'
import TransactionModal from '../../components/modal/transactionModal'
import { Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import TransactionFilter2 from '../../components/dialog/transactionFilter2'
import { useParams } from 'react-router-dom'

function TransactionContainer(props) {
  const {
    orderProps,
    changePage,
    searchValue,
    changeSearch,
    clearSearchValue,
    filterProps,
    getPaymentSuccess,
    payment,
    getPaymentByIdSuccess,
    clearPaymentData,
    passedParams,
    emptyPassedParams,
    getPaymentById,
    citiesList,
    currentPageValue,
    savedCity,
    pageSize,
    getPayment,
    setSearchValue,
  } = props
  const [transactions, setTransaction] = useState(props.payments)
  const [transactionModalVisible, setTransactionModalVisible] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorData, setErrorData] = useState('')
  const [filterValues, setFilterValues] = React.useState([])
  const [filterVisible, setFilterVisible] = React.useState(false)

  const showTransactionModal = () => setTransactionModalVisible(true)
  const hideTransactionModal = () => setTransactionModalVisible(false)

  const params = useParams()
  const transactionId = params.id

  useEffect(() => {
    props.getCurrenciesMappings()
  }, [])

  useEffect(() => {
    if (transactionId) {
      setSearchValue(transactionId)
    }
  }, [transactionId, setSearchValue])

  useEffect(() => {
    if (passedParams) {
      getPaymentById(passedParams)
      emptyPassedParams()
    }
  }, [passedParams, getPaymentById, emptyPassedParams])

  useEffect(() => {
    if (getPaymentByIdSuccess) {
      showTransactionModal()
      clearPaymentData()
    }
  }, [getPaymentByIdSuccess, clearPaymentData])

  useEffect(() => {
    setTransaction(props.payments)
  }, [props.payments])

  const handleChangePage = useCallback(
    (event, newPage) => {
      changePage(newPage)
    },
    [changePage]
  )
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
  }
  const toggleErrorModal = () => {
    setErrorModal((prevState) => !prevState)
  }

  const renderTransactionList = useCallback(
    () => (
      <TransactionItem
        transactions={transactions}
        transactionResult={props.paymentsResult}
        handleChangePage={handleChangePage}
        orderProps={orderProps}
        citiesList={props.citiesList}
        setErrorData={setErrorData}
        toggleErrorModal={toggleErrorModal}
      />
    ),
    [transactions, props, orderProps, handleChangePage]
  )

  useEffect(() => {
    if (!!filterProps?.filterValue) {
      setFilterValues(filterProps?.filterValue || [])
    }
  }, [filterProps])

  const removeFilter = React.useCallback(
    (indexValue) => {
      const filterValues = filterProps.filterValue.filter((item, index) => index !== indexValue)
      filterProps.setFilterValue(filterValues?.length > 0 ? filterValues : null)
      setFilterValues(filterValues)
    },
    [filterProps]
  )

  const reloadData = React.useCallback(() => {
    getPayment(currentPageValue, searchValue, '', savedCity || '', pageSize)
  }, [currentPageValue, searchValue, pageSize, savedCity, getPayment])

  const pressFilter = () => {
    setFilterVisible(true)
  }
  const applyFilter = React.useCallback(() => {
    setFilterVisible(false)
    // clearRentalsData()
    filterProps.setFilterValue(filterValues)
  }, [filterValues, filterProps])

  const changeSelectorValue = React.useCallback(
    (filter, filterBy) => {
      if (filterValues.filter((item) => item.filterBy === filterBy).length > 0) {
        if (!filter.length) {
          setFilterValues(filterValues?.filter((item) => item.filterBy !== filterBy))
        } else {
          setFilterValues(
            filterValues.map((item) => {
              if (item.filterBy === filterBy) {
                return { filter, filterBy }
              }
              return item
            })
          )
        }
      } else {
        setFilterValues([...filterValues, { filter, filterBy }])
      }
    },
    [filterValues, setFilterValues]
  )
  const content = () => (
    <>
      <SearchBlockWithRefreshBtn
        title={strings('title.transactions')}
        searchValue={searchValue}
        onChange={changeSearch}
        reloadData={reloadData}
        clear={clearSearchValue}
        filterProps={filterProps.filterValue}
        pressFilter={pressFilter}
        removeFilter={removeFilter}
      />
      {transactions.length > 0 ? (
        <div className={'dashboardList'}>{renderTransactionList()}</div>
      ) : (
        <p className={'emptyResultText'}>{strings('message.noTransactions')}</p>
      )}
      <TransactionModal
        visible={transactionModalVisible}
        transaction={payment}
        closeTransactionModal={hideTransactionModal}
        citiesList={citiesList}
      />
      <TransactionFilter2
        agree={applyFilter}
        visible={filterVisible}
        closeFilter={() => setFilterVisible(false)}
        disagree={() => setFilterVisible(false)}
        filterProps={filterValues}
        reloadData={reloadData}
        changeSelectorValue={changeSelectorValue}
      />
      <Modal
        open={errorModal}
        onClose={toggleErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>{errorData}</h3>
        </Box>
      </Modal>
    </>
  )

  const loading = () => <Loader />

  return <div className={'dashboardContent'}>{getPaymentSuccess ? content() : loading()}</div>
}

const mapStateToProps = ({
  payment: { payments, paymentsResult, getPaymentSuccess, payment, getPaymentByIdSuccess },
  cities: { citiesList, savedCity },
  screen: { passedParams, currentPageValue, pageSize },
}) => ({
  payments,
  paymentsResult,
  citiesList,
  savedCity,
  getPaymentSuccess,
  payment,
  getPaymentByIdSuccess,
  passedParams,
  currentPageValue,
  pageSize,
})

const mapDispatchToProps = {
  getPayment,
  clearPaymentData,
  emptyPassedParams,
  getPaymentById,
  setSearchValue,
  getCurrenciesMappings,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionContainer)
