import { LisaSelectOption } from '../../components/lisaSelect/domain'

export enum VehicleType {
  BIKE = 'BIKE',
  E_BIKE = 'E_BIKE',
  SCOOTER = 'SCOOTER',
  E_SCOOTER = 'E_SCOOTER',
  CARGO = 'CARGO',
}

export enum VehicleStatus {
  READY = 'READY',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  IN_STATION = 'IN_STATION',
  RENTING = 'RENTING',
  BROKEN_WORKSHOP = 'BROKEN_WORKSHOP',
  LOST = 'LOST',
  PICKUP_REQUIRED = 'PICKUP_REQUIRED',
  RETIRED = 'RETIRED',
  MISSING = 'MISSING',
}

export enum StationType {
  CHARGE = 'CHARGE',
  VIRTUAL = 'VIRTUAL',
  DUCKT = 'DUCKT',
}

export enum StationStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  MALFUNCTION = 'MALFUNCTION',
}

export enum LockStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum LockState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface MapFilterParams {
  search: string

  stationTypes: Set<StationType>
  stationStatuses: Set<StationStatus>

  vehicleTypes: Set<VehicleType>
  vehicleStatuses: Set<VehicleStatus>
  vehicleMinBattery?: number
  vehicleMaxBattery?: number

  lockStatuses: Set<LockStatus>
  lockStates: Set<LockState>
  lockMinBattery?: number
  lockMaxBattery?: number
}

export const VEHICLE_STATUS_OPTIONS: LisaSelectOption[] = Object.keys(
  VehicleStatus
).map<LisaSelectOption>((status) => ({ title: status, value: status }))

export const STATION_TYPE_OPTIONS: LisaSelectOption[] = Object.keys(
  StationType
).map<LisaSelectOption>((st) => ({ title: st, value: st }))

export const DEFAULT_FILTER_PARAMS: MapFilterParams = {
  search: '',

  stationTypes: new Set(Object.values(StationType)),
  stationStatuses: new Set(Object.values(StationStatus)),

  vehicleTypes: new Set(Object.values(VehicleType)),
  vehicleStatuses: new Set(Object.values(VehicleStatus)),

  lockStatuses: new Set(Object.values(LockStatus)),
  lockStates: new Set(Object.values(LockState)),
  lockMinBattery: 0,
  lockMaxBattery: 100,
}
