import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import * as endpoints from '../configs/endpoints'
import * as types from '../constants/actionTypes'

export const getUsers = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getUsers(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [types.GET_USERS_REQUEST, types.GET_USERS_SUCCESS, types.GET_USERS_FAILURE],
  }
}

export const getUser = (id) => {
  return {
    endpoint: endpoints.getUser(id),
    method: GET_METHOD,
    types: [types.GET_USER_REQUEST, types.GET_USER_SUCCESS, types.GET_USER_FAILURE],
  }
}

export const getUserForNewPage = (id) => {
  return {
    endpoint: endpoints.getUser(id),
    method: GET_METHOD,
    types: [types.GET_USER_REQUEST, types.GET_USER_FOR_NEW_PAGE_SUCCESS, types.GET_USER_FAILURE],
  }
}

export const updateUser = (id, body) => {
  return {
    endpoint: endpoints.getUser(id),
    method: PUT_METHOD,
    types: [types.UPDATE_USER_REQUEST, types.UPDATE_USER_SUCCESS, types.UPDATE_USER_FAILURE],
    body,
  }
}

export const getUserPlanStatuses = () => {
  return {
    endpoint: endpoints.userPlanStatuses(),
    method: GET_METHOD,
    types: [
      types.GET_PLAN_STATUSES_REQUEST,
      types.GET_PLAN_STATUSES_SUCCESS,
      types.GET_PLAN_STATUSES_FAILURE,
    ],
  }
}

export const getUserPlanTypes = () => {
  return {
    endpoint: endpoints.userPlanTypes(),
    method: GET_METHOD,
    types: [
      types.GET_PLAN_TYPES_REQUEST,
      types.GET_PLAN_TYPES_SUCCESS,
      types.GET_PLAN_TYPES_FAILURE,
    ],
  }
}

export const clearUserData = () => {
  return {
    type: types.CLEAR_USER_DATA,
  }
}

export const clearUser = () => {
  return {
    type: types.CLEAR_USER,
  }
}

export const removeUser = (id) => {
  return {
    endpoint: endpoints.removeUser(id),
    method: DELETE_METHOD,
    types: [types.DELETE_USERS_REQUEST, types.DELETE_USERS_SUCCESS, types.DELETE_USERS_FAILURE],
  }
}

export const cancelSubscription = (cityId, userId, planId, date) => {
  return {
    endpoint: endpoints.cancelSubscription(cityId, userId, planId, date),
    method: POST_METHOD,
    types: [
      types.CANCEL_SUBSCRIPTION_REQUEST,
      types.CANCEL_SUBSCRIPTION_SUCCESS,
      types.CANCEL_SUBSCRIPTION_FAILURE,
    ],
  }
}

export const getAllManagedUser = (page, search, selectedCity, sort, filter, size) => {
  return {
    endpoint: endpoints.getAllManagedUser(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [
      types.GET_MANAGED_USER_LIST_REQUEST,
      types.GET_MANAGED_USER_LIST_SUCCESS,
      types.GET_MANAGED_USER_LIST_FAILURE,
    ],
  }
}

export const createInternalUser = (body) => {
  return {
    endpoint: endpoints.createInternalUser,
    method: POST_METHOD,
    types: [
      types.POST_CREATE_INTERNAL_USER_REQUEST,
      types.POST_CREATE_INTERNAL_USER_SUCCESS,
      types.POST_CREATE_INTERNAL_USER_FAILURE,
    ],
    body,
  }
}

export const getInternalUser = (email) => {
  return {
    endpoint: endpoints.getInternalUser(email),
    method: GET_METHOD,
    types: [
      types.GET_INTERNAL_USER_REQUEST,
      types.GET_INTERNAL_USER_SUCCESS,
      types.GET_INTERNAL_USER_FAILURE,
    ],
  }
}

export const updateInternalUser = (body, email) => {
  return {
    endpoint: endpoints.updateInternalUser(email),
    method: PUT_METHOD,
    types: [
      types.PUT_INTERNAL_USER_UPDATE_REQUEST,
      types.PUT_INTERNAL_USER_UPDATE_SUCCESS,
      types.PUT_INTERNAL_USER_UPDATE_FAILURE,
    ],
    body,
  }
}

export const deleteInternalUser = (email) => {
  return {
    endpoint: endpoints.deleteInternalUser(email),
    method: DELETE_METHOD,
    types: [
      types.DELETE_INTERNAL_USER_REQUEST,
      types.DELETE_INTERNAL_USER_SUCCESS,
      types.DELETE_INTERNAL_USER_FAILURE,
    ],
  }
}

export const clearInternalUserData = () => {
  return {
    type: types.CLEAR_INTERNAL_USER,
  }
}
