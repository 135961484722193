/* eslint-disable */
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { WhitelabelCodeReturnDecide } from '../utils/utils'
import {
  bigIssueTheme,
  farteTheme,
  mobyTheme,
  shareBikeTheme,
  sigoTheme,
} from '../constants/WhiteLabelTheme'

export const useInput = (inputValue) => {
  const [value, setValue] = useState(inputValue)

  const onChange = (event) => setValue(event.target.value)

  const initValue = (inputValue) => setValue(inputValue)

  const clearValue = () => setValue('')

  return {
    value,
    onChange,
    initValue,
    clearValue,
  }
}

export const useShowToast = () => {
  const { addToast } = useToasts()

  return (message, appearance) =>
    addToast(message, {
      appearance,
      autoDismiss: true,
      autoDismissTimeout: 5000,
    })
}

export const useTheme = () => {
  const whiteLabelCode = useSelector((state) => state.auth.whiteLabelCode)

  return WhitelabelCodeReturnDecide(whiteLabelCode, [
    shareBikeTheme,
    mobyTheme,
    bigIssueTheme,
    farteTheme,
    sigoTheme,
  ])
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export function useCity() {
  const [selectedCity, setSelectedCity] = useState('')
  const savedCity = useSelector((state) => state.cities.savedCity)
  const savedCityFullInfo = useSelector((state) => state.cities.savedCityFullInfo)

  useEffect(() => {
    if (savedCity) {
      setSelectedCity(savedCity.split('=')[1])
    } else {
      setSelectedCity('')
    }
  }, [savedCity])
  return { selectedCity, savedCityFullInfo }
}

export function useRemapCities() {
  const [remappedCities, setRemappedCities] = useState([])
  const cityList = useSelector((state) => state.cities.citiesList)

  useEffect(() => {
    let buildCityList = []
    cityList.map((value, index) => {
      buildCityList.push({
        label: value.name,
        value: value.id,
      })
    })
    setRemappedCities(buildCityList)
  }, [cityList])

  return { remappedCities }
}

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export function useInterval(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {}
  }
  return [storedValue, setValue]
}
