import { Card, CardContent, InputAdornment, OutlinedInput } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import './styles.scss'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import { MapFilterParams } from '../../domain'
import ClickAwayListener from '@mui/material/ClickAwayListener'

interface Props {
  params: MapFilterParams
  onUpdateParams: (params: MapFilterParams) => void
  onClose: () => void
}

function MapSearch({ params, onUpdateParams, onClose }: Props) {
  const [search, setSearch] = useState<string>('')
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>()

  const handleChange = (value: string) => {
    setSearch(value)

    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      onUpdateParams({
        ...params,
        search: value,
      })
    }, 500)
  }

  useEffect(() => {
    setSearch(params.search)
  }, [params])

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Card className="mapSearch">
        <CardContent style={{ paddingBottom: 16 }}>
          <OutlinedInput
            value={search}
            onChange={(e) => handleChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => handleChange('')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            }
          />
        </CardContent>
      </Card>
    </ClickAwayListener>
  )
}

export default memo(MapSearch)
