import React from 'react'
import './styles.scss'
import TableCell from '@mui/material/TableCell/TableCell'

function TableItem(props) {
  const { className, body, align = 'center' } = props
  return (
    <TableCell style={{ width: '20rem' }} align={align} className={className}>
      {body}
    </TableCell>
  )
}

export default TableItem
