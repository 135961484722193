/* global grecaptcha */
/* eslint-disable */
import firebase from 'firebase/compat/app'
import {
  getAuth,
  getMultiFactorResolver,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import 'firebase/compat/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { BASE_URL } from '../configs/urls'

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE)

export const app = firebase.initializeApp({
  ...firebaseConfig,
  // provider: new ReCaptchaV3Provider('6Le2hq8nAAAAAMbp8uHNicABMGJAgoskENkM53V-'),
  // isTokenAutoRefreshEnabled: true,
})
let recaptchaVerifier = null
let recaptchaWidgetId = null
let resolver = null
let globalVerificationId = null
let multiFactorHints

export function refreshCurrentUser() {
  const user = getCurrentUser()
  user.reload()
}

export function logoutFromFirebase() {
  const auth = getAuth()
  signOut(auth).then(() => {
    localStorage.clear()
    sessionStorage.clear()
  })
}
export function getCurrentUser() {
  return app.auth().currentUser
}

export function isFirebase2FaEnabled() {
  return app.auth().currentUser.multiFactor?.enrolledFactors?.length > 0
}

export function disable2FA(success) {
  const user = app.auth().currentUser
  const options = user.multiFactor.enrolledFactors
  // Ask user to select from the enrolled options.
  user.multiFactor.unenroll(options[0]).then(function () {
    // User successfully unenrolled selected factor.
    success()
  })
}

export function firebaseSignUp(email, password) {
  return app.auth().createUserWithEmailAndPassword(email, password)
}

export function isMailVerified() {
  return app.auth().currentUser?.emailVerified
}

export function verifyEmail() {
  const user = app.auth().currentUser
  if (!user?.emailVerified) {
    return app.auth().currentUser.sendEmailVerification()
  } else {
    return new Promise((resolve, reject) => {
      reject(new Error('Email already verified'))
    })
  }
}

export function setupRecaptcha(success, expire) {
  recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    size: 'normal',
    callback: function (response) {
      if (response) {
        success()
      }
    },
    'expired-callback': function () {
      expire()
    },
  })
  recaptchaVerifier.render().then((widgetId) => {
    recaptchaWidgetId = widgetId
    grecaptcha.reset(recaptchaWidgetId)
  })
}

export function enrollSecondFactor(phoneNumber, error, success) {
  const user = app.auth().currentUser
  user.multiFactor
    .getSession()
    .then((session) => {
      const phoneOpts = {
        phoneNumber,
        session,
      }
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
      phoneAuthProvider
        .verifyPhoneNumber(phoneOpts, recaptchaVerifier)
        .then((verificationId) => {
          globalVerificationId = verificationId
          success()
        })
        .catch((err) => {
          error(err)
        })
    })
    .catch((err) => {
      error(err)
    })
}

export async function verifyOTP(code) {
  const cred = firebase.auth.PhoneAuthProvider.credential(globalVerificationId, code)
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
  const user = app.auth().currentUser
  await user.multiFactor.enroll(multiFactorAssertion, 'phone number')
}

export async function firebaseLogin(email, password, success, needOtp, errorCallback) {
  const auth = getAuth()
  signInWithEmailAndPassword(auth, email, password)
    .then((user) => {
      success(user)
    })
    .catch((error) => {
      if (error.code === 'auth/multi-factor-auth-required') {
        // The user is enrolled in MFA, must be verified
        // window.resolver = err.resolver

        resolver = resolver = getMultiFactorResolver(auth, error)
        multiFactorHints = resolver.hints

        // Ask user which second factor to use.
        // You can get the masked phone number via resolver.hints[selectedIndex].phoneNumber
        // You can get the display name via resolver.hints[selectedIndex].displayName
        if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          // User selected a phone second factor.
          // ...
          needOtp()
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          }
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
          phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
              // verificationId will be needed for sign-in completion.
              globalVerificationId = verificationId
            })
        } else {
          // Unsupported second factor.
          // Note that only phone second factors are currently supported.
          errorCallback(error)
        }
      } else {
        errorCallback(error)
      }
    })
}

export async function verifyLoginOTP(code) {
  // Ask user for the verification code.
  const cred = firebase.auth.PhoneAuthProvider.credential(globalVerificationId, code)

  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)

  return resolver.resolveSignIn(multiFactorAssertion)
}
export function isMFAEnabled() {
  const currentUser = app.auth().currentUser
}
export function userChangeListener(listener) {
  app.auth().onAuthStateChanged(listener)
}

export const onTokenIdChanged = (listener) => {
  app.auth().onIdTokenChanged(listener)
}

export function getToken() {
  return app.auth().currentUser?.getIdToken()
}

export function sendPasswordReset(email) {
  return sendPasswordResetEmail(getAuth(), email, { url: BASE_URL })
}

// export async function getAppCheckToken() {
//   return getToken()
// }

export const refreshUser = () => {
  const user = app.auth().currentUser
  if (!!user) {
    user.reload()
  }
}

export const imageUploader = async (address, file) => {
  if (file) {
    const storage = getStorage()
    const storageRef = ref(storage, address)

    const uploadSnapshot = await uploadBytes(storageRef, file)
    return getDownloadURL(uploadSnapshot.ref)
  }
}

//setting up appCheck
export const appCheckSetup = () => {
  if (window.location.origin.includes('localhost')) {
    return
  }
  const siteKey = process.env.REACT_APP_SITE_KEY || ''

  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(siteKey),
    isTokenAutoRefreshEnabled: true,
  })
}
