import { apiClientJSON } from '../apiClient'

export const getUsersList = (search: string): Promise<any[]> => {
  return apiClientJSON
    .get(`/account-service/dashboard/users`, {
      params: {
        page: 0,
        size: 20,
        search,
      },
    })
    .then((r) => r.data?.content || [])
}
