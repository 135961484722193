import * as endpoints from "../configs/endpoints";
import { GET_METHOD, POST_METHOD, PUT_METHOD } from "../constants/apiMethods";
import * as types from "../constants/actionTypes";

export const getDocks = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getDocks(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [
      types.GET_DOCKS_REQUEST,
      types.GET_DOCKS_SUCCESS,
      types.GET_DOCKS_FAILURE
    ]
  }
};

export const unlockDock = (id) => {
  return {
    endpoint: endpoints.unlockDock(id),
    method: POST_METHOD,
    types: [
      types.UNLOCK_DOCK_REQUEST,
      types.UNLOCK_DOCK_SUCCESS,
      types.UNLOCK_DOCK_FAILURE
    ]
  }
};

export const updateDock = (id, body) => {
  return {
    endpoint: endpoints.updateDock(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_DOCK_REQUEST,
      types.UPDATE_DOCK_SUCCESS,
      types.UPDATE_DOCK_FAILURE,
    ],
    body,
  }
};

export const getDockStatuses = () => {
  return {
    endpoint: endpoints.dockStatuses(),
    method: GET_METHOD,
    types: [
      types.GET_DOCK_STATUSES_REQUEST,
      types.GET_DOCK_STATUSES_SUCCESS,
      types.GET_DOCK_STATUSES_FAILURE,
    ]
  }
};

export const clearDocksData = () => {
  return {
    type: types.CLEAR_DOCKS_DATA,
  }
};
