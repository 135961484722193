import React, { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getWhiteLabel } from '../../actions/cities'
import { NotificationsTargetApp } from './NotificationsTargetApp'
import { NotificationsTargetCity } from './NotificationsTargetCity'
import { NotificationsTargetUser } from './NotificationsTargetUser'

export const NotificationsTarget = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWhiteLabel())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack spacing={3} width={700}>
      <Typography variant="h5">Target Notification</Typography>
      <Typography variant="body1">
        Please make <strong>up to 5</strong> choices to target the notification. It could be app(s),
        city(s) or user(s)
      </Typography>
      <NotificationsTargetApp />
      <NotificationsTargetCity />
      <NotificationsTargetUser />
    </Stack>
  )
}
