/* eslint-disable */
import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Paper } from '@mui/material'
import './styles.scss'
import { useStyles } from '../../utils/styles'
import { useDispatch, useSelector } from 'react-redux'
import { cancelBookingByCode, clearCancelBooking, getAllBookingList } from '../../actions/cities'
import { useCity } from '../../hooks'
import Moment from 'moment'
import Swal from 'sweetalert2'
import useCustomTable from '../../components/table/CustomTable'
import { reformatFilter, reformatSort, stringFromValue } from '../../utils/utils'
import { Link } from 'react-router-dom'
import { ACTIVE } from '../../constants/const'
import { strings } from '../../I18n'

const BookingScreen = () => {
  const dispatch = useDispatch()
  const bookingList = useSelector((state) => state.cities.bookingList)
  const cancelBookingSuccess = useSelector((state) => state.cities.cancelBookingSuccess)
  const bookingListSuccess = useSelector((state) => state.cities.bookingListSuccess)

  const { selectedCity } = useCity()
  const classes = useStyles()

  const userLink = useCallback(
    (userId, userName) =>
      userId ? (
        <Link
          to={`/dashboard/user/${userId}`}
          target="_blank"
          className={'bikeLink'}
          style={userId && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {stringFromValue(userName)}
        </Link>
      ) : (
        <p>{stringFromValue(userName)}</p>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const columns = useMemo(
    () => [
      {
        id: 'sortable1',
        name: strings('booking.bookingCode'),
        selector: 'code',
        center: true,
        sortable: true,
      },
      {
        id: 'sortable4',
        name: strings('descriptions.userName'),
        selector: 'username',
        sortable: true,
        center: true,
        cell: (row) => userLink(row.userId, row.username),
      },
      {
        name: strings('booking.vehicleCode'),
        selector: 'vehicle.code',
        center: true,
      },
      {
        name: strings('booking.vehicleName'),
        selector: 'vehicle.name',
        center: true,
      },
      {
        name: strings('booking.vehicleType'),
        selector: 'vehicle.type',
        center: true,
      },
      {
        id: 'sortable2',
        name: strings('descriptions.startDate'),
        selector: 'startDate',
        center: true,
        sortable: true,
        cell: (row) => dateFormat(row.startDate),
      },
      {
        id: 'sortable3',
        name: strings('booking.bookingStatus'),
        selector: 'status',
        center: true,
        sortable: true,
      },
      {
        name: strings('message.action'),
        selector: 'vehicle.status',
        center: true,
        cell: (row, index, column, id) => {
          if (row.status === ACTIVE) {
            return (
              <Button
                style={{
                  marginLeft: 5,
                }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  Swal.fire({
                    title: strings('message.removeItem'),
                    text: strings('message.noRevert'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: strings('message.yesCancel'),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire('Success!', 'Booking cancelled.', 'success')
                      dispatch(cancelBookingByCode(row?.code))
                    }
                  })
                }}
              >
                {strings('cancelBooking')}
              </Button>
            )
          } else {
            return null
          }
        },
      },
    ],
    []
  )

  const addButtonClick = () => {}

  let filterObject = [
    {
      filterBy: 'status',
      filterValue: ['ACTIVE', 'IN_USE', 'USED', 'CANCELED', 'EXPIRED'],
    },
  ]

  const reloadData = () => {
    setLoading(true)
    dispatch(
      getAllBookingList(
        localPage,
        debouncedSearchTerm,
        selectedCity,
        reformatSort(sortData),
        reformatFilter(filterData),
        localRows
      )
    )
  }

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(
    columns,
    bookingList,
    reloadData,
    addButtonClick,
    filterObject,
    'Bookings',
    true,
    false
  )

  useEffect(() => {
    resetPagination()
  }, [selectedCity, debouncedSearchTerm])

  useEffect(() => {
    if (bookingListSuccess) {
      setLoading(false)
    }
  }, [bookingListSuccess])
  useEffect(() => {
    if (cancelBookingSuccess) {
      dispatch(clearCancelBooking())
      reloadData()
    }
  }, [cancelBookingSuccess])

  useEffect(() => {
    reloadData()
  }, [localPage, debouncedSearchTerm, selectedCity, sortData, filterData, localRows])

  const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY HH:mm')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderHeaderBar()}
        {renderCustomTable()}
      </Paper>
    </div>
  )
}

export default BookingScreen
