/*eslint-disable*/
import React, { useCallback, useEffect } from 'react'
import Moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import './styles.scss'
import TableBody from '@mui/material/TableBody'
import { useStyles } from '../../../utils/styles'
import Paper from '@mui/material/Paper/Paper'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TabletPaginator from '../../table/tabletPaginator'
import { strings } from '../../../I18n'
import EditIcon from '@mui/icons-material/Assignment'
import { connect } from 'react-redux'
import {
  clearImageDirectoryData,
  clearRentalsData,
  getImageDirectoryDataAction,
  getRentalById,
  getVehicleByCode,
} from '../../../actions/rentals'
import { getBikeStatus, getVehicle } from '../../../actions/vehicle'
import { getPayment } from '../../../actions/payment'
import { setPassedParams } from '../../../actions/screen'
import { getUsers } from '../../../actions/users'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import {
  cityNameById,
  constructImageDirectory,
  secondsToMin,
  sortedItem,
  stringFromValue,
} from '../../../utils/utils'
import {
  RENTAL_END_DATE,
  RENTAL_END_DOCK_CODE,
  RENTAL_END_STATION_NAME,
  RENTAL_ID,
  RENTAL_START_DATE,
  RENTAL_START_DOCK_CODE,
  RENTAL_STATION_NAME,
  RENTAL_STATUS,
  RENTAL_USER,
  RENTAL_VEHICLE,
} from '../../../constants/orderConst'
import { withStyles } from '@mui/styles'
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from 'firebase/storage'
import { useShowToast } from '../../../hooks'
import { FINISHED_STATUS, WALLET_DUE } from '../../../constants/const'
import { RentalsItemActions } from './RentalsItemActions'

function RentalsItem(props) {
  const {
    rentals,
    rentalsResult,
    handleChangePage,
    getRentalById,
    finishRentAction,
    orderProps,
    citiesList,
    setSelectedRentalUser,
    setImagePreviewModal,
    setPreviewImageUrls,
  } = props

  const classes = useStyles()
  const showToast = useShowToast()
  const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY HH:mm')

  const editAction = React.useCallback(
    (rental) => {
      getRentalById(rental.id)
      setSelectedRentalUser(rental?.userId)
    },
    [getRentalById]
  )

  useEffect(() => {
    if (props.getImageDirectoryDataSuccess) {
      const storage = getStorage()
      const pathReference = ref(storage, constructImageDirectory(props.getImageDirectoryData))
      listAll(pathReference).then((res) => {
        if (res.items.length > 0) {
          setImagePreviewModal(true)
          res.items.forEach((itemRef) => {
            Promise.all([getMetadata(itemRef), getDownloadURL(itemRef)]).then(([meta, url]) => {
              setPreviewImageUrls((prev) => [...prev, url + `&timeCreated=${meta.timeCreated}`])
            })
          })
        } else {
          showToast('No image found', 'error')
          setImagePreviewModal(false)
        }
      })

      props.clearImageDirectoryData()
    }
  }, [props.getImageDirectoryDataSuccess])

  const userLink = useCallback(
    (rental) =>
      rental.userId ? (
        <Link
          to={`/dashboard/user/${rental.userId}`}
          target="_blank"
          className={'bikeLink'}
          style={rental.userId && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {stringFromValue(rental?.userName)}
        </Link>
      ) : (
        <p>{stringFromValue(rental?.userName)}</p>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const CustomButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const endRentButton = (rental) => (
    <CustomButton
      variant="contained"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={() => {
        finishRentAction(rental)
      }}
    >
      {strings('buttons.endRent')}
    </CustomButton>
  )

  const titles = [
    '',
    strings('descriptions.rentalId'),
    strings('descriptions.userName'),
    strings('descriptions.bikeName'),
    strings('descriptions.transactionLink'),
    strings('descriptions.startStation'),
    strings('descriptions.startDock'),
    strings('descriptions.startDate'),
    strings('descriptions.endStation'),
    strings('descriptions.endDock'),
    strings('descriptions.endDate'),
    strings('descriptions.duration'),
    strings('descriptions.cityProject'),
    strings('descriptions.status'),
    '',
    strings('descriptions.images'),
    '',
  ]

  const bikeLink = useCallback(
    (rental) =>
      rental.vehicleCode ? (
        <Link
          to={`/dashboard/bike/${rental?.vehicleCode}`}
          target="_blank"
          className={'bikeLink'}
          style={rental.vehicleCode && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {stringFromValue(rental?.vehicleName)}
        </Link>
      ) : (
        <p>{stringFromValue(rental?.vehicleName)}</p>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const transactionLink = useCallback(
    (rental) =>
      rental?.paymentId && (
        <Link
          to={`/dashboard/transaction/${rental?.paymentId}`}
          target="_blank"
          style={{ border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {strings('buttons.showTransaction')}
        </Link>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const endRentAction = (rental) => {
    return !rental?.canBeFinished ? <div>-</div> : endRentButton(rental)
  }

  const editActions = (rental) => (
    <div className={'rentalActions'}>
      <IconButton className={classes.iconButton} onClick={() => editAction(rental)}>
        <EditIcon />
      </IconButton>
    </div>
  )

  const showOutputImage = (rentInfo) => {
    if (
      (rentInfo?.status === FINISHED_STATUS || rentInfo?.status === WALLET_DUE) &&
      rentInfo?.photoFinish
    ) {
      return (
        <CustomButton
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => props.getImageDirectoryDataAction(rentInfo?.id)}
        >
          {strings('buttons.showImage')}
        </CustomButton>
      )
    }
  }

  const sortedFields = [
    sortedItem(1, RENTAL_ID, orderProps),
    sortedItem(2, RENTAL_USER, orderProps),
    sortedItem(3, RENTAL_VEHICLE, orderProps),
    sortedItem(5, RENTAL_STATION_NAME, orderProps),
    sortedItem(6, RENTAL_START_DOCK_CODE, orderProps),
    sortedItem(7, RENTAL_START_DATE, orderProps),
    sortedItem(8, RENTAL_END_STATION_NAME, orderProps),
    sortedItem(9, RENTAL_END_DOCK_CODE, orderProps),
    sortedItem(10, RENTAL_END_DATE, orderProps),
    sortedItem(13, RENTAL_STATUS, orderProps),
  ]

  const tabletBody = () => (
    <TableBody>
      {rentals.map((rental) => (
        <TableItemRow
          id={rental.id}
          key={rental.id}
          items={[
            editActions(rental),
            rental.id,
            userLink(rental),
            bikeLink(rental),
            transactionLink(rental),
            stringFromValue(rental.startStationName),
            stringFromValue(rental.startDockCode),
            stringFromValue(
              rental.startTime !== null ? dateFormat(rental.startTime) : rental.startTime
            ),
            stringFromValue(rental.endStationName),
            stringFromValue(rental.endDockCode),
            stringFromValue(rental.endTime !== null ? dateFormat(rental.endTime) : rental.endTime),
            secondsToMin(rental.duration),
            stringFromValue(cityNameById(citiesList, rental.cityId)),
            rental?.status?.replace(/_/g, ' '),
            endRentAction(rental),
            showOutputImage(rental),
            <RentalsItemActions
              rental={rental}
              onOpenPath={() => {
                setPreviewImageUrls([rental.routeImageUrl])
                setImagePreviewModal(true)
              }}
            />,
          ]}
        />
      ))}
    </TableBody>
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table stickyHeader={true} className={classes.table} aria-label="simple table">
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            {tabletBody()}
          </Table>
        </TableContainer>
        <TabletPaginator
          items={rentals}
          pageData={rentalsResult}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </div>
  )
}
const mapStateToProps = ({
  rentals: {
    rentalBike,
    getRentalBikeSuccess,
    getImageDirectoryDataSuccess,
    getImageDirectoryData,
  },
  cities: { savedCity },
}) => ({
  rentalBike,
  getRentalBikeSuccess,
  savedCity,
  getImageDirectoryDataSuccess,
  getImageDirectoryData,
})

const mapDispatchToProps = {
  getRentalById,
  getVehicleByCode,
  getBikeStatus,
  setPassedParams,
  getUsers,
  clearRentalsData,
  getVehicle,
  getPayment,
  getImageDirectoryDataAction,
  clearImageDirectoryData,
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalsItem)
