/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { FormInputDropdown } from '../../inputController/FormInputDropdown'
import InputController from '../../inputController'
import DatePickerController from '../../inputController/DatePickerController'
import { Box, Button, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useRemapCities } from '../../../hooks'
import Modal from '@mui/material/Modal'
import { clearCreateCoupon } from '../../../actions/cities'
import { useDispatch, useSelector } from 'react-redux'
import { strings } from '../../../I18n'
import { isSuperAdmin } from '../../../utils/utils'
import { CONVERT_TYPE, couponType } from '../../../constants/const'
import SwitchController from '../../inputController/SwitchController'
import ButtonWithLoader from '../../ButtonWithLoader'

const CouponModal = ({
  onSubmit,
  addModalVisible,
  handleClose,
  isEdit,
  setIsEdit,
  setAddModalVisible,
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '40%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block',
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '80%',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '1px solid white',
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '8px',
    },
  }

  const [cityToWhitelabelMapping, setCityToWhitelabelMapping] = useState(null)

  const couponByIdSuccess = useSelector((state) => state.cities.couponByIdSuccess)
  const couponById = useSelector((state) => state.cities.couponById)
  const whiteLabels = useSelector((state) => state.cities.whiteLabels)
  const adminDetails = useSelector((state) => state.auth.adminDetails)
  const cityWhitelabelMapping = useSelector((state) => state.cities.cityWhitelabelMapping)
  const createCouponSuccess = useSelector((state) => state.cities.createCouponSuccess)

  const remappedWhiteLabels = useMemo(() => {
    let remappedValues = whiteLabels?.map((whiteLabel) => {
      return {
        value: whiteLabel.id,
        label: whiteLabel.name,
      }
    })

    //update null value to empty string
    remappedValues = remappedValues?.map((whiteLabel) => {
      if (whiteLabel.value === null) {
        whiteLabel.value = 10
      }
      return whiteLabel
    })

    // return remappedValues?.filter((whiteLabel) => whiteLabel.value !== null)
    return remappedValues
  }, whiteLabels)

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      validFrom: new Date(),
      expiredAT: new Date(),
      type: couponType[0].value,
    },
  })
  const whiteLabelIdWatch = watch('whiteLabelId')
  const watchRidePass = watch('ridePass')
  const watchType = watch('type')
  const watchForSingleUser = watch('forSingleUser')

  const { remappedCities } = useRemapCities()

  useEffect(() => {
    let realId = whiteLabelIdWatch === 10 ? null : whiteLabelIdWatch
    const whitelabel = cityWhitelabelMapping?.find((mapping) => mapping.id === realId)
    if (cityWhitelabelMapping) {
      setCityToWhitelabelMapping(() => {
        return whitelabel?.cities?.map((city) => {
          return {
            value: city.id,
            label: city.name,
          }
        })
      })
    }
  }, [whiteLabelIdWatch])
  const dispatch = useDispatch()

  useEffect(() => {
    if (couponByIdSuccess === true) {
      setIsEdit(true)
      dispatch(clearCreateCoupon())
      setAddModalVisible(true)
      reset({
        cityId: couponById?.cityId,
        code: couponById?.code,
        description: couponById?.description,
        validFrom: couponById?.validFrom,
        expiredAT: couponById?.expiredAT,
        numberOfOccurrences: couponById?.numberOfOccurrences,
        durationOfFreeRide: couponById?.durationOfFreeRide,
        forSingleUser: couponById?.forSingleUser,
        whiteLabelId: couponById?.whiteLabelId || 10,
        ...couponById,
      })
    }
  }, [couponByIdSuccess])

  const watchValidFrom = watch('validFrom')

  const watchExpiredAT = watch('expiredAT')
  const watchAutoGenerate = watch('autoGenerate')

  useEffect(() => {
    if (!addModalVisible) {
      reset({
        cityId: '',
        code: '',
        description: '',
        validFrom: watchValidFrom,
        expiredAT: watchExpiredAT,
        numberOfOccurrences: '',
        durationOfFreeRide: '',
        forSingleUser: '',
        type: couponType[0].value,
      })
    }
  }, [addModalVisible])

  useEffect(() => {
    if (watchAutoGenerate) {
      register('numberOfCoupon')
      register('prefix')
      unregister('code')

      unregister('ridePass')
      unregister('amount')
      unregister('validity')
    } else {
      unregister('numberOfCoupon')
      unregister('prefix')
      register('code')

      register('ridePass')
      register('amount')
      register('validity')
    }
  }, [watchAutoGenerate])

  useEffect(() => {
    if (watchForSingleUser) {
      unregister('ridePass')
      unregister('amount')
      unregister('validity')
    } else {
      register('ridePass')
      register('amount')
      register('validity')
    }
  }, [watchAutoGenerate, watchForSingleUser])

  useEffect(() => {
    if (watchRidePass) {
      register('amount')
      register('validity')
    } else {
      unregister('amount')
      unregister('validity')
    }
  }, [watchRidePass])

  return (
    <Modal
      open={addModalVisible}
      onClose={handleClose}
      className={modalStyle}
      style={{ height: window.innerHeight }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...control}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isSuperAdmin(adminDetails) && (
              <FormInputDropdown
                name="whiteLabelId"
                options={remappedWhiteLabels}
                errors={errors}
                label={'Select Whitelabel'}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
                disabled={isEdit}
              />
            )}

            <FormInputDropdown
              name="cityId"
              options={isSuperAdmin(adminDetails) ? cityToWhitelabelMapping : remappedCities}
              errors={errors}
              label={strings('coupon.selectCity')}
              required={true}
              styles={{
                minWidth: '400px',
              }}
              disabled={isEdit}
            />

            <FormInputDropdown
              name="type"
              options={couponType}
              errors={errors}
              label={'Select Coupon Type'}
              required={true}
              styles={{
                minWidth: '400px',
              }}
              control={control}
            />

            <SwitchController
              control={control}
              name="autoGenerate"
              errors={errors}
              label={'Auto generate coupons'}
              disabled={false}
            />

            {watchAutoGenerate && (
              <>
                <InputController
                  name={'numberOfCoupon'}
                  label={'Number Of Coupon'}
                  required={!!watchAutoGenerate}
                  styles={{
                    minWidth: '400px',
                  }}
                  extraRules={{
                    min: {
                      value: 1,
                      message: 'Minimum value is 1',
                    },
                  }}
                  muiProps={{
                    type: 'number',
                  }}
                />
                <InputController
                  name={'prefix'}
                  label={'Coupon code prefix'}
                  required={!!watchAutoGenerate}
                  styles={{
                    minWidth: '400px',
                  }}
                />
              </>
            )}

            {!watchAutoGenerate && (
              <InputController
                name={'code'}
                label={strings('coupon.couponCode')}
                required={true}
                styles={{
                  minWidth: '400px',
                }}
              />
            )}

            <InputController
              name={'description'}
              label={strings('coupon.description')}
              required={true}
              styles={{
                minWidth: '400px',
              }}
            />

            <DatePickerController
              name={'validFrom'}
              errors={errors}
              required={true}
              label={strings('descriptions.startDate')}
              disablePast={true}
              styles={{
                minWidth: '400px',
              }}
            />
            <DatePickerController
              name={'expiredAT'}
              errors={errors}
              required={true}
              label={strings('coupon.endDate')}
              disablePast={true}
              styles={{
                minWidth: '400px',
              }}
              extraRules={{
                validate: (value) => {
                  if (value) {
                    if (value < watchValidFrom) {
                      return 'Expire date must be greater than start date'
                    }
                  }
                },
              }}
            />

            {/*<Controller*/}
            {/*  name="freeRide"*/}
            {/*  control={control}*/}
            {/*  render={({ field }) => (*/}
            {/*    <Switch*/}
            {/*      onChange={(event, checked) => field.onChange(checked)}*/}
            {/*      checked={field.value}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

            <InputController
              name={'numberOfOccurrences'}
              label={strings('coupon.freeRideNumber')}
              required={true}
              styles={{
                minWidth: '400px',
              }}
              extraRules={{
                min: {
                  value: 1,
                  message: 'Minimum value is 1',
                },
              }}
              muiProps={{
                type: 'number',
              }}
            />

            <InputController
              name={'durationOfFreeRide'}
              label={strings('coupon.freeRideDuration')}
              required={true}
              styles={{
                minWidth: '400px',
              }}
              extraRules={{
                min: {
                  value: 1,
                  message: 'Minimum value is 1',
                },
              }}
              muiProps={{
                type: 'number',
              }}
            />
            {!watchForSingleUser && !watchAutoGenerate && (
              <>
                <SwitchController
                  control={control}
                  name="ridePass"
                  errors={errors}
                  label={'Ride Pass'}
                  disabled={false}
                />

                {watchRidePass && (
                  <>
                    <InputController
                      name={'amount'}
                      label={'Amount'}
                      required={!!watchRidePass}
                      styles={{
                        minWidth: '400px',
                      }}
                      extraRules={{
                        min: {
                          value: 1,
                          message: 'Minimum value is 1',
                        },
                      }}
                      muiProps={{
                        type: 'number',
                      }}
                      convertType={CONVERT_TYPE.CENT}
                    />

                    <InputController
                      name={'validity'}
                      label={'Ride pass validity (Days)'}
                      required={!!watchRidePass}
                      styles={{
                        minWidth: '400px',
                      }}
                      extraRules={{
                        min: {
                          value: 1,
                          message: 'Minimum value is 1',
                        },
                      }}
                      muiProps={{
                        type: 'number',
                      }}
                    />
                  </>
                )}
              </>
            )}

            {/*<FormInputDropdown*/}
            {/*    name="period"*/}
            {/*    options={periodOptions}*/}
            {/*    errors={errors}*/}
            {/*    label={'Select period of voucher'}*/}
            {/*    required={true}*/}
            {/*/>*/}

            {/*<InputController name={'duration'} label={'Duration'} required={true} />*/}
            {/*<InputController name={'period'} label={'Period'} required={true} />*/}
            {/*<InputController name={'numFreeRides'} label={'Number of free rides'} required={true} />*/}

            {/*<InputController name={'Number'} label={'Number of coupons'} required={true} extraRules={{*/}
            {/*  min: {*/}
            {/*    value: 1,*/}
            {/*    message: "min length is 5"*/}
            {/*  },*/}
            {/*  max: {*/}
            {/*    value: 1000,*/}
            {/*    message: "min length is 1000"*/}
            {/*  }*/}
            {/*}}/>*/}

            {!watchRidePass && (
              <SwitchController
                control={control}
                name="forSingleUser"
                errors={errors}
                label={'Single user coupon'}
                disabled={false}
              />
            )}

            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                {isEdit ? null : (
                  <ButtonWithLoader
                    text={`${isEdit ? strings('coupon.update') : strings('coupon.add')}`}
                    isLoading={createCouponSuccess === undefined}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setAddModalVisible(false)
                  }}
                >
                  {strings('coupon.dismiss')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}

export default CouponModal
