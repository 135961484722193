import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'

export const FormInputDropdown = ({
  name,
  label,
  options,
  errors,
  required,
  styles,
  disabled = false,
  control,
}) => {
  const ocontrol = useFormContext()

  const dcontrol = control || ocontrol
  const generateSingleOptions = () => {
    return options?.map((option) => {
      return <MenuItem value={option.value}>{option.label}</MenuItem>
    })
  }

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: 5, marginTop: 5, ...styles }}
      error={!!errors[name]}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Controller
        rules={{
          required: {
            value: !!required,
            message: `${name} is required`,
          },
        }}
        render={({ field }) => (
          <Select
            label={label}
            disabled={disabled}
            defaultValue={''}
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            {...field}
          >
            {generateSingleOptions()}
          </Select>
        )}
        name={name}
        control={dcontrol}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  )
}
