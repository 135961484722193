export const LOGIN_SCREEN = '/'
export const FORGOT_PASSWORD_SCREEN = '/forgot-password'
export const DASHBOARD_SCREEN = '/dashboard'
export const DASHBOARD_BIKES = '/dashboard/bikes'
export const DASHBOARD_BIKE = '/dashboard/bike/:id'
export const DASHBOARD_STATIONS = '/dashboard/stations'
export const DASHBOARD_DOCKS = '/dashboard/docks'
export const DASHBOARD_RENTALS = '/dashboard/rentals'
export const DASHBOARD_RENTALS_GALLERY = '/dashboard/rentals/gallery'
export const DASHBOARD_RENTAL_RULES = '/dashboard/rental-rules'
export const DASHBOARD_TRANSACTIONS = '/dashboard/transactions'
export const DASHBOARD_TRANSACTION = '/dashboard/transaction/:id'
export const DASHBOARD_CITIES = '/dashboard/cities'
export const DASHBOARD_USERS = '/dashboard/users'
export const DASHBOARD_USER = '/dashboard/user/:id'
export const BIKES_HISTORY = '/history/:id'
export const BIKE_HISTORY = '/dashboard/bike/history'
export const USER_LEDGER = '/dashboard/ledger/:id/:cityId'
export const COUPONS_ROUTE = '/dashboard/coupons'
export const USER_MANAGEMENT_ROUTE = '/dashboard/user-management'
export const REFERRAL_ROUTE = '/dashboard/referral'
export const SETTINGS_ROUTE = '/dashboard/settings'
export const NOTIFICATIONS_ROUTE = '/dashboard/notifications'
export const BOOKING_ROUTE = '/dashboard/booking'
export const MFA_ROUTE = '/dashboard/mfa'
export const PRICING_PLAN_EDITOR_ROUTE = '/dashboard/pricing-plan-editor/:id'
export const PRICING_PLAN_EDITOR_CREATE_ROUTE = '/dashboard/pricing-plan-editor/'
export const PRICING_PLAN_DOMAINS_ROUTE = '/dashboard/pricing-plan-domains/:id'
export const USER_COUPON_ROUTE = '/dashboard/coupon-user/:id'

export const ASSET_PROJECTS = '/dashboard/asset/projects'
export const ASSET_ASSETS = '/dashboard/asset/assets'
export const ASSET_BOOKINGS = '/dashboard/asset/bookings'
