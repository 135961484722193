/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import { Tooltip } from '@mui/material'
import './styles.scss'
import { connect } from 'react-redux'
import { getCityWhitelabelMappings, getWhiteLabel } from '../../actions/cities'
import { useCity, useShowToast } from '../../hooks'
import Moment from 'moment'
import useCustomTable from '../../components/table/CustomTable'
import { reformatFilter, reformatSort, sweetAlertConfirmDialog } from '../../utils/utils'
import { strings } from '../../I18n'
import Tag from '../../components/Tags'
import { TAG_COLOR } from '../../constants/const'
import EditIcon from '@mui/icons-material/Edit'
import {
  clearInternalUserData,
  createInternalUser,
  deleteInternalUser,
  getAllManagedUser,
  getInternalUser,
  updateInternalUser,
} from '../../actions/users'
import UserAddModal from '../../components/modal/userAddModalModal'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '16px',
  },
}))

const UserManagementScreen = (props) => {
  const {
    managedUser,
    managedUserSuccess,
    internalUserUpdateSuccess,
    internalUserCreateSuccess,
    deleteInternalUserSuccess,
  } = props
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const CustomColorIconButton = withStyles({
    root: {
      color: '#086600',
    },
  })(IconButton)

  const classes = useStyles()

  const showToast = useShowToast()
  const { selectedCity } = useCity()

  useEffect(() => {
    props.getWhiteLabel()
    props.getCityWhitelabelMappings()
    setAddModalVisible(false)
  }, [])

  const activeUser = (row) => {
    sweetAlertConfirmDialog(
      "Are you sure you want to enable this user's account?",
      'You can disable it later.',
      () => {
        props.updateInternalUser({ ...row, status: 'ACTIVE' }, row?.email)
      }
    )
  }

  const disableUser = (row) => {
    sweetAlertConfirmDialog(
      "Are you sure you want to disable this user's account?",
      'You can enable it later.',
      () => {
        props.updateInternalUser({ ...row, status: 'INACTIVE' }, row?.email)
      }
    )
  }

  const deleteUser = (row) => {
    sweetAlertConfirmDialog(
      "Are you sure you want to delete this user's account?",
      'You cannot undo this action.',
      () => {
        props.deleteInternalUser(row?.email)
      }
    )
  }
  const editUser = (row) => {
    props.getInternalUser(row.email)
  }

  const columns = useMemo(
    () => [
      {
        name: 'Email',
        selector: 'email',
        center: true,
      },
      {
        id: 'sortable',
        name: 'City Name',
        selector: 'cityName',
        center: true,
      },
      {
        id: 'sortable1',
        name: 'WhiteLabel Name',
        selector: 'whiteLabelCode',
        sortable: false,
        center: true,
      },
      {
        id: 'sortable6',
        name: 'Status',
        selector: 'status',
        sortable: true,
        center: true,
        cell: (row) => {
          return (
            <Tag
              text={row?.status}
              colorTag={row?.status === 'ACTIVE' ? TAG_COLOR.GREEN : TAG_COLOR.RED}
            />
          )
        },
      },
      {
        name: strings('coupon.action'),
        selector: 'numberOfOccurrences',
        minWidth: '230px',
        center: true,
        cell: (row, index, column, id) => {
          return (
            <>
              <Tooltip
                title={strings('editUser')}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    editUser(row)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              {row?.status === 'ACTIVE' && (
                <Tooltip
                  title="Disable user"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      disableUser(row)
                    }}
                  >
                    <NotInterestedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {row?.status === 'INACTIVE' && (
                <Tooltip
                  title="Activated User"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <CustomColorIconButton
                    onClick={() => {
                      activeUser(row)
                    }}
                  >
                    <CheckIcon />
                  </CustomColorIconButton>
                </Tooltip>
              )}
              <Tooltip
                title={strings('deleteUser')}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    deleteUser(row)
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        },
      },
    ],
    []
  )

  const addButtonClick = () => {
    setAddModalVisible(true)
    setIsEdit(false)
  }

  let filterObject = [
    {
      filterBy: 'status',
      filterValue: ['ACTIVE', 'INACTIVE'],
    },
  ]

  const reloadData = () => {
    setLoading(true)

    props.getAllManagedUser(
      localPage,
      debouncedSearchTerm,
      selectedCity,
      reformatSort(sortData),
      reformatFilter(filterData),
      localRows
    )
  }

  const {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
  } = useCustomTable(columns, managedUser, reloadData, addButtonClick, filterObject, 'Users', true)

  useEffect(() => {
    if (managedUserSuccess) {
      setLoading(false)
    }
  }, [managedUserSuccess])

  useEffect(() => {
    resetPagination()
  }, [selectedCity, debouncedSearchTerm])

  useEffect(() => {
    reloadData()
  }, [localPage, debouncedSearchTerm, selectedCity, sortData, filterData, localRows])

  const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY HH:mm')

  function handleClose() {
    setAddModalVisible(false)
    setIsEdit(false)
  }

  const onSubmit = (data) => {
    if (isEdit) {
      if (data?.userType === 'SUPER_ADMIN') {
        data = {
          ...data,
          cityId: null,
          whiteLabelId: null,
        }
      } else if (data?.userType === 'WHITELABEL_ADMIN') {
        data = {
          ...data,
          cityId: null,
          whiteLabelId: String(data.whiteLabelId) === '10' ? null : data.whiteLabelId,
        }
      } else {
        data = {
          ...data,
          whiteLabelId: String(data.whiteLabelId) === '10' ? null : data.whiteLabelId,
        }
      }
      props.updateInternalUser(data, data?.email)
    } else {
      data = {
        ...data,
        whiteLabelId: String(data.whiteLabelId) === '10' ? null : data.whiteLabelId,
      }
      props.createInternalUser(data)
    }
  }

  useEffect(() => {
    if (internalUserCreateSuccess) {
      showToast('User created', 'success')
      props.clearInternalUserData()
      reloadData()
      setAddModalVisible(false)
    }
  }, [internalUserCreateSuccess])

  useEffect(() => {
    if (internalUserUpdateSuccess === true) {
      showToast('User Updated', 'success')
      reloadData()
      props.clearInternalUserData()
      setAddModalVisible(false)
    }
  }, [internalUserUpdateSuccess])

  useEffect(() => {
    if (deleteInternalUserSuccess) {
      showToast('User deleted', 'success')
      props.clearInternalUserData()
      reloadData()
    }
  }, [deleteInternalUserSuccess])

  // if (couponListSuccess === null) {
  //   return <Loader />
  // }

  return (
    <>
      {/*<Paper className={classes.paper}>*/}
      {renderHeaderBar()}
      {renderCustomTable()}
      {/*</Paper>*/}

      <UserAddModal
        onSubmit={onSubmit}
        addModalVisible={addModalVisible}
        handleClose={handleClose}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setAddModalVisible={setAddModalVisible}
      />
    </>
  )
}

const mapStateToProps = ({
  users: {
    managedUser,
    managedUserSuccess,
    internalUserUpdateSuccess,
    internalUserCreateSuccess,
    deleteInternalUserSuccess,
  },
}) => ({
  managedUser,
  managedUserSuccess,
  internalUserUpdateSuccess,
  internalUserCreateSuccess,
  deleteInternalUserSuccess,
})

const mapDispatchToProps = {
  getWhiteLabel,
  getCityWhitelabelMappings,
  getInternalUser,
  updateInternalUser,
  getAllManagedUser,
  createInternalUser,
  clearInternalUserData,
  deleteInternalUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementScreen)
