import React, { useMemo } from 'react'
import Autocomplete from '@mui/lab/Autocomplete'
import TextField from '@mui/material/TextField/TextField'
import { useSelector } from 'react-redux'

export const NotificationsTargetCity = () => {
  const cities = useSelector((s: any) => s.cities?.citiesList)

  const cityOptions = useMemo(() => {
    return cities.map((c: any) => {
      return {
        label: c.name,
        id: c.code,
      }
    })
  }, [cities])

  return (
    <Autocomplete
      multiple
      options={cityOptions}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label="Cities" placeholder="Select City" />}
    />
  )
}
