import * as types from '../constants/actionTypes'

let initialState = {
  getStationsSuccess: null,
  stationsResult: null,
  stations: [],
  stationByIdSuccess: null,
  station: null,
  linkedStations: [],
  getLinekedStationsSuccess: null,
  updateStationSuccess: null,
  createStationSuccess: null,
  unlockStationSuccess: null,
  getVehicleStatusesSuccess: null,
  vehicleStatuses: [],
  stationsStatuses: [],
  getStationsStatusesSuccess: null,
  mapData: [],
  getMapStationsSuccess: null,
  stationsByCity: [],
  getStationsByCitySuccess: null,

  appVersion: null,
  appVersionSuccess: null,

  saveAppVersion: null,
  saveAppVersionSuccess: null,

  allVirtualStations: null,
  allVirtualStationsSuccess: null,

  speedLimitZoneList: null,
  speedLimitZoneListSuccess: null,

  createSpeedLimitZone: null,
  createSpeedLimitZoneSuccess: null,

  updateSpeedLimitZone: null,
  updateSpeedLimitZoneSuccess: null,

  speedLimitZone: null,
  speedLimitZoneSuccess: null,
}

const stations = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATIONS_REQUEST: {
      return {
        ...state,
        getStationsSuccess: undefined,
      }
    }

    case types.SAVE_APP_VERSION_REQUEST: {
      return {
        ...state,
        saveAppVersionSuccess: null,
      }
    }
    case types.SAVE_APP_VERSION_SUCCESS: {
      return {
        ...state,
        saveAppVersionSuccess: true,
        saveAppVersion: action.result,
      }
    }
    case types.SAVE_APP_VERSION_FAILURE: {
      return {
        ...state,
        saveAppVersionSuccess: false,
      }
    }

    case types.CLEAR_APP_VERSION: {
      return {
        ...state,
        saveAppVersionSuccess: null,
      }
    }

    case types.APP_VERSION_GET_REQUEST: {
      return {
        ...state,
        appVersionSuccess: null,
      }
    }
    case types.APP_VERSION_GET_SUCCESS: {
      return {
        ...state,
        appVersionSuccess: true,
        appVersion: action.result,
      }
    }
    case types.APP_VERSION_GET_FAILURE: {
      return {
        ...state,
        appVersionSuccess: false,
      }
    }

    case types.GET_STATIONS_SUCCESS: {
      return {
        ...state,
        stationsResult: action.result,
        stations: action.result.content,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getStationsSuccess: true,
      }
    }
    case types.GET_STATIONS_FAILURE: {
      return {
        ...state,
        clients: [],
        getStationsSuccess: false,
      }
    }
    case types.GET_STATION_BY_ID_REQUEST: {
      return {
        ...state,
        stationByIdSuccess: null,
      }
    }
    case types.GET_STATION_BY_ID_SUCCESS: {
      return {
        ...state,
        stationByIdSuccess: true,
        station: action.result,
      }
    }
    case types.GET_STATION_BY_ID_FAILURE: {
      return {
        ...state,
        stationByIdSuccess: false,
      }
    }
    case types.UPDATE_STATION_BY_ID_REQUEST: {
      return {
        ...state,
        updateStationSuccess: null,
      }
    }
    case types.UPDATE_STATION_BY_ID_SUCCESS: {
      const index = state.stations.findIndex((item) => item.id === action.result.id)
      state.stations[index] = action.result
      return {
        ...state,
        updateStationSuccess: true,
      }
    }
    case types.UPDATE_STATION_BY_ID_FAILURE: {
      return {
        ...state,
        updateStationSuccess: false,
      }
    }
    case types.CREATE_STATION_REQUEST: {
      return {
        ...state,
        createStationSuccess: null,
      }
    }
    case types.CREATE_STATION_SUCCESS: {
      return {
        ...state,
        createStationSuccess: true,
      }
    }
    case types.CREATE_STATION_FAILURE: {
      return {
        ...state,
        createStationSuccess: false,
      }
    }
    case types.UNLOCK_STATION_REQUEST: {
      return {
        ...state,
        unlockStationSuccess: null,
      }
    }
    case types.UNLOCK_STATION_SUCCESS: {
      return {
        ...state,
        unlockStationSuccess: true,
      }
    }
    case types.UNLOCK_STATION_FAILURE: {
      return {
        ...state,
        unlockStationSuccess: false,
      }
    }
    case types.GET_VEHICLE_STATUSES_REQUEST: {
      return {
        ...state,
        getVehicleStatusesSuccess: undefined,
      }
    }
    case types.GET_VEHICLE_STATUSES_SUCCESS: {
      return {
        ...state,
        vehicleStatuses: action.result,
        getVehicleStatusesSuccess: true,
      }
    }
    case types.GET_VEHICLE_STATUSES_FAILURE: {
      return {
        ...state,
        getVehicleStatusesSuccess: false,
      }
    }
    case types.CLEAR_STATIONS_DATA: {
      return {
        ...state,
        stationByIdSuccess: null,
        updateStationSuccess: null,
        createStationSuccess: null,
        getStationsStatusesSuccess: null,
        getStationsByCitySuccess: null,
        getLinekedStationsSuccess: null,
        getVehicleStatusesSuccess: null,
        createSpeedLimitZoneSuccess: null,
        updateSpeedLimitZoneSuccess: null,
        speedLimitZoneSuccess: null,
      }
    }
    case types.GET_STATIONS_STATUSES_REQUEST: {
      return {
        ...state,
        getStationsStatusesSuccess: undefined,
      }
    }
    case types.GET_STATIONS_STATUSES_SUCCESS: {
      return {
        ...state,
        stationsStatuses: action.result,
        getStationsStatusesSuccess: true,
      }
    }
    case types.GET_STATIONS_STATUSES_FAILURE: {
      return {
        ...state,
        getStationsStatusesSuccess: false,
      }
    }
    case types.GET_STATIONS_BY_CITY_REQUEST: {
      return {
        ...state,
        getStationsByCitySuccess: null,
      }
    }
    case types.GET_STATIONS_BY_CITY_SUCCESS: {
      return {
        ...state,
        stationsByCity: action.result,
        getStationsByCitySuccess: true,
      }
    }
    case types.GET_STATIONS_BY_CITY_FAILURE: {
      return {
        ...state,
        getStationsByCitySuccess: false,
      }
    }
    case types.CLEAR_STATION: {
      return {
        ...state,
        stationByIdSuccess: null,
        updateStationSuccess: null,
        createStationSuccess: null,
        getStationsStatusesSuccess: null,
        getStationsByCitySuccess: null,
        getLinekedStationsSuccess: null,
        getVehicleStatusesSuccess: null,
        createSpeedLimitZoneSuccess: null,
        updateSpeedLimitZoneSuccess: null,
        speedLimitZoneSuccess: null,
      }
    }
    case types.GET_STATIONS_ON_MAP_REQUEST: {
      return {
        ...state,
        getMapStationsSuccess: undefined,
      }
    }
    case types.GET_STATIONS_ON_MAP_SUCCESS: {
      return {
        ...state,
        mapData: action.result,
        getMapStationsSuccess: true,
      }
    }
    case types.GET_LINKED_STATIONS_REQUEST: {
      return {
        ...state,
        getLinekedStationsSuccess: undefined,
      }
    }
    case types.GET_LINKED_STATIONS_SUCCESS: {
      return {
        ...state,
        linkedStations: action.result,
        getLinekedStationsSuccess: true,
      }
    }
    case types.GET_LINKED_STATIONS_FAILURE: {
      return {
        ...state,
        getLinekedStationsSuccess: false,
      }
    }
    case types.GET_STATIONS_ON_MAP_FAILURE: {
      return {
        ...state,
        getMapStationsSuccess: false,
      }
    }

    case types.GET_ALL_VIRTUAL_STATION_REQUEST: {
      return {
        ...state,
        allVirtualStationsSuccess: undefined,
      }
    }
    case types.GET_ALL_VIRTUAL_STATION_SUCCESS: {
      return {
        ...state,
        allVirtualStations: action.result,
        allVirtualStationsSuccess: true,
      }
    }
    case types.GET_ALL_VIRTUAL_STATION_FAILURE: {
      return {
        ...state,
        allVirtualStationsSuccess: false,
      }
    }

    case types.GET_SPEED_LIMIT_ZONES_REQUEST: {
      return {
        ...state,
        speedLimitZoneListSuccess: undefined,
      }
    }
    case types.GET_SPEED_LIMIT_ZONES_SUCCESS: {
      return {
        ...state,
        speedLimitZoneList: action.result,
        speedLimitZoneListSuccess: true,
      }
    }
    case types.GET_SPEED_LIMIT_ZONES_FAILURE: {
      return {
        ...state,
        speedLimitZoneListSuccess: false,
      }
    }

    case types.CREATE_LIMIT_ZONES_REQUEST: {
      return {
        ...state,
        createSpeedLimitZoneSuccess: undefined,
      }
    }
    case types.CREATE_LIMIT_ZONES_SUCCESS: {
      return {
        ...state,
        createSpeedLimitZone: action.result,
        createSpeedLimitZoneSuccess: true,
      }
    }
    case types.CREATE_LIMIT_ZONES_FAILURE: {
      return {
        ...state,
        createSpeedLimitZoneSuccess: false,
      }
    }

    case types.UPDATE_LIMIT_ZONES_REQUEST: {
      return {
        ...state,
        updateSpeedLimitZoneSuccess: undefined,
      }
    }
    case types.UPDATE_LIMIT_ZONES_SUCCESS: {
      return {
        ...state,
        updateSpeedLimitZone: action.result,
        updateSpeedLimitZoneSuccess: true,
      }
    }
    case types.UPDATE_LIMIT_ZONES_FAILURE: {
      return {
        ...state,
        updateSpeedLimitZoneSuccess: false,
      }
    }

    case types.GET_LIMIT_ZONE_BY_ID_REQUEST: {
      return {
        ...state,
        speedLimitZoneSuccess: undefined,
      }
    }
    case types.GET_LIMIT_ZONE_BY_ID_SUCCESS: {
      return {
        ...state,
        speedLimitZone: action.result,
        speedLimitZoneSuccess: true,
      }
    }
    case types.GET_LIMIT_ZONE_BY_ID_FAILURE: {
      return {
        ...state,
        speedLimitZoneSuccess: false,
      }
    }

    default:
      return state
  }
}

export default stations
