/*eslint-disable*/
import FormBuilderBase from '../FormBuilderBase'
import InputController from '../inputController'
import React, { useEffect, useMemo } from 'react'
import './styles.scss'
import bikeIcon from '../../images/pricingPlan/bike.png'
import ebikeIcon from '../../images/pricingPlan/ebike.png'
import scooterIcon from '../../images/pricingPlan/scooter.png'
import RadioGroupController from '../inputController/RadioGroupController'
import { strings } from '../../I18n'
import { CONVERT_TYPE, SUBSCRIPTION } from '../../constants/const'

export const ProductComponent = ({
  control = { control },
  productIndex,
  currency,
  type,
  pricingPlanType,
  register,
  unregister,
}) => {
  useEffect(() => {
    if (pricingPlanType === SUBSCRIPTION) {
      unregister(`products[${productIndex}].rate.value`)
      unregister(`products[${productIndex}].rate.intervalMinutes`)

      register(`products[${productIndex}].followingHoursRate.value`)
      register(`products[${productIndex}].followingHoursRate.intervalMinutes`)
    } else {
      register(`products[${productIndex}].rate.value`)
      register(`products[${productIndex}].rate.intervalMinutes`)

      unregister(`products[${productIndex}].followingHoursRate.value`)
      unregister(`products[${productIndex}].followingHoursRate.intervalMinutes`)
    }
  }, [pricingPlanType])

  const maxAmountResetValidity = [
    { label: '24 Hour', value: '1440' },
    { label: '12 Hour', value: '720' },
  ]

  const vehicleIcon = useMemo(() => {
    return type === 'BIKE' ? bikeIcon : type === 'SCOOTER' ? scooterIcon : ebikeIcon
  }, [type])

  return (
    <div className="elivated-card">
      <div className="header-text-icon">
        <img width={30} src={vehicleIcon} alt="" />
        <h3
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {type}
        </h3>
      </div>
      <div className="divider-new"></div>
      <FormBuilderBase
        title={strings('pricingPlan.pricing')}
        content={
          <>
            {pricingPlanType === SUBSCRIPTION ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputController
                    name={`products.${productIndex}.followingHoursRate.value`}
                    label={strings('pricingPlan.followingHourRate')}
                    required={false}
                    disabled={false}
                    muiProps={{
                      type: 'number',
                    }}
                    inputPrefix={currency}
                    info={strings('pricingPlan.rateInfo')}
                    convertType={CONVERT_TYPE.CENT}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputController
                    name={`products.${productIndex}.followingHoursRate.intervalMinutes`}
                    label={strings('pricingPlan.followingHourRateInterval')}
                    required={false}
                    disabled={false}
                    muiProps={{
                      type: 'number',
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputController
                    name={`products.${productIndex}.rate.value`}
                    label={strings('pricingPlan.price')}
                    required={true}
                    disabled={false}
                    muiProps={{
                      type: 'number',
                    }}
                    inputPrefix={currency}
                    info={strings('pricingPlan.rateInfo')}
                    convertType={CONVERT_TYPE.CENT}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputController
                    name={`products.${productIndex}.rate.intervalMinutes`}
                    label={strings('pricingPlan.pricingPlanRate')}
                    required={false}
                    disabled={false}
                    muiProps={{
                      type: 'number',
                    }}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                <InputController
                  name={`products.${productIndex}.startupAmount`}
                  label={strings('pricingPlan.startupAmount')}
                  required={false}
                  inputPrefix={currency}
                  info={strings('pricingPlan.startupAmountInfo')}
                  muiProps={{
                    type: 'number',
                  }}
                  convertType={CONVERT_TYPE.CENT}
                />

                <InputController
                  name={`products.${productIndex}.depositAmount`}
                  label={strings('pricingPlan.depositAmount')}
                  required={false}
                  muiProps={{
                    type: 'number',
                  }}
                  inputPrefix={currency}
                  convertType={CONVERT_TYPE.CENT}
                />

                <InputController
                  name={`products.${productIndex}.minutesFree`}
                  label={strings('pricingPlan.minFree')}
                  required={false}
                  info={strings('pricingPlan.minFreeInfo')}
                  muiProps={{
                    type: 'number',
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}></div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                <InputController
                  name={`products.${productIndex}.maxAmount`}
                  label={strings('pricingPlan.maxAmount')}
                  required={false}
                  muiProps={{
                    type: 'number',
                  }}
                  inputPrefix={currency}
                  info={strings('pricingPlan.maxAmountInfo')}
                  convertType={CONVERT_TYPE.CENT}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                <RadioGroupController
                  options={maxAmountResetValidity}
                  title={strings('pricingPlan.maxAmountDuration')}
                  name={`products.${productIndex}.maxAmountDurationValidity`}
                  control={control}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}
