/* eslint-disable */
import React, { useEffect } from 'react'
import InputController from '../../inputController'
import { Box, Button, FormLabel, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from '@mui/material/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { strings } from '../../../I18n'
import { CSVDownload } from 'react-csv'
import './style.scss'
import ButtonWithLoader from '../../ButtonWithLoader'
import { clearDownloadedCoupon } from '../../../actions/cities'
import { Alert } from '@mui/lab'

const CouponDownloadModal = ({
  onSubmit,
  downloadModalVisible,
  handleClose,
  setDownloadModalVisible,
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '40%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block',
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '80%',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '1px solid white',
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '8px',
    },
  }
  const downloadCouponSuccess = useSelector((state) => state.cities.downloadCouponSuccess)
  const downloadedCoupon = useSelector((state) => state.cities.downloadedCoupon)
  const dispatch = useDispatch()

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm()

  useEffect(() => {
    if (!downloadModalVisible) {
      clearFormAndState()
    }
  }, [downloadModalVisible])

  useEffect(() => {
    if (downloadedCoupon?.length > 0) {
      clearFormAndState()
      setDownloadModalVisible(false)
    }
  }, [downloadedCoupon])

  const clearFormAndState = () => {
    reset()
    dispatch(clearDownloadedCoupon())
  }

  return (
    <Modal
      open={downloadModalVisible}
      // disableBackdropClick={true}
      onClose={handleClose}
      className={modalStyle}
      style={{ height: window.innerHeight }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...control}>
          <FormLabel>{strings('coupon.pleaseConfirmAndDownload')}</FormLabel>
          <Box sx={{ marginBottom: '10px' }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputController
              name={strings('coupon.prefix')}
              // label={strings('coupon.description')}
              label={'prefix'}
              required={true}
              styles={{
                minWidth: '400px',
                maxHeight: '50px',
              }}
            />
            {downloadedCoupon?.length === 0 && <Alert severity="warning">no data found</Alert>}
            <Box sx={{ marginBottom: '20px', margintop: '20px' }} />
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                // alignItems="center"
                justifyContent="center"
              >
                {downloadedCoupon?.length > 0 && (
                  <CSVDownload
                    onClick={() => {
                      setDownloadModalVisible(false)
                      clearFormAndState()
                    }}
                    data={downloadedCoupon}
                    // headers={headers}
                    target="_blank"
                    filename="coupon.csv"
                  />
                )}
                <>
                  <ButtonWithLoader
                    text={strings('coupon.download')}
                    isLoading={downloadCouponSuccess === undefined}
                  />
                </>
              </Grid>

              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                // alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setDownloadModalVisible(false)
                  }}
                >
                  {strings('coupon.dismiss')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}

export default CouponDownloadModal
