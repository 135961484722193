/* eslint-disable */
import { BIKES, DATE_FORMAT_DEFAULT, STATIONS, VIRTUAL_STATION } from '../constants/const'
import { strings } from '../I18n'
import { FILTER_BY_CITY_ID, STATION_STATUSES, STATION_TYPES } from '../constants/filterConst'
import Moment from 'moment'
import shareBikeLogo from '../images/shareBikeLogo.png'
import mobyLogo from '../images/mobyLogo.png'
import bigissueLogo from '../images/bigissueLogo.png'
import farteBriskkel from '../images/FarteBysykkel.png'
import sigoLogo from '../images/sigo-logo.svg'
import { store } from '../store'
import Swal from 'sweetalert2'

export const reformatSort = (sortData) => {
  if (sortData?.column) {
    return `${sortData.column.selector},${sortData.sortDirection}`
  } else {
    return ''
  }
}
export const reformatFilter = (filterData) => {
  let builder = '&'
  filterData?.map((value, index) => {
    builder += `${value.filterBy}=${value.filter}&`
  })
  return builder.slice(0, -1)
}

export const reformatSearch = (oSearchData, searchText) => {
  let searchData = []
  if (oSearchData) {
    searchData.push(oSearchData)
  }

  if ((searchData?.length === 0 || !searchData) && searchText) {
    return `&search=${searchText}`
  } else if (searchData?.length > 0 && searchText) {
    let builder = '&'
    searchData?.map((value, index) => {
      builder += `${value.value}=${searchText}&`
    })
    return builder.slice(0, -1)
  } else {
    return ''
  }
}

export const stringByStatus = (status) =>
  status ? strings('descriptions.yes') : strings('descriptions.no')

export const generateEndpoint = (endpoint, page = 0, search = '', sort = '', filter = '') => {
  filter = filter && filter[0] === '&' ? filter.slice(1) : filter
  endpoint += `&page=${page}`
  endpoint += search !== '' ? `&search=${search}` : search
  endpoint += sort !== '' ? `&sort=${sort}` : sort
  endpoint += filter !== '' ? `&${filter}` : filter
  return endpoint
}
export const generateEndpointWithSearchFilter = (
  endpoint,
  page = 0,
  search = '',
  sort = '',
  filter = ''
) => {
  filter = filter && filter[0] === '&' ? filter.slice(1) : filter
  endpoint += `&page=${page}`
  // endpoint += search !== '' ? `&search=${search}` : search
  endpoint += sort !== '' ? `&sort=${sort}` : sort
  endpoint += filter !== '' ? `&${filter}` : filter
  endpoint += search !== '' ? `${search && search}` : search
  return endpoint
}

export const generateCustomEndpoint = (
  endpoint,
  page = 0,
  search = '',
  cityId = '',
  sort = '',
  filter = '',
  size = '',
  userId = '',
  startDate = '',
  endDate = '',
  couponId = ''
) => {
  let builder = ''
  builder += endpoint
  builder += `page=${page}`
  builder += `&size=${size}`
  builder += search !== '' ? `&search=${search}` : search
  builder += cityId !== '' ? `&cityId=${cityId}` : cityId
  builder += sort !== '' ? `&sort=${sort}` : sort
  builder += userId !== '' ? `&userId=${userId}` : userId
  builder += startDate !== '' ? `&startDate=${startDate}` : startDate
  builder += endDate !== '' ? `&endDate=${endDate}` : endDate
  builder += couponId !== '' ? `&couponId=${couponId}` : couponId
  builder += filter !== '' ? `${filter && filter}` : filter
  return builder
}

export const generateCustomEndpointWithSearch = (
  endpoint,
  page = 0,
  search = '',
  cityId = '',
  sort = '',
  filter = '',
  size = '',
  userId = '',
  startDate = '',
  endDate = '',
  couponId = ''
) => {
  let builder = ''
  builder += endpoint
  builder += `page=${page}`
  builder += `&size=${size}`
  builder += cityId !== '' ? `&cityId=${cityId}` : cityId
  builder += sort !== '' ? `&sort=${sort}` : sort
  builder += userId !== '' ? `&userId=${userId}` : userId
  builder += startDate !== '' ? `&startDate=${startDate}` : startDate
  builder += endDate !== '' ? `&endDate=${endDate}` : endDate
  builder += couponId !== '' ? `&couponId=${couponId}` : couponId
  builder += filter !== '' ? `${filter && filter}` : filter
  builder += search !== '' ? `${search && search}` : search
  return builder
}

export const generateMapEndpoind = (
  url,
  typeFilter = [],
  stationType = [],
  stationState = [],
  bikeState = [],
  city = null
) => {
  let result = ''
  if (typeFilter.length) {
    result = '?'
  } else {
    if (city) {
      return `${url}?${city}`
    }
    return url
  }
  typeFilter.map((type, index) => {
    result += !index ? `${type.toLowerCase()}=true` : `&${type.toLowerCase()}=true`
    return type
  })

  let stationStatuses = ''
  if (stationType.length && typeFilter.includes(STATIONS)) {
    stationStatuses = `&${STATION_TYPES}`

    stationType.map((type, index) => {
      stationStatuses += !index ? type : `,${type}`
      return type
    })
  }

  let stationTypes = ''
  if (stationState.length && typeFilter.includes(STATIONS)) {
    stationTypes = `&${STATION_STATUSES}`

    stationState.map((state, index) => {
      stationTypes += !index ? state : `,${state}`
      return state
    })
  }

  let otherStatuses = ''
  if (bikeState.length && typeFilter.includes(BIKES)) {
    bikeState.map((status) => {
      const values = status.split(' ').map((item, index) => {
        let newItem = item
        if (index) {
          newItem = newItem[0].toUpperCase() + newItem.substr(1)
        } else {
          newItem = newItem[0].toLowerCase() + newItem.substr(1)
        }
        return newItem
      })
      otherStatuses += `&${values.join('')}=true`
      return status
    })
  }

  result += stationStatuses + stationTypes + otherStatuses

  if (city) {
    result += `${result.length ? '&' : ''}${city}`
  }

  return url + result
}

export const stringFromValue = (value) => (value != null ? value : '-')
export const removeUnderscoreGetString = (value) => value.replace(/_/g, ' ')
export const sortedItem = (index, orderKey, orderProps) => ({
  index,
  orderKey,
  orderProps,
})

export const filterUrl = (filter, cities) => {
  let filterUrl = ''
  if (filter === null) {
    return ''
  }
  filter.map((item) => {
    if (filterUrl !== '') filterUrl += '&'
    filterUrl += `${item.filterBy}=${showFilter(item.filterBy, item.filter, cities)}`
    return item
  })
  return filterUrl
}

export const showFilter = (filterBy, filter, items) => {
  switch (filterBy) {
    case FILTER_BY_CITY_ID:
      let city = items.filter((item) => item.name === filter)[0]
      return city?.id
    default:
      return filter
  }
}

export const cityNameById = (citiesList, id) =>
  !!id ? citiesList.filter((item) => item.id === id)[0]?.name : null

export const filterByValue = (filterBy) => {
  if (filterBy === FILTER_BY_CITY_ID) {
    return strings('descriptions.city')
  } else {
    return filterBy
  }
}

export const secondsToMin = (seconds) => {
  if (!!seconds) {
    if (seconds < 3600) {
      return Moment.utc(seconds * 1000).format('mm:ss')
    } else {
      let hour =
        Math.floor(Moment.duration(seconds, 'seconds').asHours()) < 10
          ? '0' + Math.floor(Moment.duration(seconds, 'seconds').asHours())
          : Math.floor(Moment.duration(seconds, 'seconds').asHours())
      let minute =
        Moment.duration(seconds, 'seconds').minutes() < 10
          ? '0' + Moment.duration(seconds, 'seconds').minutes()
          : Moment.duration(seconds, 'seconds').minutes()
      let second =
        Moment.duration(seconds, 'seconds').seconds() < 10
          ? '0' + Moment.duration(seconds, 'seconds').seconds()
          : Moment.duration(seconds, 'seconds').seconds()
      return hour + ':' + minute + ':' + second
    }
  }
  return '00:00'
}

const getCurrentGeometry = (geometry) => {
  return geometry.map((cordArr) => ({
    lat: cordArr[0],
    lng: cordArr[1],
  }))
}

export const generatePolygons = (array, maps, oldPolygons, action) => {
  let oldPolygonsArray = []
  array.map((item) => {
    if (item.type === VIRTUAL_STATION && item?.geometry?.length) {
      const data = getCurrentGeometry(item.geometry)
      const geometry = new maps.Polygon({
        path: data,
        fillColor: 'rgba(68,255,44,0.25)',
        strokeColor: 'rgb(47,146,28)',
        strokeWeight: 2,
        cursor: 'move',
      })
      oldPolygonsArray = [...oldPolygonsArray, geometry]
    }
    return null
  })
  action(oldPolygonsArray)
}

export const WhitelabelCodeReturnDecide = (code, actions) => {
  switch (code) {
    case 'moby':
      return actions[1]

    case 'bigIssue':
      return actions[2]

    case 'farteBysykkel':
      return actions[3]

    case 'sigo':
      return actions[4]

    default:
      return actions[0]
  }
}

export const getWhiteLabelLogo = (code) => {
  return WhitelabelCodeReturnDecide(code, [
    shareBikeLogo,
    mobyLogo,
    bigissueLogo,
    farteBriskkel,
    sigoLogo,
  ])
}

export const isBigissue = (whiteLabelCode) => {
  return whiteLabelCode === 'bigIssue'
}

const dateFormat = (date) => Moment(date + 'Z').format('DD.MM.YYYY HH:mm')

export const isSuperAdmin = (adminDetails) => {
  return adminDetails?.cityId === null && adminDetails?.whiteLabelId === null
}
export const isSuperAdminOrCityAdmin = (adminDetails) => {
  return adminDetails?.cityId === null
}

export const openNewTabMapMarker = (lat, lng) => {
  let url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
  const win = window.open(url, '_blank')
  if (win != null) {
    win.focus()
  }
}

export const constructImageDirectory = (baseDirectoryData) => {
  return `photo_finish/${baseDirectoryData?.cityCode.toLowerCase()}/${baseDirectoryData?.pricingPlanType.toLowerCase()}/${
    baseDirectoryData?.id
  }`
}

export const convertDateToHumanReadableFormat = (date) => {
  return Moment(date).format('DD.MM.YYYY')
}

export const convertDateToUtc0InMilliseconds = (date) => {
  const dateUtc = Moment(date).utcOffset(0)
  dateUtc.add(12, 'hour')
  return dateUtc.valueOf()
}

export const convertDateFromUtc0ToLocal = (date) => {
  return Moment.utc(date).local().format(DATE_FORMAT_DEFAULT)
}

export const remapArrayToValueAndLabel = (array) => {
  return array.map((item) => ({ value: item.toString(), label: item.toString() }))
}

export const modifyCurrencyToSymbol = (currencyName) => {
  const payment = store.getState().payment
  const mappings = payment?.currencyMappings

  const localMappings = [
    {
      code: 'NOK',
      symbol: 'kr',
      afterAmount: true,
    },
    {
      code: 'SEK',
      symbol: 'kr',
      afterAmount: true,
    },
    {
      code: 'EUR',
      symbol: '€',
      afterAmount: false,
    },
    {
      code: 'GBP',
      symbol: '£',
      afterAmount: false,
    },
    {
      code: '£',
      symbol: '£',
      afterAmount: false,
    },
  ]
  //  response { code: "USD" symbol:"$", afterAmount:true}

  //find in mappings if not found then find in localMappings
  const foundMapping = mappings?.find((mapping) => mapping.code === currencyName)
  if (foundMapping) {
    return foundMapping.symbol
  } else {
    const foundLocalMapping = localMappings.find((mapping) => mapping.code === currencyName)
    if (foundLocalMapping) {
      return foundLocalMapping.symbol
    } else {
      return currencyName
    }
  }
}

export const modifyCurrencyToSymbolAndValue = (currencyName, price) => {
  const payment = store.getState().payment
  const mappings = payment?.currencyMappings
  const localMappings = [
    {
      code: 'NOK',
      symbol: 'kr',
      afterAmount: true,
    },
    {
      code: 'SEK',
      symbol: 'kr',
      afterAmount: true,
    },
    {
      code: 'EUR',
      symbol: '€',
      afterAmount: false,
    },
    {
      code: 'GBP',
      symbol: '£',
      afterAmount: false,
    },
    {
      code: '£',
      symbol: '£',
      afterAmount: false,
    },
  ]

  //  response { code: "USD" symbol:"$", afterAmount:true}

  //find in mappings if not found then find in localMappings
  const foundMapping = mappings?.find((mapping) => mapping.code === currencyName)
  if (foundMapping) {
    if (foundMapping.afterAmount === true) {
      return `${price}${foundMapping.symbol}`
    } else {
      return `${foundMapping.symbol}${price}`
    }
  } else {
    const foundLocalMapping = localMappings.find((mapping) => mapping.code === currencyName)
    if (foundLocalMapping) {
      if (foundLocalMapping.afterAmount === true) {
        return `${price}${foundLocalMapping.symbol}`
      } else {
        return `${foundLocalMapping.symbol}${price}`
      }
    } else {
      return `${currencyName}${price}`
    }
  }
}
export const sweetAlertConfirmDialog = (title, subTitle, confirm) => {
  Swal.fire({
    title: title,
    text: subTitle,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: strings('message.cancel'),
  }).then((result) => {
    if (result.isConfirmed) {
      confirm()
    }
  })
}

export function isLocalHost(hostname = window.location.hostname) {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname)
}
