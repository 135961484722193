/* eslint-disable */
import { Button, Grid } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import ModalText from '../../../components/modal/modalTextField'
import Tooltip from '../../../components/tooltip'
import {
  BIKE,
  CARGO,
  CHARGE_STATION,
  E_BIKE,
  E_SCOOTER,
  SCOOTER,
  VIRTUAL_STATION,
} from '../../../constants/const'
import { ONLINE_STATUS } from '../../../constants/orderConst'
import { strings } from '../../../I18n'
import '../styles.scss'
import logoIcon from '../../../images/logo.png'
import blueBike from '../../../images/bikes/blueBike.png'
import greenBike from '../../../images/bikes/greenBike.png'
import lightGreenBike from '../../../images/bikes/lightGreenBike.png'
import orangeBike from '../../../images/bikes/orangeBike.png'
import redBike from '../../../images/bikes/redBike.png'
import yellowBike from '../../../images/bikes/yellowBike.png'
import blueRedBike from '../../../images/bikes/blueRedBike.png'
import greenRedBike from '../../../images/bikes/greenRedBike.png'
import lightGreenRedBike from '../../../images/bikes/lightGreenRedBike.png'
import orangeRedBike from '../../../images/bikes/orangeRedBike.png'
import yellowRedBike from '../../../images/bikes/yellowRedBike.png'
import truckBikeIcon from '../../../images/bikes/truckIcon.png'

import { useNavigate } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import ModalDropdownField from '../../../components/modal/modalDropdonField'
import { openNewTabMapMarker } from '../../../utils/utils'
import { useDispatch } from 'react-redux'
import { updateBikeStatusById } from '../../../actions/vehicle'
import './styles.scss'
import { withStyles } from '@mui/styles'

const Marker = (props) => {
  const {
    info,
    id,
    code,
    savedCity,
    isTruckTrackingBike,
    statuses,
    selectedStation,
    showInfo,
    setSelectedStation,
    zIndex = 0,
  } = props
  const [inputVisible, setInputVisible] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!selectedStation) {
      showInfo(null)
    }
  }, [selectedStation])
  useEffect(() => {
    if (!!info) {
      setSelectedStation(null)
    }
  }, [info])

  const navigate = useNavigate()
  useEffect(() => {
    if (!!info || !!selectedStation) {
      setInputVisible(true)
    } else {
      setInputVisible(false)
    }
  }, [info, selectedStation])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
      margin: '1%',
    },
  })(Button)

  const returnModalText = (label, value, onChange) => {
    if (onChange) {
      return <ModalDropdownField label={label} value={value} onChange={onChange} data={statuses} />
    } else {
      return (
        <ModalText
          styles={{ height: 35 }}
          {...{ label }}
          visible={inputVisible && (info?.id === id || selectedStation?.id === id)}
          {...{ value }}
        />
      )
    }
  }

  const isBike = useMemo(() => {
    return (
      props?.type === BIKE ||
      props?.type === E_BIKE ||
      props?.type === SCOOTER ||
      props?.type === E_SCOOTER ||
      props?.type === CARGO
    )
  }, [props.type])

  const showBikeDetails = () => {
    props.setPassedParams(props?.id)
    props.getVehicle(0, '', '', savedCity || '', 0)
    navigate(routes.DASHBOARD_BIKES)
  }

  const showRentalDetails = () => {
    props.setPassedParams(props.journeyId)
    props.getRentals(0, '', '', savedCity || '', 0)
    navigate(routes.DASHBOARD_RENTALS)
  }

  const inputs = useMemo(() => {
    switch (props?.type) {
      case E_BIKE:
      case CARGO:
      case E_SCOOTER:
      case BIKE:
        return (
          <>
            <ActionButton style={{ height: 35 }} onClick={showBikeDetails}>
              {props?.name || ''}
            </ActionButton>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {returnModalText(strings('descriptions.id'), props?.id || '')}
                {returnModalText(strings('descriptions.lockNumber'), props?.lock?.code || '')}
                {returnModalText(strings('descriptions.lockingStatus'), props?.lock?.status || '')}
                {returnModalText(
                  strings('descriptions.lockOpenStatus'),
                  strings(`descriptions.${props?.lock?.open ? 'open' : 'closed'}`)
                )}
              </Grid>
              <Grid item xs={6}>
                {returnModalText('Bike number', props?.code || '')}
                {returnModalText(
                  strings('descriptions.lockBattery') + ', %',
                  props?.lock?.battery || '-'
                )}
                {props?.type === E_BIKE &&
                  returnModalText(strings('descriptions.bikeBattery') + ', %', props?.battery || 0)}
                {returnModalText(
                  strings('descriptions.bikeStatus'),
                  props?.status || '',
                  (localStatus) => {
                    dispatch(
                      updateBikeStatusById(props?.id, {
                        status: localStatus,
                      })
                    )
                  }
                )}
              </Grid>
            </Grid>
            {props.journeyId !== null ? (
              <ActionButton style={{ height: 20 }} onClick={showRentalDetails}>
                {strings('buttons.showRental')}
              </ActionButton>
            ) : null}
          </>
        )
      case SCOOTER:
        return (
          <>
            <ActionButton style={{ height: 35 }} onClick={showBikeDetails}>
              {props?.name || ''}
            </ActionButton>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {returnModalText(strings('descriptions.id'), props?.id || '')}
                {returnModalText(strings('descriptions.lockNumber'), props?.lock?.code || '')}
                {returnModalText(strings('descriptions.lockingStatus'), props?.lock?.status || '')}
                {returnModalText(
                  strings('descriptions.lockOpenStatus'),
                  strings(`descriptions.${props?.lock?.open ? 'open' : 'closed'}`)
                )}
              </Grid>
              <Grid item xs={6}>
                {returnModalText('Bike number', props?.code || '')}
                {returnModalText(
                  strings('descriptions.lockBattery') + ', %',
                  props?.lock?.battery || '-'
                )}
                {props?.type === E_BIKE &&
                  returnModalText(strings('descriptions.bikeBattery') + ', %', props?.battery || 0)}
                {returnModalText(
                  strings('descriptions.bikeStatus'),
                  props?.status || '',
                  (localStatus) => {
                    dispatch(
                      updateBikeStatusById(props?.id, {
                        status: localStatus,
                      })
                    )
                  }
                )}
              </Grid>
            </Grid>
            {props.journeyId !== null ? (
              <ActionButton style={{ height: 20 }} onClick={showRentalDetails}>
                {strings('buttons.showRental')}
              </ActionButton>
            ) : null}
          </>
        )
      default:
        return (
          <>
            {returnModalText(strings('descriptions.stationName'), props?.name || '')}
            {returnModalText(strings('descriptions.docksNumber'), props?.dockCount || 0)}
            {returnModalText(
              strings('descriptions.bikesNumber'),
              props?.availableVehicleCount || 0
            )}
            {returnModalText(
              strings('descriptions.brokenNumber'),
              props?.notAvailableVehicleCount || 0
            )}
          </>
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVisible, props])

  const virtualStationInput = () => (
    <>
      {returnModalText(strings('descriptions.stationName'), props?.selectedStation?.name || '')}
      {props?.type !== VIRTUAL_STATION &&
        returnModalText(
          strings('descriptions.docksNumber'),
          props?.selectedStation?.dockCount || 0
        )}
      {props?.type !== VIRTUAL_STATION &&
        returnModalText(
          strings('descriptions.docksNumber'),
          props?.selectedStation?.dockCount || 0
        )}
      {returnModalText('Total number of bikes', props?.selectedStation?.vehicleCount || 0)}
      {props?.type !== VIRTUAL_STATION &&
        returnModalText(
          strings('descriptions.bikesNumber'),
          props?.selectedStation?.availableVehicleCount || 0
        )}
      {props?.type !== VIRTUAL_STATION &&
        returnModalText(
          strings('descriptions.brokenNumber'),
          props?.selectedStation?.notAvailableVehicleCount || 0
        )}
    </>
  )

  const getCurrentId = useMemo(() => {
    if (isBike) {
      return ''
    } else {
      if (props?.status !== ONLINE_STATUS && props?.type === CHARGE_STATION) {
        return 'red'
      }
      return 'blue'
    }
  }, [props, isBike])

  const buttonsContent = useMemo(
    () => (
      <div className={'markerPopupButtonsContainer'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ActionButton
              style={{ backgroundColor: '#579af8' }}
              onClick={() => {
                Swal.fire({
                  title: strings('message.confirmLock'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('buttons.confirm'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.lockBike(props.id)
                  }
                })
              }}
              variant="contained"
            >
              {strings('descriptions.lockBike')}
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#579af8' }}
              onClick={() => {
                Swal.fire({
                  title: strings('message.confirmUnlock'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('buttons.confirm'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.unlockBike(props.id)
                  }
                })
              }}
              variant="contained"
            >
              {strings('descriptions.unlockBike')}
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#579af8' }}
              onClick={() => {
                Swal.fire({
                  title: strings('message.confirmAlarm'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('buttons.confirm'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.alarmBike(props.id)
                  }
                })
              }}
              variant="contained"
            >
              {strings('descriptions.alarmBike')}
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={() =>
                props.setBikeHistory({
                  bikeId: id,
                  vehicleCode: props?.lock?.code,
                  bikeName: info?.name,
                })
              }
            >
              {strings('buttons.history')}
            </ActionButton>
          </Grid>
          <Grid item xs={6}>
            <ActionButton
              style={{ backgroundColor: '#e67f31' }}
              onClick={() => {
                Swal.fire({
                  title: strings('message.confirmLock'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('buttons.confirm'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.lockBattery(props.id)
                  }
                })
              }}
              variant="contained"
            >
              {strings('descriptions.lockBattery')}
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#e67f31' }}
              onClick={() => {
                Swal.fire({
                  title: strings('message.confirmLock'),
                  // icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: strings('buttons.confirm'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.unlockBattery(props.id)
                  }
                })
              }}
              variant="contained"
            >
              {strings('descriptions.unlockBattery')}
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#43bd66' }}
              onClick={() => {
                openNewTabMapMarker(props.info.latitude, props.info.longitude)
              }}
              variant="contained"
            >
              {'Locate Bike'}
            </ActionButton>
            {props.journeyId !== null ? (
              <ActionButton
                variant="contained"
                onClick={() => props.endRent(props.city?.id, props?.journeyId)}
              >
                {strings('buttons.endRent')}
              </ActionButton>
            ) : null}
          </Grid>
        </Grid>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputVisible, props]
  )

  const currentIcon = useMemo(() => {
    if (isBike) {
      if (isTruckTrackingBike) {
        return truckBikeIcon
      }
      if (props?.broken) {
        return redBike
      } else if (!props?.renting && props?.charging && props?.warning) {
        return blueRedBike
      } else if (!props?.renting && props?.charging) {
        return blueBike
      } else if (props?.renting && !props?.lock?.open && props?.warning) {
        return lightGreenRedBike
      } else if (props?.renting && !props?.lock?.open) {
        return lightGreenBike
      } else if (props?.renting && props?.lock?.open && props?.warning) {
        return greenRedBike
      } else if (props?.renting && props?.lock?.open) {
        return greenBike
      } else if (!props?.renting && !props?.lock?.open && props?.warning) {
        return orangeRedBike
      } else if (!props?.renting && !props?.lock?.open) {
        return orangeBike
      } else if (!props?.renting && props?.lock?.open && props?.warning) {
        return yellowRedBike
      } else if (!props?.renting && props?.lock?.open) {
        return yellowBike
      }
      return redBike
    } else {
      return logoIcon
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isBike])

  const getBikeBattery = () => {
    if (isBike) {
      return (
        <div className="battery">
          {props?.type === BIKE ? (
            <div
              className={`battery-level ${
                props?.lock?.battery < 10
                  ? 'alert'
                  : props?.lock?.battery > 10 && props?.lock?.battery < 20
                  ? 'warn'
                  : ''
              }`}
              style={{ height: `${props?.lock?.battery}%` }}
            />
          ) : (
            <div
              className={`battery-level ${
                props?.battery < 10
                  ? 'alert'
                  : props?.battery > 10 && props?.battery < 20
                  ? 'warn'
                  : ''
              }`}
              style={{ height: `${props?.battery}%` }}
            />
          )}
        </div>
      )
    }
  }
  return (
    <>
      {props.type !== VIRTUAL_STATION ? (
        <>
          <div
            className={'root'}
            style={{
              zIndex: zIndex,
            }}
          >
            {props?.type === CHARGE_STATION && (
              <Tooltip count={props?.availableVehicleCount} status={props?.status} />
            )}
            <div
              className={isBike ? 'bikesMarkerContainer' : 'markerContainer'}
              id={getCurrentId}
              onClick={props.onClick}
            >
              <img className={'icon'} src={currentIcon} alt="icon" />
              {getBikeBattery()}
            </div>
          </div>
          <div
            className={info?.id === id ? 'markerPopup' : 'emptyContent'}
            ref={info?.id === id ? props.markerRef : null}
          >
            {info?.id === id && inputs}
            {(props?.type === BIKE ||
              props?.type === E_BIKE ||
              props?.type === SCOOTER ||
              props?.type === E_SCOOTER ||
              props?.type === CARGO) &&
            info?.id === id
              ? buttonsContent
              : null}
          </div>
        </>
      ) : (
        selectedStation && (
          <div
            className={selectedStation?.id === id ? 'markerPopup' : 'emptyContent'}
            ref={selectedStation?.id === id ? props.markerRef : null}
          >
            {/*{selectedStation?.id === id && virtualStationInput}*/}
            {selectedStation?.id === id && virtualStationInput()}
          </div>
        )
      )}
    </>
  )
}

export default Marker
