/* eslint-disable */
import React from 'react'
import './styles.scss'
import { strings } from '../../../I18n'
import { connect } from 'react-redux'
import { getCityById, getPricingPlans } from '../../../actions/cities'
import Paper from '@mui/material/Paper/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TabletPaginator from '../../table/tabletPaginator'
import TableBody from '@mui/material/TableBody'
import { useStyles } from '../../../utils/styles'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import { sortedItem } from '../../../utils/utils'
import { CITY_ID, CITY_NAME, CITY_STATUS } from '../../../constants/orderConst'

function CityItem(props) {
  const { cities, citiesResult, handleChangePage, orderProps, setClickedCity } = props
  const classes = useStyles()

  const showCityInfo = (city) => props.getCityById(city.id)
  const showPricingPlan = (city) => {
    setClickedCity(city)
    props.getPricingPlans(city.id)
  }

  const titles = [
    '',
    strings('descriptions.id'),
    strings('descriptions.name'),
    strings('descriptions.color'),
    strings('descriptions.status'),
    strings('descriptions.vat').toUpperCase() + ', %',
    strings('descriptions.pricingPlans'),
  ]

  const cityColor = (city) => (
    <div className={'colorContainer'}>
      <p>{city ? city.color : strings('descriptions.color')}</p>
      <div className={'colorBox'} style={{ backgroundColor: city?.color }} />
    </div>
  )

  const edit = React.useCallback(
    (city) => (
      <div className={'edit'} onClick={() => showCityInfo(city)}>
        <div className={'cityLink'} style={{ padding: 5 }}>
          {strings('descriptions.edit')}
        </div>
      </div>
    ),
    []
  )
  const pricingPlans = React.useCallback((city) => {
    setClickedCity(city)
    return (
      <div className={'edit'} onClick={() => showPricingPlan(city)}>
        <div className={'cityLink'} style={{ padding: 5 }}>
          {strings('descriptions.show')}
        </div>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    )
  }, [])

  const sortedFields = [
    sortedItem(1, CITY_ID, orderProps),
    sortedItem(2, CITY_NAME, orderProps),
    sortedItem(4, CITY_STATUS, orderProps),
  ]

  const tabletBody = () => (
    <TableBody>
      {cities.map((city) => (
        <TableItemRow
          key={city.id}
          id={city.id}
          items={[
            edit(city),
            city.id,
            city.name,
            cityColor(city),
            city.status,
            city.vat,
            pricingPlans(city),
          ]}
        />
      ))}
    </TableBody>
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            {tabletBody()}
          </Table>
        </TableContainer>
        <TabletPaginator
          items={cities}
          pageData={citiesResult}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </div>
  )
}

const mapDispatchToProps = {
  getCityById,
  getPricingPlans,
}

export default connect(null, mapDispatchToProps)(CityItem)
