import * as types from '../constants/actionTypes'

let initialState = {
  getDocksSuccess: null,
  docksResult: null,
  docks: [],
  unlockDockSuccess: null,
  updateDockSuccess: null,
  getDockStatusesSuccess: null,
  dockStatuses: [],
};

const docks = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_DOCKS_REQUEST: {
      return {
        ...state,
        getDocksSuccess: undefined
      }
    }
    case types.GET_DOCKS_SUCCESS: {
      return {
        ...state,
        docksResult: action.result,
        docks: action.result.content,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getDocksSuccess: true
      }
    }
    case types.GET_DOCKS_FAILURE: {
      return {
        ...state,
        docks: [],
        getDocksSuccess: false
      }
    }
    case types.UNLOCK_DOCK_REQUEST: {
      return {
        ...state,
        unlockDockSuccess: null
      }
    }
    case types.UNLOCK_DOCK_SUCCESS: {
      return {
        ...state,
        unlockDockSuccess: true
      }
    }
    case types.UNLOCK_DOCK_FAILURE: {
      return {
        ...state,
        unlockDockSuccess: false
      }
    }
    case types.GET_DOCK_STATUSES_REQUEST: {
      return {
        ...state,
        getDockStatusesSuccess: undefined,
      }
    }
    case types.GET_DOCK_STATUSES_SUCCESS: {
      return {
        ...state,
        getDockStatusesSuccess: true,
        dockStatuses: action.result,
      }
    }
    case types.GET_DOCK_STATUSES_FAILURE: {
      return {
        ...state,
        getDockStatusesSuccess: false,
      }
    }
    case types.UPDATE_DOCK_REQUEST: {
      return {
        ...state,
        updateDockSuccess: undefined,
      }
    }
    case types.UPDATE_DOCK_SUCCESS: {
      const index = state.docks.findIndex(
        (item) => item.id === action.result.id
      );
      state.docks[index] = action.result;
      return {
        ...state,
        updateDockSuccess: true,
      }
    }
    case types.UPDATE_DOCK_FAILURE: {
      return {
        ...state,
        updateDockSuccess: false,
      }
    }
    case types.CLEAR_DOCKS_DATA: {
      return {
        ...state,
        getDockStatusesSuccess: null,
      }
    }
    default:
      return state
  }
};

export default docks;
