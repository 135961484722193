import * as types from '../constants/actionTypes'

let initialState = {
  getRentalsSuccess: null,
  getRentalsForExcelSuccess: null,
  rentalsStatuses: [],
  rentals: [],
  rentalsForExcel: [],
  rentalBike: null,
  rentalsResult: null,
  rentalsForExcelResult: null,
  rentalByIdSuccess: null,
  rental: null,
  finallyRentalData: null,
  rentalsStatusesSuccess: null,
  postFinishRentalSuccess: null,
  rentalVehicles: null,
  rentalVehiclesSuccess: null,
  rentalDocks: null,
  rentalDocksSuccess: null,
  rentalUsers: null,
  rentalUsersSuccess: null,
  updateRentalSuccess: null,
  getRentalBikeSuccess: null,
  getFinallyRentalData: null,

  mainUserList: null,
  mainUserListSuccess: null,

  getImageDirectoryData: null,
  getImageDirectoryDataSuccess: null,
}

const rentals = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_IMAGE_DIRECTORY_DATA: {
      return {
        ...state,
        getImageDirectoryDataSuccess: null,
        getImageDirectoryData: null,
      }
    }
    case types.GET_IMAGE_DIRECTORY_DATA_REQUEST: {
      return {
        ...state,
        getImageDirectoryDataSuccess: null,
      }
    }
    case types.GET_IMAGE_DIRECTORY_DATA_SUCCESS: {
      return {
        ...state,
        getImageDirectoryDataSuccess: true,
        getImageDirectoryData: action.result,
      }
    }
    case types.GET_IMAGE_DIRECTORY_DATA_FAILURE: {
      return {
        ...state,
        getImageDirectoryDataSuccess: false,
        rental: null,
      }
    }

    case types.GET_RENTALS_REQUEST: {
      return {
        ...state,
        getRentalsSuccess: undefined,
      }
    }
    case types.GET_RENTALS_SUCCESS: {
      return {
        ...state,
        rentals: action.result.content,
        rentalsResult: action.result,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getRentalsSuccess: true,
      }
    }
    case types.GET_RENTALS_FAILURE: {
      return {
        ...state,
        rentals: [],
        getRentalsSuccess: false,
      }
    }
    case types.GET_RENTALS_FOR_EXCEL_REQUEST: {
      return {
        ...state,
        getRentalsForExcelSuccess: undefined,
      }
    }
    case types.GET_RENTALS_FOR_EXCEL_SUCCESS: {
      return {
        ...state,
        rentalsForExcel: action.result.content,
        rentalsForExcelResult: action.result,
        currentPage: action.result.number,
        totalPages: action.result.totalPages,
        getRentalsForExcelSuccess: true,
      }
    }
    case types.GET_RENTALS_FOR_EXCEL_FAILURE: {
      return {
        ...state,
        rentalsForExcel: [],
        getRentalsForExcelSuccess: false,
      }
    }
    case types.CLEAR_RENTALS_FOR_EXCEL: {
      return {
        ...state,
        rentalsForExcel: [],
        getRentalsForExcelSuccess: null,
      }
    }

    case types.GET_MAIN_RENTAL_USER_REQUEST: {
      return {
        ...state,
        mainUserListSuccess: null,
      }
    }
    case types.GET_MAIN_RENTAL_USER_SUCCESS: {
      return {
        ...state,
        mainUserListSuccess: true,
        mainUserList: action.result,
      }
    }
    case types.GET_MAIN_RENTAL_USER_FAILURE: {
      return {
        ...state,
        mainUserListSuccess: false,
        rental: null,
      }
    }

    case types.GET_RENTAL_BY_ID_REQUEST: {
      return {
        ...state,
        rentalByIdSuccess: null,
      }
    }
    case types.GET_RENTAL_BY_ID_SUCCESS: {
      return {
        ...state,
        rentalByIdSuccess: true,
        rental: action.result,
      }
    }
    case types.GET_RENTAL_BY_ID_FAILURE: {
      return {
        ...state,
        rentalByIdSuccess: false,
        rental: null,
      }
    }
    case types.POST_FINISH_RENT_REQUEST: {
      return {
        ...state,
        postFinishRentalSuccess: null,
      }
    }
    case types.POST_FINISH_RENT_SUCCESS: {
      return {
        ...state,
        postFinishRentalSuccess: true,
      }
    }
    case types.POST_FINISH_RENT_FAILURE: {
      return {
        ...state,
        postFinishRentalSuccess: false,
      }
    }
    case types.UPDATE_RENTAL_BY_ID_REQUEST: {
      return {
        ...state,
        updateRentalSuccess: null,
      }
    }
    case types.UPDATE_RENTAL_BY_ID_SUCCESS: {
      return {
        ...state,
        updateRentalSuccess: true,
      }
    }
    case types.UPDATE_RENTAL_BY_ID_FAILURE: {
      return {
        ...state,
        updateRentalSuccess: false,
      }
    }
    case types.GET_RENTAL_DOCS_REQUEST: {
      return {
        ...state,
        rentalDocksSuccess: null,
      }
    }
    case types.GET_RENTAL_DOCS_SUCCESS: {
      return {
        ...state,
        rentalDocksSuccess: true,
        rentalDocks: action.result,
      }
    }
    case types.GET_RENTAL_DOCS_FAILURE: {
      return {
        ...state,
        rentalDocksSuccess: false,
      }
    }
    case types.GET_RENTAL_VEHICLE_REQUEST: {
      return {
        ...state,
        rentalVehiclesSuccess: null,
      }
    }
    case types.GET_RENTAL_VEHICLE_SUCCESS: {
      return {
        ...state,
        rentalVehiclesSuccess: true,
        rentalVehicles: action.result,
      }
    }
    case types.GET_RENTAL_VEHICLE_FAILURE: {
      return {
        ...state,
        rentalVehiclesSuccess: false,
      }
    }
    case types.GET_RENTAL_USER_REQUEST: {
      return {
        ...state,
        rentalUsersSuccess: null,
      }
    }
    case types.GET_RENTAL_USER_SUCCESS: {
      return {
        ...state,
        rentalUsersSuccess: true,
        rentalUsers: action.result,
      }
    }
    case types.GET_RENTAL_USER_FAILURE: {
      return {
        ...state,
        rentalUsersSuccess: false,
      }
    }
    case types.GET_RENTALS_STATUSES_REQUEST: {
      return {
        ...state,
        rentalsStatusesSuccess: undefined,
      }
    }
    case types.GET_RENTALS_STATUSES_SUCCESS: {
      return {
        ...state,
        rentalsStatuses: action.result,
        rentalsStatusesSuccess: true,
      }
    }
    case types.CLEAR_RENTAL_DATA: {
      return {
        ...state,
        rentalsStatusesSuccess: null,
        getRentalBikeSuccess: null,
        getFinallyRentalData: null,
      }
    }
    case types.GET_RENTALS_STATUSES_FAILURE: {
      return {
        ...state,
        rentalsStatusesSuccess: false,
      }
    }
    case types.CLEAR_RENTAL: {
      return {
        ...state,
        rental: null,
        rentalByIdSuccess: null,
        rentalVehicles: null,
        rentalVehiclesSuccess: null,
        rentalDocks: null,
        rentalDocksSuccess: null,
        rentalUsers: null,
        rentalUsersSuccess: null,
        updateRentalSuccess: null,
        postFinishRentalSuccess: null,
        getFinallyRentalData: null,
        rentalsStatusesSuccess: null,
      }
    }
    case types.GET_VEHICLE_BY_CODE_REQUEST: {
      return {
        ...state,
        getRentalBikeSuccess: undefined,
      }
    }
    case types.GET_VEHICLE_BY_CODE_SUCCESS: {
      return {
        ...state,
        rentalBike: action.result,
        getRentalBikeSuccess: true,
      }
    }
    case types.GET_VEHICLE_BY_CODE_FAILURE: {
      return {
        ...state,
        getRentalBikeSuccess: false,
      }
    }
    case types.GET_FINALLY_DATA_OF_RENTAL_REQUEST: {
      return {
        ...state,
        getFinallyRentalData: undefined,
      }
    }
    case types.GET_FINALLY_DATA_OF_RENTAL_SUCCESS: {
      return {
        ...state,
        finallyRentalData: action.result,
        getFinallyRentalData: true,
      }
    }
    case types.GET_FINALLY_DATA_OF_RENTAL_FAILURE: {
      return {
        ...state,
        getFinallyRentalData: false,
      }
    }
    default:
      return state
  }
}

export default rentals
