import React, { useEffect, useState } from 'react'
import GenericFilter from '../dialog/genericFilter'
import GenericMultiInputFilter from '../dialog/genericMultiInputFilter'

const useFilter = (applyFilter, filterObject) => {
  const [filterModalVisible, setFilterModalVisible] = useState(false)
  const [filterValues, setFilterValues] = useState({})

  const [filterValuesAsArray, setFilterValuesAsArray] = useState([])

  const [multipleFilter, setMultipleFilter] = useState()
  const [multipleFilterAsArray, setMultipleFilterAsArray] = useState([])

  const [multipleFilterData, setMultipleFilterData] = useState()

  const [filterData, setFilterData] = useState([])
  const toggleFilter = () => {
    setFilterModalVisible((prevState) => !prevState)
  }
  const changeSelectorValue = (filter, filterBy) => {
    if (filter) {
      setFilterValues((prevState) => ({
        ...prevState,
        [filterBy]: filter,
      }))
    } else {
      let copyFilterValues = { ...filterValues }
      delete copyFilterValues[filterBy]
      setFilterValues(copyFilterValues)
      setFilterValuesAsArray(rearrangeFilterAsArray(copyFilterValues))
    }
  }

  const internalApply = () => {
    setFilterData(filterValuesAsArray)
    setMultipleFilterData(multipleFilterAsArray)
    setFilterModalVisible(false)
  }

  useEffect(() => {
    setMultipleFilterAsArray(rearrangeFilterAsArray(multipleFilter))
  }, [multipleFilter])

  useEffect(() => {
    setFilterValuesAsArray(rearrangeFilterAsArray(filterValues))
  }, [filterValues])

  // useEffect(()=>{
  //     setFilterData(filterValuesAsArray)
  // },[filterValuesAsArray])

  const removeFilterByIndex = (index) => {
    setFilterValues((prevState) => {
      const newFilterValues = { ...prevState }
      delete newFilterValues[filterValuesAsArray[index].filter]
      return newFilterValues
    })
    setFilterData((prevState) => {
      const newFilterData = [...prevState]
      newFilterData.splice(index, 1)
      return newFilterData
    })
  }

  const removeMultipleFilterByIndex = (index) => {
    setMultipleFilter((prevState) => {
      const newMultipleFilter = { ...prevState }
      delete newMultipleFilter[multipleFilterAsArray[index].filter]
      return newMultipleFilter
    })
    setMultipleFilterData((prevState) => {
      const newMultipleFilterData = [...prevState]
      newMultipleFilterData.splice(index, 1)
      return newMultipleFilterData
    })
  }

  const rearrangeFilterAsArray = (filter) => {
    let rearrangedFilter = []
    if (filter) {
      for (const [key, value] of Object.entries(filter)) {
        rearrangedFilter.push({
          filterBy: key,
          filter: value,
        })
      }
    }
    return rearrangedFilter
  }

  const renderGenericFilter = () => {
    return (
      <GenericFilter
        filterObject={filterObject}
        agree={applyFilter}
        visible={filterModalVisible}
        closeFilter={toggleFilter}
        disagree={toggleFilter}
        hideFilter={toggleFilter}
        // reloadData={reloadData}
        changeSelectorValue={changeSelectorValue}
        filterProps={filterValuesAsArray}
      />
    )
  }

  const renderGenericMultiInputFilter = () => {
    return (
      <GenericMultiInputFilter
        multipleFilter={multipleFilter}
        setMultipleFilter={setMultipleFilter}
        filterObject={filterObject}
        agree={applyFilter}
        visible={filterModalVisible}
        closeFilter={toggleFilter}
        disagree={toggleFilter}
        hideFilter={toggleFilter}
        // reloadData={reloadData}
        changeSelectorValue={changeSelectorValue}
        filterProps={filterValuesAsArray}
      />
    )
  }

  return {
    filterModalVisible,
    toggleFilter,
    renderGenericFilter,
    filterData,
    removeFilterByIndex,
    internalApply,
    renderGenericMultiInputFilter,
    multipleFilterData,
    removeMultipleFilterByIndex,
  }
}
export default useFilter
