import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: '1rem 0',
  },
  container: {
    minWidth: '20%',
    maxWidth: '400px',
    backgroundColor: 'white',
    overflow: 'scroll',
    minHeight: '10%',
    maxHeight: '70%',
    paddingTop: '16px',
    paddingRight: '32px',
    paddingBottom: '24px',
    paddingLeft: '32px',
  },
  cityNameStyle: {
    fontWeight: 'bold',
  },
  dividerStyle: {
    background: '#303f9f',
  },
  planType: {
    textTransform: 'uppercase',
  },
  planRuleStyle: {
    margin: '0.5rem 0',
  },
  planRuleLableStyle: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  planRuleLableImageStyle: {
    paddingRight: '1rem',
  },
  buttonStyle: {
    fontSize: '0.77rem',
    fontWeight: 'bold',
  },
  priceTextStyle: {
    fontSize: '1.3rem',
  },
  descriptionTextStyle: {
    whiteSpace: 'pre-line',
  },
}))
