import * as types from "../constants/actionTypes";

let initialState = {
	user: null,
	token: null,
	errorMessage: null,
	city: null,
	adminDetails: null,
	whiteLabelCode: null,
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case types.SAVE_USER: {
			return {
				...state,
				user: action.user,
				city: action.user.user.email,
			};
		}
		case types.REMOVE_USER: {
			return {
				...state,
				user: null,
				token: null,
				city: null,
			};
		}
		case types.SAVE_TOKEN: {
			return {
				...state,
				token: action.token,
			};
		}
		case types.EXPIRED_SESSION: {
			return {
				...initialState,
			};
		}
		case types.ERROR_MESSAGE: {
			return {
				...state,
				errorMessage: action.description,
			};
		}
		case types.CLEAR_ERROR: {
			return {
				...state,
				errorMessage: null,
			};
		}
		case types.SET_ADMIN_DETAILS_REQUEST: {
			return {
				...state,
				adminDetails: null,
				whitelabelCode: null,
			};
		}
		case types.SET_ADMIN_DETAILS_SUCCESS: {
			return {
				...state,
				adminDetails: action.result,
				whiteLabelCode: action.result.whiteLabelCode,
			};
		}
		case types.SET_ADMIN_DETAILS_FAILURE: {
			return {
				...state,
			};
		}
		default:
			return state;
	}
};

export default auth;
