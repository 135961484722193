/* eslint-disable */
import React from 'react'
import './styles.scss'
import { strings } from '../../../I18n'
import { connect } from 'react-redux'
import Moment from 'moment'
import { sortedItem, stringFromValue } from '../../../utils/utils'
import { getBikeId, getBikeStatus, lockBattery, unlockBattery } from '../../../actions/vehicle'
import { getUsers } from '../../../actions/users'
import { setPassedParams } from '../../../actions/screen'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TabletPaginator from '../../table/tabletPaginator'
import { useStyles } from '../../../utils/styles'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import {
  BIKE_BATTERY,
  BIKE_BRAND,
  BIKE_CITY,
  BIKE_CODE,
  BIKE_DOCK_NUMBER,
  BIKE_LAST_SEEN,
  BIKE_LOCK_BATTERY,
  BIKE_LOCK_NUMBER,
  BIKE_LOCK_STATUS,
  BIKE_NAME,
  BIKE_STATION,
  BIKE_STATUS,
  BIKE_TYPE,
  BIKE_USER_NAME,
} from '../../../constants/orderConst'
import { getStations } from '../../../actions/stations'
import { getRentals } from '../../../actions/rentals'
import { BikeItemActions } from './BikeItemActions'
import { BikeItemStatus } from './BikeItemStatus'
import { E_BIKE } from '../../../constants/const'

function BikeItemV2(props) {
  const { bikes, bikesResult, handleChangePage, orderProps, onDelete, selectMenu } = props
  const classes = useStyles()
  const hasExternalId = bikes.some((b) => !!b?.lock?.externalId)
  const hasAdditionalCode = bikes.some((b) => !!b?.lock?.additionalCode)

  const momentDate = (date) => (date ? Moment(date + 'Z').format('DD.MM.YYYY HH:mm') : '-')

  const showBikeInfo = (bike) => {
    props.getBikeId(bike.id)
    props.getBikeStatus()
  }

  const titles = [
    '',
    strings('descriptions.bikeName'),
    strings('descriptions.bikeCode'),
    strings('descriptions.lockNumber'),
    strings('descriptions.dockNumber'),
    strings('descriptions.lockBattery'),
    strings('descriptions.bikeBattery'),
    strings('descriptions.type'),
    strings('descriptions.cityProject'),
    strings('descriptions.station'),
    strings('descriptions.lastSeenOnline'),
    strings('descriptions.status'),
    strings('descriptions.lockStatus'),
    strings('descriptions.lock'),
    strings('descriptions.brand'),
  ]

  if (hasExternalId) {
    titles.push(strings('descriptions.externalId'))
  }

  if (hasAdditionalCode) {
    titles.push(strings('descriptions.additionalCode'))
  }

  const sortedFields = [
    sortedItem(2, BIKE_USER_NAME, orderProps),
    sortedItem(3, BIKE_NAME, orderProps),
    sortedItem(4, BIKE_CODE, orderProps),
    sortedItem(5, BIKE_LOCK_NUMBER, orderProps),
    sortedItem(6, BIKE_DOCK_NUMBER, orderProps),
    sortedItem(7, BIKE_LOCK_BATTERY, orderProps),
    sortedItem(8, BIKE_BATTERY, orderProps),
    sortedItem(9, BIKE_TYPE, orderProps),
    sortedItem(10, BIKE_CITY, orderProps),
    sortedItem(11, BIKE_STATION, orderProps),
    sortedItem(12, BIKE_LAST_SEEN, orderProps),
    sortedItem(13, BIKE_STATUS, orderProps),
    sortedItem(14, BIKE_LOCK_STATUS, orderProps),
    sortedItem(16, BIKE_BRAND, orderProps),
  ]

  const lockStatus = (value) => {
    switch (value) {
      case true:
        return strings('descriptions.unlocked')
      default:
        return strings('descriptions.locked')
    }
  }

  const getBikeUpdateBody = (bike, status) => {
    const body = {
      name: bike?.name,
      status,
      stationId: bike?.station?.id,
      linkedStationId: bike?.linkedStation?.id,
      latitude: bike?.latitude,
      longitude: bike?.longitude,
      cityId: bike.city?.id,
      code: bike?.code,
      type: bike.type,
    }
    if (bike?.type === E_BIKE || bike?.battery !== null) {
      body.battery = bike?.battery
    }

    return body
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            <TableBody>
              {bikes.map((bike) => {
                const items = [
                  <BikeItemActions bike={bike} onEdit={showBikeInfo} onDelete={onDelete} />,
                  stringFromValue(bike?.name),
                  stringFromValue(bike?.code),
                  stringFromValue(bike.lock?.code),
                  stringFromValue(bike.dock?.code),
                  stringFromValue(bike?.lock?.battery),
                  stringFromValue(bike?.battery),
                  bike.type,
                  stringFromValue(bike.city?.name),
                  stringFromValue(bike.station?.name),
                  momentDate(bike?.lastSeenOnline),
                  <BikeItemStatus
                    status={bike.status}
                    onChange={(status) => selectMenu(bike, getBikeUpdateBody(bike, status))}
                  />,
                  stringFromValue(bike.lock?.status.replace(/_/g, ' ')),
                  lockStatus(bike.lock?.open),
                  bike.brand,
                ]

                if (hasExternalId) {
                  items.push(bike?.lock?.externalId)
                }

                if (hasAdditionalCode) {
                  items.push(bike?.lock?.additionalCode)
                }

                return <TableItemRow key={bike.id} id={bike.id} items={items} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TabletPaginator items={bikes} pageData={bikesResult} handleChangePage={handleChangePage} />
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ cities: { savedCity } }) => ({
  savedCity,
})

const mapDispatchToProps = {
  getBikeId,
  getBikeStatus,
  getUsers,
  setPassedParams,
  getRentals,
  getStations,
  lockBattery,
  unlockBattery,
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeItemV2)
