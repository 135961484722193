/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import ModalContainer from '../modal'
import { strings } from '../../../I18n'
import ModalText from '../modalTextField'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { useInput, useShowToast } from '../../../hooks'
import MenuItem from '@mui/material/MenuItem'
import { useStyles } from '../../../utils/styles'
import { limitZoneStatusConst, VIRTUAL_STATION } from '../../../constants/const'
import { TAB_STATE } from '../../../containers/stationContainer'
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api'
import { MAP_KEY } from '../../../configs/settings'
import TextField from '@mui/material/TextField/TextField'

const scaleCoordinates = (coords, factor) => {
  const center = {
    lat: (Math.max(...coords.map((c) => c.lat)) + Math.min(...coords.map((c) => c.lat))) / 2,
    lng: (Math.max(...coords.map((c) => c.lng)) + Math.min(...coords.map((c) => c.lng))) / 2,
  }

  return coords.map((coord) => ({
    lat: center.lat + (coord.lat - center.lat) * factor,
    lng: center.lng + (coord.lng - center.lng) * factor,
  }))
}

function StationModal(props) {
  const showToast = useShowToast()
  const { station, clearStation, updateStation, visible, cityNameList, statuses = [] } = props
  const classes = useStyles()
  const [stationId, setStationId] = React.useState('')
  const [stationCode, setStationCode] = React.useState('')
  const [coords, setCoords] = React.useState([])
  const [bufferPolygon, setBufferPolygon] = React.useState([])
  const [isLinkedStation, setIsLinkedStation] = React.useState(false)
  const [hasBufferStation, setHasBufferStation] = React.useState(false)
  const [bufferCoefficient, setBufferCoefficient] = React.useState(1.2)
  const [coordsFromCamera, setCoordsFromCamera] = React.useState({
    lat: 59.912438985282066,
    lng: 10.74462890624996,
  })

  const [currentZoom, setZoom] = useState(16)
  const [mapInstance, setMapInstance] = useState(null)

  // const zoneTypes = ['STATION', 'SPEED_LIMITING_ZONES']
  const zoneTypes = ['STATION']

  const selectedZone = props.selectedTab === TAB_STATE.STATIONS ? 'STATION' : 'SPEED_LIMITING_ZONES'

  const zoneType = useInput()
  const speedLimit = useInput()
  const description = useInput()
  const name = useInput('')
  const address = useInput('')
  const city = useInput('')
  const latitude = useInput(0)
  const longitude = useInput(0)
  const status = useInput()
  const type = useInput()
  const polygonRef = useRef(null)
  const bufferPolygonRef = useRef(null)
  // const coordsFromCamera = useRef({})

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
  })

  const initCoords = (lat, lng) => {
    setCoords([
      { lat: lat + 0.0007, lng: lng - 0.0011 },
      { lat: lat + 0.0007, lng: lng + 0.0011 },
      { lat: lat - 0.0007, lng: lng + 0.0011 },
      { lat: lat - 0.0007, lng: lng - 0.0011 },
    ])
    setBufferPolygon([
      { lat: lat + 0.0009, lng: lng - 0.0013 },
      { lat: lat + 0.0009, lng: lng + 0.0013 },
      { lat: lat - 0.0009, lng: lng + 0.0013 },
      { lat: lat - 0.0009, lng: lng - 0.0013 },
    ])
  }

  const getCurrentCoords = () => {
    return polygonRef.current
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() }
      })
  }

  const refreshZone = () => {
    setBufferPolygon(scaleCoordinates(getCurrentCoords(), bufferCoefficient))
  }

  const refreshStationGeo = (lat, lng) => {
    setCoords([
      { lat: lat + 0.0007, lng: lng - 0.0011 },
      { lat: lat + 0.0007, lng: lng + 0.0011 },
      { lat: lat - 0.0007, lng: lng + 0.0011 },
      { lat: lat - 0.0007, lng: lng - 0.0011 },
    ])
  }

  const isStationSelected = zoneType.value === 'STATION'

  const isVirtualStationOrLimitZone =
    type.value === VIRTUAL_STATION || selectedZone === 'SPEED_LIMITING_ZONES'

  const initValue = () => {
    type.initValue(VIRTUAL_STATION)
    setStationId(station?.id)
    setStationCode(station?.code)
    name.initValue(station?.name)
    address.initValue(station?.address)
    status.initValue(station?.status)
    city.initValue(station?.city?.id)
    setIsLinkedStation(station?.linkedStation)
    zoneType.initValue(selectedZone)
    speedLimit.initValue(0)

    const stationCoords = { lat: station?.latitude, lng: station?.longitude }
    if (!station && !props.speedLimitZone) {
      initCoords(coordsFromCamera?.lat, coordsFromCamera?.lng)
      type.initValue(VIRTUAL_STATION)
      name.initValue('')
      city.initValue('')
      address.initValue('')
      status.initValue('')
      setIsLinkedStation(false)
      setHasBufferStation(false)
    } else {
      if (isStationSelected && station) {
        if (props.isEdit) {
          setCoordsFromCamera(stationCoords)
          type.initValue(station?.type)
          setTimeout(() => {
            latitude.initValue(station?.latitude)
            longitude.initValue(station?.longitude)
          }, 300)
          station?.geometry
            ? setCoords(
                station.geometry.map((cordArr) => ({
                  lat: cordArr[0],
                  lng: cordArr[1],
                }))
              )
            : initCoords(stationCoords.lat, stationCoords.lng)

          if (station?.parkingArea) {
            setBufferPolygon(
              station.parkingArea.map((cordArr) => ({
                lat: cordArr[0],
                lng: cordArr[1],
              }))
            )
          }

          setHasBufferStation(!!station?.parkingArea)
        }
      } else if (!isStationSelected && props?.speedLimitZone) {
        setCoordsFromCamera({
          lat: props?.speedLimitZone?.geometry
            ? props?.speedLimitZone?.geometry[0][0]
            : 59.912438985282066,
          lng: props?.speedLimitZone?.geometry
            ? props?.speedLimitZone?.geometry[0][1]
            : 10.74462890624996,
        })
        setStationId(props?.speedLimitZone?.id)
        setStationCode(props?.speedLimitZone?.code)
        name.initValue(props?.speedLimitZone?.name)
        address.initValue(props?.speedLimitZone?.address)
        status.initValue(props?.speedLimitZone?.status)
        city.initValue(props?.speedLimitZone?.cityId)
        type.initValue(props?.speedLimitZone?.type)
        description.initValue(props?.speedLimitZone?.description)
        speedLimit.initValue(props?.speedLimitZone?.maxSpeed)
        props?.speedLimitZone?.geometry &&
          setCoords(
            props?.speedLimitZone.geometry.map((cordArr) => ({
              lat: cordArr[0],
              lng: cordArr[1],
            }))
          )
      }
    }
    if (!props.isEdit) {
      latitude.initValue(59.912438985282066)
      longitude.initValue(10.74462890624996)
      initCoords(coordsFromCamera?.lat, coordsFromCamera?.lng)
      type.initValue(VIRTUAL_STATION)
      // name.initValue('')
      setStationId('')
      setStationCode('')
      name.initValue('')
      city.initValue('')
      address.initValue('')
      status.initValue('')
      // status.initValue('')
      setIsLinkedStation(false)
      setHasBufferStation(false)
      description.initValue('')
      speedLimit.initValue(0)
    }
  }

  useEffect(() => {
    const cityById = props?.cityNameList?.filter((item) => item.id === city.value).pop()
    if (cityById) {
      latitude.initValue(cityById.latitude ? cityById.latitude : 0)
      longitude.initValue(cityById.longitude ? cityById.longitude : 0)
    }
    if (!props.isEdit) {
      setCoordsFromCamera((prevState) => ({
        lat: cityById?.latitude || prevState.lat,
        lng: cityById?.longitude || prevState.lng,
      }))
    }
  }, [city.value])

  React.useEffect(() => {
    initValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, props.selectedTab])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const requiredFieldsForStations = [
    {
      name: 'name',
      value: name.value,
      required: true,
    },
    {
      name: 'description',
      value: address.value,
      required: isStationSelected,
    },
    {
      name: 'description',
      value: description.value,
      required: !isStationSelected,
    },
    {
      name: 'latitude',
      value: latitude.value,
      required: isStationSelected,
    },
    {
      name: 'longitude',
      value: longitude.value,
      required: isStationSelected,
    },
    {
      name: 'status',
      value: status.value,
      required: !isStationSelected,
    },
    {
      name: 'city',
      value: city.value,
      required: true,
    },
    {
      name: 'speedLimit',
      value: speedLimit.value,
      required: !isStationSelected,
    },
  ]

  const save = () => {
    let geometry = null
    let bufferZone = null
    //validate if one of them fails showToast and return
    for (let i = 0; i < requiredFieldsForStations.length; i++) {
      const field = requiredFieldsForStations[i]
      if (field.required && !field.value) {
        showToast(`${field.name} is required`, 'error')
        return
      }
    }

    if (isVirtualStationOrLimitZone) {
      const polygonValues = getCurrentCoords()

      let bufferValues = null
      if (hasBufferStation) {
        bufferValues = bufferPolygonRef?.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() }
          })
        bufferZone = [...bufferValues, bufferValues[0]].map((item) => [item.lat, item.lng])
      }

      geometry = [...polygonValues, polygonValues[0]].map((item) => [item.lat, item.lng])
    }

    //Random code for station
    const code = Math.floor(Math.random() * 1000000000)

    const body = {
      name: name.value,
      type: type.value,
      status: status.value,
      address: address.value,
      description: description.value,
      latitude: latitude.value,
      longitude: longitude.value,
      cityId: city.value,
      code,
      linkedStation: isLinkedStation,
      geometry,
      maxSpeed: speedLimit.value,
      parkingArea: hasBufferStation ? bufferZone : null,
    }
    if (isStationSelected) {
      props.isEdit ? updateStation(stationId, body) : updateStation(body)
    } else {
      props.isEdit ? props.updateSpeedLimitZone(stationId, body) : props.updateSpeedLimitZone(body)
    }
  }

  const inputContainer = (
    <div>
      <ModalText
        defaultValue={selectedZone}
        label={'Zone Type'}
        disabled={props.isEdit}
        content={zoneTypes.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
        {...zoneType}
      />
      <ModalText label={strings('descriptions.id')} value={stationId} />
      <ModalText label={strings('descriptions.code')} value={stationCode} />
      <ModalText label={strings('descriptions.name')} {...name} />
      {isStationSelected && <ModalText label={strings('descriptions.address')} {...address} />}
      {!isStationSelected && (
        <>
          <ModalText type={'number'} label={strings('descriptions.speedLimit')} {...speedLimit} />
          <ModalText label={strings('descriptions.description')} {...description} />
        </>
      )}
      {isStationSelected && (
        <>
          <ModalText type={'number'} label={strings('descriptions.latitude')} {...latitude} />
          <ModalText type={'number'} label={strings('descriptions.longitude')} {...longitude} />
        </>
      )}
    </div>
  )

  const buttonContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={save}
      >
        {strings('descriptions.save')}
      </ActionButton>
      <ActionButton
        className={'stationModalButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={clearStation}
      >
        {strings('descriptions.discard')}
      </ActionButton>

      {isVirtualStationOrLimitZone && (
        <ActionButton
          className={'stationModalButton'}
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => refreshStationGeo(coordsFromCamera.lat, coordsFromCamera.lng)}
        >
          {strings('descriptions.refreshStation')}
        </ActionButton>
      )}

      {isVirtualStationOrLimitZone && hasBufferStation && (
        <ActionButton
          className={'stationModalButton'}
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => refreshZone()}
        >
          {strings('descriptions.refreshBufferZone')}
        </ActionButton>
      )}
    </div>
  )
  const getStatuses = () => {
    if (zoneType.value === 'STATION') {
      return statuses
    } else {
      return limitZoneStatusConst
    }
  }

  const dropContent = (
    <div>
      <ModalText
        defaultValue={station?.status}
        label={strings('descriptions.status')}
        content={getStatuses().map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
        {...status}
      />

      <ModalText
        defaultValue={station?.city?.id}
        label={strings('descriptions.cityName')}
        content={cityNameList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
        {...city}
      />
      {zoneType.value === 'STATION' && (
        <ModalText
          defaultValue={station?.type}
          label={strings('descriptions.type')}
          value={type.value}
        />
      )}

      {zoneType.value === 'STATION' && (
        <div className="checkbox-stations">
          <div className="checkbox-input">
            <div className="title-checkbox">{strings('descriptions.linkedStation')}</div>
            <input
              type="checkbox"
              className={'checkbox'}
              checked={isLinkedStation || false}
              onChange={() => setIsLinkedStation(!isLinkedStation)}
            />
          </div>
        </div>
      )}
      {zoneType.value === 'STATION' && (
        <div className="checkbox-stations">
          <div className="checkbox-input">
            <div className="title-checkbox">{strings('descriptions.bufferStation')}</div>
            <input
              type="checkbox"
              className={'checkbox'}
              checked={hasBufferStation || false}
              onChange={() => {
                setHasBufferStation((prevState) => !prevState)
                refreshZone()
              }}
            />
          </div>
          <TextField
            size="small"
            type="number"
            label="Buffer coefficient"
            value={bufferCoefficient}
            onChange={(e) => setBufferCoefficient(e.target.valueAsNumber)}
            sx={{ width: 150 }}
          />
        </div>
      )}
    </div>
  )

  const mapContainerStyle = {
    height: '100%',
    width: '100%',
  }

  const onMapLoaded = (lMap) => {
    setMapInstance(lMap)
  }

  const onPolygonLoad = (polygon) => {
    polygonRef.current = polygon
  }
  const onBufferPolygonLoad = (polygon) => {
    bufferPolygonRef.current = polygon
  }

  const visiblePolygonOptions = {
    strokeColor: 'rgb(47,146,28)',
    strokeWeight: 2,
    fillColor: 'rgba(68,255,44,0.25)',
    zIndex: 1,
  }
  const bufferPolygonOptions = {
    strokeColor: 'rgb(0,0,0)',
    strokeWeight: 2,
    fillColor: 'rgb(255,255,255)',
    fillColorOpacity: 0.1,
    zIndex: 1,
  }

  const showMapContainer = isVirtualStationOrLimitZone && (
    <div style={{ height: '100%', width: '100%' }}>
      {loadError ? (
        <div>
          <h1>{strings('descriptions.mpNotLoaded')}</h1>
        </div>
      ) : !isLoaded ? (
        <div>
          <h1>{strings('descriptions.loading')}</h1>
        </div>
      ) : (
        <GoogleMap
          id="marker-example"
          mapContainerStyle={mapContainerStyle}
          zoom={currentZoom}
          center={coordsFromCamera}
          onLoad={onMapLoaded}
          onUnmount={(map) => {
            setMapInstance(null)
          }}
        >
          <Marker
            draggable
            onDragEnd={(e) => {
              const { lat, lng } = e.latLng.toJSON()

              latitude.initValue(lat)
              longitude.initValue(lng)
              setCoordsFromCamera({ lat, lng })
            }}
            position={{ lat: coordsFromCamera.lat, lng: coordsFromCamera.lng }}
          />

          <Polygon
            draggable={true}
            editable={true}
            onLoad={onPolygonLoad}
            paths={coords && coords}
            options={visiblePolygonOptions}
          />
          {hasBufferStation && (
            <Polygon
              draggable={true}
              editable={true}
              onLoad={onBufferPolygonLoad}
              paths={bufferPolygon && bufferPolygon}
              options={bufferPolygonOptions}
            />
          )}
        </GoogleMap>
      )}
    </div>
  )

  return (
    <ModalContainer
      className={isVirtualStationOrLimitZone ? classes.mapModal : classes.modal}
      visible={visible}
      closeAction={() => {
        clearStation()
        setCoords([])
      }}
      body={
        <>
          <div className={'stationModalContainer'} style={{ height: window.innerHeight * 0.7 }}>
            <div
              className={isVirtualStationOrLimitZone ? 'stationFieldsHalf' : 'stationFieldsFull'}
            >
              {inputContainer}
              {dropContent}
            </div>
            {isVirtualStationOrLimitZone && (
              <div className={'mapContainer'}>{showMapContainer}</div>
            )}
          </div>
          {buttonContainer()}
        </>
      }
    />
  )
}

export default StationModal
