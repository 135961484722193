import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useBikesResult = () => {
  const [bikesResult, setBikesResult] = useState<any>(null)
  const savedFilters = useSelector((state: any) => state.vehicle.bikesResult)

  useEffect(() => setBikesResult(savedFilters || null), [savedFilters])

  return { bikesResult }
}
