import { apiClientJSONAssets } from '../apiClient'
import { AssetProject, AssetProjectDto } from '../../domain/asset'

export const getAssetProjects = (
  cityId: number,
  onlyActive: boolean = false
): Promise<AssetProject[]> => {
  return apiClientJSONAssets
    .get(`/project`, {
      params: {
        cityId,
        minEndDate: onlyActive ? new Date() : undefined,
      },
    })
    .then((r) => r.data)
    .then((items: any[]) =>
      items.map<AssetProject>((i) => ({
        ...i,
        startDate: new Date(i.startDate),
        endDate: new Date(i.endDate),
      }))
    )
}

export const createAssetProject = (dto: AssetProjectDto): Promise<void> => {
  return apiClientJSONAssets.post(`/project`, dto)
}

export const updateAssetProject = (projectId: number, dto: AssetProjectDto): Promise<void> => {
  return apiClientJSONAssets.patch(`/project/${projectId}`, dto)
}

export const deleteAssetProject = (projectId: number): Promise<void> => {
  return apiClientJSONAssets.delete(`/project/${projectId}`)
}
