import { BASE_URL } from '../configs/urls'
import { PATCH_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import { ERROR_MESSAGE, EXPIRED_SESSION } from '../constants/actionTypes'
import { OK_STATUS_201 } from '../constants/const'
import { getToken } from '../utils/firebase'
import axios from 'axios'
import { getLangToDisplay } from '../I18n'
import * as Sentry from '@sentry/react'

axios.defaults.baseURL = `${BASE_URL}`
/*Convert it to axios because sometime from backend we get null or blank response
and fetch is not able to convert them into JSON*/
export async function getFetchAction(endpoint, method, body, options) {
  let updatedToken = await getToken()
  let headers = {
    Authorization: `Bearer ${updatedToken}`,
    'Content-type': 'application/json',
  }
  if (updatedToken) {
    if (method === POST_METHOD || method === PUT_METHOD || method === PATCH_METHOD) {
      return axios({
        url: `${endpoint}${endpoint.indexOf('?') === -1 ? '?' : '&'}lang=${getLangToDisplay()}`,
        method: method,
        headers: headers,
        data: JSON.stringify(body),
        timeout: 300000,
        timeoutErrorMessage: 'Request Timeout',
        ...options,
      })
    }

    return axios({
      url: `${endpoint}${endpoint.indexOf('?') === -1 ? '?' : '&'}lang=${getLangToDisplay()}`,
      method: method,
      headers: headers,
      ...options,
    })
  }
}
export function callApi(endpoint, method, body) {
  return Promise.race([getFetchAction(endpoint, method, body)]).then((response) => {
    if (response?.status === OK_STATUS_201) {
      return { json: OK_STATUS_201, response }
    }
    return response?.data
  })
}

export const apiMiddleware = (store) => (next) => (action) => {
  let { endpoint } = action
  const { types, method, body } = action
  if (!endpoint && !method && !types && action.type) {
    return next(action)
  }

  const state = store.getState()

  if (typeof endpoint === 'function') {
    endpoint = endpoint(state)
  }

  if (!method) {
    throw new Error('method is not exist')
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const [requestType, successType, failureType] = types
  let resultFailureType = failureType
  next(Object.assign({}, { type: requestType }))

  return callApi(endpoint, method, body).then(
    (response) => {
      const result = response
      next(Object.assign({}, { type: successType, result }))
      return result
    },

    // {
    //   tags: {
    //     page: window.location.pathname,
    //   },
    //   extra: {
    //     apiEndpoint: endpoint,
    //     error: error,
    //   },
    // }
    (error) => {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName(error.response?.data?.message || 'API Error')
        scope.setTag('page', window.location.pathname)
        scope.setExtra('apiEndpoint', endpoint)
        scope.setExtra('error', error)
        return scope
      })
      switch (error.response?.status) {
        case 401:
          resultFailureType = EXPIRED_SESSION
          break
        default:
          break
      }
      next(
        Object.assign(
          {},
          {
            type: resultFailureType,
            status: 'ERROR',
            errorCode: error.response?.data?.statusCode,
            description: error.response?.data?.message,
          }
        )
      )
      next(
        Object.assign(
          {},
          {
            type: ERROR_MESSAGE,
            description: error.response?.data?.message,
          }
        )
      )
      throw error
    }
  )
}
