import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AssetAsset } from '../../../domain/asset'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { getAssetAssets } from '../../../apis/asset/asset-asset-apis'
import { AssetAssetsAddEdit } from './components/AssetAssetsAddEdit'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import { AssetAssetsDelete } from './components/AssetAssetsDelete'

export const AssetAssets = () => {
  const cityId = useSelector((s: any) => s?.auth?.adminDetails?.cityId)
  const [assets, setAssets] = useState<AssetAsset[]>([])
  const [isAddEditOpen, setIsAddEditOpen] = useState(false)
  const [assetToEdit, setAssetToEdit] = useState<AssetAsset | null>(null)
  const [assetToDelete, setAssetToDelete] = useState<AssetAsset | null>(null)

  const fetchAndSetAssets = () => {
    getAssetAssets().then((assets) => setAssets(assets))
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetAssets()
    }

    setIsAddEditOpen(false)
    setAssetToEdit(null)
    setAssetToDelete(null)
  }

  useEffect(fetchAndSetAssets, [cityId])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography variant="h4">Assets</Typography>

        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddEditOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Id</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Code</strong>
            </TableCell>
            <TableCell sx={{ width: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((a) => (
            <TableRow key={a.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{a.id}</TableCell>
              <TableCell>{a.name}</TableCell>
              <TableCell>{a.code}</TableCell>
              <TableCell>
                <ButtonGroup size="small">
                  <IconButton color="default">
                    <InfoIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => setAssetToEdit(a)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => setAssetToDelete(a)}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {isAddEditOpen && <AssetAssetsAddEdit onClose={handleClose} />}
      {assetToEdit && <AssetAssetsAddEdit asset={assetToEdit} onClose={handleClose} />}
      {assetToDelete && <AssetAssetsDelete asset={assetToDelete} onClose={handleClose} />}
    </Box>
  )
}
