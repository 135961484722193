import React from 'react'
import { FormControl, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const TextAreaController = ({
  name,
  label,
  required,
  multiline,
  extraRules,
  muiProps,
  styles,
  control,
}) => {
  const scontrol = useFormContext()
  const dcontrol = control || scontrol
  return (
    <FormControl style={{ marginBottom: 10, marginTop: 10, ...styles }}>
      <Controller
        name={name}
        control={dcontrol}
        rules={{
          required: { value: !!required, message: required ? `${name} is required` : '' },
          ...extraRules,
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <TextField
            {...field}
            {...muiProps}
            inputRef={ref}
            id={name}
            autoComplete={name}
            multiline={!!multiline}
            variant="outlined"
            fullWidth={true}
            error={!!error}
            helperText={error ? error.message : null}
            label={label}
            minRows={4}
            margin={'dense'}
          />
        )}
      />
    </FormControl>
  )
}

export default TextAreaController
