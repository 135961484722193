import React, { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { getPricingPlanDomains } from '../../apis/pricing-plan/pricing-plan-apis'
import { PricingPlanDomain } from '../../domain/pricing-plan'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { PricingPlanDomainAdd } from './PricingPlanDomainAdd'
import { useParams } from 'react-router-dom'
import { PricingPlanDomainDelete } from './PricingPlanDomainDelete'

export const PricingPlanDomains = () => {
  const { id } = useParams()
  const planId = parseInt(id || '0')
  const [isAddOpen, setIsAddOpen] = React.useState(false)
  const [domains, setDomains] = useState<PricingPlanDomain[]>([])
  const [domainToDelete, setDomainToDelete] = useState<PricingPlanDomain | null>(null)

  const fetchAndSetDomains = () => {
    getPricingPlanDomains(planId).then((d) => setDomains(d))
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetDomains()
    }

    setIsAddOpen(false)
    setDomainToDelete(null)
  }

  useEffect(() => {
    getPricingPlanDomains(planId).then((d) => setDomains(d))
  }, [])

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4">Pricing Plan Domains</Typography>
        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ fontWeight: 700 }}>
            <TableRow>
              <TableCell>
                <strong>Id</strong>
              </TableCell>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ width: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((d) => (
              <TableRow key={d.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{d.id}</TableCell>
                <TableCell>{d.type}</TableCell>
                <TableCell>{d.email}</TableCell>
                <TableCell>{d.phone}</TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <IconButton color="error" onClick={() => setDomainToDelete(d)}>
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {isAddOpen && <PricingPlanDomainAdd planId={planId} onClose={() => handleClose(true)} />}
      {!!domainToDelete && (
        <PricingPlanDomainDelete domain={domainToDelete} onClose={() => handleClose(true)} />
      )}
    </Box>
  )
}
