import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  modalTitle: {
    fontWeight: 'bold',
  },
  dividerStyle: {
    background: '#303f9f',
  },
  card: {
    margin: '1rem 0',
  },
  buttonStyle: {
    fontSize: '0.77rem',
    fontWeight: 'bold',
  },
}))
