import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { getAssetProjects } from '../../../apis/asset/asset-project-apis'
import { AssetProject } from '../../../domain/asset'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AssetProjectsAddEdit } from './components/AssetProjectsAddEdit'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { AssetProjectsDelete } from './components/AssetProjectsDelete'

export const AssetProjects = () => {
  const cityId = useSelector((s: any) => s?.auth?.adminDetails?.cityId)
  const [projects, setProjects] = useState<AssetProject[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [projectToEdit, setProjectToEdit] = useState<AssetProject | null>(null)
  const [projectToDelete, setProjectToDelete] = useState<AssetProject | null>(null)

  const fetchAndSetProjects = () => {
    getAssetProjects(cityId).then((projects) => setProjects(projects))
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetProjects()
    }

    setIsAddOpen(false)
    setProjectToEdit(null)
    setProjectToDelete(null)
  }

  useEffect(fetchAndSetProjects, [cityId])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography variant="h4">Projects</Typography>

        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            <TableCell>
              <strong>Id</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Start Date</strong>
            </TableCell>
            <TableCell align="right">
              <strong>End Date</strong>
            </TableCell>
            <TableCell sx={{ width: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((p) => (
            <TableRow key={p.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{p.id}</TableCell>
              <TableCell>{p.name}</TableCell>
              <TableCell align="right">{p.startDate.toLocaleString()}</TableCell>
              <TableCell align="right">{p.endDate.toLocaleString()}</TableCell>
              <TableCell>
                <ButtonGroup size="small">
                  <IconButton color="default">
                    <InfoIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => setProjectToEdit(p)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => setProjectToDelete(p)}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {isAddOpen && <AssetProjectsAddEdit cityId={cityId} onClose={handleClose} />}
      {!!projectToEdit && (
        <AssetProjectsAddEdit project={projectToEdit} cityId={cityId} onClose={handleClose} />
      )}
      {!!projectToDelete && <AssetProjectsDelete project={projectToDelete} onClose={handleClose} />}
    </Box>
  )
}
