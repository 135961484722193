import { PricingPlanDomain, PricingPlanDomainsCreateDto } from '../../domain/pricing-plan'
import { apiClient } from '../apiClient'

export const getPricingPlanDomains = (planId: number): Promise<PricingPlanDomain[]> => {
  return apiClient
    .get(`/payment-service/dashboard/pricing/plan/${planId}/domains`)
    .then((r) => r.data)
}

export const createPricingPlanDomains = (
  planId: number,
  dto: PricingPlanDomainsCreateDto
): Promise<PricingPlanDomain[]> => {
  return apiClient
    .post(`/payment-service/dashboard/pricing/plan/${planId}/domains`, dto)
    .then((r) => r.data)
}

export const deletePricingPlanDomain = (id: number): Promise<void> => {
  return apiClient.delete(`/payment-service/dashboard/pricing/plan/domains/${id}`)
}
