import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import React, { memo } from 'react'
import LisaSelectedItem from './LisaSelectedItem'
import { ALL_VALUE, LisaSelectOption } from './domain'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

type Props = SelectProps<string[]> & {
  options: LisaSelectOption[]
}

const LisaSelect = ({ options, onChange, value, label, ...rest }: Props) => {
  const isAllSelected = value?.length === options.length

  const handleChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
    if (React.isValidElement(child) && child?.key?.toString()?.includes(ALL_VALUE)) {
      if (isAllSelected) {
        event.target.value = []
      } else {
        event.target.value = options.map((o) => o.value)
      }
    }
    onChange && onChange(event, child)
  }

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        fullWidth
        multiple
        value={value}
        renderValue={(selected) => <LisaSelectedItem selected={selected} options={options} />}
        label={label}
        onChange={handleChange}
        {...rest}
      >
        <MenuItem value={ALL_VALUE} key={ALL_VALUE}>
          <Checkbox checked={isAllSelected} />
          <ListItemText primary="All" />
        </MenuItem>
        {options.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              <Checkbox checked={value?.includes(option.value)} />
              <ListItemText primary={option.title} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default memo(LisaSelect)
