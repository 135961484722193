export const BIKE_ID = 'id'
export const BIKE_BRAND = 'brand'
export const BIKE_CODE = 'code'
export const BIKE_TYPE = 'type'
export const BIKE_STATUS = 'status'
export const BIKE_NAME = 'name'
export const BIKE_LOCK_BATTERY = 'lock_battery'
export const BIKE_BATTERY = 'battery'
export const BIKE_CITY = 'city_name'
export const BIKE_STATION = 'station_name'
export const BIKE_LAST_SEEN = 'lastSeenOnline'
export const BIKE_LOCK_STATUS = 'lock_status'
export const BIKE_USER_NAME = 'userName'
export const BIKE_LOCK_NUMBER = 'lock_code'
export const BIKE_DOCK_NUMBER = 'dock_code'

export const ONLINE_STATUS = 'ONLINE'
export const OFFLINE_STATUS = 'OFFLINE'
export const RENTING_STATUS = 'RENTING'
export const READY = 'READY'
export const IN_STATION = 'IN_STATION'
export const BROKEN_WORKSHOP = 'BROKEN_WORKSHOP'
export const UNKNOWN = 'UNKNOWN'
export const LOST = 'LOST'
export const PICKUP_REQUIRED = 'PICKUP_REQUIRED'
export const RETIRED = 'RETIRED'
export const MISSING = 'MISSING'

export const MALFUNCTION_STATUS = 'MALFUNCTION'

export const CITY_ID = 'id'
export const CITY_NAME = 'name'
export const CITY_STATUS = 'status'

export const USER_ID = 'profile_userId'
export const USER_NAME = 'profile_fullName'
export const USER_EMAIL = 'profile_email'
export const USER_PHONE = 'profile_phone'
export const USER_CREATION_DATE = 'dateCreated'
export const USER_SUBSCRIPTION_TYPE = 'plan_type'
export const USER_SUBSCRIPTION_STATUS = 'status'
export const USER_PLAN_EXPIRY_DATE = 'currentPeriodEnd'
export const USER_ACTIVE_CITY = 'profile_activeCityId'
export const USER_STRIPE_PROFILE = 'stripeProfileUrl'

export const DOCK_ID = 'id'
export const DOCK_CODE = 'code'
export const DOCK_VEHICLE_CODE = 'code'
export const DOCK_STATUS = 'status'
export const DOCK_TYPE = 'type'
export const DOCK_IS_OPEN = 'open'
export const DOCK_CITY = 'station_city_name'

export const LOCK_ID = 'id'
export const LOCK_BRAND = 'brand'
export const LOCK_CODE = 'code'
export const LOCK_VEHICLE_CODE = 'code'
export const LOCK_STATUS = 'status'
export const LOCK_TYPE = 'type'
export const LOCK_IS_OPEN = 'open'

export const RENTAL_ID = 'id'
export const RENTAL_START_DATE = 'startTime'
export const RENTAL_END_DATE = 'endTime'
export const RENTAL_STATION_NAME = 'startStationName'
export const RENTAL_END_STATION_NAME = 'endStationName'
export const RENTAL_START_DOCK_CODE = 'startDockCode'
export const RENTAL_END_DOCK_CODE = 'endDockCode'
export const RENTAL_VEHICLE = 'vehicleName'
export const RENTAL_USER = 'userName'
export const RENTAL_STATUS = 'status'

export const STATION_NAME = 'name'
export const STATION_ID = 'id'
export const STATION_CODE = 'code'
export const STATION_DOCK_CODE = 'dockCount'
export const STATION_VEHICLE_COUNT = 'vehicleCount'
export const STATION_STATUS = 'status'
export const STATION_TYPE = 'type'
export const STATION_IS_ONLINE = 'online'
export const STATION_LAST_SEEN = 'lastSeenOnline'

export const TRANSACTION_ID = 'id'
export const TRANSACTION_USER_NAME = 'userName'
export const TRANSACTION_STATUS = 'status'
export const TRANSACTION_AMOUNT = 'amount'
export const TRANSACTION_AUTHORIZED_AMOUNT = 'authorizedAmount'
export const TRANSACTION_CAPTURED_AMOUNT = 'capturedAmount'
export const TRANSACTION_CAPTURED_DATE = 'capturedDate'
export const TRANSACTION_CITY = 'plan_cityId'
export const TRANSACTION_AUTHORIZED_DATE = 'authorizedDate'
export const TRANSACTION_SUBSCR_TYPE = 'plan_type'

export const TRANSACTION_WALLET_CAPTURED_AMOUNT = 'walletCapturedAmount'
export const TRANSACTION_DIRECT_PAYMENT_FEE = 'directPaymentFee'
export const TRANSACTION_WALLET_DUE_AMOUNT = 'walletDueAmount'
export const TRANSACTION_TYPE = 'type'
