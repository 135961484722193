import React from 'react'
import './styles.scss'
import TableRow from '@mui/material/TableRow'
import TableItem from '../tableItem'

function TableItemRow(props) {
  const { items, id, onClick, className = '' } = props

  const tableItem = (item) => <TableItem key={Math.random()} body={item} />

  return (
    <TableRow
      id={id}
      key={id}
      className={className}
      hover={!!onClick}
      onClick={onClick}
      component={'tr'}
    >
      {items.map(tableItem)}
    </TableRow>
  )
}

export default TableItemRow
