import React from 'react'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import CouponItem from '../../items/couponItem'
import { strings } from '../../../I18n'

const PreviewCouponModal = ({ open, onClose, getCouponListByUser, selectedUser }) => {
  const modalStyle = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflow: 'scroll',
    maxHeight: '80%',
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {getCouponListByUser ? (
          <CouponItem
            getCouponListByUser={getCouponListByUser}
            onClose={onClose}
            selectedUser={selectedUser}
          />
        ) : (
          <h1>{strings('noCouponAvailable')}</h1>
        )}
      </Box>
    </Modal>
  )
}

export default PreviewCouponModal
