/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import './styles.scss'
import ConfirmationDialog from '../confirmationDialog'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import { strings } from '../../../I18n'
import MenuItem from '@mui/material/MenuItem'
import Moment from 'moment'
import { DateTimePicker } from '@material-ui/pickers'
import { connect } from 'react-redux'

function InputDialog(props) {
  const {
    visibility,
    hideDialog,
    title,
    positiveAction,
    negativeAction,
    stationsList = [],
    docksList = [],
    rentalData = {},
    disablePast,
  } = props

  const [selectedStation, setStation] = useState('')
  const [selectedDock, setDock] = useState('')
  const [amount, setAmount] = useState((rentalData?.finalAmount || 0) / 100)
  const [endDate, setEndDate] = useState(rentalData?.endTime || '')
  const [isDockDisabled, disableDock] = useState(true)

  useEffect(() => {
    if (rentalData) {
      setAmount(rentalData?.finalAmount / 100)
      setEndDate(rentalData?.endTime)
    }
  }, [rentalData])

  useEffect(() => {
    if (
      !(
        (stationsList?.find((station) => station.code === selectedStation) || {})?.type === 'CHARGE'
      )
    ) {
      disableDock(true)
    } else {
      disableDock(false)
      setDock('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStation])

  const dropContent = (item, type) => {
    if (type === 'station') {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      )
    } else if (type === 'dock') {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.code}
        </MenuItem>
      )
    }
  }

  const endRent = () => {
    const data = {}
    if (selectedStation) {
      data.endStationCode = selectedStation
    }
    if (selectedDock) {
      data.endDockCode = selectedDock
    }
    if (amount) {
      data.finalAmount = amount * 100
    }
    if (endDate) {
      data.endTime = endDate
    }
    positiveAction(rentalData.id, data)
  }

  return (
    <ConfirmationDialog
      visibility={visibility}
      hideDialog={hideDialog}
      title={title}
      positiveAction={endRent}
      negativeAction={negativeAction}
      selectedStation={selectedStation}
      content={
        <DialogContent>
          <TextField
            value={selectedStation}
            onChange={({ target }) => setStation(target.value)}
            label={strings('descriptions.endStation')}
            select
            fullWidth
            margin="dense"
            required
            helperText="You must select a station"
            error={selectedStation === '' ? true : false}
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {stationsList?.map((item) => dropContent(item, 'station'))}
          </TextField>
          {!isDockDisabled ? (
            <TextField
              value={selectedDock}
              onChange={({ target }) => setDock(target.value)}
              label={strings('descriptions.endDock')}
              select
              fullWidth
              margin="dense"
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {docksList?.map((item) => dropContent(item, 'dock'))}
            </TextField>
          ) : null}
          <DateTimePicker
            disablePast={disablePast}
            margin="dense"
            fullWidth
            variant="inline"
            ampm={false}
            format={'DD-MM-YYYY HH:mm'}
            label={strings('descriptions.endTime')}
            value={endDate ? Moment(endDate + 'Z').format('YYYY-MM-DDTHH:mm') : null}
            onChange={(value) => setEndDate(Moment(value).utc().format('YYYY-MM-DDTHH:mm:ss'))}
          />
          {amount ? (
            <TextField
              onChange={({ target }) => setAmount(target.value)}
              value={amount}
              autoFocus
              margin="dense"
              label={strings('placeholders.finalAmount')}
              type="number"
              fullWidth
            />
          ) : null}
        </DialogContent>
      }
    />
  )
}

const mapStateToProps = ({ stations: { allVirtualStations } }) => ({
  allVirtualStations,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InputDialog)
