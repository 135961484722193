import axios, { AxiosRequestConfig } from 'axios'
import { getToken } from '../utils/firebase'

const assetsBaseURL = `/assets-service`

const requestInterceptor = async (config: AxiosRequestConfig) => {
  let token = await getToken()

  if (!config.headers) {
    config.headers = {}
  }

  config.headers['Authorization'] = `Bearer ${token}`

  return config
}

export const apiClient = axios.create({})
apiClient.interceptors.request.use(requestInterceptor)

export const apiClientJSON = axios.create({
  responseType: 'json',
})
apiClientJSON.interceptors.request.use(requestInterceptor)

export const apiClientAssets = axios.create({
  baseURL: assetsBaseURL,
})
apiClient.interceptors.request.use(requestInterceptor)

export const apiClientJSONAssets = axios.create({
  baseURL: assetsBaseURL,
  responseType: 'json',
})
apiClientJSON.interceptors.request.use(requestInterceptor)
