import React from 'react'
import './styles.scss'
import TableSortLabel from '@mui/material/TableSortLabel'
import { ASC, DESC } from '../../../constants/const'
import { StyledTableCell } from '../../../utils/styles'

function SortedTableItem(props) {
  const {
    orderKey,
    title,
    orderProps: { order, setOrder, orderBy, setOrderBy },
  } = props

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === ASC
    setOrder(isAsc ? DESC : ASC)
    setOrderBy(property)
  }

  return (
    <StyledTableCell
      key={orderKey}
      padding={'none'}
      align={'center'}
      sortDirection={orderBy === orderKey ? order : false}
    >
      <TableSortLabel
        hideSortIcon={true}
        active={orderBy === orderKey}
        direction={orderBy === orderKey ? order : ASC}
        onClick={createSortHandler(orderKey)}
      >
        <p className={'sortedTitle'}>{title}</p>
      </TableSortLabel>
    </StyledTableCell>
  )
}

export default SortedTableItem
