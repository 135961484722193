import React from "react";
import { ONLINE_STATUS } from "../../constants/orderConst";
import "./styles.scss";

const Tooltip = ({ count, status }) => (
  <div id="tooltip" className="top">
    <div className="tooltip-arrow" />
    <div className="tooltip-label">{status === ONLINE_STATUS ? count : '!'}</div>
  </div>
);

export default React.memo(Tooltip);
