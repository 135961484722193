import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import Paper from '@mui/material/Paper/Paper'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import { Button, Fab, TableBody } from '@mui/material'
import TableHeaderRow from '../../components/table/tableHeaderRow'
import { useStyles } from '../../utils/styles'
import { strings } from '../../I18n'
import { DateTimePicker } from '@material-ui/pickers'
import TableItemRow from '../../components/table/tableItemRow'
import greenBike from '../../images/bikes/greenBike.png'
import {
  alarmBike,
  clearBike,
  clearHasBikeHistory,
  getBikeHistory,
  getBikeId,
  getBikeStatus,
  getPaginatedBikeHistory,
  lockBike,
  unlockBike,
  updateBike,
} from '../../actions/vehicle'
import Moment from 'moment'
import './styles.scss'
import EditIcon from '@mui/icons-material/Assignment'
import BikeModal from '../../components/modal/bikeModal'
import { useShowToast } from '../../hooks'
import { getLinkedStationsByCity, getStationsByCity } from '../../actions/stations'
import { useLocation } from 'react-router'
import {
  BIKE_HISTORY_DAY,
  BIKE_HISTORY_PAGE_SIZE,
  DATE_FORMAT,
  DATE_FORMAT_DEFAULT,
  PAGINATED_BIKE_HISTORY_PAGE_SIZE,
} from '../../constants/const'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ScrollToTop from 'react-scroll-up'
import { MAP_KEY } from '../../configs/settings'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import { DotLoader } from 'react-spinners'

let interval = null

const BikeHistoryContainer = (props) => {
  const {
    clearBike,
    unlockBike,
    alarmBike,
    lockBike,
    stationsByCity,
    getStationsByCity,
    getLinkedStationsByCity,
    linkedStations,
    bikeTypes,
    savedCity,
    bikeHistory,
    getBikeHistory,
    bike,
  } = props
  const classes = useStyles()
  const showToast = useShowToast()
  const location = useLocation()
  const { bikeId, vehicleCode, bikeName } = location.state
  const [pageNumber, setPageNumber] = useState(0)
  const [tablePageNumber, setTablePageNumber] = useState(0)

  const [isLoading, setLoading] = useState(false)
  const [selectedBike, setSelectedBike] = useState(null)
  const [startDate, setStartDate] = useState(
    Moment().subtract(BIKE_HISTORY_DAY, 'days').format(DATE_FORMAT_DEFAULT)
  )
  const [endDate, setEndDate] = useState(Moment().format(DATE_FORMAT_DEFAULT))
  const [editClicked, setEditClicked] = useState(false)
  const [bikeModalVisible, setBikeModalVisible] = useState(false)
  const [bikeHistoryContent, setBikeHistoryContent] = useState([])
  const [currentZoom, setZoom] = useState(16)

  const [mapInstance, setMapInstance] = useState(null)

  const hideModal = () => setBikeModalVisible(false)
  const showModal = () => setBikeModalVisible(true)

  const increasePageNumber = () => {
    if (props.hasMoreBikeHistoryInTable) {
      setTablePageNumber((prevState) => prevState + 1)
    }
  }

  const decreasePageNumber = () => {
    setTablePageNumber((prevState) => (prevState < 0 ? 0 : prevState - 1))
  }

  useEffect(() => {
    if (bikeHistory?.content?.length > 0) {
      setBikeHistoryContent(bikeHistory.content)
    } else {
      setBikeHistoryContent([])
    }
  }, [bikeHistory])

  const closeModal = () => {
    clearBike()
    hideModal()
  }
  const convertDateToUtc0 = (date) => {
    const dateUtc = Moment(date).utcOffset(0)
    return dateUtc.format(DATE_FORMAT_DEFAULT)
  }

  const flatBikeHistory = useMemo(() => {
    return bikeHistory?.content.flat()
  }, [bikeHistory])

  const flatPaginatedBikeHistory = useMemo(() => {
    return props.paginatedBikeHistory?.content.flat()
  }, [props.paginatedBikeHistory])

  useEffect(() => {
    if (props.updateBikeSuccess) {
      showToast('Success', 'success')
      clearBike()
      hideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateBikeSuccess, clearBike])

  useEffect(() => {
    if (props.hasMoreBikeHistory) {
      getBikeHistory(
        0,
        BIKE_HISTORY_PAGE_SIZE,
        vehicleCode,
        convertDateToUtc0(startDate),
        convertDateToUtc0(endDate)
      )
    }
    if (props.getBikeByIdSuccess === false) {
      setLoading(false)
    } else if (props.getBikeByIdSuccess === true) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getBikeByIdSuccess, bike])

  const isValidDateRange = () => {
    const start = Moment(startDate)
    const end = Moment(endDate)
    const diff = end.diff(start, 'days')
    return diff <= 1
  }

  const isPositiveDateDiff = () => {
    const start = Moment(startDate)
    const end = Moment(endDate)
    const diff = end.diff(start, 'days')
    return diff >= 0
  }

  const showBikeHistory = () => {
    setZoom(16)
    setTablePageNumber(0)
    if (!isPositiveDateDiff()) {
      showToast(strings('descriptions.dateRangePositiveError'), 'error')
      return
    }
    if (isValidDateRange()) {
      setPageNumber(0)
      props.clearHasBikeHistory()
    } else {
      showToast(strings('descriptions.dateRangeError'), 'error')
    }
  }

  useEffect(() => {
    if (props.hasMoreBikeHistory) {
      setLoading(true)
      getBikeHistory(
        pageNumber,
        BIKE_HISTORY_PAGE_SIZE,
        vehicleCode,
        convertDateToUtc0(startDate),
        convertDateToUtc0(endDate)
      )
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, vehicleCode, props.hasMoreBikeHistory])

  useEffect(() => {
    interval = setInterval(() => {
      setPageNumber((prevState) => prevState + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
      props.clearHasBikeHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editClicked) props.getBikeByIdSuccess && showModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editClicked, props.getBikeByIdSuccess, savedCity])

  const showBikeInfo = () => {
    props.getBikeId(bikeId)
    props.getBikeStatus()
    setEditClicked(true)
  }

  const setBikeOnMap = React.useCallback(
    (index) => {
      setSelectedBike(flatBikeHistory[index])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flatBikeHistory, selectedBike]
  )

  useEffect(() => {
    props.getPaginatedBikeHistory(
      tablePageNumber,
      PAGINATED_BIKE_HISTORY_PAGE_SIZE,
      vehicleCode,
      convertDateToUtc0(startDate),
      convertDateToUtc0(endDate)
    )
    // eslint-disable-next-line
  }, [startDate, endDate, tablePageNumber, vehicleCode])

  const tableBody = () => (
    <TableBody>
      {flatPaginatedBikeHistory &&
        flatPaginatedBikeHistory?.map((bike, index) => (
          <TableItemRow
            id={bike?.id}
            key={bike?.id}
            items={[
              Moment(bike?.timestamp).local().format('YYYY-MM-DD HH:mm:ss'),
              bike?.code,
              bike?.imei,
              bike?.latitude,
              bike?.longitude,
              bike?.journeyid,
            ]}
            onClick={() => setBikeOnMap(index)}
          />
        ))}
    </TableBody>
  )

  const timeFilter = () => {
    return (
      <div className={'bikeHistoryTimeFilter'}>
        <DateTimePicker
          className={'bikeHistoryDateInput'}
          inputVariant="outlined"
          variant="inline"
          format={DATE_FORMAT}
          label={strings('descriptions.startDate')}
          value={startDate ? Moment(startDate).format(DATE_FORMAT_DEFAULT) : null}
          onChange={(value) => setStartDate(Moment(value).format(DATE_FORMAT_DEFAULT))}
          ampm={false}
        />
        <DateTimePicker
          className={'bikeHistoryDateInput'}
          inputVariant="outlined"
          variant="inline"
          format={DATE_FORMAT}
          label={strings('descriptions.endDate')}
          value={endDate ? Moment(endDate).format(DATE_FORMAT_DEFAULT) : null}
          onChange={(value) => setEndDate(Moment(value).format(DATE_FORMAT_DEFAULT))}
          ampm={false}
        />
        <Button color="primary" variant="contained" onClick={showBikeHistory}>
          {strings('descriptions.filter')}
        </Button>
      </div>
    )
  }

  const titles = [
    strings('descriptions.time'),
    strings('descriptions.vehicleCode'),
    strings('descriptions.imei'),
    strings('descriptions.latitude'),
    strings('descriptions.longitude'),
    'Journey ID',
  ]

  useEffect(() => {}, [bikeHistoryContent])

  const remapbikeHistoryContent = useMemo(() => {
    //  convert to path like object
    return flatBikeHistory?.map((bike, index) => {
      return {
        lat: parseFloat(bike.latitude),
        lng: parseFloat(bike.longitude),
      }
    })
    // .filter((v, i, a) => a.findIndex((v2) => ['lat', 'lng'].every((k) => v2[k] === v[k])) === i)
  }, [flatBikeHistory])

  const remapDataToPath = (data) => {
    return data?.map((bike, index) => {
      return {
        lat: parseFloat(bike.latitude),
        lng: parseFloat(bike.longitude),
      }
    })
  }

  const mapContainerStyle = {
    height: '50vh',
    width: '100%',
  }

  const inactiveRentOptions = {
    strokeColor: '#fd4242',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#fd4242',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  }

  const activeRentOptions = {
    strokeColor: '#028d10',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#028d10',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  }

  const onMapLoaded = (lMap) => {
    setMapInstance(lMap)
  }

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
  })

  return (
    <div className={'bikeHistoryContainer'}>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DotLoader color="#36d7b7" />
        </div>
      )}
      <div className={classes.root}>
        {flatBikeHistory?.length > 0 ? (
          <div style={{ height: '50vh', width: '100%' }}>
            {loadError ? (
              <div>
                <h1>{strings('descriptions.mpNotLoaded')}</h1>
              </div>
            ) : !isLoaded ? (
              <div>
                <h1>{strings('descriptions.loading')}</h1>
              </div>
            ) : (
              <GoogleMap
                id="marker-example"
                mapContainerStyle={mapContainerStyle}
                zoom={currentZoom}
                center={remapbikeHistoryContent[0]}
                onLoad={onMapLoaded}
                onUnmount={(map) => {
                  setMapInstance(null)
                }}
              >
                {!props.hasMoreBikeHistory &&
                  bikeHistory.content.map((bikePath, index) => {
                    return (
                      <Polyline
                        path={remapDataToPath(bikePath)}
                        options={bikePath[0]?.journeyid ? activeRentOptions : inactiveRentOptions}
                      />
                    )
                  })}
                <Marker
                  icon={{
                    url: greenBike,
                    scaledSize: mapInstance ? new window.google.maps.Size(30, 30) : null,
                  }}
                  position={remapbikeHistoryContent[0]}
                />
                <Marker
                  icon={
                    'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'
                  }
                  position={remapbikeHistoryContent[remapbikeHistoryContent.length - 1]}
                />
                {/*{animatedMarker && <Marker position={animatedMarker} />}*/}

                {selectedBike && (
                  <Marker
                    icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      fillColor: 'yellow',
                      fillOpacity: 0.9,
                      scale: 7,
                      strokeColor: 'gold',
                      strokeWeight: 2,
                    }}
                    position={{
                      lat: parseFloat(selectedBike.latitude),
                      lng: parseFloat(selectedBike.longitude),
                    }}
                  />
                )}
              </GoogleMap>
            )}
          </div>
        ) : (
          <div className={'noBikeHistory'}>
            <h1>{strings('descriptions.noBikeHistory')}</h1>
          </div>
        )}
        <div className={'bikeHistoryHeader'}>
          <p className={'bikeHistoryTitle'}>{`${strings(
            'title.history'
          )}: ${bikeName}(${bikeId})`}</p>
          <div className={'rentalActions'}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={showBikeInfo}
            >
              {strings('descriptions.edit')}
            </Button>
          </div>
        </div>
        <BikeModal
          visible={bikeModalVisible}
          clearBike={closeModal}
          bike={props.bike}
          statuses={props.statuses}
          updateBike={props.updateBike}
          cityNameList={props.citiesList}
          unlockBike={unlockBike}
          alarmBike={alarmBike}
          lockBike={lockBike}
          stations={stationsByCity}
          getStations={getStationsByCity}
          getLinkedStations={getLinkedStationsByCity}
          linkedStations={linkedStations}
          bikeTypes={bikeTypes}
        />
        <Paper className={classes.paper} elevation={0}>
          {timeFilter()}
          {bikeHistoryContent.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button variant="outlined" color="primary" onClick={decreasePageNumber}>
                  {'<'}
                </Button>
                <Button variant="outlined" color="primary" onClick={increasePageNumber}>
                  {'>'}
                </Button>
              </div>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHeaderRow items={titles} aria-label="simple table" />
                  {tableBody()}
                </Table>
              </TableContainer>

              <ScrollToTop showUnder={160}>
                <Fab color="primary">
                  <KeyboardArrowUpIcon fontSize="small" />
                </Fab>
              </ScrollToTop>
            </>
          )}
        </Paper>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  vehicle: {
    bike,
    updateBikeSuccess,
    getBikeByIdSuccess,
    statuses,
    updateBike,
    bikeTypes,
    bikeHistory,
    bikeHistorySuccess,
    hasMoreBikeHistory,
    paginatedBikeHistory,
    hasMoreBikeHistoryInTable,
  },
  cities: { citiesList, savedCity },
  stations: { stationsByCity, linkedStations },
  screen: { passedParams },
}) => ({
  bike,
  updateBikeSuccess,
  getBikeByIdSuccess,
  citiesList,
  stationsByCity,
  linkedStations,
  statuses,
  updateBike,
  bikeTypes,
  savedCity,
  bikeHistory,
  passedParams,
  bikeHistorySuccess,
  hasMoreBikeHistory,
  paginatedBikeHistory,
  hasMoreBikeHistoryInTable,
})

const mapDispatchToProps = {
  updateBike,
  unlockBike,
  alarmBike,
  lockBike,
  getBikeId,
  getLinkedStationsByCity,
  getStationsByCity,
  getBikeStatus,
  getBikeHistory,
  clearBike,
  clearHasBikeHistory,
  getPaginatedBikeHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeHistoryContainer)
