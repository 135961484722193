import React from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from '../paginatorAction'
import { PAGE_SIZES } from '../../../constants/const'
import { setPageSize } from '../../../actions/screen'
import { useStyles } from '../../../utils/styles'

function TabletPaginator(props) {
  const { items, pageData, handleChangePage, pageSize } = props
  const classes = useStyles()
  return (
    <>
      {items?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={PAGE_SIZES}
          colSpan={3}
          count={pageData?.totalElements}
          rowsPerPage={pageSize}
          page={pageData?.number}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => props.setPageSize(event.target.value)}
          component={'div'}
          ActionsComponent={TablePaginationActions}
          className={classes.pagination}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ screen: { pageSize } }) => ({
  pageSize,
})

const mapDispatchToProps = {
  setPageSize,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabletPaginator)
