import React from 'react'
import './styles.scss'
import { Checkbox, FormControlLabel } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'

interface Props extends Omit<FormControlLabelProps, 'control' | 'label'> {
  IconClass: SvgIconComponent
  isChecked: boolean
  onCheckChange: (checked: boolean) => void
}

export default function IconCheckbox({ IconClass, isChecked, onCheckChange, ...rest }: Props) {
  return (
    <FormControlLabel
      {...rest}
      control={<Checkbox checked={isChecked} onChange={(e) => onCheckChange(e.target.checked)} />}
      label={<IconClass color="primary" />}
      classes={{ label: 'iconCheckboxLabel', root: 'iconCheckbox' }}
      labelPlacement="top"
    />
  )
}
