export enum RentalRuleAction {
  FINISH = 'FINISH',
}

export enum RentalRuleCause {
  START = 'START',
  PAUSE = 'PAUSE',
}

export enum Brand {
  OMNI = 'OMNI',
  KINGMETER = 'KINGMETER',
  ACTON_OMNI = 'ACTON_OMNI',
  HONGJI = 'HONGJI',
  VAIMOO = 'VAIMOO',
  FREEBIKE = 'FREEBIKE',
  RIDEMOVI = 'RIDEMOVI',
}

export interface RentalRule {
  id: number
  dateCreated: Date
  dateUpdated: Date
  action: RentalRuleAction
  cause: RentalRuleCause
  waitingTimeInMs: number
  cityId: number
  brands?: Brand[]
}

export type RentalRuleDto = Omit<RentalRule, 'id' | 'dateUpdated' | 'dateCreated'>
