import React, { useState } from 'react'
import './styles.scss'
import { Search, Tune } from '@mui/icons-material'
import MapFilter from '../mapFilter/MapFilter'
import { MapFilterParams } from '../../domain'
import MapSearch from '../mapSearch/MapSearch'
import { Badge } from '@mui/material'
import { isFilterParamsDefault } from '../../utils'

interface Props {
  params: MapFilterParams
  onUpdateParams: (params: MapFilterParams) => void
}

export default function MapFilterAndSearch({ params, onUpdateParams }: Props) {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)

  return (
    <div className="mapFilterAndSearch">
      {!showFilter && (
        <Badge color="primary" variant="dot" invisible={isFilterParamsDefault(params)}>
          <button
            className="mapFilterAndSearchActivator"
            type="button"
            onClick={() => setShowFilter(true)}
          >
            <Tune color="primary" className="mapFilterAndSearchActivatorIcon" />
          </button>
        </Badge>
      )}

      {showFilter && (
        <MapFilter
          params={params}
          onUpdateParams={onUpdateParams}
          onClose={() => setShowFilter(false)}
        />
      )}

      {!showSearch && (
        <Badge color="primary" variant="dot" invisible={!params.search}>
          <button
            className="mapFilterAndSearchActivator"
            type="button"
            onClick={() => setShowSearch(true)}
          >
            <Search color="primary" className="mapFilterAndSearchActivatorIcon" />
          </button>
        </Badge>
      )}

      {showSearch && (
        <MapSearch
          params={params}
          onUpdateParams={onUpdateParams}
          onClose={() => setShowSearch(false)}
        />
      )}
    </div>
  )
}
