import React, { useEffect } from 'react'
import './styles.scss'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Select from '@mui/material/Select/Select'
import { SelectorInput } from '../../../utils/styles'
import MenuItem from '@mui/material/MenuItem'
import { filterByValue } from '../../../utils/utils'

function FilterItem(props) {
  const { selectorItems, changeSelectorValue, filterBy, filterProps = [] } = props
  const [value, setValue] = React.useState('')

  const handleSelectorValueChange = React.useCallback(
    (event) => {
      setValue(event.target.value)
      changeSelectorValue(event.target.value, filterBy)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeSelectorValue, filterBy, selectorItems]
  )

  useEffect(() => {
    if (!!filterProps && filterProps.length) {
      filterProps.forEach((filter) => {
        if (filter.filterBy === filterBy) {
          setValue(filter.filter)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps, filterBy, selectorItems])

  return (
    <div className={'filterItemContainer'}>
      <InputLabel className={'filterInputLabel'}>
        {filterByValue(filterBy)
          .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
          .toLowerCase()
          .replace(/_/g, ' ')}
      </InputLabel>
      <Select
        className={'filterSelector'}
        onChange={handleSelectorValueChange}
        input={<SelectorInput />}
        value={value}
      >
        <MenuItem value={''}>
          <em>None</em>
        </MenuItem>
        {selectorItems?.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            {item.name.replace(/_/g, ' ')}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default FilterItem
