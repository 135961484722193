import React, { useEffect, useState } from 'react'
import { debounce, MenuItem } from '@mui/material'
import Autocomplete from '@mui/lab/Autocomplete'
import TextField from '@mui/material/TextField/TextField'
import { useSelector } from 'react-redux'
import { getUsersList } from '../../apis/user/user-apis'

export const NotificationsTargetUser = () => {
  const whiteLabels = useSelector((s: any) => s.cities?.whiteLabels as any[])
  const [userSearch, setUserSearch] = useState<string>('')
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [userOptions, setUserOptions] = useState<any[]>([])

  const onUserInputChange = debounce((value: string) => {
    setUserSearch(value)
  }, 1000)

  useEffect(() => {
    if (userSearch.length >= 3 && whiteLabels?.length) {
      setUserLoading(true)
      getUsersList(userSearch)
        .then((users) => {
          setUserOptions(
            users.map((u) => {
              const whiteLabelName = whiteLabels.find((wl) => (wl.id || 0) === u.whiteLabelId)?.name

              return {
                label: u.fullName || 'UNKNOWN',
                id: u.id,
                phone: u.phone,
                whiteLabelId: u.whiteLabelId,
                whiteLabelName: whiteLabelName || u.whiteLabelId,
              }
            })
          )
        })
        .finally(() => setUserLoading(false))
    }
  }, [userSearch, whiteLabels])

  return (
    <Autocomplete
      multiple
      options={userOptions}
      groupBy={(option) => option.whiteLabelName}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <div>
            {option.label} <small style={{ color: 'gray' }}>({option.phone})</small>
          </div>
        </MenuItem>
      )}
      filterSelectedOptions
      onInputChange={(e, value) => onUserInputChange(value)}
      loading={userLoading}
      renderInput={(params) => (
        <TextField {...params} label="Users" placeholder="Input at least 3 chars to start search" />
      )}
    />
  )
}
