import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react'
import { RentalRule } from '../../domain/rental-rule'
import { getRentalRules } from '../../apis/rental/rental-rule-apis'
import { RentalRulesAddEdit } from './RentalRulesAddEdit'
import { RentalRulesDelete } from './RentalRulesDelete'
import { useCity } from '../../hooks'
import moment from 'moment'
import { actionToOption, causeToOption } from './rental-rules-domain'

export const RentalRulesContainer = () => {
  const { selectedCity } = useCity()
  const cityId = parseInt(selectedCity)
  const [rules, setRules] = useState<RentalRule[]>([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [ruleToDelete, setRuleToDelete] = useState<RentalRule | null>(null)

  const getFormattedTime = (timeInMs: number) => {
    const d = moment.duration(timeInMs)
    const arr: string[] = []

    !!d.hours() && arr.push(`${d.hours()}h`)
    !!d.minutes() && arr.push(`${d.minutes()}min`)
    !!d.seconds() && arr.push(`${d.seconds()}sec`)

    return arr.join(' ')
  }

  const fetchAndSetRules = () => {
    if (cityId) {
      getRentalRules(cityId).then((rules) => setRules(rules))
    } else {
      setRules([])
    }
  }

  const handleClose = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchAndSetRules()
    }

    setIsAddOpen(false)
    setRuleToDelete(null)
  }

  useEffect(fetchAndSetRules, [cityId])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography variant="h4">Rental Rules</Typography>

        <ButtonGroup variant="contained">
          <Button size="small" onClick={() => setIsAddOpen(true)}>
            Add
          </Button>
        </ButtonGroup>
      </Box>

      {!!selectedCity && (
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ fontWeight: 700 }}>
            <TableRow>
              <TableCell>
                <strong>Id</strong>
              </TableCell>
              <TableCell>
                <strong>Cause</strong>
              </TableCell>
              <TableCell>
                <strong>Action</strong>
              </TableCell>
              <TableCell>
                <strong>Waiting time</strong>
              </TableCell>
              <TableCell sx={{ width: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((r) => (
              <TableRow key={r.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{r.id}</TableCell>
                <TableCell>{causeToOption(r.cause).label}</TableCell>
                <TableCell>{actionToOption(r.action).label}</TableCell>
                <TableCell>{getFormattedTime(r.waitingTimeInMs)}</TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <IconButton color="error" onClick={() => setRuleToDelete(r)}>
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {!selectedCity && (
        <Alert sx={{ marginTop: 4 }} severity="warning">
          Please select a city to see the rules.
        </Alert>
      )}

      {isAddOpen && <RentalRulesAddEdit cityId={cityId} onClose={() => handleClose(true)} />}
      {!!ruleToDelete && (
        <RentalRulesDelete rule={ruleToDelete} onClose={() => handleClose(true)} />
      )}
    </Box>
  )
}
