import React from 'react'
import { Stack, Typography } from '@mui/material'

export const NotificationsContent = () => {
  return (
    <Stack spacing={3} width={700}>
      <Typography variant="h5">Target Notification</Typography>
      <Typography variant="body1">
        Please make <strong>up to 5</strong> choices to target the notification. It could be app(s),
        city(s) or user(s)
      </Typography>
    </Stack>
  )
}
