/* eslint-disable */
import React, { useCallback, useMemo } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  cityNameById,
  modifyCurrencyToSymbolAndValue,
  removeUnderscoreGetString,
  sortedItem,
  stringFromValue,
} from '../../../utils/utils'
import Moment from 'moment'
import { strings } from '../../../I18n'
import { getPaymentById } from '../../../actions/payment'
import { getUsers } from '../../../actions/users'
import { setPassedParams } from '../../../actions/screen'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Assignment'
import TabletPaginator from '../../table/tabletPaginator'
import { useStyles } from '../../../utils/styles'
import TableHeaderRow from '../../table/tableHeaderRow'
import TableItemRow from '../../table/tableItemRow'
import {
  TRANSACTION_AMOUNT,
  TRANSACTION_AUTHORIZED_AMOUNT,
  TRANSACTION_AUTHORIZED_DATE,
  TRANSACTION_CAPTURED_AMOUNT,
  TRANSACTION_CAPTURED_DATE,
  TRANSACTION_CITY,
  TRANSACTION_DIRECT_PAYMENT_FEE,
  TRANSACTION_ID,
  TRANSACTION_STATUS,
  TRANSACTION_SUBSCR_TYPE,
  TRANSACTION_TYPE,
  TRANSACTION_USER_NAME,
  TRANSACTION_WALLET_CAPTURED_AMOUNT,
  TRANSACTION_WALLET_DUE_AMOUNT,
} from '../../../constants/orderConst'
import * as routes from '../../../constants/routes'
import ReactTooltip from 'react-tooltip'

function TransactionItem(props) {
  const {
    transactions,
    transactionResult,
    handleChangePage,
    orderProps,
    citiesList,
    getPaymentById,
    getUsers,
    setPassedParams,
    savedCity,
    setErrorData,
    toggleErrorModal,
  } = props
  const classes = useStyles()
  const navigate = useNavigate()

  const clickOnLink = (transaction) => {
    if (!!transaction.link) {
      const win = window.open(transaction.link, '_blank')
      if (win != null) {
        win.focus()
      }
    }
  }

  const momentDate = (date) => (date ? Moment(date + 'Z').format('DD.MM.YYYY HH:mm') : '-')

  const transactionLink = (transaction) =>
    transaction.link && transaction.link !== '' ? strings('descriptions.showPayment') : '-'

  const showUser = (transaction) => {
    if (!!transaction.userId) {
      getUsers(0, '', '', savedCity || '', 0)
      setPassedParams(transaction.userId)
      navigate(routes.DASHBOARD_USERS)
    }
  }

  const getErrorDetails = (transaction) => (
    <>
      {transaction?.errorDetails ? (
        <div
          onClick={() => {
            setErrorData(transaction?.errorDetails)
            toggleErrorModal()
          }}
        >
          <p data-tip={transaction?.errorDetails}>{transaction?.errorDetails.slice(0, 5)}...</p>
          <ReactTooltip type="error" />
        </div>
      ) : (
        '-'
      )}
    </>
  )

  // const userLink = useCallback(
  // 	(transaction) =>
  // 		transaction.userId ? (
  // 			<Button
  // 				className={"bikeLink"}
  // 				style={
  // 					transaction.userId && {
  // 						border: "1px solid #ced4da",
  // 						borderRadius: 2,
  // 						padding: 5,
  // 					}
  // 				}
  // 				onClick={() => {
  // 					if (transaction.userId) {
  // 						showUser(transaction);
  // 					}
  // 				}}>
  // 				{stringFromValue(transaction?.userName)}
  // 			</Button>
  // 		) : (
  // 			<p>{stringFromValue(transaction?.userName)}</p>
  // 		),
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // 	[]
  // );

  const userLink = useCallback(
    (transaction) =>
      transaction.userId ? (
        <Link
          to={`/dashboard/user/${transaction.userId}`}
          target="_blank"
          className={'bikeLink'}
          style={transaction.userId && { border: '1px solid #ced4da', borderRadius: 2, padding: 5 }}
        >
          {stringFromValue(transaction?.userName)}
        </Link>
      ) : (
        <p>{stringFromValue(transaction?.userName)}</p>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const titles = useMemo(
    () => [
      '',
      strings('descriptions.transactionId'),
      strings('descriptions.userName'),
      strings('descriptions.status'),
      strings('descriptions.amount'),
      strings('descriptions.authorizedAmount'),
      strings('descriptions.capturedAmount'),
      strings('descriptions.walletCapturedAmount'),
      strings('descriptions.walletDueAmount'),
      strings('descriptions.directPaymentFee'),
      strings('descriptions.authorizedDate'),
      strings('descriptions.capturedDate'),
      strings('descriptions.planType'),
      strings('descriptions.paymentType'),
      strings('descriptions.cityProject'),
      strings('descriptions.link'),
      strings('descriptions.errorDetails'),
    ],
    []
  )

  const link = (transaction) => (
    <p
      style={transaction && { color: 'blue', cursor: 'pointer' }}
      onClick={() => clickOnLink(transaction)}
    >
      {transactionLink(transaction)}
    </p>
  )

  const editAction = useCallback(
    (transaction) => {
      getPaymentById(transaction.id)
    },
    [getPaymentById]
  )

  const editActions = (transaction) => (
    <IconButton className={classes.iconButton} onClick={() => editAction(transaction)}>
      <EditIcon />
    </IconButton>
  )
  const sortedFields = useMemo(
    () => [
      sortedItem(1, TRANSACTION_ID, orderProps),
      sortedItem(2, TRANSACTION_USER_NAME, orderProps),
      sortedItem(3, TRANSACTION_STATUS, orderProps),
      sortedItem(4, TRANSACTION_AMOUNT, orderProps),
      sortedItem(5, TRANSACTION_AUTHORIZED_AMOUNT, orderProps),
      sortedItem(6, TRANSACTION_CAPTURED_AMOUNT, orderProps),
      sortedItem(7, TRANSACTION_WALLET_CAPTURED_AMOUNT, orderProps),
      sortedItem(8, TRANSACTION_WALLET_DUE_AMOUNT, orderProps),
      sortedItem(9, TRANSACTION_DIRECT_PAYMENT_FEE, orderProps),
      sortedItem(10, TRANSACTION_AUTHORIZED_DATE, orderProps),
      sortedItem(11, TRANSACTION_CAPTURED_DATE, orderProps),
      sortedItem(12, TRANSACTION_SUBSCR_TYPE, orderProps),
      sortedItem(13, TRANSACTION_TYPE, orderProps),
      sortedItem(14, TRANSACTION_CITY, orderProps),
    ],
    [orderProps]
  )

  const getPricingPlanType = (baseType, isWallet) => {
    if (isWallet) {
      return strings('descriptions.wallet')
    } else {
      return baseType.replace(/_/g, ' ')
    }
  }

  const paymentTypeAndCouponLink = (transaction) => {
    const paymentType = removeUnderscoreGetString(transaction.type)

    return (
      <div>
        <p>{paymentType}</p>
        {transaction?.couponId && (
          <Link to={`/dashboard/coupon-user/${transaction?.couponId}`} target={'_blank'}>
            <p
              style={{
                fontSize: 14,
              }}
            >
              Show Coupon
            </p>
          </Link>
        )}
      </div>
    )
  }
  const tabletBody = () => (
    <TableBody>
      {transactions.map((transaction) => (
        <TableItemRow
          id={transaction.id}
          key={transaction.id}
          items={[
            editActions(transaction),
            transaction.id,
            userLink(transaction),
            transaction.status.replace(/_/g, ' '),
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.amount || 0) / 100
            )}`,
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.authorizedAmount || 0) / 100
            )}`,
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.capturedAmount || 0) / 100
            )}`,
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.walletCapturedAmount || 0) / 100
            )}`,
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.walletDueAmount || 0) / 100
            )}`,
            `${modifyCurrencyToSymbolAndValue(
              transaction.currency,
              (transaction?.directPaymentFee || 0) / 100
            )}`,
            momentDate(transaction.authorizedDate),
            momentDate(transaction.capturedDate || transaction.walletCapturedDate),
            getPricingPlanType(transaction?.plan?.type, transaction?.plan?.paymentMethodWallet),
            paymentTypeAndCouponLink(transaction),
            stringFromValue(cityNameById(citiesList, transaction.cityId)),
            link(transaction),
            getErrorDetails(transaction),
          ]}
        />
      ))}
    </TableBody>
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table stickyHeader={true} className={classes.table} aria-label="simple table">
            <TableHeaderRow items={titles} sortedIndexes={sortedFields} />
            {tabletBody()}
          </Table>
        </TableContainer>
        <TabletPaginator
          items={transactions}
          pageData={transactionResult}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ cities: { savedCity } }) => ({
  savedCity,
})

const mapDispatchToProps = {
  getPaymentById,
  getUsers,
  setPassedParams,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionItem)
