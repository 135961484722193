import { GET_METHOD, PATCH_METHOD, POST_METHOD } from '../constants/apiMethods'
import * as endpoints from '../configs/endpoints'
import * as types from '../constants/actionTypes'

export const getPayment = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getPayment(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [types.GET_PAYMENT_REQUEST, types.GET_PAYMENT_SUCCESS, types.GET_PAYMENT_FAILURE],
  }
}

export const getPaymentById = (id) => {
  return {
    endpoint: endpoints.getPaymentById(id),
    method: GET_METHOD,
    types: [
      types.GET_PAYMENT_BY_ID_REQUEST,
      types.GET_PAYMENT_BY_ID_SUCCESS,
      types.GET_PAYMENT_BY_ID_FAILURE,
    ],
  }
}

export const getCurrenciesMappings = () => {
  return {
    endpoint: endpoints.getCurrencyMapping,
    method: GET_METHOD,
    types: [
      types.GET_CURRENCY_MAPPINGS_REQUEST,
      types.GET_CURRENCY_MAPPINGS_SUCCESS,
      types.GET_CURRENCY_MAPPINGS_FAILURE,
    ],
  }
}

export const clearPaymentData = () => {
  return {
    type: types.CLEAR_PAYMENT_DATA,
  }
}

export const clearWalletPresetsData = () => {
  return {
    type: types.CLEAR_WALLET_PRESETS,
  }
}

export const saveWalletPresetsAction = (name, code, topupPresets) => {
  return {
    endpoint: endpoints.saveWalletPresetsApi(),
    method: PATCH_METHOD,
    types: [
      types.GET_WALLET_PRESETS_REQUEST,
      types.GET_WALLET_PRESETS_SUCCESS,
      types.GET_WALLET_PRESETS_FAILURE,
    ],
    body: {
      name,
      code,
      topupPresets,
    },
  }
}

export const getWalletPresetsAction = (code) => {
  return {
    endpoint: endpoints.getWalletPresetsByWhitelabel(code),
    method: GET_METHOD,
    types: [
      types.GET_WALLET_WHITELABEL_PRESET_REQUEST,
      types.GET_WALLET_WHITELABEL_PRESET_SUCCESS,
      types.GET_WALLET_WHITELABEL_PRESET_FAILURE,
    ],
  }
}

export const getUserDetailInfo = (userId) => {
  return {
    endpoint: endpoints.userDetailInfo(userId),
    method: GET_METHOD,
    types: [
      types.GET_USER_DETAIL_INFO_REQUEST,
      types.GET_USER_DETAIL_INFO_SUCCESS,
      types.GET_USER_DETAIL_INFO_FAILURE,
    ],
  }
}

export const getUserLedgerAction = (
  page,
  search,
  selectedCity,
  sort,
  filter,
  size,
  userId,
  startDate,
  endDate
) => {
  return {
    endpoint: endpoints.getUserLedgerApi(
      page,
      search,
      selectedCity,
      sort,
      filter,
      size,
      userId,
      startDate,
      endDate
    ),
    method: GET_METHOD,
    types: [
      types.GET_USER_LEDGER_REQUEST,
      types.GET_USER_LEDGER_SUCCESS,
      types.GET_USER_LEDGER_FAILURE,
    ],
  }
}

export const adjustWalletBalance = (body) => {
  return {
    endpoint: endpoints.adjustWalletBalance,
    method: POST_METHOD,
    types: [
      types.ADJUST_WALLET_BALANCE_REQUEST,
      types.ADJUST_WALLET_BALANCE_SUCCESS,
      types.ADJUST_WALLET_BALANCE_FAILURE,
    ],
    body,
  }
}

export const couponUserList = (page, search, selectedCity, sort, filter, size, couponId) => {
  return {
    endpoint: endpoints.couponUserList(page, search, selectedCity, sort, filter, size, couponId),
    method: GET_METHOD,
    types: [
      types.GET_USER_COUPON_LIST_REQUEST,
      types.GET_USER_COUPON_LIST_SUCCESS,
      types.GET_USER_COUPON_LIST_FAILURE,
    ],
  }
}
