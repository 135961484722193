import * as types from '../constants/actionTypes'
import {
  ASC,
  DEFAULT_PAGE_SIZE,
  itemsTypes,
  stationStates,
  stationTypes,
  bikesStates,
} from "../constants/const";

let initialState = {
  pageSize: DEFAULT_PAGE_SIZE,
  currentPageValue: 0,
  searchValue: "",
  filterValue: null,
  orderBy: "",
  order: ASC,
  typeFilter: itemsTypes,
  stationTypeFilter: stationTypes,
  stationStateFilter: stationStates,
  bikeStateFilter: bikesStates,
  passedParams: null,
};

const screen = (state = initialState, action) => {
  switch(action.type) {
    case types.SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.value,
      }
    }
    case types.SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.value,
      }
    }
    case types.SET_FILTER_VALUE: {
      return {
        ...state,
        filterValue: action.value,
      }
    }
    case types.SET_CURRENT_PAGE_VALUE: {
      return {
        ...state,
        currentPageValue: action.value,
      }
    }
    case types.SET_ORDER_BY: {
      return {
        ...state,
        orderBy: action.value,
      }
    }
    case types.SET_ORDER: {
      return {
        ...state,
        order: action.value,
      }
    }
    case types.SET_MAP_TYPE_FILTER: {
      return {
        ...state,
        typeFilter: action.value,
      }
    }
    case types.SET_MAP_BIKE_STATE_FILTER: {
      return {
        ...state,
        bikeStateFilter: action.value,
      }
    }
    case types.SET_MAP_STATION_TYPE_FILTER: {
      return {
        ...state,
        stationTypeFilter: action.value,
      }
    }
    case types.SET_MAP_STATION_STATE_FILTER: {
      return {
        ...state,
        stationStateFilter: action.value,
      }
    }
    case types.SET_PASSED_PARAMS: {
      return {
        ...state,
        passedParams: action.value,
      }
    }
    case types.EMPTY_PASSED_PARAMS: {
      return {
        ...state,
        passedParams: null,
      }
    }
    default:
      return state
  }
};

export default screen;
