import React from 'react'
import Moment from 'moment'
import ModalContainer from '../modal'
import { useStyles } from '../../../utils/styles'
import ModalText from '../modalTextField'
import { strings } from '../../../I18n'
import { DateTimePicker } from '@material-ui/pickers'
import './styles.scss'

function TransactionModal(props) {
  const { visible, transaction = null, closeTransactionModal, citiesList } = props
  const classes = useStyles()

  const momentDate = (date) => (date ? Moment(date + 'Z').format('YYYY-MM-DDTHH:mm') : null)

  const datePicker = (title, defaultValue) => (
    <DateTimePicker
      id={title}
      margin="dense"
      className={'dateTimePicker'}
      fullWidth
      disabled={true}
      variant="inline"
      format={'DD-MM-YYYY HH:mm'}
      label={title}
      value={momentDate(defaultValue)}
    />
  )

  const transactionInfoContainer = (
    <div>
      <ModalText label={strings('descriptions.amount')} value={(transaction?.amount || 0) / 100} />
      <ModalText
        label={strings('descriptions.capturedAmount')}
        value={(transaction?.capturedAmount || 0) / 100}
      />
      <ModalText
        label={strings('descriptions.authorizedAmount')}
        value={(transaction?.authorizedAmount || 0) / 100}
      />

      {transaction?.walletCapturedAmount && (
        <ModalText
          label={strings('descriptions.walletCapturedAmount')}
          value={(transaction?.walletCapturedAmount || 0) / 100}
        />
      )}

      {transaction?.walletDueAmount && (
        <ModalText
          label={strings('descriptions.walletDueAmount')}
          value={(transaction?.walletDueAmount || 0) / 100}
        />
      )}

      {transaction?.directPaymentFee && (
        <ModalText
          label={strings('descriptions.directPaymentFee')}
          value={(transaction?.directPaymentFee || 0) / 100}
        />
      )}

      <ModalText label={strings('descriptions.status')} value={transaction?.status} />
      <ModalText
        label={strings('descriptions.city')}
        value={citiesList.find((city) => city.id === transaction?.cityId)?.name}
      />
      <ModalText label={strings('descriptions.status')} value={transaction?.status} />
      {datePicker(strings('descriptions.capturedDate'), transaction?.capturedDate)}
    </div>
  )

  return (
    <ModalContainer
      className={classes.modal}
      visible={visible}
      closeAction={closeTransactionModal}
      body={<div>{transactionInfoContainer}</div>}
    />
  )
}

export default TransactionModal
