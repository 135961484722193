import React from 'react';
import './styles.scss';
import FilterDialog from "../filter";
import FilterItem from "../filterItem";
import { FILTER_BY_STATUS, STATION_NAME } from "../../../constants/filterConst";

function DockFilter(props) {
  const {visible, hideFilter, changeSelectorValue, dockStatuses, agree, disagree, filterProps} = props;
  const handleChangeSelectorValue = (filter, filterBy) => changeSelectorValue(filter, filterBy);

  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        <>
          <FilterItem
            filterBy={FILTER_BY_STATUS}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={dockStatuses.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
          <FilterItem
            filterBy={STATION_NAME}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={['st1', 'st2'].map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
        </>
      }
    />
  )
}

export default DockFilter;
