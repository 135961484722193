/*eslint-disable*/
import React from 'react'
import ModalContainer from '../modal'
import { useStyles } from '../../../utils/styles'
import { strings } from '../../../I18n'
import TextField from '@mui/material/TextField/TextField'
import MenuItem from '@mui/material/MenuItem'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { DOCK_TYPE, STATUS, USER_TYPE } from '../../../constants/const'
import Moment from 'moment'
import { DateTimePicker } from '@material-ui/pickers'
import { useShowToast } from '../../../hooks'
import Autocomplete from '@mui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { mainUserList } from '../../../actions/rentals'

function RentalModal(props) {
  const { visible, clearRental, updateRental, rental, stations, vehicles, docks, statuses } = props

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [startStation, setStartStation] = React.useState('')
  const [endStation, setEndStation] = React.useState('')
  const [startDock, setStartDock] = React.useState('')
  const [endDock, setEndDock] = React.useState('')
  const [bike, setBike] = React.useState('')
  const [user, setUser] = React.useState('')
  const [status, setStatus] = React.useState('')
  const classes = useStyles()
  const [allUser, setAllUser] = React.useState([])
  const [changeduser, setChangeduser] = React.useState()

  const rentalUsers = useSelector((state) => state.rentals.mainUserList)

  React.useEffect(() => {
    setAllUser(rentalUsers)
  }, [rentalUsers])

  const initValue = (rental) => {
    setStartDate(rental?.startTime)
    setEndDate(rental?.endTime)
    setStartStation(rental?.startStationCode)
    setEndStation(rental?.endStationCode)
    setStartDock(rental?.startDockCode)
    setEndDock(rental?.endDockCode)
    setBike(rental?.vehicleCode)
    setUser(rental?.userId)
    setStatus(rental?.status)
  }

  React.useEffect(() => {
    initValue(rental)
  }, [rental])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const changeStartDate = (value) => setStartDate(value)

  const changeEndDate = (value) => setEndDate(value)

  const changeStartStation = ({ target }) => setStartStation(target.value)

  const changeEndStation = ({ target }) => setEndStation(target.value)

  const changeStartDock = ({ target }) => setStartDock(target.value)

  const changeEndDock = ({ target }) => setEndDock(target.value)

  const changeBike = ({ target }) => setBike(target.value)

  const changeUser = ({ target }) => setUser(target.value)

  const changeStatus = ({ target }) => setStatus(target.value)

  const showToast = useShowToast()
  const dispatch = useDispatch()

  const save = () => {
    if (Moment(endDate).isBefore(rental?.startTime)) {
      showToast('End time cannot be before start time', 'error')
    } else {
      const body = {
        ...rental,
        startTime: startDate,
        endTime: endDate,
        startStationCode: startStation,
        endStationCode: endStation,
        startDockCode: startDock,
        endDockCode: endDock,
        vehicleCode: bike,
        userId: changeduser ? changeduser : rental?.userId,
        status,
      }
      updateRental(rental?.id, body)
    }
  }

  const momentDate = (date) => (date ? Moment(date + 'Z').format('YYYY-MM-DDTHH:mm') : null)

  const datePicker = (action, title, defaultValue) => (
    <DateTimePicker
      id={title}
      margin="dense"
      fullWidth
      ampm={false}
      variant="inline"
      format={'DD-MM-YYYY HH:mm'}
      label={title}
      value={momentDate(defaultValue)}
      onChange={(value) => action(Moment(value).utc().format('YYYY-MM-DDTHH:mm:ss'))}
    />
  )

  const dropContent = (type, item) => {
    if (type === DOCK_TYPE) {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.code}
        </MenuItem>
      )
    } else if (type === USER_TYPE) {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.fullName}
        </MenuItem>
      )
    } else if (type === STATUS) {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      )
    } else {
      return (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      )
    }
  }

  const dropInput = (action, value, label, items, type) => (
    <TextField
      defaultValue={value}
      value={value}
      onChange={action}
      margin={'dense'}
      id={label}
      label={label}
      select
      fullWidth
    >
      {items?.map((item) => dropContent(type, item))}
    </TextField>
  )

  const buttonContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton
        className={'stationModalActionButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={save}
      >
        {strings('descriptions.save')}
      </ActionButton>
      <ActionButton
        className={'stationModalActionButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={clearRental}
      >
        {strings('descriptions.discard')}
      </ActionButton>
    </div>
  )

  const searchUser = (searchName) => {
    dispatch(mainUserList(searchName))
  }

  const autoCompleteUser = () => {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={allUser || []}
        getOptionLabel={(option) => option?.fullName || ''}
        style={{ width: '100%' }}
        defaultValue={{ id: rental?.userId, fullName: rental?.userName }}
        onInputChange={(event, value) => {
          searchUser(value)
        }}
        onChange={(event, value) => {
          setChangeduser(value?.id)
        }}
        // onInputChange={(event, value) => {
        //   searchUser(value)
        // }}
        renderInput={(params) => (
          <TextField
            id="combo-box-demo"
            {...params}
            label={strings('title.users')}
            variant="standard"
          />
        )}
      />
    )
  }

  return (
    <ModalContainer
      visible={visible}
      closeAction={clearRental}
      className={classes.rental}
      body={
        <div>
          {datePicker(changeStartDate, strings('descriptions.startDate'), startDate)}
          {datePicker(changeEndDate, strings('descriptions.endDate'), endDate)}
          {dropInput(
            changeStartStation,
            startStation,
            strings('descriptions.startStation'),
            stations
          )}
          {dropInput(changeEndStation, endStation, strings('descriptions.endStation'), stations)}
          {dropInput(changeStatus, status, strings('descriptions.status'), statuses, STATUS)}
          {dropInput(
            changeStartDock,
            startDock,
            strings('descriptions.startDock'),
            docks,
            DOCK_TYPE
          )}
          {dropInput(changeEndDock, endDock, strings('descriptions.endDock'), docks, DOCK_TYPE)}
          {dropInput(changeBike, bike, strings('descriptions.bike'), vehicles)}
          {/*{dropInput(changeUser, user, strings('descriptions.user'), users, USER_TYPE)}*/}
          {autoCompleteUser()}
          {buttonContainer()}
        </div>
      }
    />
  )
}

export default RentalModal
