import React from 'react'
import './styles.scss'
import FilterDialog from '../filter'
import FilterItem from '../filterItem'
import { FILTER_BY_PAYMENT_STATUS, FILTER_BY_TRANS_PLAN_TYPE } from '../../../constants/filterConst'

function TransactionFilter2(props) {
  const { visible, hideFilter, changeSelectorValue, filterProps, disagree, agree } = props

  const handleChangeSelectorValue = (filter, filterBy) => changeSelectorValue(filter, filterBy)
  const stationVehicleStatuses = ['AUTHORIZED', 'CAPTURED', 'CANCELED', 'ERROR']
  const planTypes = ['PAY_AS_YOU_GO', 'SUBSCRIPTION', 'BUSINESS']
  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        // <FilterItem
        //   filterBy={FILTER_BY_CITY_ID}
        //   changeSelectorValue={handleChangeSelectorValue}
        //   selectorItems={citiesList}
        // />
        <>
          <FilterItem
            filterBy={FILTER_BY_PAYMENT_STATUS}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={stationVehicleStatuses.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
          <FilterItem
            filterBy={FILTER_BY_TRANS_PLAN_TYPE}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={planTypes.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
        </>
      }
    />
  )
}

export default TransactionFilter2
