/* eslint-disable */
import React, { useState } from 'react'
import './styles.scss'
import { Checkbox } from '@mui/material'
import DataTable from 'react-data-table-component'
import TablePagination from '@mui/material/TablePagination'
import { PAGE_SIZES } from '../../../constants/const'
import TablePaginationActionsCustom from '../paginatorActionCustom'
import { useStyles } from '../../../utils/styles'
import SearchBlockWithRefreshBtn from '../searchBlockWithRefreshBtn'
import { useDebounce } from '../../../hooks'
import useFilter from '../../filterHook'
import { ReactComponent as BikeLoader } from '../../../images/loader.svg'

const useCustomTable = (
  columns,
  data,
  reloadData,
  addButtonClick,
  filterObject,
  headerTitle,
  isFilterVisible = true,
  isAddVisible = true,
  onDownloadClick = null,
  searchDataOptions = null
) => {
  const [localPage, setLocalPage] = useState(0)
  const [localRows, setLocalRows] = useState(10)
  const [sortData, setSortData] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [paginationResetToggle, setPaginationResetToggle] = useState(false)
  const [searchFilter, setSearchFilter] = useState()

  const resetPagination = () => {
    setPaginationResetToggle(!paginationResetToggle)
  }

  const appleFilter = () => {
    internalApply()
  }

  const {
    filterModalVisible,
    toggleFilter,
    renderGenericFilter,
    filterData,
    removeFilterByIndex,
    internalApply,
  } = useFilter(appleFilter, filterObject)

  const classes = useStyles()

  const CustomMaterialPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
  }) => (
    <TablePagination
      component={'div'}
      rowsPerPageOptions={PAGE_SIZES}
      colSpan={3}
      count={rowCount}
      rowsPerPage={rowsPerPage}
      page={currentPage - 1}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onPageChange={onChangePage}
      onRowsPerPageChange={({ target }) => onChangeRowsPerPage(Number(target.value))}
      ActionsComponent={TablePaginationActionsCustom}
      className={classes.pagination}
    />
  )

  const debouncedSearchTerm = useDebounce(searchValue, 500)

  const changeSearchValue = (e) => {
    setSearchValue(e.target.value)
  }
  const clearSearchValue = () => {
    setSearchValue('')
  }

  const handleSort = async (column, sortDirection) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
    setSortData({ column, sortDirection })
    // setData(remoteData);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLocalRows(newPerPage)
    // setLocalPage(page - 1)
  }
  const handlePageChange = (page) => {
    setLocalPage(page - 1)
  }

  const customTableStyles = {
    headCells: {
      style: {
        backgroundColor: '#f9f9f9',
        paddingLeft: '3px', // override the cell padding for head cells
        paddingRight: '3px',
        height: 80,
        fontSize: 14,
        lineHeight: '14px',
        maxWidth: 100,
      },
    },
    table: {
      style: {
        padding: 10,
        borderWidth: 1,
        minWidth: 750,
      },
    },
    tableWrapper: {
      style: {},
    },
    cells: {
      style: {
        padding: 6,
        wordBreak: 'break-word',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.43,
      },
      draggingStyle: {},
    },
  }

  const renderCustomTable = () => {
    /**
     * react-data-table doesn't work with string selector, but with function
     */
    const columnsProceed = columns?.map((column) => {
      const key = column?.selector
      let selector = key

      if (typeof selector === 'string') {
        selector = (row) => row[key]
      }

      return { ...column, selector: selector }
    })

    return (
      <>
        <DataTable
          columns={columnsProceed}
          data={data?.content}
          defaultSortField="title"
          pagination
          paginationServer
          paginationTotalRows={data?.totalElements}
          className={classes.table}
          progressPending={loading}
          sortServer
          onSort={handleSort}
          customStyles={customTableStyles}
          selectableRowsComponent={Checkbox}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationComponent={CustomMaterialPagination}
          progressComponent={<BikeLoader />}
          paginationResetDefaultPage={paginationResetToggle}
        />
        {renderGenericFilter()}
      </>
    )
  }

  const renderHeaderBar = () => (
    <SearchBlockWithRefreshBtn
      title={headerTitle}
      searchValue={searchValue}
      onChange={changeSearchValue}
      clear={clearSearchValue}
      searchDataOptions={searchDataOptions}
      reloadData={reloadData}
      addAction={isAddVisible && addButtonClick}
      downloadCoupon={onDownloadClick}
      removeFilter={isFilterVisible && removeFilterByIndex}
      pressFilter={isFilterVisible && toggleFilter}
      filterProps={isFilterVisible && filterData.length > 0 ? filterData : []}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
    />
  )

  return {
    renderCustomTable,
    renderHeaderBar,
    localPage,
    localRows,
    sortData,
    debouncedSearchTerm,
    filterData,
    setLoading,
    resetPagination,
    searchFilter,
  }
}

export default useCustomTable
