import React from 'react'
import './styles.scss'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import { strings } from '../../../I18n'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { makeStyles } from '@mui/styles'
import InputBase from '@mui/material/InputBase'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CloseIcon from '@mui/icons-material/Close'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filter: {
    minWidth: 350,
  },
}))

function SearchBlock(props) {
  const {
    title,
    searchValue,
    onChange,
    pressFilter,
    addAction,
    clear,
    filterProps,
    removeFilter,
    extraContent = null,
  } = props
  const classes = useStyles()
  return (
    <div>
      <div className={'dashboardTitleContainer'}>
        <p className={'dashboardTitle'}>{title}</p>
        <div className={'searchBlockContainer'}>
          <Paper component="form" className={classes.root}>
            <InputBase
              autoFocus={!isMobile}
              className={classes.input}
              placeholder={strings('placeholders.search')}
              value={searchValue}
              onChange={onChange}
              variant={'outlined'}
            />
            <IconButton className={classes.iconButton} aria-label="clear" onClick={clear}>
              <CloseIcon />
            </IconButton>
            {(pressFilter || addAction) && (
              <Divider className={classes.divider} orientation="vertical" />
            )}
            {pressFilter && (
              <IconButton className={classes.iconButton} aria-label="filter" onClick={pressFilter}>
                <FilterListIcon />
              </IconButton>
            )}
            {addAction && (
              <IconButton className={classes.iconButton} aria-label="add" onClick={addAction}>
                <AddBoxIcon />
              </IconButton>
            )}
            {extraContent && extraContent}
          </Paper>
        </div>
      </div>
      {filterProps?.length > 0 &&
        filterProps.map(
          (filter, index) =>
            !!filter.filter && (
              <div key={index} className={'filterDescriptionBloc'}>
                <InputBase
                  className={classes.filter}
                  readOnly={true}
                  placeholder={strings('placeholders.search')}
                  value={`${filter.filterBy
                    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                    .toLowerCase()}: ${filter.filter.replace(/_/g, ' ')}`}
                  variant={'outlined'}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={() => removeFilter(index)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )
        )}
    </div>
  )
}

export default SearchBlock
