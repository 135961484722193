import { Box, Chip } from '@mui/material'
import React, { memo } from 'react'
import { LisaSelectOption } from './domain'

interface Props {
  selected: string[]
  options: LisaSelectOption[]
}

const LisaSelectedItem = ({ selected, options }: Props) => {
  if (selected.length === options.length) {
    return <Chip label={'All'} />
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value) => (
        <Chip key={value} label={value} />
      ))}
    </Box>
  )
}

export default memo(LisaSelectedItem)
