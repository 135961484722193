/* eslint-disable */
import React, { useEffect } from 'react'
import { FormInputDropdown } from '../../inputController/FormInputDropdown'
import InputController from '../../inputController'
import DatePickerController from '../../inputController/DatePickerController'
import { Box, Button, FormControl, FormControlLabel, Grid, Switch } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useRemapCities } from '../../../hooks'
import Modal from '@mui/material/Modal'
import { clearCreateCoupon } from '../../../actions/cities'
import { connect, useDispatch, useSelector } from 'react-redux'
import { strings } from '../../../I18n'
import { saveToken } from '../../../actions/auth'
import CheckboxGroup from '../../inputController/CheckBoxCntroller'
import RadioGroupController from '../../inputController/RadioGroupController'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { modifyCurrencyToSymbol } from '../../../utils/utils'

const AdjustBalanceModal = ({
  onSubmit,
  addModalVisible,
  handleClose,
  setAddModalVisible,
  currency,
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '40%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block',
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '80%',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '1px solid white',
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '8px',
    },
  }

  const groupOptions = [
    { label: 'ADD', value: 'ADD' },
    { label: 'SUBTRACT', value: 'SUBTRACT' },
  ]

  const schema = yup.object().shape({
    amount: yup.number().required().positive(),
    note: yup.string().required(),
  })
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      action: groupOptions[0].value,
    },
  })

  const { remappedCities } = useRemapCities()

  useEffect(() => {
    reset({
      action: groupOptions[0].value,
    })
  }, [addModalVisible])

  return (
    <Modal
      open={addModalVisible}
      onClose={handleClose}
      className={modalStyle}
      style={{ height: window.innerHeight }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...control}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4
              style={{
                textAlign: 'center',
              }}
            >
              {strings('adjustBalance')}
            </h4>
            <RadioGroupController
              options={groupOptions}
              name={'action'}
              control={control}
              label={strings('message.action')}
              row
            />

            <InputController
              name={'amount'}
              label={strings('descriptions.amount')}
              required={false}
              inputPrefix={modifyCurrencyToSymbol(currency)}
              styles={{
                minWidth: '400px',
              }}
            />

            <InputController
              name={'note'}
              label={strings('descriptions.remarks')}
              required={true}
              styles={{
                minWidth: '400px',
              }}
            />

            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Button type="submit" variant="contained" color="primary">
                  {strings('buttons.submit')}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setAddModalVisible(false)
                  }}
                >
                  {strings('coupon.dismiss')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}

const mapStateToProps = ({ auth: { user, token }, stations: { station }, cities: { city } }) => ({
  user,
  station,
  city,
  token,
})
const mapDispatchToProps = {
  saveToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustBalanceModal)
