import {
  generateCustomEndpoint,
  generateCustomEndpointWithSearch,
  generateEndpoint,
  generateEndpointWithSearchFilter,
  generateMapEndpoind,
} from '../utils/utils'

export const getUsers = (page, search, sort, filter, pageSize) =>
  generateEndpoint(`/payment-service/dashboard/users?size=${pageSize}`, page, search, sort, filter)

export const getUser = (id) => `/payment-service/dashboard/users/${id}`
export const removeUser = (id) => `/payment-service/dashboard/users/${id}`
export const userPlanTypes = () => '/payment-service/dashboard/pricing/plans/statuses'
export const userPlanStatuses = () => '/payment-service/dashboard/users/cities/statuses'

export const getStations = (page, search, selectedCity, sort, filter, size) =>
  generateCustomEndpoint(
    `/station-service/dashboard/stations?`,
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )

export const getMapStations = (
  typeFilter,
  stationType,
  stationState,
  bikeState,
  city,
  brokenStatuses,
  search
) => {
  return (
    generateMapEndpoind(
      '/station-service/dashboard/stations/map',
      typeFilter,
      stationType,
      stationState,
      bikeState,
      city
      // eslint-disable-next-line
    ) +
    `${
      brokenStatuses && brokenStatuses.length > 0
        ? `&brokenStatuses=${brokenStatuses.join(',')}`
        : ''
    }` +
    `${search ? `&search=${search}` : ''}`
  )
}

export const getMapStationsV2 = (paramsStr) => {
  return `/station-service/dashboard/stations/map/v2?${paramsStr}`
}

export const stationById = (id) => `/station-service/dashboard/stations/${id}`
export const unlockStation = (id) => `/station-service/dashboard/docks/${id}/unlock`
export const createStation = () => `/station-service/dashboard/stations`
export const stationVehicleStatuses = () => '/station-service/dashboard/stations/vehicles/statuses'
export const stationStatuses = () => '/station-service/dashboard/stations/statuses'
export const refreshStation = (id) => `/station-service/dashboard/stations/${id}/refresh`
export const reloginStation = (id) => `/station-service/dashboard/stations/${id}/relogin`

export const getPayment = (page, search, sort, filter, pageSize) =>
  generateEndpoint(
    `/payment-service/dashboard/payments?size=${pageSize}`,
    page,
    search,
    sort,
    filter
  )
export const getPaymentById = (id) => `/payment-service/dashboard/payments/${id}`

export const getBikeById = (id) => `/station-service/dashboard/vehicles/${id}`
export const getVehicle = (page, search, sort, filter, pageSize) =>
  generateEndpointWithSearchFilter(
    `/station-service/dashboard/vehicles?size=${pageSize}`,
    page,
    search,
    sort,
    filter
  )
export const bikeById = (id) => `/station-service/dashboard/vehicles/${id}`
export const bikeStatusById = (id) => `/station-service/dashboard/vehicles/${id}/status`
export const bikeStatus = () => `/station-service/dashboard/vehicles/statuses`
export const unlockBike = (id) => `/station-service/dashboard/vehicles/${id}/unlock`
export const alarmBike = (id) => `/station-service/dashboard/vehicles/${id}/alarm`
export const lockBike = (id) => `/station-service/dashboard/vehicles/${id}/lock`
export const getBikeBrandItems = () => '/station-service/dashboard/vehicles/brands'
export const getBikeLockStatusItems = () => '/station-service/dashboard/locks/statuses'
export const listOfBikeTypes = `/station-service/dashboard/vehicles/types`
// export const bikeHistory = (page, size, vehicleCode, startDate, endDate) => {
//   return `/journey-service/dashboard/journeys/vehicle/log?page=${page}&size=${size}&vehicleCode=${vehicleCode}&startDate=${startDate}&endDate=${endDate}`
// }

export const bikeHistory = (page, size, vehicleCode, startDate, endDate) => {
  return `/station-service/dashboard/vehicles/${vehicleCode}/history?size=${size}&page=${page}&start-date=${startDate}&end-date=${endDate}`
}

export const getLocks = (page, search, sort, filter, pageSize) =>
  generateEndpoint(`/station-service/dashboard/locks?size=${pageSize}`, page, search, sort, filter)
export const unlockLocks = (id) => `/station-service/dashboard/locks/${id}/unlock`

export const getDocks = (page, search, sort, filter, pageSize) =>
  generateEndpoint(`/station-service/dashboard/docks?size=${pageSize}`, page, search, sort, filter)
export const unlockDock = (id) => `/station-service/dashboard/docks/${id}/unlock`

export const updateDock = (id) => `/station-service/dashboard/docks/${id}`

export const dockStatuses = () => `/station-service/dashboard/docks/statuses`

export const getCities = (page, search, sort, filter, pageSize) =>
  generateEndpoint(`/station-service/dashboard/cities?size=${pageSize}`, page, search, sort, filter)
export const cityById = (id) => `/station-service/dashboard/cities/${id}`
export const createCity = () => `/station-service/dashboard/cities`
export const citiesList = () => `/station-service/dashboard/cities/list`
export const pricingPlans = (cityId) =>
  `/payment-service/dashboard/v2/user/settings/cities/plans?cityId=${cityId}`

export const rentals = (page, search, sort, filter, pageSize) =>
  generateEndpointWithSearchFilter(
    `/journey-service/dashboard/journeys?size=${pageSize}`,
    page,
    search,
    sort,
    filter
  )
export const rentalById = (id) => `/journey-service/dashboard/journeys/${id}`
export const finishRent = (id) => `/journey-service/dashboard/journeys/${id}/finish`
export const rentalsDockList = (id) =>
  `/station-service/dashboard/docks/list${id ? `?cityId=${id}` : ''}`
export const stationsListByCity = (id, onlyLinked) =>
  `/station-service/dashboard/stations/list${id ? `?cityId=${id}` : ''}${
    onlyLinked ? `${id ? '&' : '?'}linkedStation=true` : ''
  }`
export const rentalsVehiclesList = () => '/station-service/dashboard/vehicles/list'
export const rentalUserList = (search) => `/payment-service/dashboard/users/list?search=${search}`
export const userList = (search) => `/payment-service/dashboard/users/list?search=${search}`
export const rentalStatuses = () => '/journey-service/dashboard/journeys/statuses'
export const finallyDataOfRental = (id) => `/journey-service/dashboard/journeys/${id}/state`
export const getVehicleByCode = (code) => `/station-service/dashboard/vehicles/one?code=${code}`

export const getAdminInfo = () => `/account-service/dashboard/profile/admin`
export const cancelSubscription = (cityId, userId, planId, date) =>
  `/payment-service/dashboard/users/subscription/unsubscribe/city/${cityId}/user/${userId}/plans/${planId}/cancel?cancelAt=${date}`
export const getReferrals = (page, search, selectedCity, sort, filter, size) =>
  `${generateCustomEndpoint(
    '/coupon-service/dashboard/referral?',
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )}`
export const getAllCoupon = (page, search, selectedCity, sort, filter, size) =>
  `${generateCustomEndpointWithSearch(
    '/coupon-service/dashboard/coupon?',
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )}`

export const getAllManagedUser = (page, search, selectedCity, sort, filter, size) =>
  `${generateCustomEndpoint(
    '/account-service/dashboard/internal-user?',
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )}`
export const getAllBooking = (page, search, selectedCity, sort, filter, size) =>
  `${generateCustomEndpoint(
    '/station-service/dashboard/booking/?',
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )}`
export const cancelBooking = (code) => `/station-service/dashboard/booking/${code}/cancel`
export const createCoupon = () => `/coupon-service/dashboard/coupon`
export const downloadCoupon = (prefix) =>
  `/coupon-service/dashboard/coupon/download/list?prefix=${prefix}`
export const getCouponById = (couponId) => `/coupon-service/dashboard/coupon/${couponId}`
export const deleteCouponById = (couponId) => `/coupon-service/dashboard/coupon/${couponId}`
export const updateCouponById = (couponId) => `/coupon-service/dashboard/coupon/${couponId}`
export const deleteReferralById = (couponId) => `/coupon-service/dashboard/referral/${couponId}`

export const couponListByUser = (userId, cityId) =>
  `/coupon-service/dashboard/user-coupon/${userId}/${cityId}/active`
export const deleteCouponByUser = (userId, cityId) =>
  `/coupon-service/dashboard/user-coupon/${userId}/${cityId}`

export const lockBattery = (id) => `station-service/dashboard/vehicles/${id}/battery/lock`
export const unLockBattery = (id) => `station-service/dashboard/vehicles/${id}/battery/unlock`
export const saveAppVersion = `/station-service/dashboard/app-version`
export const getAppVersion = (whitelabelCode) =>
  `/station-service/dashboard/app-version/${whitelabelCode}`
export const getBrokenVehicleStatus = () => `/station-service/dashboard/vehicles/broken`

export const getImageDirectoryDataEndpoint = (id) =>
  `/journey-service/dashboard/journeys/${id}/directory-data`

export const getWhiteLabelList = () => '/station-service/dashboard/whitelabels'
export const saveWalletPresetsApi = () => '/station-service/dashboard/whitelabels/topup/preset'
export const userDetailInfo = (userId) => `/payment-service/dashboard/wallet/info/${userId}`
export const getWalletPresetsByWhitelabel = (whitelabelCode) =>
  `/station-service/dashboard/whitelabels/${whitelabelCode}/topup/preset`

// export const getUserLedgerApi = (userId) => `/payment-service/dashboard/wallet?userId=${userId}`
export const getUserLedgerApi = (
  page,
  search,
  selectedCity,
  sort,
  filter,
  size,
  userId,
  startDate,
  endDate
) =>
  `${generateCustomEndpoint(
    `/payment-service/dashboard/wallet?`,
    page,
    search,
    selectedCity,
    sort,
    filter,
    size,
    userId,
    startDate,
    endDate
  )}`

export const getAllVirtualStationsApi = (cityId) =>
  `/station-service/dashboard/stations/virtual?cityId=${cityId}`

export const getPricingPlanDetailsById = (planId) =>
  `/payment-service/dashboard/pricing/plan/${planId}`
export const getDraftPricingPlanDetailsById = (planId) =>
  `/payment-service/dashboard/pricing/draft/${planId}`

export const getPricingPlanFields = `/payment-service/dashboard/pricing/plan/fields`
export const deletePricingPlan = (id) => `/payment-service/dashboard/pricing/plan/${id}`
export const deleteDraftPricingPlan = (id) => `/payment-service/dashboard/pricing/draft/${id}`
export const createInternalUser = `/account-service/dashboard/internal-user`
export const updateInternalUser = (email) => `/account-service/dashboard/internal-user/${email}`
export const deleteInternalUser = (email) => `/account-service/dashboard/internal-user/${email}`
export const getInternalUser = (email) => `/account-service/dashboard/internal-user/${email}`
export const getCurrencyMapping = `/payment-service/dashboard/pricing/currencies`
export const getCityWhitelabelMappings = `/station-service/dashboard/whitelabels`
export const adjustWalletBalance = `/payment-service/dashboard/wallet/adjust-balance`
export const couponUserList = (page, search, selectedCity, sort, filter, size, couponId) =>
  `${generateCustomEndpoint(
    `/coupon-service/dashboard/user-coupon?`,
    page,
    search,
    selectedCity,
    sort,
    filter,
    size,
    '',
    '',
    '',
    couponId
  )}`

export const createPricingPlan = `/payment-service/dashboard/pricing/plan`
export const updatePricingPlan = (planId) => `/payment-service/dashboard/pricing/plan/${planId}`
export const getSpeedLimitZones = (page, search, selectedCity, sort, filter, size) =>
  `${generateCustomEndpoint(
    '/station-service/dashboard/limit/zone?',
    page,
    search,
    selectedCity,
    sort,
    filter,
    size
  )}`

export const createLimitZones = `/station-service/dashboard/limit/zone/`
export const updateLimitZones = (id) => `/station-service/dashboard/limit/zone/${id}`
export const getLimitZonesById = (id) => `/station-service/dashboard/limit/zone/${id}`
