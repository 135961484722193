import React from "react";
import "./styles.scss";
import FilterDialog from "../filter";
import FilterItem from "../filterItem";
import {
  FILTER_BY_STATUS,
} from "../../../constants/filterConst";

function RentalFilter(props) {
  const {
    visible,
    hideFilter,
    changeSelectorValue,
    agree,
    disagree,
    filterProps,
    statuses = [],
  } = props;

  const handleChangeSelectorValue = (filter, filterBy) =>
    changeSelectorValue(filter, filterBy);

  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        <>
          <FilterItem
            filterBy={FILTER_BY_STATUS}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={statuses.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
        </>
      }
    />
  );
}

export default RentalFilter;
