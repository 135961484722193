import React from 'react'
import './styles.scss'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import { strings } from '../../../I18n'

function ConfirmationDialog(props) {
  const {
    visibility,
    hideDialog,
    positiveAction,
    negativeAction,
    title,
    content,
    selectedStation,
    disableAgree,
  } = props

  const handleCloseDialog = React.useCallback(() => {
    hideDialog()
  }, [hideDialog])

  return (
    <div>
      <Dialog open={!!visibility} onClose={handleCloseDialog}>
        <DialogTitle>{title}</DialogTitle>
        {content}
        <DialogActions>
          <Button
            onClick={positiveAction}
            color={'primary'}
            disabled={disableAgree || selectedStation === ''}
          >
            {strings('buttons.agree')}
          </Button>

          <Button onClick={negativeAction} color={'primary'}>
            {strings('buttons.disagree')}
          </Button>
          {/* <Button onClick={positiveAction} color={"primary"} autoFocus>
						{strings("buttons.agree")}
					</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
