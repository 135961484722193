import * as endpoints from '../configs/endpoints'
import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from '../constants/apiMethods'
import * as types from '../constants/actionTypes'

export const getCities = (page = 0, search = '', sort = '', filter = '', pageSize = 10) => {
  return {
    endpoint: endpoints.getCities(page, search, sort, filter, pageSize),
    method: GET_METHOD,
    types: [types.GET_CITIES_REQUEST, types.GET_CITIES_SUCCESS, types.GET_CITIES_FAILURE],
  }
}

export const getCityById = (id) => {
  return {
    endpoint: endpoints.cityById(id),
    method: GET_METHOD,
    types: [
      types.GET_CITY_BY_ID_REQUEST,
      types.GET_CITY_BY_ID_SUCCESS,
      types.GET_CITY_BY_ID_FAILURE,
    ],
  }
}

export const updateCity = (id, body) => {
  return {
    endpoint: endpoints.cityById(id),
    method: PUT_METHOD,
    types: [types.UPDATE_CITY_REQUEST, types.UPDATE_CITY_SUCCESS, types.UPDATE_CITY_FAILURE],
    body,
  }
}

export const createCity = (body) => {
  return {
    endpoint: endpoints.createCity(),
    method: POST_METHOD,
    types: [types.CREATE_CITY_REQUEST, types.CREATE_CITY_SUCCESS, types.CREATE_CITY_FAILURE],
    body,
  }
}

export const cityList = () => {
  return {
    endpoint: endpoints.citiesList(),
    method: GET_METHOD,
    types: [
      types.GET_CITIES_LIST_REQUEST,
      types.GET_CITIES_LIST_SUCCESS,
      types.GET_CITIES_LIST_FAILURE,
    ],
  }
}

export const clearCity = () => {
  return {
    type: types.CLEAR_CITY,
  }
}

export const clearSavedCity = () => {
  return {
    type: types.CLEAR_SAVED_CITY,
  }
}

export const selectCity = (city) => {
  return {
    type: types.SAVE_CITY,
    payload: city,
  }
}

export const selectCityFullInfo = (city) => {
  return {
    type: types.SAVE_CITY_WITH_FULL_INFO,
    payload: city,
  }
}

export const getPricingPlans = (cityId) => {
  return {
    endpoint: endpoints.pricingPlans(cityId),
    method: GET_METHOD,
    types: [
      types.GET_CURRENT_PRICING_PLAN_REQUEST,
      types.GET_CURRENT_PRICING_PLAN_SUCCESS,
      types.GET_CURRENT_PRICING_PLAN_FAILURE,
    ],
  }
}

export const clearPricingPlans = () => {
  return {
    type: types.CLEAR_PRICING_PLANS,
  }
}

export const getUserReferralsList = (page, search, selectedCity, sort, filter, size) => {
  return {
    endpoint: endpoints.getReferrals(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [
      types.GET_USER_REFERRAL_REQUEST,
      types.GET_USER_REFERRAL_SUCCESS,
      types.GET_USER_REFERRAL_FAILURE,
    ],
  }
}

export const getAllCouponList = (page, search, selectedCity, sort, filter, size) => {
  return {
    endpoint: endpoints.getAllCoupon(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [
      types.GET_COUPON_LIST_REQUEST,
      types.GET_COUPON_LIST_SUCCESS,
      types.GET_COUPON_LIST_FAILURE,
    ],
  }
}

export const getAllBookingList = (page, search, selectedCity, sort, filter, size) => {
  return {
    endpoint: endpoints.getAllBooking(page, search, selectedCity, sort, filter, size),
    method: GET_METHOD,
    types: [
      types.GET_BOOKING_LIST_REQUEST,
      types.GET_BOOKING_LIST_SUCCESS,
      types.GET_BOOKING_LIST_FAILURE,
    ],
  }
}

export const cancelBookingByCode = (code) => {
  return {
    endpoint: endpoints.cancelBooking(code),
    method: PUT_METHOD,
    types: [
      types.CANCEL_BOOKING_REQUEST,
      types.CANCEL_BOOKING_SUCCESS,
      types.CANCEL_BOOKING_FAILURE,
    ],
  }
}

export const clearCancelBooking = () => {
  return {
    type: types.CLEAR_CANCEL_BOOKING,
  }
}

export const createCouponApi = (body) => {
  return {
    endpoint: endpoints.createCoupon(),
    method: POST_METHOD,
    types: [
      types.POST_COUPON_CREATE_REQUEST,
      types.POST_COUPON_CREATE_SUCCESS,
      types.POST_COUPON_CREATE_FAILURE,
    ],
    body,
  }
}
export const downloadCouponWithPrefix = (prefix) => {
  return {
    endpoint: endpoints.downloadCoupon(prefix),
    method: GET_METHOD,
    types: [
      types.GET_COUPON_DOWNLOAD_REQUEST,
      types.GET_COUPON_DOWNLOAD_SUCCESS,
      types.GET_COUPON_DOWNLOAD_FAILURE,
    ],
  }
}
export const clearDownloadedCoupon = () => {
  return {
    type: types.CLEAR_DOWNLOADED_COUPON,
  }
}
export const clearCreateCoupon = () => {
  return {
    type: types.CLEAR_CREATE_COUPON,
  }
}

export const clearDeleteCoupon = () => {
  return {
    type: types.CLEAR_DELETE_MAIN_COUPON,
  }
}

export const getSingleCouponById = (id) => {
  return {
    endpoint: endpoints.getCouponById(id),
    method: GET_METHOD,
    types: [types.COUPON_BY_ID_REQUEST, types.COUPON_BY_ID_SUCCESS, types.COUPON_BY_ID_FAILURE],
  }
}

export const editSingleCouponById = (id, body) => {
  return {
    endpoint: endpoints.updateCouponById(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_COUPON_BY_ID_REQUEST,
      types.UPDATE_COUPON_BY_ID_SUCCESS,
      types.UPDATE_COUPON_BY_ID_FAILURE,
    ],
    body,
  }
}

export const deleteSingleReferralById = (id) => {
  return {
    endpoint: endpoints.deleteReferralById(id),
    method: DELETE_METHOD,
    types: [types.DELETE_COUPON_REQUEST, types.DELETE_COUPON_SUCCESS, types.DELETE_COUPON_FAILURE],
  }
}

export const deleteSingleCouponById = (id) => {
  return {
    endpoint: endpoints.deleteCouponById(id),
    method: DELETE_METHOD,
    types: [
      types.DELETE_MAIN_COUPON_REQUEST,
      types.DELETE_MAIN_COUPON_SUCCESS,
      types.DELETE_MAIN_COUPON_FAILURE,
    ],
  }
}

export const getAllCouponByUserId = (id, cityId) => {
  return {
    endpoint: endpoints.couponListByUser(id, cityId),
    method: GET_METHOD,
    types: [
      types.COUPON_LIST_BY_USER_LIST_REQUEST,
      types.COUPON_LIST_BY_USER_LIST_SUCCESS,
      types.COUPON_LIST_BY_USER_LIST_FAILURE,
    ],
  }
}

export const deleteCouponByUserId = (id, cityId) => {
  return {
    endpoint: endpoints.deleteCouponByUser(id, cityId),
    method: DELETE_METHOD,
    types: [
      types.DELETE_COUPON_BY_USER_REQUEST,
      types.DELETE_COUPON_BY_USER_SUCCESS,
      types.DELETE_COUPON_BY_USER_FAILURE,
    ],
  }
}

export const getWhiteLabel = () => {
  return {
    endpoint: endpoints.getWhiteLabelList(),
    method: GET_METHOD,
    types: [types.GET_WHITE_LABEL, types.GET_WHITE_LABEL_SUCCESS, types.GET_WHITE_LABEL_FAILURE],
  }
}

export const getPricingPlanDetails = (planId) => {
  return {
    endpoint: endpoints.getPricingPlanDetailsById(planId),
    method: GET_METHOD,
    types: [
      types.GET_PRICING_PLAN_DETAILS_REQUEST,
      types.GET_PRICING_PLAN_DETAILS_SUCCESS,
      types.GET_PRICING_PLAN_DETAILS_FAILURE,
    ],
  }
}
export const getDraftPricingPlanDetails = (planId) => {
  return {
    endpoint: endpoints.getDraftPricingPlanDetailsById(planId),
    method: GET_METHOD,
    types: [
      types.GET_PRICING_PLAN_DETAILS_REQUEST,
      types.GET_PRICING_PLAN_DETAILS_SUCCESS,
      types.GET_PRICING_PLAN_DETAILS_FAILURE,
    ],
  }
}

export const getPricingPlanFields = () => {
  return {
    endpoint: endpoints.getPricingPlanFields,
    method: GET_METHOD,
    types: [
      types.GET_PRICING_PLAN_FIELDS_REQUEST,
      types.GET_PRICING_PLAN_FIELDS_SUCCESS,
      types.GET_PRICING_PLAN_FIELDS_FAILURE,
    ],
  }
}

export const deletePricingPlanById = (id, isDraft) => {
  return {
    endpoint: isDraft ? endpoints.deleteDraftPricingPlan(id) : endpoints.deletePricingPlan(id),
    method: DELETE_METHOD,
    types: [
      types.DELETE_PRICING_PLAN_REQUEST,
      types.DELETE_PRICING_PLAN_SUCCESS,
      types.DELETE_PRICING_PLAN_FAILURE,
    ],
  }
}

export const getCityWhitelabelMappings = () => {
  return {
    endpoint: endpoints.getCityWhitelabelMappings,
    method: GET_METHOD,
    types: [
      types.GET_CITY_WHITELABEL_MAPPINGS_REQUEST,
      types.GET_CITY_WHITELABEL_MAPPINGS_SUCCESS,
      types.GET_CITY_WHITELABEL_MAPPINGS_FAILURE,
    ],
  }
}

export const createPricingPlan = (body) => {
  return {
    endpoint: endpoints.createPricingPlan,
    method: POST_METHOD,
    types: [
      types.CREATE_PRICING_PLAN_REQUEST,
      types.CREATE_PRICING_PLAN_SUCCESS,
      types.CREATE_PRICING_PLAN_FAILURE,
    ],
    body,
  }
}

export const updatePricingPlan = (id, body) => {
  return {
    endpoint: endpoints.updatePricingPlan(id),
    method: PUT_METHOD,
    types: [
      types.UPDATE_PRICING_PLAN_REQUEST,
      types.UPDATE_PRICING_PLAN_SUCCESS,
      types.UPDATE_PRICING_PLAN_FAILURE,
    ],
    body,
  }
}

export const clearPricingPlan = () => {
  return {
    type: types.CLEAR_PRICING_PLAN,
  }
}
