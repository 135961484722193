import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import ModalContainer from '../modal'
import { useStyles } from './styles'
import { strings } from '../../../I18n'
import CloseIcon from '@mui/icons-material/Close'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { DatePicker } from '@material-ui/pickers'

function CancelSubscriptionModal(props) {
  const {
    visible,
    cancelSubscriptionDate,
    setCancelSubscriptionDate,
    subscriptionType,
    setSubscriptionType,
    closeCancelSubscriptionModal,
    handleCancelSubscriptionRequest,
  } = props

  const classes = useStyles()

  return (
    <ModalContainer
      visible={visible}
      body={
        <>
          <Typography className={classes.modalTitle} align="center" variant="h5">
            {strings('buttons.cancelSubscription')}
          </Typography>
          <Divider className={classes.dividerStyle} light variant="middle" />
          <Card elevation={2} className={classes.card}>
            <CardContent>
              <Typography>{strings('setTheSubscriptionType')}</Typography>
              <RadioGroup
                name="subscription"
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
              >
                <FormControlLabel
                  value="immediate"
                  control={<Radio />}
                  label={strings('immediate')}
                />
                <FormControlLabel value="date" control={<Radio />} label={strings('selectADate')} />
                <div
                  style={subscriptionType === 'date' ? { display: 'block' } : { display: 'none' }}
                >
                  <DatePicker
                    label={strings('select')}
                    value={cancelSubscriptionDate}
                    onChange={(date) => setCancelSubscriptionDate(date)}
                    format="DD-MM-YYYY"
                  />
                </div>
              </RadioGroup>
            </CardContent>
          </Card>
          <Grid direction="row" container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={handleCancelSubscriptionRequest}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<ArrowUpwardIcon />}
                className={classes.buttonStyle}
              >
                {strings('buttons.submit')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={closeCancelSubscriptionModal}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CloseIcon />}
                className={classes.buttonStyle}
              >
                {strings('buttons.close')}
              </Button>
            </Grid>
          </Grid>
        </>
      }
    />
  )
}

export default CancelSubscriptionModal
