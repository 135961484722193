import React from 'react'
import './styles.scss'
import FilterDialog from '../filter'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { makeStyles, useTheme } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    paddingHorizontal: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  }
}

function GenericMultiInputFilter(props) {
  const { visible, hideFilter, disagree, agree, filterObject, setMultipleFilter } = props

  const classes = useStyles()
  const theme = useTheme()
  const [personName, setPersonName] = React.useState([])

  function handleChange(event, filterBy) {
    setPersonName(event.target.value)

    setMultipleFilter((prevState) => ({
      ...prevState,
      [filterBy]: event.target.value,
    }))
  }

  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        <>
          {filterObject &&
            filterObject?.map((singleFilter) => {
              return (
                <div
                  className={'filterItemContainer'}
                  key={`filterItemContainer-${singleFilter.filterBy}`}
                >
                  <InputLabel className={'filterInputLabel'}>
                    {singleFilter?.filterBy
                      ?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                      .toLowerCase()
                      .replace(/_/g, ' ')}
                  </InputLabel>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">
                      {`Select ` +
                        singleFilter?.filterBy
                          ?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                          .toLowerCase()
                          .replace(/_/g, ' ')}
                    </InputLabel>
                    <Select
                      multiple
                      value={personName}
                      onChange={(event) => {
                        handleChange(event, singleFilter?.filterBy)
                      }}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {singleFilter &&
                        singleFilter?.filterValue?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )
            })}
        </>
      }
    />
  )
}

export default GenericMultiInputFilter
