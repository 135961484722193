import React from 'react'
import './styles.scss'
import FilterDialog from '../filter'
import FilterItem from '../filterItem'
import {
  FILTER_BY_STATUS,
  FILTER_BY_LOCK_STATUS,
  FILTER_BY_BRAND,
} from '../../../constants/filterConst'

function BikeFilter(props) {
  const {
    visible,
    hideFilter,
    changeSelectorValue,
    agree,
    disagree,
    filterProps,
    statuses = [],
    brands = [],
    lockStatuses = [],
  } = props

  const handleChangeSelectorValue = (filter, filterBy) => changeSelectorValue(filter, filterBy)

  return (
    <FilterDialog
      visible={visible}
      hideDialog={hideFilter}
      agree={agree}
      disagree={disagree}
      body={
        <>
          <FilterItem
            filterBy={FILTER_BY_STATUS}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={statuses.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
          <FilterItem
            filterBy={FILTER_BY_BRAND}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={brands.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
          <FilterItem
            filterBy={FILTER_BY_LOCK_STATUS}
            changeSelectorValue={handleChangeSelectorValue}
            selectorItems={lockStatuses.map((item, index) => ({
              id: index,
              name: item,
            }))}
            filterProps={filterProps}
          />
        </>
      }
    />
  )
}

export default BikeFilter
