import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import { firebaseLogin, setupRecaptcha, verifyLoginOTP } from '../../utils/firebase'
import * as routes from '../../constants/routes'
import { clearErrorMessage, logIn, logOut, saveToken } from '../../actions/auth'
import { strings } from '../../I18n'
import { isMobile } from 'react-device-detect'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useStyles } from '../../utils/styles'
import * as Sentry from '@sentry/browser'

function LoginScreen(props) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [login, setLogin] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [needOTP, setNeedOTP] = React.useState(false)
  const [otp, setOtp] = React.useState()
  const [isCapchaFilled, setIsCapchaFilled] = React.useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    props.logOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeLogin = ({ target }) => setLogin(target.value)

  const changePassword = ({ target }) => setPassword(target.value)

  const loginSuccess = (user) => {
    if (user) {
      Sentry.setUser({ email: user?.user?.email })
      user.user.getIdToken().then((token) => {
        if (token) {
          props.clearErrorMessage()
          props.saveToken(token)
          props.logIn(user)
          setTimeout(() => {
            navigate(routes.DASHBOARD_SCREEN)
            setLoading(false)
          }, 0)
        }
      })
    }
  }

  useEffect(() => {
    setIsCapchaFilled(false)
    setupRecaptcha(() => {
      setMessage('')
      setIsCapchaFilled(true)
    })
  }, [])

  // const loginError = (error) => {
  //   setMessage(error.message)
  //   setLoading(false)
  //   setTimeout(() => setMessage(''), 5000)
  // }

  const signIn = () => {
    // setLoading(true)
    if (!isCapchaFilled) {
      setMessage('Please fill out the captcha')
      return
    }
    !needOTP
      ? firebaseLogin(
          login,
          password,
          (user) => {
            loginSuccess(user)
          },
          () => {
            setNeedOTP(true)
          },
          (err) => {
            setMessage(err.message)
          }
        )
      : verifyLoginOTP(otp)
          .then((user) => {
            loginSuccess(user)
          })
          .catch((err) => {
            setMessage(err.message)
          })
  }

  return (
    <div className={isMobile ? 'mobileApp' : 'app'}>
      <div className={'authBlock'}>
        <div className={'inputBlock'}>
          <p className={'textDescription'}>{strings('placeholders.email')}:</p>
          <input
            className={'input'}
            placeholder={strings('placeholders.email')}
            type={'email'}
            name={'text'}
            onChange={changeLogin}
          />
        </div>
        <div className={'inputBlock'}>
          <p className={'textDescription'}>{strings('placeholders.password')}:</p>
          <input
            className={'input'}
            placeholder={strings('placeholders.password')}
            type={'password'}
            accept={'password'}
            onChange={changePassword}
          />
        </div>
        {needOTP && (
          <div className={'inputBlock'}>
            <p className={'textDescription'}>{'Enter OTP'}:</p>
            <input
              className={'input'}
              placeholder={'OTP'}
              type={'number'}
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value)
              }}
            />
          </div>
        )}
        <p className={'message'}>{message}</p>
        <div id="recaptcha-container"></div>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            disabled={loading}
            variant="outlined"
            className={'button'}
            onClick={signIn}
          >
            {needOTP ? strings('buttons.verify') : strings('buttons.signIn')}
          </Button>
          <Link className={'link'} to={routes.FORGOT_PASSWORD_SCREEN}>
            <p className={'item'}>{strings('buttons.resetPassword')}</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  logIn,
  saveToken,
  logOut,
  clearErrorMessage,
}

export default connect(null, mapDispatchToProps)(LoginScreen)
