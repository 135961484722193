import { apiClientJSONAssets } from '../apiClient'
import { AssetBooking, AssetBookingDto, AssetProject } from '../../domain/asset'

export const getAssetBookings = (): Promise<AssetBooking[]> => {
  return apiClientJSONAssets
    .get(`/booking`)
    .then((r) => r.data)
    .then((items: any[]) =>
      items.map<AssetProject>((i) => ({
        ...i,
        startDate: new Date(i.startDate),
        endDate: new Date(i.endDate),
      }))
    )
}

export const createAssetBooking = (dto: AssetBookingDto): Promise<void> => {
  return apiClientJSONAssets.post(`/booking`, dto)
}

export const deleteAssetBooking = (bookingId: number): Promise<void> => {
  return apiClientJSONAssets.delete(`/booking/${bookingId}`)
}
