import React from 'react'
import './styles.scss'
import { useStyles } from '../../../utils/styles'
import Pagination from '@mui/lab/Pagination'

function TablePaginationActionsCustom(props) {
  const classes = useStyles()
  const { count, page, onPageChange, rowsPerPage } = props

  const handleChange = (event, value) => {
    onPageChange(value)
  }

  return (
    <div className={classes.rootPaginator}>
      <Pagination
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={handleChange}
      />
    </div>
  )
}

export default TablePaginationActionsCustom
