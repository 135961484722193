/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './styles.scss'
import ModalContainer from '../modal'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { strings } from '../../../I18n'
import ModalText from '../modalTextField'
import ClickedBlock from '../clickedBlock'
import { SketchPicker } from 'react-color'
import { useStyles } from '../../../utils/styles'
import { useInput, useShowToast } from '../../../hooks'
import { TimePicker } from '@material-ui/pickers'
import Moment from 'moment'
import { timeInputStyles } from './style'
import MenuItem from '@mui/material/MenuItem'
import Dropzone from 'react-dropzone'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { imageUploader } from '../../../utils/firebase'
import Switch from '@mui/material/Switch'
import { FormControlLabel } from '@mui/material'
import { getWhiteLabel } from '../../../actions/cities'
import { connect } from 'react-redux'
import { isSuperAdmin } from '../../../utils/utils'
import momentTimezone from 'moment-timezone'
import TextField from '@mui/material/TextField/TextField'
import Autocomplete from '@mui/lab/Autocomplete'

const menuStatusItems = [
  strings('descriptions.active'),
  strings('descriptions.disabled'),
  strings('descriptions.deleted'),
  strings('descriptions.inactive'),
]

const closeStatusItems = ['WINTER', 'MAINTENANCE', 'TEMPORARILY', 'PERMANENTLY']

function CityModal(props) {
  const [loading, setLoading] = useState(false)

  const { city, visible, cityAction, clearCity, closeCityModal } = props
  const [cityId, setCityId] = useState('')
  const [cityLatitude, setCityLatitude] = useState('')
  const [cityLongitude, setCityLongitude] = useState('')
  const [vat, setVat] = useState('')
  const [cityName, setCityName] = useState('')
  const [cityColor, setCityColor] = useState('')
  const [cityStatus, setCityStatus] = useState('')
  const [cityStatusModalVisible, setModalVisible] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [bikeIcon, setBikeIcon] = useState('')
  const [bikeIconError, setBikeIconError] = useState('')
  const [eBikeIcon, setEBikeIcon] = useState('')
  const [eBikeIconError, setEBikeIconError] = useState('')
  const [scooterIcon, setScooterIcon] = useState('')
  const [scooterIconError, setScooterIconError] = useState('')
  const [cityIcon, setCityIcon] = useState('')
  const [cityIconError, setCityIconError] = useState('')
  const [privacyUrl, setPrivacyUrl] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactAddress, setContactAddress] = useState('')
  const [howItWorksUrl, setHowItWorksUrl] = useState('')
  const [domains, setDomains] = useState('')
  const [termsUrl, setTermsUrl] = useState('')
  const [supportUrl, setSupportUrl] = useState('')
  const [faqUrl, setFaq] = useState('')
  const [supportEmail, setSupportEmail] = useState('')
  const [publicAccessEnabled, setPublicAccessEnabled] = useState(false)
  const [isPhotoFinishEnabled, setIsPhotoFinishEnabled] = useState(false)
  const [isMailVerified, setIsMailVerified] = useState(false)
  const [whiteLabelId, setWhitelabelId] = useState(null)
  const [whiteLabelName, setWhitelabelName] = useState('')

  const [serverTimeZone, setServerTimeZone] = useState('')

  const [discoveryUrlAndroid, setDiscoveryUrlAndroid] = useState('')
  const [discoveryUrlIos, setDiscoveryUrlIos] = useState('')
  const [storeUriIos, setStoreUriIos] = useState('')
  const [storeUriAndroid, setStoreUriAndroid] = useState('')

  const cReason = useInput()
  const cWhiteLabel = useInput()
  const timezones = useInput()

  const [operationalHourStartsAt, setOperationalHourStartsAt] = useState(Moment())
  const [operationalHourEndsAt, setOperationalHourEndsAt] = useState(Moment())

  const classes = useStyles()
  const timeClasses = timeInputStyles()

  const showToast = useShowToast()

  useEffect(() => {
    cReason.initValue(city?.closeReason)
    // eslint-disable-line
  }, [visible])

  useEffect(() => {
    if (props.whiteLabels && city) {
      const whiteLabelRes = props.whiteLabels.filter((el) => el.id === city?.whiteLabelId)
      setWhitelabelName(cWhiteLabel.value)
      cWhiteLabel.initValue(whiteLabelRes[0].name)
    }
  }, [visible, city, props.whiteLabels])

  useEffect(() => {
    props.getWhiteLabel()
  }, [])

  useEffect(() => {
    setCityId(city?.id)
    setCityName(city ? city.name : '')
    setCityColor(city?.color ? city?.color : '#ffffff')

    setCityStatus(city?.status)

    setCityLatitude(city?.latitude ? city?.latitude : '')
    setCityLongitude(city?.longitude ? city?.longitude : '')
    setVat(city?.vat ? city?.vat : '0')
    setCompanyName(city?.company?.name || '')
    setCompanyAddress(city?.company?.address || '')
    setCompanyPhone(city?.company?.phone || '')
    setCompanyEmail(city?.company?.email || '')
    setBikeIcon(city?.bikeIconUrl || '')
    setEBikeIcon(city?.electricBikeIconUrl || '')
    setScooterIcon(city?.scooterIconUrl || '')
    // setCityIcon(city?.iconUrl || '')
    setCityIcon(city?.iconUrl || '')
    setPrivacyUrl(city?.privacyUrl || '')
    setContactPhone(city?.contactPhone || '')
    setContactAddress(city?.contactAddress || '')
    setHowItWorksUrl(city?.howItWorksUrl || '')
    setDomains(city?.domains || '')
    setTermsUrl(city?.termsUrl || '')
    setSupportUrl(city?.supportUrl || '')
    setFaq(city?.faqUrl || '')
    setOperationalHourStartsAt(
      (city?.operationalHourEndsAt && Moment(city?.operationalHourStartsAt, 'HH:mm')) ||
        Moment('00:00', 'HH:mm')
    )
    setOperationalHourEndsAt(
      (city?.operationalHourEndsAt && Moment(city?.operationalHourEndsAt, 'HH:mm')) ||
        Moment('23:59', 'HH:mm')
    )
    setSupportEmail(city?.supportEmail)
    setIsPhotoFinishEnabled(city?.photoFinish || false)
    setIsMailVerified(city?.mailVerificationRequired || false)
    setWhitelabelId(city?.whiteLabelId)
    setWhitelabelName('')
    setServerTimeZone(city?.timezone)
    setPublicAccessEnabled(city?.publicAccessEnabled)

    setDiscoveryUrlIos(city?.rentalUri?.ios?.discovery_uri || '')
    setDiscoveryUrlAndroid(city?.rentalUri?.android?.discovery_uri || '')
    setStoreUriIos(city?.rentalUri?.ios?.store_uri || '')
    setStoreUriAndroid(city?.rentalUri?.android?.store_uri || '')
  }, [city])

  // useEffect(()=>{
  //   for(let i = 0; i< props.whiteLabels.length; i++){
  //     if(props.whiteLabels[i].id == whiteLabelId){
  //       setWhitelabelName(props.whiteLabels[i].name)
  //       break;
  //     }
  //   }
  // },[whiteLabelId])

  const ActionButton = withStyles({
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
  })(Button)

  const handleChange = (color) => setCityColor(color.hex)

  const hideCityStatusModal = () => setModalVisible(false)

  const changeCityStatus = () => setModalVisible(!cityStatusModalVisible)

  const changeCityName = ({ target }) => setCityName(target.value)

  const changeCityLatitude = ({ target }) => setCityLatitude(target.value)

  const changeCityLongitude = ({ target }) => setCityLongitude(target.value)

  const changeCityColor = ({ target }) => setCityColor(target.value)

  const changeVat = ({ target }) => setVat(target.value)

  const changeCompanyName = ({ target }) => setCompanyName(target.value)
  const changeCompanyAddress = ({ target }) => setCompanyAddress(target.value)
  const changeCompanyPhone = ({ target }) => setCompanyPhone(target.value)
  const changeCompanyEmail = ({ target }) => setCompanyEmail(target.value)
  const chnagePublicAccessEnabled = ({ target }) =>
    setPublicAccessEnabled((prevState) => !prevState)

  const changeDiscoveryUrlAndroid = ({ target }) => setDiscoveryUrlAndroid(target.value)
  const changeDiscoveryUrlIos = ({ target }) => setDiscoveryUrlIos(target.value)
  const changeStoreUriIos = ({ target }) => setStoreUriIos(target.value)
  const changeStoreUriAndroid = ({ target }) => setStoreUriAndroid(target.value)

  const changeWhiteLabelId = ({ target }) => {
    setWhitelabelName(target.value)
    for (let i = 0; i < props.whiteLabels.length; i++) {
      if (props.whiteLabels[i].name == target.value) {
        setWhitelabelId(props.whiteLabels[i].id)
        break
      }
    }
  }

  const changeServerTimeZone = (event, value) => setServerTimeZone(value)

  const changeBikeIconUrl = (data, err) => {
    if (data) {
      setBikeIcon(data)
      setBikeIconError('')
    } else {
      setBikeIconError(err)
    }
  }
  const changeEBikeIconUrl = (data, err) => {
    if (data) {
      setEBikeIcon(data)
      setEBikeIconError('')
    } else {
      setEBikeIconError(err)
    }
  }
  const changeScooterIconUrl = (data, err) => {
    if (data) {
      setScooterIcon(data)
      setScooterIconError('')
    } else {
      setScooterIconError(err)
    }
  }
  const changeCityIconUrl = (data, err) => {
    if (data) {
      setCityIcon(data)
      setCityIconError('')
    } else {
      setCityIconError(err)
    }

    // setCityIcon(data.map(file => Object.assign(file, {
    //   preview: URL.createObjectURL(file)
    // })));
  }
  const changePrivacyUrl = ({ target }) => setPrivacyUrl(target.value)
  const changeTermsUrl = ({ target }) => setTermsUrl(target.value)
  const changeFaqUrl = ({ target }) => setFaq(target.value)
  const changeSupportUrl = ({ target }) => setSupportUrl(target.value)
  const changeContactPhone = ({ target }) => setContactPhone(target.value)
  const changeContacAddress = ({ target }) => setContactAddress(target.value)
  const changeHowItWorksUrl = ({ target }) => setHowItWorksUrl(target.value)
  const changeDomains = ({ target }) => setDomains(target.value)
  const changeSupportEmail = ({ target }) => setSupportEmail(target.value)

  const selectStatus = (status) => {
    setCityStatus(status)
    hideCityStatusModal()
  }

  const save = () => {
    let newCityIcon = ''
    let newBikeIcon = ''
    let newEBikeIcon = ''
    let newScooterIcon = ''
    if (cityStatus.toUpperCase() === 'INACTIVE') {
      if (!!!cReason.value) {
        showToast('You must provide a reason', 'error')
        return
      }
    }

    if (!companyName || !companyAddress || !companyEmail || !companyEmail) {
      showToast('Fill company info', 'error')
    } else if (!serverTimeZone) {
      showToast('Select server time zone', 'error')
    } else if (!vat || !cityName) {
      showToast('Fill the city data completely', 'error')
    } else if (!bikeIcon || !cityIcon) {
      showToast('Fill icons url please', 'error')
    } else if (bikeIconError || eBikeIconError || scooterIconError || cityIconError) {
      showToast('Invalid image file', 'error')
    } else {
      setLoading(!loading)
      const p1 = imageUploader(`cities_images/${city?.code}/iconUrl`, cityIcon.file)
      const p2 = imageUploader(`cities_images/${city?.code}/bikeIconUrl`, bikeIcon.file)
      const p3 = imageUploader(`cities_images/${city?.code}}/electricBikeIconUrl`, eBikeIcon.file)
      const p4 = imageUploader(`cities_images/${city?.code}/scooterIconUrl`, scooterIcon.file)

      Promise.all([p1, p2, p3, p4]).then((res) => {
        newCityIcon = res[0]
        newBikeIcon = res[1]
        newEBikeIcon = res[2]
        newScooterIcon = res[3]

        const body = {
          color: cityColor,
          name: cityName,
          status: cityStatus.toUpperCase(),
          closeReason: cReason.value,
          longitude: cityLongitude,
          latitude: cityLatitude,
          vat,
          company: {
            name: companyName,
            address: companyAddress,
            email: companyEmail,
            phone: companyPhone,
          },
          iconUrl: newCityIcon || cityIcon,
          bikeIconUrl: newBikeIcon || bikeIcon,
          electricBikeIconUrl: newEBikeIcon || eBikeIcon,
          scooterIconUrl: newScooterIcon || scooterIcon,
          privacyUrl,
          contactPhone,
          contactAddress,
          howItWorksUrl,
          domains,
          termsUrl,
          supportUrl,
          faqUrl,
          operationalHourStartsAt: operationalHourStartsAt.format('HH:mm'),
          operationalHourEndsAt: operationalHourEndsAt.format('HH:mm'),
          supportEmail: supportEmail,
          photoFinish: isPhotoFinishEnabled,
          whiteLabelId,
          mailVerificationRequired: isMailVerified,
          timezone: serverTimeZone ? serverTimeZone : null,
          publicAccessEnabled: publicAccessEnabled,
          rentalUri: {
            android: {
              discovery_uri: discoveryUrlAndroid,
              store_uri: storeUriAndroid,
            },
            ios: {
              discovery_uri: discoveryUrlIos,
              store_uri: storeUriIos,
            },
          },
        }
        city ? cityAction(city.id, body) : cityAction(body)
      })

      setLoading(!loading)
    }
  }

  const discard = () => {
    setCityName(city ? city.name : '')
    setCityColor(city ? city.color : '#ffffff')
    setCityStatus(city ? city.status : strings('descriptions.setStatus'))
    closeCityModal()
    setCityIconError('')
    setBikeIconError('')
    setEBikeIconError('')
    setScooterIconError('')
    clearCity()
  }
  const renderInput = (title, value, onChange) => (
    <ModalText
      customStyle={{ width: '95%', marginBottom: 10 }}
      label={title}
      value={value}
      onChange={onChange}
      multiline={true}
    />
  )

  const renderFile = (title, error, url, onChange) => (
    <div>
      <p>{title}</p>
      <Dropzone
        multiple={false}
        onDrop={(val) => {
          // onChange(val[0].name)

          if (
            val[0].name.toLowerCase().includes('png') ||
            val[0].name.toLowerCase().includes('jpg') ||
            val[0].name.toLowerCase().includes('jpeg')
          ) {
            const obj = URL.createObjectURL(val[0])
            onChange({ file: val[0], preview: obj })
          } else {
            onChange(null, 'Please enter a valid jpg/jpeg/png file')
            return
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div>
              <div className={'dropzone'} {...getRootProps()}>
                <input {...getInputProps()} />
                {/* {value ||  (<div>
                  <AddCircleOutlineRoundedIcon />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>)
                } */}

                {url ? (
                  <div>
                    <img alt={'image'} className={'image'} src={url.preview || url} />
                    <p>{strings('clickToChangeImage')}</p>
                  </div>
                ) : (
                  <div style={{ paddingTop: '50px' }}>
                    <AddCircleOutlineRoundedIcon />
                    <p>{strings('pleaseAddJpgjpegpngFiles')}</p>
                  </div>
                )}
              </div>
            </div>

            {error ? <p style={{ color: 'red' }}>{error}</p> : null}
          </section>
        )}
      </Dropzone>
    </div>
  )

  const renderCheckBox = (label, mainState, handleCheckBoxChange) => {
    return (
      <div style={{ paddingTop: 10, paddingBottom: 20 }}>
        <FormControlLabel
          value="start"
          checked={mainState}
          onChange={(event) => {
            handleCheckBoxChange(event.target.checked)
          }}
          control={<Switch color="primary" />}
          label={label}
          labelPlacement="start"
        />
      </div>
    )
  }

  const changeIsPhotoFinishEnabled = () => {
    setIsPhotoFinishEnabled(!isPhotoFinishEnabled)
  }

  const changeIsMailVerificationRequired = () => {
    setIsMailVerified(!isMailVerified)
  }

  const inputBlock = () => (
    <div className={'cityInputBlock'} style={{ height: window.innerHeight * 0.5 }}>
      {renderInput(strings('descriptions.id'), cityId ? cityId : 0, null)}
      {props.whiteLabels && isSuperAdmin(props.adminDetails) && (
        <ModalText
          customStyle={{ width: '95%', marginBottom: 10 }}
          label={'WhiteLabel'}
          content={props.whiteLabels.map((item) => (
            <MenuItem key={item?.id} value={item?.name}>
              {item.name}
            </MenuItem>
          ))}
          {...cWhiteLabel}
          value={whiteLabelName}
          onChange={changeWhiteLabelId}
        />
      )}
      {renderInput(strings('descriptions.name'), cityName, changeCityName)}
      {renderInput(strings('descriptions.latitude'), cityLatitude, changeCityLatitude)}
      {renderInput(strings('descriptions.longitude'), cityLongitude, changeCityLongitude)}
      {renderInput(`${strings('descriptions.vat').toUpperCase()}, %`, vat, changeVat)}
      {renderInput(strings('descriptions.companyName'), companyName, changeCompanyName)}
      {renderInput(strings('descriptions.companyAddress'), companyAddress, changeCompanyAddress)}
      {renderInput(strings('descriptions.companyPhone'), companyPhone, changeCompanyPhone)}
      {renderInput(strings('descriptions.companyEmail'), companyEmail, changeCompanyEmail)}
      {renderFile(strings('descriptions.iconUrl'), cityIconError, cityIcon, changeCityIconUrl)}
      {renderFile(strings('descriptions.bikesUrl'), bikeIconError, bikeIcon, changeBikeIconUrl)}
      {renderFile(strings('descriptions.eBikesUrl'), eBikeIconError, eBikeIcon, changeEBikeIconUrl)}
      {renderFile(
        strings('descriptions.scooterUrl'),
        scooterIconError,
        scooterIcon,
        changeScooterIconUrl
      )}
      {renderInput(strings('descriptions.privacyUrl'), privacyUrl, changePrivacyUrl)}
      {renderInput(strings('descriptions.termsUrl'), termsUrl, changeTermsUrl)}
      {renderInput(strings('descriptions.supportUrl'), supportUrl, changeSupportUrl)}
      {renderInput(strings('descriptions.howItWorksUrl'), howItWorksUrl, changeHowItWorksUrl)}
      {renderInput(strings('descriptions.faqUrl'), faqUrl, changeFaqUrl)}
      {renderInput(strings('descriptions.contactPhone'), contactPhone, changeContactPhone)}
      {renderInput(strings('descriptions.contactAddress'), contactAddress, changeContacAddress)}
      {renderInput(strings('descriptions.supportEmail'), supportEmail, changeSupportEmail)}

      {renderInput(strings('descriptions.domains'), domains, changeDomains)}

      {
        <div style={{ marginBottom: 15 }}>
          <Autocomplete
            id="combo-box-demo"
            options={momentTimezone.tz.names()}
            getOptionLabel={(option) => option}
            style={{ width: '95%' }}
            value={serverTimeZone}
            onChange={changeServerTimeZone}
            renderInput={(params) => (
              <TextField {...params} label={strings('descriptions.timezone')} variant="outlined" />
            )}
          />
        </div>
      }

      <TimePicker
        className={timeClasses.timeInput}
        inputVariant="outlined"
        ampm={false}
        format="HH:mm"
        label={strings('descriptions.operationalHourStart')}
        value={operationalHourStartsAt}
        onChange={(value) => setOperationalHourStartsAt(value)}
      />
      <TimePicker
        className={timeClasses.timeInput}
        inputVariant="outlined"
        ampm={false}
        format="HH:mm"
        label={strings('descriptions.operationalHourEnd')}
        value={operationalHourEndsAt}
        onChange={(value) => setOperationalHourEndsAt(value)}
      />
      <div className={'cityInputWithColor'}>
        <div className={'colorInput'}>
          <ModalText
            label={strings('descriptions.color')}
            value={cityColor}
            onChange={changeCityColor}
          />
        </div>
        <div className={'changeColorContainer'} style={{ backgroundColor: cityColor }} />
      </div>
      <div style={{ width: '95%' }}>
        <ClickedBlock
          description={strings('descriptions.status')}
          title={cityStatus ? cityStatus : strings('descriptions.setStatus')}
          action={changeCityStatus}
        />
      </div>
      {cityStatus === 'INACTIVE' && (
        <ModalText
          customStyle={{ width: '95%', marginBottom: 10 }}
          label={strings('descriptions.closeMessage')}
          content={
            // <MenuItem key={'WINTER'} value={'WINTER'}>
            //   SUMMER
            // </MenuItem>
            closeStatusItems.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))
          }
          {...cReason}
        />
      )}

      {renderCheckBox(
        strings('descriptions.isPhotoFinishEnabled'),
        isPhotoFinishEnabled,
        changeIsPhotoFinishEnabled
      )}
      {renderCheckBox(
        strings('descriptions.isMailVerificationRequired'),
        isMailVerified,
        changeIsMailVerificationRequired
      )}

      {renderCheckBox(
        strings('descriptions.publicAccessEnabled'),
        publicAccessEnabled,
        chnagePublicAccessEnabled
      )}

      {renderInput(
        strings('descriptions.discoveryUrlAndroid'),
        discoveryUrlAndroid,
        changeDiscoveryUrlAndroid
      )}

      {renderInput(strings('descriptions.storeUrlAndroid'), storeUriAndroid, changeStoreUriAndroid)}

      {renderInput(strings('descriptions.discoveryUrlIos'), discoveryUrlIos, changeDiscoveryUrlIos)}

      {renderInput(strings('descriptions.storeUrlIos'), storeUriIos, changeStoreUriIos)}
    </div>
  )

  const colorPickerBlock = () => (
    <div>
      <div className={'cityColorPickerBlock'}>
        <SketchPicker color={cityColor} onChange={handleChange} />
      </div>
    </div>
  )

  const changeCityStatusModal = () => (
    <ModalContainer
      visible={cityStatusModalVisible}
      closeAction={hideCityStatusModal}
      body={
        <div>
          {menuStatusItems.map((status, index) => (
            <Button
              key={index}
              className={'stationModalStatusButton'}
              variant="outlined"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => selectStatus(status.toUpperCase())}
            >
              {status}
            </Button>
          ))}
        </div>
      }
    />
  )

  const buttonContainer = () => (
    <div className={'stationModalButtonsContainer'}>
      <ActionButton
        className={'stationModalActionButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={save}
        disabled={loading}
      >
        {city ? strings('descriptions.save') : strings('descriptions.create')}
      </ActionButton>
      <ActionButton
        className={'stationModalActionButton'}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={discard}
      >
        {strings('descriptions.discard')}
      </ActionButton>
    </div>
  )

  return (
    <ModalContainer
      visible={visible}
      closeAction={discard}
      className={classes.colorModal}
      body={
        <div>
          <div className={'cityEditBlock'}>
            {inputBlock()}
            {colorPickerBlock()}
          </div>
          {buttonContainer()}
          {changeCityStatusModal()}
        </div>
      }
    />
  )
}

const mapStateToProps = ({
  auth: { adminDetails },
  cities: { whiteLabels, getWhiteLabelSuccess },
}) => ({
  whiteLabels,
  getWhiteLabelSuccess,
  adminDetails,
})

const mapDispatchToProps = {
  getWhiteLabel,
}

// export default CityModal

export default connect(mapStateToProps, mapDispatchToProps)(CityModal)
